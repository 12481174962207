<h2 class="content-block">Profil</h2>

<div class="content-block dx-card responsive-paddings">
    <dx-form
        id="form"
        [formData]="korisnik"
        [showColonAfterLabel]="true"
        [colCount]="2">
            <dxi-item itemType="group" caption="Opće informacije">
                <dxi-item
                    dataField="kor_punoIme"
                    [label]="{text: 'Ime i prezime'}"
                    [editorOptions]="{stylingMode:'filled'}">
                    <dxi-validation-rule
                        type="required"
                        message="Ime i prezime su obavezno polje!">
                    </dxi-validation-rule>
                </dxi-item>
                <dxi-item
                    dataField="kor_oib"
                    [label]="{text: 'OIB'}"
                    [editorOptions]="{readOnly: true,stylingMode:'filled'}">
                    <dxi-validation-rule
                        type="required"
                        message="OIB je obavezno polje!"
                        *ngIf="pravaApi == 3">
                    </dxi-validation-rule>
                    <dxi-validation-rule
                        type="stringLength"
                        [min]="11"
                        [max]="11"
                        message="OIB treba imati točno 11 znakova!"
                        *ngIf="pravaApi == 3">
                    </dxi-validation-rule>
                  <!--  <dxi-validation-rule
                        type="custom"
                        [validationCallback]="provjeraOIB"
                        message="Navedeni OIB se već koristi!">
                    </dxi-validation-rule> -->
                </dxi-item>
                <dxi-item
                    dataField="kor_kvalifikacije" 
                    [label]="{text: 'Kvalifikacija'}"
                    [editorOptions]="{stylingMode:'filled'}"
                    *ngIf="pravaApi == 2">
                </dxi-item>
                <dxi-item
                    dataField="kor_email"
                    [label]="{text: 'E-mail'}"
                    [editorOptions]="{stylingMode:'filled'}" >
                    <dxi-validation-rule
                    type="required"
                    message="E-mail je obavezno polje!">
                    </dxi-validation-rule>
                    <dxi-validation-rule
                    type="email"
                    message="Neispravan format e-maila!">
                    </dxi-validation-rule>
                </dxi-item>
                <dxi-item
                    dataField="kor_telefon"
                    [label]="{text: 'Telefon'}"
                    [editorOptions]="{stylingMode:'filled'}">
                </dxi-item>
            </dxi-item>
            <dxi-item itemType="group" caption="Podaci za prijavu">
                <dxi-item
                    dataField="kor_korisnickoIme"
                    [label]="{text: 'Korisničko ime'}"
                    [editorOptions]="{readOnly: true,stylingMode:'filled'}">
                    <dxi-validation-rule
                        type="required"
                        message="Korisničko ime je obavezno polje!">
                    </dxi-validation-rule>
                </dxi-item>
                <dxi-item dataField="novaLozinka"
                          mode="password"
                          [label]="{text: 'Nova lozinka'}"
                          [editorOptions]="{stylingMode:'filled'}" >
                          <!-- <dxi-validation-rule
                            type="required"
                            message="Unesite lozinku!">
                          </dxi-validation-rule> -->
                          <dxi-validation-rule
                            type="stringLength"
                            [min]="5"
                            [max]="20"
                            message="Lozinka treba imati između 5 i 20 znakova!">
                          </dxi-validation-rule>
                </dxi-item>
            </dxi-item>
    </dx-form>
    <!-- ToolTip -->
    <dx-tooltip
        position="bottom"
        showEvent="mouseenter"
        hideEvent="mouseleave"
        target="label[for*='kor_korisnickoIme']">
        <div *dxTemplate="let data = data of 'content'">
           Korisničko se ne može promijeniti!
        </div>
    </dx-tooltip>
    <dx-tooltip
        position="bottom"
        showEvent="mouseenter"
        hideEvent="mouseleave"
        target="label[for*='kor_oib']">
        <div *dxTemplate="let data = data of 'content'">
           OIB se ne može promijeniti!
        </div>
    </dx-tooltip>
    <!-- Button -->
    <dx-button
        text="Uredi"
        (onClick)="urediProfil(korisnik)"
        type="default">
    </dx-button>
</div>