import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ApiService } from 'src/app/shared/services';

import * as moment from 'moment';

import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import notify from 'devextreme/ui/notify';
import { custom } from 'devextreme/ui/dialog';
import { encodeHtml } from 'devextreme/core/utils/string';
import { IdUcionicaService } from 'src/app/shared/services/id-ucionica.service';

@Component({
  selector: 'app-ucionica-polaznik',
  templateUrl: './ucionica-polaznik.component.html',
  styleUrls: ['./ucionica-polaznik.component.scss']
})

export class UcionicaPolaznikComponent implements OnInit {

  access_token: any = localStorage.getItem('access_token');
  localTab = localStorage.getItem('tab');

  ucionicaNaziv: any;
  datotekeURL = this._api.datotekeURL;
  predmeti: any;
  korisnikID: any;
  ucionicaID: any;
  tabovi = [
    { id: 0, text: 'Predmeti' },
    { id: 1, text: 'Predavanja' },
    { id: 2, text: 'Ispitni rokovi' },
    { id: 3, text: 'Kalendar' },
    { id: 4, text: 'Obavijesti' },
    { id: 5, text: 'Chat' }
  ];
  odabraniTab: number = 0;
  currentDate: Date = new Date();
  daysViewStartDate: Date = this.currentDate;
  weeksViewStartDate: Date = this.currentDate;
  predavanja = [];
  DataSourceStorage: any = [];
  materijali: any;
  vimeoURL: string;
  prikaziVideo: boolean = false;
  odaberiVrstuKviza: boolean = false;
  predmetID: any;
  predmet: any;
  window: any;
  prikaziLoading = false;
  rokovi: any;
  obavijesti = [];
  danas = new Date().toISOString();
  danasnjiDan: any;
  selectedIndex = 0;
  predavanjaKalendar: any;
  predavanjaiRokoviKalendar: any;
  // prikazIspita: Array<{id: Number, text: String}> = [
  //   {id: 0, text: 'Riješeni ispiti'},
  //   {id: 1, text: 'Novi ispit'}
  // ]
  
  constructor(
    private _api: ApiService,
    private _activatedRoute: ActivatedRoute,
    private _idUcionicaService: IdUcionicaService,
    private _router: Router
  ) {
    this.korisnikID = parseInt(localStorage.getItem('idkorisnik'));
    this.ucionicaID = this._activatedRoute.snapshot.params['id'];
    this.ucionicaNaziv = this._activatedRoute.snapshot.params['naziv'];
    // this.danasnjiDan = moment(this.danas).format('DD.MM.YYYY hh:mm:ss');
    // this.danasnjiDan = moment(this.danas).format();
    this.danasnjiDan = this.danas + 1;
    if(this.localTab === '2'){
      this.selectedIndex = 2;
      this.odabraniTab = 2;
      this.dohvatiIspitneRokove();
    } else {
      this.selectedIndex = 0;
    }
   }

  ngOnInit() {
    this.predmetiPolaznika();
    this.materijaliPredmeta();
  }

  prikaziKvizove(vrijednost){
    // console.log(this.predmetID, this.ucionicaID, vrijednost)
     this._router.navigate(['kvizovi/' + this.ucionicaID + '/' + this.predmetID + '/' + vrijednost]);
  }

  // ispiti(e){
  //   this._router.navigate(['ispiti/' + this.ucionicaID + '/' + e.data.idpredmet]);
  // }

  kvizovi(e){
    // console.log(e)
    this.predmet = e.data.pred_naziv;
    this.predmetID = e.data.idpredmet;
    this.odaberiVrstuKviza = true;
    // console.log(this.predmet)
    // this._router.navigate(['kvizovi/' + this.ucionicaID + '/' + e.data.idpredmet]);
  }

  selectTab(e){
    localStorage.removeItem('tab');
    this.odabraniTab = e.itemIndex;
    if(this.odabraniTab == 1) {
      this.popisPredavanja();
    } else if(this.odabraniTab == 2 || this.odabraniTab == 3){
      this.dohvatiIspitneRokove();
      // console.log(this.danasnjiDan)
    } else if (this.odabraniTab == 4){
      this.popisObavijesti();
    } else if (this.odabraniTab === 5){
      this._idUcionicaService.posaljiIdUcionica(this.ucionicaID);
    }
  }

  hover(e){
    if (e.rowType === "data" && e.column.dataField === "pmat_naziv"){ 
      if (e.eventType == 'mouseover'){
        e.cellElement.style.fontWeight = "bold";
        e.cellElement.style.fontSize = "18px";
        e.cellElement.style.cursor = 'pointer';
      } else {
        e.cellElement.style.fontWeight = "normal";
        e.cellElement.style.fontSize = "14px";
        }  
    }
  }

  cellClickEvent(e){
    if (e.rowType == "data" && e.column.dataField === "pmat_naziv") {
      this.prikaziLoading = true;
      if (e.data.pmat_vrsta == "video" || e.data.pmpred_vrsta == "video") {
        this.vimeoURL = "https://player.vimeo.com/video/"+e.data.pmat_link;
        this.prikaziVideo = true;
        this.prikaziLoading = false;
      } else {
        var url = (this.datotekeURL + e.data.uploaddokument_iduploaddokument + "&access_token=" + this.access_token);
        window.open(url, "_blank");
      }
    }
  }

  onCellPrepared(e){
    if (e.rowType === "data" && e.column.dataField === "pmat_naziv") {
      e.cellElement.style.color = '#03a9f4';
      e.cellElement.style.textDecoration = 'underline';
    }
  }

// vrstaIspita(data, e){
//   console.log(e.itemData.id)
//   if(e.itemData.id === 0){
//     alert('Riješeni ispiti')
//   } else {
//   this._router.navigate(['ispiti/' + this.ucionicaID + '/' + data.data.idpredmet]);
//   }
// }

/**-------------------------- TAB3: ISPITI ----------------------------------------------- */
  onRowPreparedIspiti(e){
    if (e.rowType == 'data') {
      if(e.data.krajIspita != undefined || e.data.krajIspita != null){
          let ispitVrijeme = moment(e.data.krajIspita).format();
          if (ispitVrijeme < this.danasnjiDan){
          e.rowElement.style.background = '#EEEEEE';
          }
        }
    }
  }

  ispitClick(e){
  //  console.log(e.data);
    // popup s upozorenjem
  /** AKO JE POLAZNIK PRVI PUTA ZAPOČINJE RJEŠAVANJE ISPITA  */
  if(e.data.ispitRezultat === 0){
      let encodedMessage = encodeHtml("Jeste li sigurni da želite započeti ispit?");
      let myDialog = custom({
          title: "Započni ispit",
          messageHtml: encodedMessage,
          buttons: [{
              text: "Započni",
              onClick: (e) => {
                  return { buttonText: e.component.option("text") }
              }
          },{
              text: "Odustani",
              onClick: (e) => {
                  return { buttonText: e.component.option("text") }
              }
          }]
      });
      myDialog.show().then((dialogResult) => {
        if(dialogResult.buttonText === 'Započni'){
          this._router.navigate(['ispiti/' + this.ucionicaID + '/' + e.data.idpredmet + '/' + e.data.idispitrok]);
          localStorage.setItem('tab','2');
        }
    });

  /** AKO JE POLAZNIK VEĆ ZAOČEO RJEŠAVANJE ISPITA, BACI GA ODMAH NA ISPIT (rok još nije istekao pa ima prava pristupa)  */
    } else {
      sessionStorage.setItem('start', '1');
      let podaci = {
        "access_token": this.access_token,
        "trajanje": +e.data.isprok_trajanje,
        "idkorisnik": +localStorage.getItem('idkorisnik'),
        "idispit": e.data.idispit,
        "iducionica": this.ucionicaID,
        "idispitrok": e.data.idispitrok,
      }
        this._api.pocetakIspita(podaci).subscribe(res => {
          // console.log(res)
          if(res.success === true){
            this._router.navigate(['ispiti/' + this.ucionicaID + '/' + e.data.idpredmet + '/' + e.data.idispitrok]);
          } else {
            notify(res.message, "error", 1000);
          }
        })
      }
  }

//**------------------------- TAB4: KALENDAR ---------------------------------- */ //TODO: ISPITNI ROKOVI - KALENDAR

  vodiNaLink(link){
    let linkNaZoom = link.ucip_linkPolaznik;
    window.open(linkNaZoom, "_blank");
  }

  /** kada se učita - boja polja */
  onAppointmentRendered(e){
    if(e.targetedAppointmentData.iducionicapredavanje){
      // console.log( e)
      e.appointmentElement.style.background = 'lightcoral';
    }
  }

  onAppointmentFormOpening(data){
    // console.log(data)
    let popupOptions = data.popup.option("toolbarItems");
    // console.log(popupOptions);
    data.popup.option("toolbarItems[0].options.text", "Zatvori");
    
  var that = this,
  form = data.form,
  info = data.appointmentData || {};

  if(data.appointmentData.iducionicapredavanje != undefined){
/** ---------------------- prikaz predavanja -------------------------- */
    form.option("items", [
        { colSpan:2,
          label: {
              text: "PREDAVANJE"
          },
          name: "naziv",
          editorType: "dxTextBox",
          editorOptions: {
              value: info.naziv,
              readOnly: true,
          }
        },
        {
          label: {
              text: "Početak:"
          },
          name: "pocetak",
          editorType: "dxDateBox",
          editorOptions: {
              value: info.pocetak,
              readOnly: true
          },
        },
        {
          label: {
              text: "Trajanje:"
          },
          icon: 'clock',
          name: "trajanje",
          editorType: "dxTextBox",
          editorOptions: {
              value: info.trajanje + ' min',
              readOnly: true
          },
        },
        { 
          label: {
              text: "Meeting ID"
          },
          name: "meetingID",
          editorType: "dxTextBox",
          editorOptions: {
              value: info.ucip_meetingID,
              readOnly: true,
          }
        },
        { 
          label: {
              text: "Lozinka"
          },
          name: "lozinka",
          editorType: "dxTextBox",
          editorOptions: {
              value: info.ucip_lozinka,
              readOnly: true,
          }
        },
        { colSpan:2,
          label: {
              text: "Link na predavanje"
          },
          name: "linkPredavanje",
          editorType: "dxButton",
          editorOptions: {
            onClick: () => { this.vodiNaLink(info) },
              icon: 'link'
          }
        }
    ])
  } else {
/** ---------------------- prikaz ispitnih rokova -------------------------- */
      form.option("items", [
        { colSpan:2,
          label: {
              text: "ISPTINI ROK:"
          },
          name: "naziv",
          editorType: "dxTextBox",
          editorOptions: {
              value: info.naziv,
              readOnly: true,
          },
        },
        { colSpan:2,
          label: {
              text: "Predmet:"
          },
          name: "predmet",
          editorType: "dxTextBox",
          editorOptions: {
              value: info.pred_naziv,
              readOnly: true
          },
        },
        {
          label: {
              text: "Početak:"
          },
          name: "pocetak",
          editorType: "dxDateBox",
          editorOptions: {
              value: info.pocetak,
              readOnly: true
          },
        },
        {
          label: {
              text: "Kraj:"
          },
          name: "kraj",
          editorType: "dxDateBox",
          editorOptions: {
              value: info.kraj,
              readOnly: true
          },
        },
        {
          label: {
              text: "Trajanje:"
          },
          icon: 'clock',
          name: "trajanje",
          editorType: "dxTextBox",
          editorOptions: {
              value: info.trajanje + ' min',
              readOnly: true
          },
        },
        ]);
      }
  }

  predmetiPolaznika(){
    this._api.predmetPopisZaUcionice({"access_token": this.access_token, "iducionica": this.ucionicaID, "idkorisnik": this.korisnikID}).subscribe(res => {
      if(res.success == true){
      this.predmeti = res.predmeti;
      // console.log(this.predmeti);
      } else {
        notify(res.message, "error", 1000);
      }
    });
  }

  materijaliPredmeta(){
    this._api.ucionicaMaterijali({"access_token": this.access_token, "iducionica": this.ucionicaID}).subscribe(res => {
      // console.log(res);
      if(res.success == true){
        this.materijali = [];
        // this.materijali = res.materijali;
        for(let i = 0; i < res.materijali.length; i++){
          if(res.materijali[i].predmet_idpredmet){
            this.materijali.push({
              'predmet_idpredmet': res.materijali[i].predmet_idpredmet,
              'uploaddokument_iduploaddokument': res.materijali[i].uploaddokument_iduploaddokument,
              'pmat_naziv': res.materijali[i].pmat_naziv,
              'pmat_vrsta': res.materijali[i].pmat_vrsta
            });
          }
          if(res.materijali[i].idpredmetpredavac){
            this.materijali.push({
              'predmet_idpredmet': res.materijali[i].idpredmetpredavac,
              'uploaddokument_iduploaddokument': res.materijali[i].uploaddokumentpredavac,
              'pmat_naziv': res.materijali[i].pmpred_naziv,
              'pmat_vrsta': res.materijali[i].pmpred_vrsta
            });
          }
        }
        // console.log(this.materijali);
        } else {
          notify(res.message, "error", 1000);
        }
    });
  }

  dohvatiMaterijale(key) {
    // console.log(key);
    let item = this.DataSourceStorage.find((i) => i.key === key);
    if (!item) {
        item = {
            key: key,
            dataSourceInstance: new DataSource({
                store: new ArrayStore({
                    data: this.materijali,
                    key: 'predmet_idpredmet',
                }),
                 filter: ["predmet_idpredmet", "=", key]
            })
        };
        this.DataSourceStorage.push(item);
    }
    return item.dataSourceInstance;
  }

  dohvatiIspitneRokove(){
    this.predavanjaiRokoviKalendar = [];
    this._api.ispitniRokoviPopisKorisnikZaUcionicu({"access_token": this.access_token, "iducionica": this.ucionicaID, "idkorisnik": this.korisnikID}).subscribe(res => {
      if(res.success == true){
       this.rokovi = res.ispitniRokovi;
       this.predavanjaKalendar = res.predavanja;
      } else {
        notify(res.message, "error", 1000);
      }
      // console.log(res)
      // console.log(this.rokovi)
      // console.log(this.predavanjaKalendar);
      // this.predavanjaiRokoviKalendar = this.rokovi.concat(this.predavanjaKalendar)
      for(let i = 0; i < this.rokovi.length; i++){
        this.predavanjaiRokoviKalendar.push({
          /** rokovi */
          idispit: this.rokovi[i].idispit,
          idispitrezultat: this.rokovi[i].idispitrezultat,
          idispitrok: this.rokovi[i].idispitrok,
          idpredmet: this.rokovi[i].idpredmet,
          isp_naziv: this.rokovi[i].isp_naziv,
          ispitRezultat: this.rokovi[i].ispitRezultat,
          isprez_zavrsen: this.rokovi[i].isprez_zavrsen,
          kraj: this.rokovi[i].isprok_kraj,
          naziv:  this.rokovi[i].isprok_naziv,
          pocetak: this.rokovi[i].isprok_pocetak,
          trajanje: this.rokovi[i].isprok_trajanje,
          korisnik_idkorisnik: this.rokovi[i].korisnik_idkorisnik,
          krajIspita: this.rokovi[i].krajIspita,
          kreirao: this.rokovi[i].kreirao,
          pocetakIspita: this.rokovi[i].pocetakIspita,
          pred_naziv: this.rokovi[i].pred_naziv,
          uredio: this.rokovi[i].uredio,
          isprez_ocjena: this.rokovi[i].isprez_ocjena
        })
      }
        /** predavanja */
        for(let i = 0; i < this.predavanjaKalendar.length; i++){
          this.predavanjaiRokoviKalendar.push({
          iducionicapredavanje: this.predavanjaKalendar[i].iducionicapredavanje,
          korisnik_idkorisnik: this.predavanjaKalendar[i].korisnik_idkorisnik,
          predmet_idpredmet: this.predavanjaKalendar[i].predmet_idpredmet,
          ucionica_iducionica: this.predavanjaKalendar[i].ucionica_iducionica,
          ucip_linkPolaznik: this.predavanjaKalendar[i].ucip_linkPolaznik,
          ucip_linkPredavac: this.predavanjaKalendar[i].ucip_linkPredavac,
          ucip_lozinka: this.predavanjaKalendar[i].ucip_lozinka,
          ucip_meetingID: this.predavanjaKalendar[i].ucip_meetingID,
          naziv: this.predavanjaKalendar[i].ucip_naziv,
          trajanje: this.predavanjaKalendar[i].ucip_trajanje,
          pocetak: this.predavanjaKalendar[i].ucip_vrijeme,
          isprez_ocjena: this.rokovi[i].isprez_ocjena
        })
      }
      // console.log(this.predavanjaiRokoviKalendar)
    });
  }

  /* Obavijesti */ 
  popisObavijesti(){
    this._api.ucionicaObavijestPopis({"iducionica": this.ucionicaID, "access_token": this.access_token}).subscribe(res=>{
      if(res.success == true){
        this.obavijesti = res['obavijesti'];
      } else {
        notify(res.message, "error");
      }
    });
  }

  /* PREDAVANJA */
  onRowPreparedPredavanja(e){
    if (e.rowType == 'data') {
      let d = e.values[1].toISOString();
      let b = moment(d).format();
      if (b <= this.danasnjiDan){
      // e.rowElement.style.color = 'lightgreen';
      // e.rowElement.style.textDecoration = 'line-through'      
      e.rowElement.style.background = '#EEEEEE';
      // console.log(e.rowElement.style)
      // e.rowElement.style.color = 'red'
      }
    }
  }

  popisPredavanja(){
    this._api.ucionicaPredavanjePopisPrisutnostKorisnik({"iducionica": this.ucionicaID, 'idkorisnik': this.korisnikID, "access_token": this.access_token}).subscribe(res=>{
      // console.log(res);
      if(res.success == true){
        this.predavanja = res.polaznici;
      } else {
        notify(res.message, "error");
      }
    });
  }

  hoverPredavanje(e){
    if(e.values){
      let d = e.values[1].toISOString();
      var b = moment(d).format();
    }
    if (e.rowType === "data" && e.column.dataField === "ucip_meetingID" && this.danasnjiDan <= b ){      if (e.eventType == 'mouseover'){
        e.cellElement.style.fontWeight = "bold";
        e.cellElement.style.fontSize = "18px";
        e.cellElement.style.cursor = 'pointer';
      } else {
        e.cellElement.style.fontWeight = "normal";
        e.cellElement.style.fontSize = "14px";
      }
    }
  }

  onPredavanjePrepared(e){
    if(e.values){
      let d = e.values[1].toISOString();
      var b = moment(d).format();
    }
    if (e.rowType === "data" && e.column.dataField === "ucip_meetingID" && this.danasnjiDan <= b) {
      e.cellElement.style.color = 'blue';
      e.cellElement.style.textDecoration = 'underline';
    }
  }

  cellPredavanjeEvent(e){
    if(e.values){
      let d = e.values[1].toISOString();
      var b = moment(d).format();
    }
    if (e.rowType == "data" && e.column.dataField === "ucip_meetingID" && this.danasnjiDan <= b) { /*  && this.danasnjiDan <= b */
      // console.log(this.danasnjiDan)
      var url = e.data.ucip_linkPolaznik;
      window.open(url, "_blank");
    }
  }

}