<dx-validation-group>
  <div class="login-header">
    <div class="title">Reset lozinke</div>
  </div>
  <div class="dx-field">
    <dx-text-box [(value)]="email" placeholder="E-mail" width="100%">
      <dx-validator>
        <dxi-validation-rule type="required" message="E-mail je obavezno polje!"></dxi-validation-rule>
        <dxi-validation-rule type="email" message="Neispravan format e-maila!"></dxi-validation-rule>
      </dx-validator>
    </dx-text-box>
  </div>
  <div class="dx-field">
    <dx-button type="default" text="Resetiraj" (onClick)="onResetClick($event)" width="100%" [disabled]="disabledButton"></dx-button>
  </div>
</dx-validation-group>