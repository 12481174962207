<h2 class="content-block">{{ucionicaNaziv}}</h2>

<div class="content-block responsive-paddings">
    <div id="tabs content-block">
        <dx-tabs
            class="tabs"
            id="tabovi"
            [dataSource]="tabovi"
            [(selectedIndex)]="selectedIndex"
            (onItemClick)="selectTab($event)">
        </dx-tabs>
    </div>
<!---------------------------------- TAB1: PREDMETI--------------------------------------------------->
    <dx-data-grid *ngIf="odabraniTab === 0"
        [showRowLines]="false"
        class="wide-card"
        [hoverStateEnabled]="true"
        [wordWrapEnabled]="true"
        [dataSource]="predmeti"
        [columnAutoWidth]="true"
        [columnHidingEnabled]="true"
        keyExpr="idpredmet"
        [masterDetail]="{ enabled: true, template: 'popisMaterijala' }"
    >

    <dxo-paging [pageSize]="10"></dxo-paging>
    <dxo-pager [showPageSizeSelector]="true" [showInfo]="true"></dxo-pager>
    <dxo-search-panel [visible]="true"></dxo-search-panel>
        <dxi-column dataField="pred_naziv" caption="Naziv predmeta"></dxi-column>
        <dxi-column caption="Kvizovi" cellTemplate="kvizovi" alignment="center">
            <div *dxTemplate="let cell of 'kvizovi'">
                <dx-button icon="columnchooser" class="kviz" (onClick)="kvizovi(cell)" *ngIf="cell.data.zavrsio === 0" ></dx-button>
            </div>
        </dxi-column>

        <!-- <dxi-column caption="Ispiti" cellTemplate="ispiti" alignment="center">
            <div *dxTemplate="let cell of 'ispiti'">
                <dx-drop-down-button
                    text="Ispiti"
                    icon="file"
                    [items]="prikazIspita"
                    keyExpr="id" 
                    displayExpr="text" 
                    wordWrapEnabled="true"  
                    [dropDownOptions]="{ width: 150 }"  
                    (onItemClick)="vrstaIspita(cell, $event)"  
                    >
                </dx-drop-down-button>
                                
            </div>
        </dxi-column> -->

        <dxi-column dataField="uppz_ocjena" caption="Ocjena" alignment="center">
            <!-- <div *dxTemplate="let cell of 'polozen'">
                <div *ngIf="cell.value === 1">Da</div>
                <div *ngIf="cell.value === 0">Ne</div>
            </div> -->
        </dxi-column>

        <div *dxTemplate="let materijal of 'popisMaterijala'">
            <p class="materijali">MATERIJALI PREDMETA</p>
            <dx-data-grid
                class="skladista"
                [dataSource]="dohvatiMaterijale(materijal.key)"
                (onCellHoverChanged)="hover($event)"
                (onCellClick)="cellClickEvent($event)"
                (onCellPrepared)="onCellPrepared($event)"
                [wordWrapEnabled]="true">

                <dxi-column dataField="uploaddokument_iduploaddokument" caption="ID" [visible]="false"></dxi-column>
                <dxi-column dataField="pmat_naziv" caption="Naziv dokumenta"></dxi-column>
                <dxi-column dataField="pmat_vrsta" caption="Vrsta"></dxi-column>

            </dx-data-grid>
        </div>

    </dx-data-grid>

<!---------------------------------- TAB2: PREDAVANJA --------------------------------------------------->
    <dx-data-grid *ngIf="odabraniTab === 1"
        id="predavanja"
        [showRowLines]="false"
        class="wide-card"
        [hoverStateEnabled]="true"
        [wordWrapEnabled]="true"
        [dataSource]="predavanja"
        [columnAutoWidth]="true"
        [columnHidingEnabled]="true"
        (onCellHoverChanged)="hoverPredavanje($event)"
        (onCellClick)="cellPredavanjeEvent($event)"
        (onCellPrepared)="onPredavanjePrepared($event)"
        (onRowPrepared)="onRowPreparedPredavanja($event)">

        <dxo-search-panel [visible]="true"></dxo-search-panel>
         <dxi-column dataField="ucip_naziv" caption="Naziv"></dxi-column>
         <dxi-column dataField="ucip_vrijeme" caption="Datum i vrijeme" dataType="datetime"></dxi-column>
         <dxi-column dataField="ucip_trajanje" caption="Trajanje (min)" alignment="center"></dxi-column>
         <dxi-column dataField="ucip_meetingID" caption="Meeting ID"></dxi-column>
         <dxi-column dataField="pred_naziv" caption="Predmet"></dxi-column>
         <dxi-column dataField="prisutan" caption="Prisutnost" dataType="boolean" width="150" cssClass="prisutnost"></dxi-column>
    </dx-data-grid>

<!---------------------------------- TAB3: ISPITI --------------------------------------------------->
    <dx-data-grid *ngIf="odabraniTab === 2"
        [showRowLines]="false"
        class="wide-card"
        [hoverStateEnabled]="true"
        [wordWrapEnabled]="true"
        [dataSource]="rokovi"
        [columnAutoWidth]="true"
        [columnHidingEnabled]="true"
        keyExpr="idispitrok"
        (onRowPrepared)="onRowPreparedIspiti($event)">
        <dxo-search-panel [visible]="true"></dxo-search-panel>
            <dxi-column dataField="isprok_naziv" caption="Ispitni rok"></dxi-column>
            <dxi-column dataField="pred_naziv" caption="Predmet"></dxi-column>
            <dxi-column dataField="isp_naziv" caption="Ispit"></dxi-column>
            <dxi-column dataField="isprok_pocetak" caption="Početak" alignment="center" dataType="date"></dxi-column>
            <dxi-column dataField="isprok_kraj" caption="Kraj" alignment="center" dataType="date"></dxi-column>
            <dxi-column dataField="isprok_trajanje" caption="Trajanje" alignment="center"></dxi-column>
            <dxi-column  alignment="center" cellTemplate="cellTemplate" caption="Pristup ispitu">
                <div *dxTemplate="let data of 'cellTemplate'">
                    <!-- <dx-button *ngIf="data.data.isprok_trajanje === '150'" icon="movetofolder" (onClick)="ispitClick(data)"></dx-button> -->
                    <!-- <dx-button *ngIf="data.data.idispit != null && danasnjiDan >= data.data.isprok_pocetak && danasnjiDan <= data.data.isprok_kraj" 
                                icon="movetofolder" (onClick)="ispitClick(data)">
                    </dx-button> -->
                    <dx-button id="bojaIspita" *ngIf="data.data.idispit != null && (danasnjiDan >= data.data.pocetakIspita && danasnjiDan <= data.data.krajIspita)" 
                                icon="movetofolder" (onClick)="ispitClick(data)" [disabled]="data.data.isprez_zavrsen === 1">
                    </dx-button>
                </div>
            </dxi-column>
            <dxi-column dataField="isprez_ocjena" caption="Ocjena" alignment="center"></dxi-column>
    </dx-data-grid>

<!---------------------------------- TAB4: KALENDAR--------------------------------------------------->
<dx-scheduler *ngIf="odabraniTab === 3"
            id="scheduler"
            [dataSource]="predavanjaiRokoviKalendar"
            [views]="['month', 'week', 'day']"
            currentView="month"
            [firstDayOfWeek]="1"
            [height]="600"
            textExpr="naziv"
            startDateExpr="pocetak"
            endDateExpr="kraj"
            (onAppointmentFormOpening)="onAppointmentFormOpening($event)"
            (onAppointmentRendered)="onAppointmentRendered($event)"
            appointmentTooltipTemplate="appointment-template">

            <div *dxTemplate="let appointment of 'appointment-template'">
                <div class="appointment-content">
                    <i class="dx-icon dx-icon-isnotblank"></i>
                    <p class="appointment">{{appointment.appointmentData.naziv}}</p>        
                </div>
        </div>
     
          <dxo-editing #editingOptions
                [allowAdding]="false"
                [allowUpdating]="false"
                [allowDeleting]="false">
          </dxo-editing>
</dx-scheduler>

<!---------------------------------- TAB5: OBAVIJESTI --------------------------------------------------->
    <dx-data-grid *ngIf="odabraniTab === 4"
        [showRowLines]="false"
        class="wide-card"
        [hoverStateEnabled]="true"
        [wordWrapEnabled]="true"
        [dataSource]="obavijesti"
        [columnAutoWidth]="true"
        [columnHidingEnabled]="true">
        <dxo-search-panel [visible]="true"></dxo-search-panel>
            <dxi-column dataField="uciob_naslov" caption="Naslov"></dxi-column>
            <dxi-column dataField="uciob_tekst" caption="Sadržaj"></dxi-column>
            <dxi-column dataField="kor_punoIme" caption="Autor" width="200"></dxi-column>
            <dxi-column dataField="createdAt" caption="Objavljeno" dataType="date" width="140"></dxi-column>
    </dx-data-grid>

<!---------------------------------- kvizovi odabir --------------------------------------------------->
<dx-popup
    title="{{ predmet }} - Vrsta kviza"
    [width]="450"
    [height]="'auto'"
    [(visible)]="odaberiVrstuKviza">

    <ul class="kocke">
        <li class="dx-card" (click)="prikaziKvizove(0)"><p class="tekst">Vježba</p></li>
        <li class="dx-card" (click)="prikaziKvizove(1)"><p class="tekst" >Završni kviz</p></li>
    </ul>

</dx-popup>

 <!---------------------------------- Prikaz video zapisa  -------------------------------------------->
<dx-popup
    title="Prikaz video zapisa"
    [(visible)]="prikaziVideo"
    [width]="500"
    [maxHeight]="400"
    [position]="{ my: 'center', at: 'center', of: window }">
    <dx-load-indicator id="large-indicator" height="60" width="60" [visible]="prikaziLoading"></dx-load-indicator>
    <iframe [src]="vimeoURL | safe" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
</dx-popup>

<!-- CHAT -->
<app-chat *ngIf="odabraniTab == 5"></app-chat>

</div>