import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';

import { ApiService } from './../../../shared/services/api.service';
import { IdPredmetService } from 'src/app/shared/services/id-predmet.service';

import notify from 'devextreme/ui/notify';
import { custom } from 'devextreme/ui/dialog';
import { encodeHtml } from 'devextreme/core/utils/string';
import { DxFileUploaderComponent } from 'devextreme-angular/ui/file-uploader';
import validationEngine from "devextreme/ui/validation_engine";

@Component({
  selector: 'app-kviz',
  templateUrl: './kviz.component.html',
  styleUrls: ['./kviz.component.scss']
})
export class KvizComponent implements OnInit, OnDestroy {

  access_token: any = localStorage.getItem('access_token');
  idKorisnik: any = +localStorage.getItem('idkorisnik');
  prava:any = localStorage.getItem('kor_prava');

  @ViewChild('notesUploader') uploader: DxFileUploaderComponent;
  datotekeURL = this._api.datotekeURL;
  uploadURL = this._api.uploadURL;
  subscription: any;
  window: any;
  editActive: boolean = false;
  kvizovi = [];
  pitanja = [];
  odabraniOdgovor: number;
  odgovorABCD: string;
  checkBoxABCD: boolean = false;
  idKviz: number;
  kreiranoPitanje: boolean = false;
  nazivTekstualnogPitanja: string;
  idUploadDokument: number;
  nazivDatoteke: string;
  vrstaOdgovora: number = null;
  idKvizPitanje: number;
  prikazPitanjaOdgovora: boolean = false;
  vrstaPitanja: number = null;
  pitanjePrikazTekst: string;
  imaOdgovora: boolean = false;
  nizOdgovori: any;
  odgovorPrikaz: string;
  tocanIlinetocan: boolean = false;
  idUploadDokumentOdgovorPrikaz: number;
  editingPopup: boolean = false;
  idKvizOdgovor: number;
  nemaOdgovora: boolean = false;
  cijeloPitanje: string;
  predmetID: any;
  naslov: string;
  dodajTekstualnoPitanje: boolean = false;
  dodajGrafickoPitanje: boolean = false;
  predmetNaziv: any;
  // allowAddingGlobalno: boolean = false;
  value: any[] = [];
  odgovoriTabs = [
    { id: 0, text: 'A', disabled: false },
    { id: 1, text: 'B', disabled: true },
    { id: 2, text: 'C', disabled: true },
    { id: 3, text: 'D', disabled: true }
  ];
  odgovoriTabsPrikaz = [
    { id: 0, text: 'A' },
    { id: 1, text: 'B' },
    { id: 2, text: 'C' },
    { id: 3, text: 'D' }
  ];

  constructor(
    public _api: ApiService, 
    private _idPredmetService: IdPredmetService
  ) {
    this.onShowing = this.onShowing.bind(this); 
  }

  ngOnInit() {
    //ID učionica
    this.subscription = this._idPredmetService.currentMessage.subscribe((message) => {
      if(message){
        this.predmetID = +message;
        this.dohvatiPopisKvizova();
      }
    });
    //Prava
    // if(this.prava == '0' || this.prava == '1'){
    //   this.allowAddingGlobalno = true;
    // } else {
    //   this.allowAddingGlobalno = false;
    // }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onShowing(e){
    e.component.option("title", this.naslov);
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        locateInMenu:'auto',
        location: 'after',
        template: "videoIliupload",
      }
    )
  }

  dohvatiPopisKvizova(){
    this._api.kvizPopis({"idpredmet": this.predmetID, "access_token": this.access_token}).subscribe(res=>{
      if(res.success == true){
        this.kvizovi = res.kvizovi;
      } else {
        notify(res.message, "error", 1000);
      }
    });
  }

  onInitNewRowKviz(e){
    this.naslov = 'Dodaj kviz';
  }

  onRowInsertedKviz(e){
    let kvizZaSlanje = {
      "naziv": e.data['kviz_naziv'],
      "kreirao": this.idKorisnik,
      "idpredmet": +this.predmetID,
      "access_token": this.access_token
    };
    this._api.kvizDodaj(kvizZaSlanje).subscribe(res=>{
      if(res.success == true){
        this.dohvatiPopisKvizova();
        notify(res.message, "success", 1000);
      } else {
        notify(res.message, "error", 1000);
      }
    });
  }

  onEditingStartKviz(e){
    this.naslov = 'Uredi kviz';
  }

  onRowUpdatedKviz(e){
    let kvizZaEdit = {
      "idkviz": e.data['idkviz'],
      "uredio": this.idKorisnik,
      "naziv": e.data['kviz_naziv'],
      "access_token": this.access_token
    };
    this._api.kvizUredi(kvizZaEdit).subscribe(res=>{
      if(res.success == true){
        this.dohvatiPopisKvizova();
        notify(res.message, "success", 1000);
      } else {
        notify(res.message, "error", 1000);
      }
    });
  }

  onRowRemovedKviz(e){
    this._api.kvizObrisi({"idkviz": e.data['idkviz']}).subscribe(res=>{
      if(res.success == true){
        this.dohvatiPopisKvizova();
        notify(res.message, "success", 1000);
      } else {
        notify(res.message, "error", 1000);
      }
    });
  }

  onRowExpanding(e){
    e.component.collapseAll(-1);
    this.idKviz = e.key.idkviz;
  }

  prikazPitanjaJednogKviza(e){
    this._api.kvizJedan({"idkviz": e.data['idkviz'], "access_token": this.access_token}).subscribe(res=>{
      if(res.success == true){
        this.pitanja = res.pitanja;
        // notify(res.message, "success", 1000);
      } else {
        notify(res.message, "error", 1000);
      }
    });
  }

  osvjeziPitanjaJednogKviza(idKviz){
    this._api.kvizJedan({"idkviz": idKviz, "access_token": this.access_token}).subscribe(res=>{
      if(res.success == true){
        this.pitanja = res.pitanja;
        // notify(res.message, "success", 1000);
      } else {
        notify(res.message, "error", 1000);
      }
    });
  }

  onInitNewRowTekstualnoPitanje(e){
    this.dodajTekstualnoPitanje = true;
  }

  kreirajTekstualnoPitanje(e){
    let pitanjeZaSlanje = {
        "naziv": this.nazivTekstualnogPitanja,
        "vrsta": 0,
        "opis": null,
        "idkviz": this.idKviz,
        "access_token": this.access_token
    };
    this._api.kvizPitanjeDodaj(pitanjeZaSlanje).subscribe(res=>{
      if(res.success == true){
        this.idKvizPitanje = res.idpitanja;
        this.kreiranoPitanje = true;
        this.osvjeziPitanjaJednogKviza(this.idKviz);
      } else {
        notify(res.message, "error", 1000);
      }
    });
  }

  pitanje_fieldDataChanged(e){
    // console.log(e.value);
    if(typeof(e.value) != 'number'){
      this.nazivTekstualnogPitanja = e.value;
    } 
  }

  onUploadedPitanje(e){
    let file = e.file;
    let formData:FormData = new FormData();
    formData.append('datoteka', file, file.name);
    formData.append('predmet', this.predmetID);
    formData.append('iddokument', this.predmetNaziv);
    formData.append('naziv', this.nazivTekstualnogPitanja || file.name);
    formData.append('idkorisnik', this.idKorisnik);
    formData.append('nazivtablice', 'materijali');
    formData.append('access_token', this.access_token);
    // this.kreiranoPitanje = true;
    this._api.uploadDatotekaKviz(formData).subscribe(res=>{
      if(res.success == true){
        if(res.iduploaddokument){
          this.idUploadDokument = res.iduploaddokument;
          this.nazivDatoteke = file.name;
          this.uploader.instance.reset();
        }
      } else {
        notify(res.message, "error");
      }
    });
  }

  kreirajGrafickoPitanje(){
    if(this.idUploadDokument && this.nazivTekstualnogPitanja){
      let pitanjeZaSlanje = {
        "naziv": this.nazivTekstualnogPitanja,
        "vrsta": 1,
        "opis": null,
        "idkviz": this.idKviz,
        "iduploaddokument": this.idUploadDokument,
        "access_token": this.access_token
      };
      this._api.kvizPitanjeDodaj(pitanjeZaSlanje).subscribe(res=>{
        if(res.success == true){
          this.kreiranoPitanje = true;
          this.idKvizPitanje = res.idpitanja;
          this.osvjeziPitanjaJednogKviza(this.idKviz);
        } else {
          notify(res.message, "error");
        }
      });
    } else {
      notify("Dogodila se greška prilikom kreiranja pitanja!", "error");
    }
  }

  cellClickPitanje(e){
    if (e.rowType == "data" && e.column.dataField === "kpit_naziv") { 
      this._api.kvizPitanjeSaOdgovorima({"idpitanje": e.data.idkvizpitanje, "access_token": this.access_token}).subscribe(res=>{
        if(res.success == true){
          //Pitanja
          this.vrstaPitanja = res.pitanje[0].kpit_vrsta;
          this.idUploadDokument = res.pitanje[0].uploaddokument_iduploaddokument;
          this.pitanjePrikazTekst = res.pitanje[0].kpit_naziv;
          this.prikazPitanjaOdgovora = true;
          //Odgovori
          if(res.odgovori.length > 0){
            this.imaOdgovora = true;
            this.nizOdgovori = res.odgovori;
            //Inicijalno učitavanje odgovora prije nego se počne klikati po tabovima
            this.nizOdgovori[0];
            if(res.odgovori[0].kodg_vrsta == 0){
              this.vrstaOdgovora = 0;
              this.odgovorPrikaz = res.odgovori[0].kodg_tekst;
              this.tocanIlinetocan = (res.odgovori[0].kodg_tocan == "1" ? true : false);
            } else {
              this.vrstaOdgovora = 1;
              this.idUploadDokumentOdgovorPrikaz = res.odgovori[0].uploaddokument_iduploaddokument;
              this.odgovorPrikaz = res.odgovori[0].kodg_tekst;
              this.tocanIlinetocan = (res.odgovori[0].kodg_tocan == "1" ? true : false);
            }
          } else {
            notify("Za ovo pitanje nisu dodani odgovori.");
          }
        } else {
          notify(res.message, "error", 1000);
        }
      });
    }
  }

  odgovoriTabPrikaz(e){
    this.odabraniOdgovor = e.itemData['id']; 
    if(this.odabraniOdgovor == 0){
      if(this.nizOdgovori[0]){
        this.nemaOdgovora = false;
        this.idUploadDokumentOdgovorPrikaz = this.nizOdgovori[0].uploaddokument_iduploaddokument;
        this.odgovorPrikaz = this.nizOdgovori[0].kodg_tekst;
        this.tocanIlinetocan = (this.nizOdgovori[0].kodg_tocan == "1" ? true : false);
        this.idKvizOdgovor = this.nizOdgovori[0].idkvizodgovor;
      } else {
        notify("Nema odgovora A");
        this.nemaOdgovora = true;
        this.idUploadDokumentOdgovorPrikaz = null;
        this.odgovorPrikaz = null;
        this.tocanIlinetocan = null;
      }
    } else if (this.odabraniOdgovor == 1){
      if(this.nizOdgovori[1]){
        this.nemaOdgovora = false;
        this.idUploadDokumentOdgovorPrikaz = this.nizOdgovori[1].uploaddokument_iduploaddokument;
        this.odgovorPrikaz = this.nizOdgovori[1].kodg_tekst;
        this.tocanIlinetocan = (this.nizOdgovori[1].kodg_tocan == "1" ? true : false);
        this.idKvizOdgovor = this.nizOdgovori[1].idkvizodgovor;
      } else {
        notify("Nema odgovora B");
        this.nemaOdgovora = true;
        this.idUploadDokumentOdgovorPrikaz = null;
        this.odgovorPrikaz = null;
        this.tocanIlinetocan = null;
      }
    } else if (this.odabraniOdgovor == 2){
      if(this.nizOdgovori[2]){
        this.nemaOdgovora = false;
        this.idUploadDokumentOdgovorPrikaz = this.nizOdgovori[2].uploaddokument_iduploaddokument;
        this.odgovorPrikaz = this.nizOdgovori[2].kodg_tekst;
        this.tocanIlinetocan = (this.nizOdgovori[2].kodg_tocan == "1" ? true : false);
        this.idKvizOdgovor = this.nizOdgovori[2].idkvizodgovor;
      } else {
        notify("Nema odgovora C");
        this.nemaOdgovora = true;
        this.idUploadDokumentOdgovorPrikaz = null;
        this.odgovorPrikaz = null;
        this.tocanIlinetocan = null;
      }
    } else if (this.odabraniOdgovor == 3){
      if(this.nizOdgovori[3]){
        this.nemaOdgovora = false;
        this.idUploadDokumentOdgovorPrikaz = this.nizOdgovori[3].uploaddokument_iduploaddokument;
        this.odgovorPrikaz = this.nizOdgovori[3].kodg_tekst;
        this.tocanIlinetocan = (this.nizOdgovori[3].kodg_tocan == "1" ? true : false);
        this.idKvizOdgovor = this.nizOdgovori[3].idkvizodgovor;
      } else {
        notify("Nema odgovora D");
        this.nemaOdgovora = true;
        this.idUploadDokumentOdgovorPrikaz = null;
        this.odgovorPrikaz = null;
        this.tocanIlinetocan = null;
      }
    }
  }

  popupHiddenPitanjeTekst(e){
    validationEngine.resetGroup('kvizPopup');
    this.vrstaOdgovora = null;
    this.kreiranoPitanje = false;
  }

  popupHiddenPitanjeSlika(e){
    validationEngine.resetGroup('formPopupUpload');
    this.vrstaOdgovora = null;
    this.kreiranoPitanje = false;
    this.uploader.instance.reset();
  }

  popupHiddenPitanjeOdgovor(e){
    validationEngine.resetGroup('formPopupUpload');
    this.vrstaOdgovora = null;
    this.kreiranoPitanje = false;
    this.cijeloPitanje = '';
    this.odabraniOdgovor = 0;
    this.idUploadDokumentOdgovorPrikaz = null;
    if(this.uploader){
      this.uploader.instance.reset();
    }

    // this.prikazPitanjaOdgovora = false;
    // this.idUploadDokument = null;
    // this.imaOdgovora = false;
    // this.editingPopup = false;
    // this.odgovorPrikaz = '';
    // this.tocanIlinetocan = null;
  }

  hoverPitanje(e){
    if (e.rowType === "data" && e.column.dataField === "kpit_naziv"){        
      if (e.eventType == 'mouseover'){
        e.cellElement.style.fontWeight = "bold";
        e.cellElement.style.fontSize = "18px";
        e.cellElement.style.cursor = 'pointer';
      } else {
        e.cellElement.style.fontWeight = "normal";
        e.cellElement.style.fontSize = "14px";
        }
    }
  }

  onInitNewRowGrafickoPitanje(e){
    // console.log(e);
    this.dodajGrafickoPitanje = true;
  }

  odabirVrsteOdgovora(vrsta){
    // this.kreiranoPitanje = true;
    this.odabraniOdgovor = 0;
    if(vrsta == 'tekst'){
      this.vrstaOdgovora = 0;
    } else {
      this.vrstaOdgovora = 1;
    }
  }

  odgovoriTabOdabir(e){
    this.odabraniOdgovor = e.itemData['id'];
  }

  odgovor_fieldDataChanged(e){
    // console.log(e.value);
    this.odgovorABCD = e.value;
  }

  spremiOdgovorTekst(e){
    if(this.odgovorABCD){
      // if(this.checkBoxABCD == true){
      //   this.checkBoxABCD = false;
      // }
      let odgovorZaSlanje = {
        "vrsta": 0,
        "tekst": this.odgovorABCD,
        "tocan": this.checkBoxABCD,
        "idkviz": this.idKviz,
        "idkvizpitanje": this.idKvizPitanje,
        "access_token": this.access_token
      };
      this.odabraniOdgovor = this.odabraniOdgovor+1;
      if(this.odabraniOdgovor == 1){
        this.odgovoriTabs = [
          { id: 0, text: 'A', disabled: true },
          { id: 1, text: 'B', disabled: false },
          { id: 2, text: 'C', disabled: true },
          { id: 3, text: 'D', disabled: true }
        ]
      } else if (this.odabraniOdgovor == 2){
        this.odgovoriTabs = [
          { id: 0, text: 'A', disabled: true },
          { id: 1, text: 'B', disabled: true },
          { id: 2, text: 'C', disabled: false },
          { id: 3, text: 'D', disabled: true }
        ]
      } else if (this.odabraniOdgovor == 3){
        this.odgovoriTabs = [
          { id: 0, text: 'A', disabled: true },
          { id: 1, text: 'B', disabled: true },
          { id: 2, text: 'C', disabled: true },
          { id: 3, text: 'D', disabled: false }
        ]
      } else {
        this.dodajTekstualnoPitanje = false;
        this.dodajGrafickoPitanje = false;
      }
      this._api.kvizOdgovorDodaj(odgovorZaSlanje).subscribe(res=>{
        if(res.success == true){
          notify(res.message, "success");
          this.checkBoxABCD = false;
        } else {
          notify(res.message, "error");
        }
      });
    } else {
      notify("Unesite odgovor!", "error");
    }
  }

  onUploadedOdgovor(e){
    let file = e.file;
    let formData:FormData = new FormData();
    formData.append('datoteka', file, file.name);
    formData.append('predmet', this.predmetID);
    formData.append('iddokument', this.predmetNaziv);
    formData.append('naziv', this.odgovorABCD || file.name);
    formData.append('idkorisnik', this.idKorisnik);
    formData.append('nazivtablice', 'materijali');
    formData.append('access_token', this.access_token);
    // this.kreiranoPitanje = true;
    this._api.uploadDatotekaKviz(formData).subscribe(res=>{
      if(res.success == true){
        if(res.iduploaddokument){
          this.idUploadDokument = res.iduploaddokument;
          this.nazivDatoteke = file.name;
          this.uploader.instance.reset();
        }
      } else {
        notify(res.message, "error", 1000);
      }
    });
  }

  spremiOdgovorSlika(){
    let odgovorZaSlanje = {
      "vrsta": 1,
      "tekst": this.odgovorABCD,
      "tocan": this.checkBoxABCD,
      "idkviz": this.idKviz,
      "idkvizpitanje": this.idKvizPitanje,
      "iduploaddokument": this.idUploadDokument
    };
    // console.log(odgovorZaSlanje);
    this.odabraniOdgovor = this.odabraniOdgovor+1;
      if(this.odabraniOdgovor == 1){
        this.odgovoriTabs = [
          { id: 0, text: 'A', disabled: true },
          { id: 1, text: 'B', disabled: false },
          { id: 2, text: 'C', disabled: true },
          { id: 3, text: 'D', disabled: true }
        ]
      } else if (this.odabraniOdgovor == 2){
        this.odgovoriTabs = [
          { id: 0, text: 'A', disabled: true },
          { id: 1, text: 'B', disabled: true },
          { id: 2, text: 'C', disabled: false },
          { id: 3, text: 'D', disabled: true }
        ]
      } else if (this.odabraniOdgovor == 3){
        this.odgovoriTabs = [
          { id: 0, text: 'A', disabled: true },
          { id: 1, text: 'B', disabled: true },
          { id: 2, text: 'C', disabled: true },
          { id: 3, text: 'D', disabled: false }
        ]
      } else {
        this.dodajTekstualnoPitanje = false;
        this.dodajGrafickoPitanje = false;
      }
      this._api.kvizOdgovorDodaj(odgovorZaSlanje).subscribe(res=>{
        if(res.success == true){
          notify(res.message, "success");
          this.checkBoxABCD = false;
          // this.uploader.instance.reset();
        } else {
          notify(res.message, "error");
        }
      });
  }

  brisanjePitanja(e){
    let encodedMessage = encodeHtml("Jeste li sigurni da želite obrisati ovaj zapis?");
      let myDialog = custom({
          title: "Brisanje pitanja i odgovora",
          messageHtml: encodedMessage,
          buttons: [{
              text: "OBRIŠI",
              onClick: (e) => {
                  return { buttonText: e.component.option("text") }
              }
          },{
            text: "ODUSTANI",
            onClick: (e) => {
                return { buttonText: e.component.option("text") }
            }
        }]
      });
      myDialog.show().then((dialogResult) => {
          if(dialogResult.buttonText === 'OBRIŠI'){
            this._api.kvizObrisiPitanje({"idkvizpitanje": e.data['idkvizpitanje'], "access_token": this.access_token}).subscribe(res=>{
              if(res.success == true){
                notify(res.message, "success", 1000);
                this.dohvatiPopisKvizova();
              } else {
                notify(res.message, "error", 1000);
              }
            });      
          }else{}  
      });
  }

  odgovoriEditPopup(e){
    this.idKvizPitanje = e.data.idkvizpitanje;
    this.nazivTekstualnogPitanja = e.data.kpit_naziv;
    this.editingPopup = true;
    this._api.kvizPitanjeSaOdgovorima({"idpitanje": e.data.idkvizpitanje, "access_token": this.access_token}).subscribe(res=>{
      if(res.success == true){
        //Pitanja
        this.cijeloPitanje = res.pitanje[0];
        this.vrstaPitanja = res.pitanje[0].kpit_vrsta;
        this.idUploadDokument = res.pitanje[0].uploaddokument_iduploaddokument;
        this.pitanjePrikazTekst = res.pitanje[0].kpit_naziv;
        //Odgovori
        if(res.odgovori && res.odgovori.length > 0){
          this.imaOdgovora = true;
          this.nizOdgovori = res.odgovori;
          //Inicijalno učitavanje odgovora prije nego se počne klikati po tabovima
          if(res.odgovori[0].kodg_vrsta == 0){
            this.vrstaOdgovora = 0;
            this.odgovorPrikaz = res.odgovori[0].kodg_tekst;
            this.tocanIlinetocan = (res.odgovori[0].kodg_tocan == "1" ? true : false);
            this.idKvizOdgovor = res.odgovori[0].idkvizodgovor;
          } else {
            this.vrstaOdgovora = 1;
            this.idUploadDokumentOdgovorPrikaz = res.odgovori[0].uploaddokument_iduploaddokument;
            this.odgovorPrikaz = res.odgovori[0].kodg_tekst;
            this.tocanIlinetocan = (res.odgovori[0].kodg_tocan == "1" ? true : false);
            this.idKvizOdgovor = res.odgovori[0].idkvizodgovor;
            this.idUploadDokumentOdgovorPrikaz = res.odgovori[0].uploaddokument_iduploaddokument;
          }
        } else {
          notify("Za ovo pitanje nisu dodani odgovori.");
        }
      } else {
        notify(res.message, "error");
      }
    });
  }

  urediTekstualnoPitanje(e){
    // console.log(e);
    let pitanjeZaEdit = {
        "naziv": this.nazivTekstualnogPitanja,
        "vrsta": 0,
        "opis": null,
        "idkvizpitanje": this.idKvizPitanje,
        "access_token": this.access_token
    }
    // console.log(pitanjeZaEdit);
    this._api.kvizPitanjeUredi(pitanjeZaEdit).subscribe(res=>{
      if(res.success == true){
        this.idKvizPitanje = res.idpitanja;
        // this.kreiranoPitanje = true;
        this.editingPopup = false;
        notify(res.message, "success");
        this.osvjeziPitanjaJednogKviza(this.idKviz);
      } else {
        notify(res.message, "error");
      }
    });
  }

  onEditedPitanje(e){
    let file = e.file;
    let formData:FormData = new FormData();
    formData.append('datoteka', file, file.name);
    formData.append('predmet', this.predmetID);
    formData.append('iddokument', this.predmetNaziv);
    formData.append('naziv', this.nazivTekstualnogPitanja || file.name);
    formData.append('idkorisnik', this.idKorisnik);
    formData.append('nazivtablice', 'materijali');
    formData.append('access_token', this.access_token);
    // this.kreiranoPitanje = true;
    this._api.uploadDatotekaKviz(formData).subscribe(res=>{
      if(res.success == true){
        if(res.iduploaddokument){
          this.idUploadDokument = res.iduploaddokument;
          this.nazivDatoteke = file.name;
          this.uploader.instance.reset();
        }
      } else {
        notify(res.message, "error");
      }
    });
  }

  onEditedOdgovor(e){
    let file = e.file;
    let formData:FormData = new FormData();
    formData.append('datoteka', file, file.name);
    formData.append('predmet', this.predmetID);
    formData.append('iddokument', this.predmetNaziv);
    formData.append('naziv', this.nazivTekstualnogPitanja || file.name);
    formData.append('idkorisnik', this.idKorisnik);
    formData.append('nazivtablice', 'materijali');
    formData.append('access_token', this.access_token);
    // this.kreiranoPitanje = true;
    this._api.uploadDatotekaKviz(formData).subscribe(res=>{
      if(res.success == true){
        if(res.iduploaddokument){
          this.idUploadDokumentOdgovorPrikaz = res.iduploaddokument;
          this.nazivDatoteke = file.name;
          this.uploader.instance.reset();
        }
      } else {
        notify(res.message, "error");
      }
    });
  }

  urediGrafickoPitanje(value){
    // console.log(value);
    if(this.idUploadDokument && this.nazivTekstualnogPitanja){
      let pitanjeZaEdit = {
        "naziv": this.nazivTekstualnogPitanja,
        "vrsta": 1,
        "opis": null,
        "idkvizpitanje": this.idKvizPitanje,
        "iduploaddokument": this.idUploadDokument,
        "access_token": this.access_token
      };
      // console.log(pitanjeZaEdit);
      this._api.kvizPitanjeUredi(pitanjeZaEdit).subscribe(res=>{
        if(res.success == true){
          this.kreiranoPitanje = true;
          this.editingPopup = false;
          this.idKvizPitanje = res.idpitanja;
          notify(res.message, "success");
          this.osvjeziPitanjaJednogKviza(this.idKviz);
        } else {
          notify(res.message, "error");
        }
      });
    } else {
      notify("Dogodila se greška prilikom kreiranja pitanja!", "error");
    }
  }

  odgovorEdit_fieldDataChanged(e){
    // console.log(e.value);
    this.odgovorPrikaz = e.value;
  }

  urediOdgovorTekst(e){
    let odgovorZaSlanje = {
        "vrsta": 0,
        "tekst": this.odgovorPrikaz,
        "tocan": this.tocanIlinetocan,
        "idkvizodgovor": this.idKvizOdgovor,
        "access_token": this.access_token
    };
    this._api.kvizOdgovorUredi(odgovorZaSlanje).subscribe(res=>{
        if(res.success == true){
          notify(res.message, "success");
        } else {
          notify(res.message, "error");
        }
    });
  }

  urediGrafickiOdgovor(value){
    let odgovorZaEdit = {
     "vrsta": 1,
     "tekst": this.odgovorPrikaz,
     "tocan": this.tocanIlinetocan,
     "idkvizodgovor": this.idKvizOdgovor,
     "iduploaddokument": this.idUploadDokumentOdgovorPrikaz,
     "access_token": this.access_token
   };
   this._api.kvizOdgovorUredi(odgovorZaEdit).subscribe(res=>{
     if(res.success == true){
       notify(res.message, "success");
     } else {
       notify(res.message, "error");
     }
   });
 }

}