<dx-data-grid
class="wide-card"
[hoverStateEnabled]="true"
[wordWrapEnabled]="true"
[dataSource]="ispiti"
[rowAlternationEnabled]="false"
[showRowLines]="false"
[showColumnLines]="false"
[showBorders]="false"
[columnAutoWidth]="true"
[columnHidingEnabled]="true"
[masterDetail]="{ enabled: true, template: 'popisPitanja' }"
(onInitNewRow)="onInitNewRowIspit()"
(onRowInserted)="onRowInsertedIspit($event)"
(onEditingStart)="onEditingStartIspit()"
(onRowUpdated)="onRowUpdatedIspit($event)"
(onCellClick)="deAktivacija($event);prikazPitanjaOdgovoraJednogIspita($event);"
(onRowExpanding)="onRowExpanding($event)"
>

<dxo-paging [pageSize]="10"></dxo-paging>
<dxo-pager [showPageSizeSelector]="true" [showInfo]="true"></dxo-pager>
<dxo-search-panel [visible]="true"></dxo-search-panel>

<dxo-editing
mode="popup"
[allowUpdating]="true"
[allowDeleting]="false"
[allowAdding]="true">

<dxo-popup
[closeOnOutsideClick]="true"
[onShowing]="onShowing"
title=""
[showTitle]="true"
[width]="500"
[maxHeight]="280"
[position]="{ my: 'center', at: 'center', of: window }">
</dxo-popup>

<dxo-form
    [showColonAfterLabel]="true">
    <dxi-item itemType="group" [colCount]="1" [colSpan]="2">
        <dxi-item
            dataField="isp_naziv"
            [editorOptions]="{stylingMode: 'filled'}">
            <dxi-validation-rule 
                type="required"
                message="Naziv je obavezno polje!">
            </dxi-validation-rule>
        </dxi-item>
    </dxi-item>
</dxo-form>

</dxo-editing>

<dxi-column dataField="isp_naziv" caption="Naziv ispita"></dxi-column>
<dxi-column dataField="brojPitanja" caption="Pitanja" alignment="center" [width]="150" [formItem]="{ visible: false }"></dxi-column>
<dxi-column dataField="isp_aktivan" caption="Aktivan" [width]="100" [allowSorting]="true" [formItem]="{ visible: false }" cellTemplate="cellTemplate" alignment="center"> 
    <div *dxTemplate="let data of 'cellTemplate'">
        <dx-switch [value]="data.value"></dx-switch>
    </div>
</dxi-column>

<div *dxTemplate="let pitanje of 'popisPitanja'">
    <dx-data-grid
        [dataSource]="pitanja"
        [rowAlternationEnabled]="false"
        [showRowLines]="false"
        [showColumnLines]="false"
        [showBorders]="false"
        (onToolbarPreparing)="onToolbarPreparing($event)"
        [columnAutoWidth]="true">
            <dxi-column dataField="ispp_tekst" caption="Pitanja"></dxi-column>
            <dxi-column type="buttons" [width]="110"><dxi-button template="buttonTemplate"></dxi-button></dxi-column>
            <div *dxTemplate="let buttonInfo of 'buttonTemplate'">     
                <dx-button icon="edit" type="back" (click)="pitanjeEditPopup(buttonInfo)" *ngIf="buttonInfo.data.isprje_odgovoreno == 0 || buttonInfo.data.isprje_odgovoreno == null"></dx-button>
                <dx-button icon="info" type="back" (click)="pitanjePregledPopup(buttonInfo)" *ngIf="buttonInfo.data.isprje_odgovoreno == 1"></dx-button>
                <dx-button icon="trash" type="back" (click)="brisanjePitanja(buttonInfo)" [disabled]="buttonInfo.data.isprje_odgovoreno == 1"></dx-button>
            </div>

        <!-- Toolbar -->
        <div *dxTemplate="let data of 'toolbarCustom'">
            <div><strong>Dodaj pitanje:</strong>
                <dx-button icon="startswith" hint="Dodaj pitanje" (onClick)="onInitNewRowPitanje($event)"></dx-button>
            </div>
        </div>
    </dx-data-grid>
</div>

</dx-data-grid>
    
    <!-- Dodaj pitanje -->
    <dx-popup
    title="Novo pitanje"
    [(visible)]="dodajPitanjePopup"
    (onHidden)="popupHiddenPitanje()"
    [width]="730"
    [height]="'auto'"
    [position]="{ my: 'center', at: 'center', of: window }">
        <div *dxTemplate="let data of 'content'">
            <dx-form
            validationGroup="pitanjePopup"
            [showColonAfterLabel]="true"
            (onFieldDataChanged)="pitanje_fieldDataChanged($event)">
            <dxi-item itemType="group" [colCount]="1" [colSpan]="1">
                <dxi-item
                    dataField="pitanje"
                    [label]="{text: 'Sadržaj pitanja'}"
                    [editorOptions]="{stylingMode: 'filled'}"
                    editorType="dxTextArea">
                    <dxi-validation-rule 
                        type="required"
                        message="Sadržaj pitanja je obavezno polje!">
                    </dxi-validation-rule>
                </dxi-item>
                <dxi-item>
                <div *dxTemplate="let data of 'upload'">
                    <span>Opcionalno možete dodati datoteku:</span>
                    <dx-file-uploader
                        #notesUploader
                        uploadUrl="{{uploadURL}}"
                        [multiple]="false"
                        accept="/*"
                        [(value)]="value"
                        uploadMode="instantly"
                        (onUploaded)="onUploadedPitanje($event)"
                    ></dx-file-uploader>
                </div>
                </dxi-item>
                <dxi-item
                    [label]="{text: 'Vrsta pitanja'}">
                        <dxi-validation-rule
                            type="required"
                            message="Vrsta pitanja je obavezno polje!">
                        </dxi-validation-rule>
                        <dx-radio-group
                        [dataSource]="vrstaPitanja"
                        displayExpr="naziv"
                        valueExpr="id"
                        layout="horizontal"
                        [disabled]="false"
                        (onValueChanged)="onValueChanged($event)">
                        </dx-radio-group>
                </dxi-item>
                <dxi-item style="float: right;">
                    <dx-button stylingMode="text" type="default" (onClick)="kreirajPitanje($event)" class="spremi">SPREMI PITANJE</dx-button>
                </dxi-item>
            </dxi-item>
            </dx-form>
        </div>
    </dx-popup>

    <!-- Uredi pitanje -->
    <dx-popup
    title="Uređivanje pitanja"
    [(visible)]="urediPitanjePopup"
    (onHidden)="popupHiddenPitanjeEdit()"
    [width]="730"
    [height]="'auto'"
    [position]="{ my: 'top', at: 'center', of: window }">
        <!-- TN odgovori - prikaži uređivanje -->
        <dxi-toolbar-item
        *ngIf="oblikPitanja == 1"
        widget="dxButton"
        location="after"
        cssClass="infoButton"
        [options]="{
        icon: 'bulletlist',
        text: 'Odgovor',
        onClick: prikaziOdgovorTNzaEdit
        }">
        </dxi-toolbar-item>
        <!-- Kviz odgovori - prikaži uređivanje -->
        <dxi-toolbar-item
        *ngIf="oblikPitanja == 0"
        widget="dxButton"
        location="after"
        cssClass="infoButton"
        [options]="{
        icon: 'bulletlist',
        text: 'Odgovor',
        onClick: prikaziOdgovorABCDzaEdit
        }">
        </dxi-toolbar-item>
    <div *dxTemplate="let data of 'content'">
        <dx-form
        validationGroup="pitanjePopupEdit"
        [showColonAfterLabel]="true"
        [(formData)]="pitanjeZaEdit"
        (onFieldDataChanged)="pitanjeEdit_fieldDataChanged($event)">
        <!-- Tekst -->
        <dxi-item itemType="group" [colCount]="1" [colSpan]="1" *ngIf="!pitanjeImaDokument">
            <dxi-item
                dataField="ispp_tekst"
                [label]="{text: 'Sadržaj pitanja'}"
                [editorOptions]="{stylingMode: 'filled'}"
                editorType="dxTextArea">
                <dxi-validation-rule
                    type="required"
                    message="Sadržaj pitanja je obavezno polje!">
                </dxi-validation-rule>
            </dxi-item>
            <dxi-item
                itemType="group"
                [label]="{text: 'Vrsta pitanja'}">
                    <dx-radio-group
                    [(value)]="oblikPitanja"
                    [dataSource]="vrstaPitanja"
                    displayExpr="naziv"
                    valueExpr="id"
                    layout="horizontal"
                    [disabled]="true">
                    </dx-radio-group>
            </dxi-item>
            <dxi-item>
                <dx-button stylingMode="text" type="default" (onClick)="urediPitanje($event)" class="spremi" style="float: right;">UREDI</dx-button>
            </dxi-item>
        </dxi-item>
        <!-- Dokument ili slika -->
        <dxi-item itemType="group" [colCount]="1" [colSpan]="1" *ngIf="pitanjeImaDokument">
            <dxi-item
                dataField="ispp_tekst"
                [label]="{text: 'Sadržaj pitanja'}"
                [editorOptions]="{stylingMode: 'filled'}"
                editorType="dxTextArea">
                <dxi-validation-rule 
                    type="required"
                    message="Sadržaj pitanja je obavezno polje!">
                </dxi-validation-rule>
            </dxi-item>
            <dxi-item itemType="group" [colCount]="2" [colSpan]="2">
                <dxi-item>
                    <ul><li><a href="{{datotekeURL}}+{{idUploadDokumentPitanjePrikaz}}+&access_token={{access_token}}" target="_blank">Postojeća datoteka</a></li></ul>
                </dxi-item>
                <dxi-item>
                    <div *dxTemplate="let data of 'upload'">
                        <span>Opcionalno možete promijeniti datoteku:</span>
                        <dx-file-uploader
                        #notesUploader
                        uploadUrl="{{uploadURL}}"
                        [multiple]="false"
                        accept="/*"
                        [(value)]="value"
                        uploadMode="instantly"
                        (onUploaded)="onUploadedPitanje($event)"
                        ></dx-file-uploader>
                    </div>
                </dxi-item>
            </dxi-item>
            <dxi-item
                itemType="group"
                [label]="{text: 'Vrsta pitanja'}">
                    <dx-radio-group
                    [(value)]="oblikPitanja"
                    [dataSource]="vrstaPitanja"
                    displayExpr="naziv"
                    valueExpr="id"
                    layout="horizontal"
                    [disabled]="true">
                    </dx-radio-group>
            </dxi-item>
            <dxi-item>
                <dx-button stylingMode="text" type="default" (onClick)="urediPitanje($event)" class="spremi" style="float: right;">UREDI</dx-button>
            </dxi-item>
        </dxi-item>
        </dx-form>
    </div>
    </dx-popup>

    <!-- Dodaj odgovor KVIZ -->
    <dx-popup
    title="Odgovor"
    [(visible)]="dodajOdgovorPopup"
    (onHidden)="popupHiddenOdgovor()"
    [width]="600"
    [height]="'auto'"
    [position]="{ my: 'center', at: 'center', of: window }">
        <div>
            <div *ngIf="vrstaOdgovora == null">
                <p>Vrsta odgovora:</p>
                <ul class="kocke">
                    <li class="dx-card" (click)="odabirVrsteOdgovora('tekst')">
                        <dx-button icon="startswith" class="slika"></dx-button>
                        <p class="opis">Tekst</p>
                    </li>
                    <li class="dx-card" (click)="odabirVrsteOdgovora('slika')">
                        <dx-button icon="image" class="slika"></dx-button>
                        <p class="opis">Slika</p>
                    </li>
                </ul>
            </div>
            <!-- Tekstualna vrsta odgovora -->
            <div *ngIf="vrstaOdgovora == 0 && kreiranoPitanje == true">
                <div id="tabs">
                    <dx-tabs
                        [dataSource]="odgovoriTabsABCD"
                        [selectedIndex]="odabraniOdgovor"
                        (onItemClick)="odgovoriTabOdabirABCD($event)"
                    ></dx-tabs>
                </div>
                <!-- Odgovor A tekst -->
                <div *ngIf="odabraniOdgovor == 0">
                    <dx-form
                    id="formOdgovor"
                    [colCount]="1"
                    (onFieldDataChanged)="odgovorABCD_fieldDataChanged($event)">
                        <dxi-item itemType="group">
                            <dxi-item
                                dataField="odgovorABCD"
                                [editorOptions]="{stylingMode: 'filled'}"
                                editorType="dxTextArea">
                                <dxo-label [visible]="false"></dxo-label>
                                <dxi-validation-rule
                                    type="required"
                                    message="Odgovor A je obavezno polje!">
                                </dxi-validation-rule>
                            </dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group">
                            <dx-check-box
                                [(value)]="checkBoxABCD"
                                [width]="150"
                                text="Točan odgovor">
                            </dx-check-box>
                        </dxi-item>
                        <dxi-item style="float: right;">
                            <dx-button stylingMode="text" type="default" (onClick)="spremiOdgovorTekstABCD($event)" class="spremi">SPREMI</dx-button>
                        </dxi-item>
                    </dx-form>
                </div>
                <!-- Odgovor B tekst -->
                <div *ngIf="odabraniOdgovor == 1">
                    <dx-form
                    id="formOdgovor"
                    [colCount]="1"
                    (onFieldDataChanged)="odgovorABCD_fieldDataChanged($event)">
                        <dxi-item itemType="group">
                            <dxi-item
                                dataField="odgovorABCD"
                                [editorOptions]="{stylingMode: 'filled'}"
                                editorType="dxTextArea">
                                <dxo-label [visible]="false"></dxo-label>
                                <dxi-validation-rule
                                    type="required"
                                    message="Odgovor B je obavezno polje!">
                                </dxi-validation-rule>
                            </dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group">
                            <dx-check-box
                                [(value)]="checkBoxABCD"
                                [width]="150"
                                text="Točan odgovor">
                            </dx-check-box>
                        </dxi-item>
                        <dxi-item style="float: right;">
                            <dx-button stylingMode="text" type="default" (onClick)="spremiOdgovorTekstABCD($event)" class="spremi">SPREMI</dx-button>
                        </dxi-item>
                    </dx-form>
                </div>
                <!-- Odgovor C tekst -->
                <div *ngIf="odabraniOdgovor == 2">
                    <dx-form
                    id="formOdgovor"
                    [colCount]="1"
                    (onFieldDataChanged)="odgovorABCD_fieldDataChanged($event)">
                        <dxi-item itemType="group">
                            <dxi-item
                                dataField="odgovorABCD"
                                [editorOptions]="{stylingMode: 'filled'}"
                                editorType="dxTextArea">
                                <dxo-label [visible]="false"></dxo-label>
                                <dxi-validation-rule
                                    type="required"
                                    message="Odgovor C je obavezno polje!">
                                </dxi-validation-rule>
                            </dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group">
                            <dx-check-box
                                [(value)]="checkBoxABCD"
                                [width]="150"
                                text="Točan odgovor">
                            </dx-check-box>
                        </dxi-item>
                        <dxi-item style="float: right;">
                            <dx-button stylingMode="text" type="default" (onClick)="spremiOdgovorTekstABCD($event)" class="spremi">SPREMI</dx-button>
                        </dxi-item>
                    </dx-form>
                </div>
                <!-- Odgovor D tekst-->
                <div *ngIf="odabraniOdgovor == 3">
                    <dx-form
                    id="formOdgovor"
                    [colCount]="1"
                    (onFieldDataChanged)="odgovorABCD_fieldDataChanged($event)">
                        <dxi-item itemType="group">
                            <dxi-item
                                dataField="odgovorABCD"
                                [editorOptions]="{stylingMode: 'filled'}"
                                editorType="dxTextArea">
                                <dxo-label [visible]="false"></dxo-label>
                                <dxi-validation-rule 
                                    type="required"
                                    message="Odgovor D je obavezno polje!">
                                </dxi-validation-rule>
                            </dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group">
                            <dx-check-box
                                [(value)]="checkBoxABCD"
                                [width]="150"
                                text="Točan odgovor">
                            </dx-check-box>
                        </dxi-item>
                        <dxi-item style="float: right;">
                            <dx-button stylingMode="text" type="default" (onClick)="spremiOdgovorTekstABCD($event)" class="spremi">SPREMI</dx-button>
                        </dxi-item>
                    </dx-form>
                </div>
            </div>
            <!-- Grafička vrsta odgovora -->
            <div *ngIf="vrstaOdgovora == 1 && kreiranoPitanje == true">
                <div id="tabs">
                    <dx-tabs
                        [dataSource]="odgovoriTabsABCD"
                        [selectedIndex]="odabraniOdgovor"
                        (onItemClick)="odgovoriTabOdabirABCD($event)"
                    ></dx-tabs>
                </div>
                <!-- Odgovor A slika -->
                <div *ngIf="odabraniOdgovor == 0">
                    <dx-form
                    id="formOdgovor"
                    [colCount]="1"
                    (onFieldDataChanged)="odgovorABCD_fieldDataChanged($event)">
                        <dxi-item itemType="group">
                            <dx-file-uploader
                                #notesUploader
                                uploadUrl="{{uploadURL}}"
                                [multiple]="false"
                                accept="image/*"
                                [(value)]="value"
                                uploadMode="instantly"
                                (onUploaded)="onUploadedOdgovor($event)"
                            ></dx-file-uploader>
                        </dxi-item>
                        <dxi-item itemType="group">
                            <dx-check-box
                                [(value)]="checkBoxABCD"
                                [width]="150"
                                text="Točan odgovor">
                            </dx-check-box>
                        </dxi-item>
                        <dxi-item style="float: right;">
                            <dx-button stylingMode="text" type="default" (onClick)="spremiOdgovorSlikaABCD($event)" class="spremi">SPREMI</dx-button>
                        </dxi-item>
                    </dx-form>
                </div>
                <!-- Odgovor B slika -->
                <div *ngIf="odabraniOdgovor == 1">
                    <dx-form
                    id="formOdgovor"
                    [colCount]="1"
                    (onFieldDataChanged)="odgovorABCD_fieldDataChanged($event)">
                        <dxi-item itemType="group">
                            <dx-file-uploader
                                #notesUploader
                                uploadUrl="{{uploadURL}}"
                                [multiple]="false"
                                accept="image/*"
                                [(value)]="value"
                                uploadMode="instantly"
                                (onUploaded)="onUploadedOdgovor($event)"
                            ></dx-file-uploader>
                        </dxi-item>
                        <dxi-item itemType="group">
                            <dx-check-box
                                [(value)]="checkBoxABCD"
                                [width]="150"
                                text="Točan odgovor">
                            </dx-check-box>
                        </dxi-item>
                        <dxi-item style="float: right;">
                            <dx-button stylingMode="text" type="default" (onClick)="spremiOdgovorSlikaABCD($event)" class="spremi">SPREMI</dx-button>
                        </dxi-item>
                    </dx-form>
                </div>
                <!-- Odgovor C slika -->
                <div *ngIf="odabraniOdgovor == 2">
                    <dx-form
                    id="formOdgovor"
                    [colCount]="1"
                    (onFieldDataChanged)="odgovorABCD_fieldDataChanged($event)">
                        <dxi-item itemType="group">
                            <dx-file-uploader
                                #notesUploader
                                uploadUrl="{{uploadURL}}"
                                [multiple]="false"
                                accept="image/*"
                                [(value)]="value"
                                uploadMode="instantly"
                                (onUploaded)="onUploadedOdgovor($event)"
                            ></dx-file-uploader>
                        </dxi-item>
                        <dxi-item itemType="group">
                            <dx-check-box
                                [(value)]="checkBoxABCD"
                                [width]="150"
                                text="Točan odgovor">
                            </dx-check-box>
                        </dxi-item>
                        <dxi-item style="float: right;">
                            <dx-button stylingMode="text" type="default" (onClick)="spremiOdgovorSlikaABCD($event)" class="spremi">SPREMI</dx-button>
                        </dxi-item>
                    </dx-form>
                </div>
                <!-- Odgovor D slika -->
                <div *ngIf="odabraniOdgovor == 3">
                    <dx-form
                    id="formOdgovor"
                    [colCount]="1"
                    (onFieldDataChanged)="odgovorABCD_fieldDataChanged($event)">
                        <dxi-item itemType="group">
                            <dx-file-uploader
                                #notesUploader
                                uploadUrl="{{uploadURL}}"
                                [multiple]="false"
                                accept="image/*"
                                [(value)]="value"
                                uploadMode="instantly"
                                (onUploaded)="onUploadedOdgovor($event)"
                            ></dx-file-uploader>
                        </dxi-item>
                        <dxi-item itemType="group">
                            <dx-check-box
                                [(value)]="checkBoxABCD"
                                [width]="150"
                                text="Točan odgovor">
                            </dx-check-box>
                        </dxi-item>
                        <dxi-item style="float: right;">
                            <dx-button stylingMode="text" type="default" (onClick)="spremiOdgovorSlikaABCD($event)" class="spremi">SPREMI</dx-button>
                        </dxi-item>
                    </dx-form>
                </div>
            </div>
        </div>
    </dx-popup>

    <!-- Dodaj odgovor TN -->
    <dx-popup
    title="Odgovor"
    [(visible)]="dodajOdgovorPopupTN"
    (onHidden)="popupHiddenOdgovorTN()"
    [width]="600"
    [height]="'auto'"
    [position]="{ my: 'center', at: 'center', of: window }">
        <div id="tabsTocnoNetocno">
            <dx-tabs
                [dataSource]="odgovoriTabsTocnoNetocno"
                [selectedIndex]="odabraniOdgovor"
                (onItemClick)="odgovoriTabOdabirTN($event)"
            ></dx-tabs>
            <p>
            <!-- Odgovor A -->
            <div *ngIf="odabraniOdgovor == 0">
                <dx-form
                id="formOdgovor"
                [colCount]="1"
                (onFieldDataChanged)="odgovorTN_fieldDataChanged($event)">
                    <dxi-item itemType="group">
                        <dxi-item
                            dataField="odgovorTN"
                            [editorOptions]="{stylingMode: 'filled'}"
                            editorType="dxTextArea">
                            <dxo-label [visible]="false"></dxo-label>
                            <dxi-validation-rule
                                type="required"
                                message="Odgovor A je obavezno polje!">
                            </dxi-validation-rule>
                        </dxi-item>
                    </dxi-item>
                    <dxi-item itemType="group">
                        <dx-check-box
                            [(value)]="checkBoxTN"
                            [width]="150"
                            text="Točan odgovor">
                        </dx-check-box>
                    </dxi-item>
                    <dxi-item style="float: right;">
                        <dx-button stylingMode="text" type="default" (onClick)="spremiOdgovorTN($event)" class="spremi">SPREMI</dx-button>
                    </dxi-item>
                </dx-form>
            </div>
            <!-- Odgovor B -->
            <div *ngIf="odabraniOdgovor == 1">
                <dx-form
                id="formOdgovor"
                [colCount]="1"
                (onFieldDataChanged)="odgovorTN_fieldDataChanged($event)">
                    <dxi-item itemType="group">
                        <dxi-item
                            dataField="odgovorTN"
                            [editorOptions]="{stylingMode: 'filled'}"
                            editorType="dxTextArea">
                            <dxo-label [visible]="false"></dxo-label>
                            <dxi-validation-rule
                                type="required"
                                message="Odgovor B je obavezno polje!">
                            </dxi-validation-rule>
                        </dxi-item>
                    </dxi-item>
                    <dxi-item itemType="group">
                        <dx-check-box
                            [(value)]="checkBoxTN"
                            [width]="150"
                            text="Točan odgovor">
                        </dx-check-box>
                    </dxi-item>
                    <dxi-item style="float: right;">
                        <dx-button stylingMode="text" type="default" (onClick)="spremiOdgovorTN($event)" class="spremi">SPREMI</dx-button>
                    </dxi-item>
                </dx-form>
            </div>
        </div>
    </dx-popup>

    <!-- Uredi odgovor KVIZ -->
    <dx-popup
    title="Uredi odgovor"
    [(visible)]="urediOdgovorPopup"
    (onHidden)="popupHiddenOdgovorUredi()"
    [width]="600"
    [height]="'auto'"
    [position]="{ my: 'center', at: 'center', of: window }">
        <div>
            <!-- Kviz - Odabir vrste odgovora u slučaju kada uz pitanje nije dodan niti jedna odgovor -->
            <div *ngIf="imaOdgovora == false && vrstaOdgovora == null">
                <p>Vrsta odgovora:</p>
                <ul class="kocke">
                    <li class="dx-card" (click)="odabirVrsteOdgovora('tekst')">
                        <dx-button icon="startswith" class="slika"></dx-button>
                        <p class="opis">Tekst</p>
                    </li>
                    <li class="dx-card" (click)="odabirVrsteOdgovora('slika')">
                        <dx-button icon="image" class="slika"></dx-button>
                        <p class="opis">Slika</p>
                    </li>
                </ul>
            </div>
            <!-- KVIZ -->
            <div id="tabsKviz" *ngIf="oblikPitanja == 0">
                <dx-tabs
                    *ngIf="vrstaOdgovora != null && oblikPitanja == 0"
                    [dataSource]="odgovoriTabsABCDprikaz"
                    [selectedIndex]="odabraniOdgovor"
                    (onItemClick)="odgovoriTabOdabirABCD($event)"
                ></dx-tabs>
                <!-- Odgovor A uredi -->
                <div *ngIf="odabraniOdgovor == 0">
                    <dx-form
                    *ngIf="imaOdgovora == true"
                    [(formData)]="jedanOdgovorABCD[0]"
                    (onFieldDataChanged)="odgovorEdit_fieldDataChanged($event)"
                    [colCount]="1">
                        <!-- Tekst -->
                        <dxi-item itemType="group" [colCount]="1" [colSpan]="2" *ngIf="vrstaOdgovora == 0 && idUploadDokumentOdgovorPrikaz == null">
                            <dxi-item
                                dataField="ispo_tekst"
                                [editorOptions]="{stylingMode: 'filled'}"
                                editorType="dxTextArea">
                                <dxo-label [visible]="false"></dxo-label>
                            </dxi-item>
                            <dxi-item itemType="group">
                                <dx-check-box
                                    *ngIf="idUploadDokumentOdgovorPrikaz || odgovorPrikaz"
                                    [(value)]="tocanIlinetocan"
                                    [width]="150"
                                    text="Točan odgovor">
                                </dx-check-box>
                            </dxi-item>
                            <dxi-item style="float: right;">
                                <dx-button stylingMode="text" type="default" (onClick)="ispitOdgovorUrediTekstABCD($event)" class="spremi">UREDI ODGOVOR</dx-button>
                            </dxi-item>
                        </dxi-item>
                        <!-- Slika -->
                        <dxi-item itemType="group" *ngIf="vrstaOdgovora == 1 && idUploadDokumentOdgovorPrikaz != null" [colCount]="2" [colSpan]="2">
                            <dxi-item>
                                <p><a href="{{datotekeURL}}+{{idUploadDokumentOdgovorPrikaz}}+&access_token={{access_token}}" target="_blank"><img src="{{datotekeURL}}+{{idUploadDokumentOdgovorPrikaz}}+&access_token={{access_token}}" height="100" width="auto"></a></p>
                            </dxi-item>
                            <dxi-item>
                                <div *dxTemplate="let data of 'upload'">
                                    <dx-file-uploader
                                    #notesUploader
                                    uploadUrl="{{uploadURL}}"
                                    [multiple]="false"
                                    accept="image/*,application/pdf"
                                    [(value)]="value"
                                    uploadMode="instantly"
                                    (onUploaded)="onEditedOdgovor($event)"
                                    ></dx-file-uploader>
                                </div>
                            </dxi-item>
                            <dxi-item>
                                <dx-check-box 
                                    *ngIf="idUploadDokumentOdgovorPrikaz || odgovorPrikaz"
                                    [(value)]="tocanIlinetocan"
                                    [width]="150"
                                    text="Točan odgovor">
                                </dx-check-box>
                            </dxi-item>
                            <dxi-item style="float: right;">
                                <dx-button stylingMode="text" type="default" (onClick)="ispitOdgovorUrediGrafickiABCD(value)" class="spremi">UREDI ODGOVOR</dx-button>
                            </dxi-item>
                        </dxi-item>
                    </dx-form>
                    <!-- Nema postojećeg odgovora A - dodaj tekstualni -->
                    <dx-form 
                    *ngIf="imaOdgovora == false && vrstaOdgovora == 0"
                    id="formOdgovor"
                    [colCount]="1"
                    (onFieldDataChanged)="odgovorABCD_fieldDataChanged($event)">
                        <dxi-item itemType="group">
                            <dxi-item
                                dataField="odgovorABCD"
                                [editorOptions]="{stylingMode: 'filled'}"
                                editorType="dxTextArea">
                                <dxo-label [visible]="false"></dxo-label>
                                <dxi-validation-rule 
                                    type="required"
                                    message="Odgovor A je obavezno polje!">
                                </dxi-validation-rule>
                            </dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group">
                            <dx-check-box
                                [(value)]="checkBoxABCD"
                                [width]="150"
                                text="Točan odgovor">
                            </dx-check-box>
                        </dxi-item>
                        <dxi-item style="float: right;">
                            <dx-button stylingMode="text" type="default" (onClick)="spremiOdgovorTekstABCD($event)" class="spremi">DODAJ ODGOVOR</dx-button>
                        </dxi-item>
                    </dx-form>
                    <!-- Nema postojećeg odgovora A - dodaj grafički -->
                    <dx-form 
                    *ngIf="imaOdgovora == false && vrstaOdgovora == 1"
                    id="formOdgovor"
                    [colCount]="1"
                    (onFieldDataChanged)="odgovorABCD_fieldDataChanged($event)">
                        <dxi-item itemType="group">
                            <dx-file-uploader
                                #notesUploader
                                uploadUrl="{{uploadURL}}"
                                [multiple]="false"
                                accept="image/*"
                                [(value)]="value"
                                uploadMode="instantly"
                                (onUploaded)="onUploadedOdgovor($event)"
                            ></dx-file-uploader>
                        </dxi-item>
                        <dxi-item itemType="group">
                            <dx-check-box
                                [(value)]="checkBoxABCD"
                                [width]="150"
                                text="Točan odgovor">
                            </dx-check-box>
                        </dxi-item>
                        <dxi-item style="float: right;">
                            <dx-button stylingMode="text" type="default" (onClick)="spremiOdgovorSlikaABCD($event)" class="spremi">SPREMI</dx-button>
                        </dxi-item>
                    </dx-form>
                </div>
                <!-- Odgovor B uredi -->
                <div *ngIf="odabraniOdgovor == 1">
                    <dx-form
                    *ngIf="imaOdgovora == true"
                    [(formData)]="jedanOdgovorABCD[1]"
                    (onFieldDataChanged)="odgovorEdit_fieldDataChanged($event)"
                    [colCount]="1">
                        <!-- Tekst -->
                        <dxi-item itemType="group" [colCount]="1" [colSpan]="2" *ngIf="vrstaOdgovora == 0 && idUploadDokumentOdgovorPrikaz == null">
                            <dxi-item
                                dataField="ispo_tekst"
                                [editorOptions]="{stylingMode: 'filled'}"
                                editorType="dxTextArea">
                                <dxo-label [visible]="false"></dxo-label>
                            </dxi-item>
                            <dxi-item itemType="group">
                                <dx-check-box
                                    *ngIf="idUploadDokumentOdgovorPrikaz || odgovorPrikaz"
                                    [(value)]="tocanIlinetocan"
                                    [width]="150"
                                    text="Točan odgovor">
                                </dx-check-box>
                            </dxi-item>
                            <dxi-item style="float: right;">
                                <dx-button stylingMode="text" type="default" (onClick)="ispitOdgovorUrediTekstABCD($event)" class="spremi">UREDI ODGOVOR</dx-button>
                            </dxi-item>
                        </dxi-item>
                        <!-- Slika -->
                        <dxi-item itemType="group" *ngIf="vrstaOdgovora == 1 && idUploadDokumentOdgovorPrikaz != null" [colCount]="2" [colSpan]="2">
                            <dxi-item>
                                <p><a href="{{datotekeURL}}+{{idUploadDokumentOdgovorPrikaz}}+&access_token={{access_token}}" target="_blank"><img src="{{datotekeURL}}+{{idUploadDokumentOdgovorPrikaz}}+&access_token={{access_token}}" height="100" width="auto"></a></p>
                            </dxi-item>
                            <dxi-item>
                                <div *dxTemplate="let data of 'upload'">
                                    <dx-file-uploader
                                    #notesUploader
                                    uploadUrl="{{uploadURL}}"
                                    [multiple]="false"
                                    accept="image/*,application/pdf"
                                    [(value)]="value"
                                    uploadMode="instantly"
                                    (onUploaded)="onEditedOdgovor($event)"
                                    ></dx-file-uploader>
                                </div>
                            </dxi-item>
                            <dxi-item>
                                <dx-check-box
                                    *ngIf="idUploadDokumentOdgovorPrikaz || odgovorPrikaz"
                                    [(value)]="tocanIlinetocan"
                                    [width]="150"
                                    text="Točan odgovor">
                                </dx-check-box>
                            </dxi-item>
                            <dxi-item style="float: right;">
                                <dx-button stylingMode="text" type="default" (onClick)="ispitOdgovorUrediGrafickiABCD(value)" class="spremi">UREDI ODGOVOR</dx-button>
                            </dxi-item>
                        </dxi-item>
                    </dx-form>
                    <!-- Nema postojećeg odgovora B - dodaj tekstualni -->
                    <dx-form 
                    *ngIf="imaOdgovora == false && vrstaOdgovora == 0"
                    id="formOdgovor"
                    [colCount]="1"
                    (onFieldDataChanged)="odgovorABCD_fieldDataChanged($event)">
                        <dxi-item itemType="group">
                            <dxi-item
                                dataField="odgovorABCD"
                                [editorOptions]="{stylingMode: 'filled'}"
                                editorType="dxTextArea">
                                <dxo-label [visible]="false"></dxo-label>
                                <dxi-validation-rule 
                                    type="required"
                                    message="Odgovor A je obavezno polje!">
                                </dxi-validation-rule>
                            </dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group">
                            <dx-check-box
                                [(value)]="checkBoxABCD"
                                [width]="150"
                                text="Točan odgovor">
                            </dx-check-box>
                        </dxi-item>
                        <dxi-item style="float: right;">
                            <dx-button stylingMode="text" type="default" (onClick)="spremiOdgovorTekstABCD($event)" class="spremi">DODAJ ODGOVOR</dx-button>
                        </dxi-item>
                    </dx-form>
                    <!-- Nema postojećeg odgovora B - dodaj grafički -->
                    <dx-form
                    *ngIf="imaOdgovora == false && vrstaOdgovora == 1"
                    id="formOdgovor"
                    [colCount]="1"
                    (onFieldDataChanged)="odgovorABCD_fieldDataChanged($event)">
                        <dxi-item itemType="group">
                            <dx-file-uploader
                                #notesUploader
                                uploadUrl="{{uploadURL}}"
                                [multiple]="false"
                                accept="image/*"
                                [(value)]="value"
                                uploadMode="instantly"
                                (onUploaded)="onUploadedOdgovor($event)"
                            ></dx-file-uploader>
                        </dxi-item>
                        <dxi-item itemType="group">
                            <dx-check-box
                                [(value)]="checkBoxABCD"
                                [width]="150"
                                text="Točan odgovor">
                            </dx-check-box>
                        </dxi-item>
                        <dxi-item style="float: right;">
                            <dx-button stylingMode="text" type="default" (onClick)="spremiOdgovorSlikaABCD($event)" class="spremi">SPREMI</dx-button>
                        </dxi-item>
                    </dx-form>
                </div>
                <!-- Odgovor C uredi -->
                <div *ngIf="odabraniOdgovor == 2">
                    <dx-form
                    *ngIf="imaOdgovora == true"
                    [(formData)]="jedanOdgovorABCD[2]"
                    (onFieldDataChanged)="odgovorEdit_fieldDataChanged($event)"
                    [colCount]="1">
                        <!-- Tekst -->
                        <dxi-item itemType="group" [colCount]="1" [colSpan]="2" *ngIf="vrstaOdgovora == 0 && idUploadDokumentOdgovorPrikaz == null">
                            <dxi-item
                                dataField="ispo_tekst"
                                [editorOptions]="{stylingMode: 'filled'}"
                                editorType="dxTextArea">
                                <dxo-label [visible]="false"></dxo-label>
                            </dxi-item>
                            <dxi-item itemType="group">
                                <dx-check-box 
                                    *ngIf="idUploadDokumentOdgovorPrikaz || odgovorPrikaz"
                                    [(value)]="tocanIlinetocan"
                                    [width]="150"
                                    text="Točan odgovor">
                                </dx-check-box>
                            </dxi-item>
                            <dxi-item style="float: right;">
                                <dx-button stylingMode="text" type="default" (onClick)="ispitOdgovorUrediTekstABCD($event)" class="spremi">UREDI ODGOVOR</dx-button>
                            </dxi-item>
                        </dxi-item>
                        <!-- Slika -->
                        <dxi-item itemType="group" *ngIf="vrstaOdgovora == 1 && idUploadDokumentOdgovorPrikaz != null" [colCount]="2" [colSpan]="2">
                            <dxi-item>
                                <p><a href="{{datotekeURL}}+{{idUploadDokumentOdgovorPrikaz}}+&access_token={{access_token}}" target="_blank"><img src="{{datotekeURL}}+{{idUploadDokumentOdgovorPrikaz}}+&access_token={{access_token}}" height="100" width="auto"></a></p>
                            </dxi-item>
                            <dxi-item>
                                <div *dxTemplate="let data of 'upload'">
                                    <dx-file-uploader
                                    #notesUploader
                                    uploadUrl="{{uploadURL}}"
                                    [multiple]="false"
                                    accept="image/*,application/pdf"
                                    [(value)]="value"
                                    uploadMode="instantly"
                                    (onUploaded)="onEditedOdgovor($event)"
                                    ></dx-file-uploader>
                                </div>
                            </dxi-item>
                            <dxi-item>
                                <dx-check-box 
                                    *ngIf="idUploadDokumentOdgovorPrikaz || odgovorPrikaz"
                                    [(value)]="tocanIlinetocan" 
                                    [width]="150"
                                    text="Točan odgovor">
                                </dx-check-box>
                            </dxi-item>
                            <dxi-item style="float: right;">
                                <dx-button stylingMode="text" type="default" (onClick)="ispitOdgovorUrediGrafickiABCD(value)" class="spremi">UREDI ODGOVOR</dx-button>
                            </dxi-item>
                        </dxi-item>
                    </dx-form>
                    <!-- Nema postojećeg odgovora C - dodaj tekstualni -->
                    <dx-form 
                    *ngIf="imaOdgovora == false && vrstaOdgovora == 0"
                    id="formOdgovor"
                    [colCount]="1"
                    (onFieldDataChanged)="odgovorABCD_fieldDataChanged($event)">
                        <dxi-item itemType="group">
                            <dxi-item
                                dataField="odgovorABCD"
                                [editorOptions]="{stylingMode: 'filled'}"
                                editorType="dxTextArea">
                                <dxo-label [visible]="false"></dxo-label>
                                <dxi-validation-rule 
                                    type="required"
                                    message="Odgovor A je obavezno polje!">
                                </dxi-validation-rule>
                            </dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group">
                            <dx-check-box
                                [(value)]="checkBoxABCD"
                                [width]="150"
                                text="Točan odgovor">
                            </dx-check-box>
                        </dxi-item>
                        <dxi-item style="float: right;">
                            <dx-button stylingMode="text" type="default" (onClick)="spremiOdgovorTekstABCD($event)" class="spremi">DODAJ ODGOVOR</dx-button>
                        </dxi-item>
                    </dx-form>
                    <!-- Nema postojećeg odgovora C - dodaj grafički -->
                    <dx-form 
                    *ngIf="imaOdgovora == false && vrstaOdgovora == 1"
                    id="formOdgovor"
                    [colCount]="1"
                    (onFieldDataChanged)="odgovorABCD_fieldDataChanged($event)">
                        <dxi-item itemType="group">
                            <dx-file-uploader
                                #notesUploader
                                uploadUrl="{{uploadURL}}"
                                [multiple]="false"
                                accept="image/*"
                                [(value)]="value"
                                uploadMode="instantly"
                                (onUploaded)="onUploadedOdgovor($event)"
                            ></dx-file-uploader>
                        </dxi-item>
                        <dxi-item itemType="group">
                            <dx-check-box
                                [(value)]="checkBoxABCD"
                                [width]="150"
                                text="Točan odgovor">
                            </dx-check-box>
                        </dxi-item>
                        <dxi-item style="float: right;">
                            <dx-button stylingMode="text" type="default" (onClick)="spremiOdgovorSlikaABCD($event)" class="spremi">SPREMI</dx-button>
                        </dxi-item>
                    </dx-form>
                </div>
                <!-- Odgovor D uredi -->
                <div *ngIf="odabraniOdgovor == 3">
                    <dx-form
                    *ngIf="imaOdgovora == true"
                    [(formData)]="jedanOdgovorABCD[3]"
                    (onFieldDataChanged)="odgovorEdit_fieldDataChanged($event)"
                    [colCount]="1">
                        <!-- Tekst -->
                        <dxi-item itemType="group" [colCount]="1" [colSpan]="2" *ngIf="vrstaOdgovora == 0 && idUploadDokumentOdgovorPrikaz == null">
                            <dxi-item
                                dataField="ispo_tekst"
                                [editorOptions]="{stylingMode: 'filled'}"
                                editorType="dxTextArea">
                                <dxo-label [visible]="false"></dxo-label>
                            </dxi-item>
                            <dxi-item itemType="group">
                                <dx-check-box
                                    *ngIf="idUploadDokumentOdgovorPrikaz || odgovorPrikaz"
                                    [(value)]="tocanIlinetocan"
                                    [width]="150"
                                    text="Točan odgovor">
                                </dx-check-box>
                            </dxi-item>
                            <dxi-item style="float: right;">
                                <dx-button stylingMode="text" type="default" (onClick)="ispitOdgovorUrediTekstABCD($event)" class="spremi">UREDI ODGOVOR</dx-button>
                            </dxi-item>
                        </dxi-item>
                        <!-- Slika -->
                        <dxi-item itemType="group" *ngIf="vrstaOdgovora == 1 && idUploadDokumentOdgovorPrikaz != null" [colCount]="2" [colSpan]="2">
                            <dxi-item>
                                <p><a href="{{datotekeURL}}+{{idUploadDokumentOdgovorPrikaz}}+&access_token={{access_token}}" target="_blank"><img src="{{datotekeURL}}+{{idUploadDokumentOdgovorPrikaz}}+&access_token={{access_token}}" height="100" width="auto"></a></p>
                            </dxi-item>
                            <dxi-item>
                                <div *dxTemplate="let data of 'upload'">
                                    <dx-file-uploader
                                    #notesUploader
                                    uploadUrl="{{uploadURL}}"
                                    [multiple]="false"
                                    accept="image/*,application/pdf"
                                    [(value)]="value"
                                    uploadMode="instantly"
                                    (onUploaded)="onEditedOdgovor($event)"
                                    ></dx-file-uploader>
                                </div>
                            </dxi-item>
                            <dxi-item>
                                <dx-check-box
                                    *ngIf="idUploadDokumentOdgovorPrikaz || odgovorPrikaz"
                                    [(value)]="tocanIlinetocan"
                                    [width]="150"
                                    text="Točan odgovor">
                                </dx-check-box>
                            </dxi-item>
                            <dxi-item style="float: right;">
                                <dx-button stylingMode="text" type="default" (onClick)="ispitOdgovorUrediGrafickiABCD(value)" class="spremi">UREDI ODGOVOR</dx-button>
                            </dxi-item>
                        </dxi-item>
                    </dx-form>
                    <!-- Nema postojećeg odgovora D - dodaj tekstualni -->
                    <dx-form
                    *ngIf="imaOdgovora == false && vrstaOdgovora == 0"
                    id="formOdgovor"
                    [colCount]="1"
                    (onFieldDataChanged)="odgovorABCD_fieldDataChanged($event)">
                        <dxi-item itemType="group">
                            <dxi-item
                                dataField="odgovorABCD"
                                [editorOptions]="{stylingMode: 'filled'}"
                                editorType="dxTextArea">
                                <dxo-label [visible]="false"></dxo-label>
                                <dxi-validation-rule
                                    type="required"
                                    message="Odgovor A je obavezno polje!">
                                </dxi-validation-rule>
                            </dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group">
                            <dx-check-box
                                [(value)]="checkBoxABCD"
                                [width]="150"
                                text="Točan odgovor">
                            </dx-check-box>
                        </dxi-item>
                        <dxi-item style="float: right;">
                            <dx-button stylingMode="text" type="default" (onClick)="spremiOdgovorTekstABCD($event)" class="spremi">DODAJ ODGOVOR</dx-button>
                        </dxi-item>
                    </dx-form>
                    <!-- Nema postojećeg odgovora D - dodaj grafički -->
                    <dx-form 
                    *ngIf="imaOdgovora == false && vrstaOdgovora == 1"
                    id="formOdgovor"
                    [colCount]="1"
                    (onFieldDataChanged)="odgovorABCD_fieldDataChanged($event)">
                        <dxi-item itemType="group">
                            <dx-file-uploader
                                #notesUploader
                                uploadUrl="{{uploadURL}}"
                                [multiple]="false"
                                accept="image/*"
                                [(value)]="value"
                                uploadMode="instantly"
                                (onUploaded)="onUploadedOdgovor($event)"
                            ></dx-file-uploader>
                        </dxi-item>
                        <dxi-item itemType="group">
                            <dx-check-box
                                [(value)]="checkBoxABCD"
                                [width]="150"
                                text="Točan odgovor">
                            </dx-check-box>
                        </dxi-item>
                        <dxi-item style="float: right;">
                            <dx-button stylingMode="text" type="default" (onClick)="spremiOdgovorSlikaABCD($event)" class="spremi">SPREMI</dx-button>
                        </dxi-item>
                    </dx-form>
                </div>
            </div>
        </div>
    </dx-popup>

    <!-- Uredi odgovor TN-->
    <dx-popup
    title="Uredi odgovor"
    [(visible)]="urediOdgovorPopupTN"
    (onHidden)="popupHiddenOdgovorUrediTN()"
    [width]="600"
    [height]="'auto'"
    [position]="{ my: 'center', at: 'center', of: window }">
        <div id="tabsTocnoNetocno">
            <dx-tabs
                [dataSource]="odgovoriTabsTocnoNetocnoPrikaz"
                [selectedIndex]="odabraniOdgovor"
                (onItemClick)="odgovoriTabOdabirTN($event)"
            ></dx-tabs> <!-- *ngIf="vrstaOdgovora != null" -->
            <p>
            <!-- Odgovor A uredi-->
            <div *ngIf="odabraniOdgovor == 0 && imaOdgovora == true">
                <dx-form
                id="formOdgovorUredi"
                [(formData)]="jedanOdgovorTN[0]"
                [colCount]="1"
                (onFieldDataChanged)="odgovorTNUredi_fieldDataChanged($event)">
                    <dxi-item itemType="group">
                        <dxi-item
                            dataField="ispo_tekst"
                            [editorOptions]="{stylingMode: 'filled'}"
                            editorType="dxTextArea">
                            <dxo-label [visible]="false"></dxo-label>
                            <dxi-validation-rule
                                type="required"
                                message="Odgovor A je obavezno polje!">
                            </dxi-validation-rule>
                        </dxi-item>
                    </dxi-item>
                    <dxi-item itemType="group">
                        <dx-check-box
                            [(value)]="tocanIlinetocan"
                            [width]="150"
                            text="Točan odgovor">
                        </dx-check-box>
                    </dxi-item>
                    <dxi-item style="float: right;">
                        <dx-button stylingMode="text" type="default" (onClick)="urediOdgovorTN($event)" class="spremi">UREDI</dx-button>
                    </dxi-item>
                </dx-form>
            </div>
            <!-- Odgovor A dodaj ako ne postoji -->
            <div *ngIf="odabraniOdgovor == 0 && imaOdgovora == false">
                <dx-form
                id="formOdgovor"
                [colCount]="1"
                (onFieldDataChanged)="odgovorTNUredi_fieldDataChanged($event)">
                    <dxi-item itemType="group">
                        <dxi-item
                            dataField="odgovorTN"
                            [editorOptions]="{stylingMode: 'filled'}"
                            editorType="dxTextArea">
                            <dxo-label [visible]="false"></dxo-label>
                            <dxi-validation-rule
                                type="required"
                                message="Odgovor A je obavezno polje!">
                            </dxi-validation-rule>
                        </dxi-item>
                    </dxi-item>
                    <dxi-item itemType="group">
                        <dx-check-box
                            [(value)]="checkBoxTN"
                            [width]="150"
                            text="Točan odgovor">
                        </dx-check-box>
                    </dxi-item>
                    <dxi-item style="float: right;">
                        <dx-button stylingMode="text" type="default" (onClick)="spremiOdgovorTN($event)" class="spremi">SPREMI</dx-button>
                    </dxi-item>
                </dx-form>
            </div>
            <!-- Odgovor B uredi -->
            <div *ngIf="odabraniOdgovor == 1 && imaOdgovora == true">
                <dx-form
                id="formOdgovorUredi"
                [(formData)]="jedanOdgovorTN[1]"
                [colCount]="1"
                (onFieldDataChanged)="odgovorTNUredi_fieldDataChanged($event)">
                    <dxi-item itemType="group">
                        <dxi-item
                            dataField="ispo_tekst"
                            [editorOptions]="{stylingMode: 'filled'}"
                            editorType="dxTextArea">
                            <dxo-label [visible]="false"></dxo-label>
                            <dxi-validation-rule
                                type="required"
                                message="Odgovor B je obavezno polje!">
                            </dxi-validation-rule>
                        </dxi-item>
                    </dxi-item>
                    <dxi-item itemType="group">
                        <dx-check-box
                            [(value)]="tocanIlinetocan"
                            [width]="150"
                            text="Točan odgovor">
                        </dx-check-box>
                    </dxi-item>
                    <dxi-item style="float: right;">
                        <dx-button stylingMode="text" type="default" (onClick)="urediOdgovorTN($event)" class="spremi">UREDI</dx-button>
                    </dxi-item>
                </dx-form>
            </div>
            <!-- Odgovor B ako ne postoji -->
            <div *ngIf="odabraniOdgovor == 1 && imaOdgovora == false">
                <dx-form
                id="formOdgovor"
                [colCount]="1"
                (onFieldDataChanged)="odgovorTNUredi_fieldDataChanged($event)">
                    <dxi-item itemType="group">
                        <dxi-item
                            dataField="odgovorTN"
                            [editorOptions]="{stylingMode: 'filled'}"
                            editorType="dxTextArea">
                            <dxo-label [visible]="false"></dxo-label>
                            <dxi-validation-rule 
                                type="required"
                                message="Odgovor B je obavezno polje!">
                            </dxi-validation-rule>
                        </dxi-item>
                    </dxi-item>
                    <dxi-item itemType="group">
                        <dx-check-box
                            [(value)]="checkBoxTN"
                            [width]="150"
                            text="Točan odgovor">
                        </dx-check-box>
                    </dxi-item>
                    <dxi-item style="float: right;">
                        <dx-button stylingMode="text" type="default" (onClick)="spremiOdgovorTN($event)" class="spremi">SPREMI</dx-button>
                    </dxi-item>
                </dx-form>
            </div>
        </div>
    </dx-popup>

    <!-- Prikaz pitanja i odgovora koji se više ne mogu uređivati -->
    <dx-popup
    title="Detalji pitanja"
    [(visible)]="prikaziPitanjePopup"
    (onHidden)="popupHiddenPitanjeEdit()"
    [width]="730"
    [height]="'auto'"
    [position]="{ my: 'top', at: 'center', of: window }">
        <!-- TN odgovori - prikaži odgovore -->
        <dxi-toolbar-item
        *ngIf="oblikPitanja == 1"
        widget="dxButton"
        location="after"
        cssClass="infoButton"
        [options]="{
        icon: 'bulletlist',
        text: 'Odgovor',
        onClick: prikaziOdgovorTN
        }">
        </dxi-toolbar-item>
        <!-- Kviz odgovori - prikaži odgovore -->
        <dxi-toolbar-item
        *ngIf="oblikPitanja == 0"
        widget="dxButton"
        location="after"
        cssClass="infoButton"
        [options]="{
        icon: 'bulletlist',
        text: 'Odgovor',
        onClick: prikaziOdgovorABCD
        }">
        </dxi-toolbar-item>
    <div *dxTemplate="let data of 'content'">
        <dx-form
        validationGroup="pitanjePopupEdit"
        [showColonAfterLabel]="true"
        [(formData)]="pitanjeZaEdit">
        <!-- Tekst -->
        <dxi-item itemType="group" [colCount]="1" [colSpan]="1" *ngIf="!pitanjeImaDokument">
            <dxi-item
                dataField="ispp_tekst"
                [label]="{text: 'Sadržaj pitanja'}"
                [editorOptions]="{stylingMode: 'filled'}"
                editorType="dxTextArea"
                disabled="true">
                <dxi-validation-rule
                    type="required"
                    message="Sadržaj pitanja je obavezno polje!">
                </dxi-validation-rule>
            </dxi-item>
            <dxi-item
                itemType="group"
                [label]="{text: 'Vrsta pitanja'}">
                    <dx-radio-group
                    [(value)]="oblikPitanja"
                    [dataSource]="vrstaPitanja"
                    displayExpr="naziv"
                    valueExpr="id"
                    layout="horizontal"
                    [disabled]="true">
                    </dx-radio-group>
            </dxi-item>
        </dxi-item>
        <!-- Dokument ili slika -->
        <dxi-item itemType="group" [colCount]="1" [colSpan]="1" *ngIf="pitanjeImaDokument">
            <dxi-item
                dataField="ispp_tekst"
                [label]="{text: 'Sadržaj pitanja'}"
                [editorOptions]="{stylingMode: 'filled'}"
                editorType="dxTextArea">
                <dxi-validation-rule 
                    type="required"
                    message="Sadržaj pitanja je obavezno polje!">
                </dxi-validation-rule>
            </dxi-item>
            <dxi-item itemType="group" [colCount]="2" [colSpan]="2">
                <dxi-item>
                    <ul><li><a href="{{datotekeURL}}+{{idUploadDokumentPitanjePrikaz}}+&access_token={{access_token}}" target="_blank">Prikaži datoteku</a></li></ul>
                </dxi-item>
            </dxi-item>
            <dxi-item
                itemType="group"
                [label]="{text: 'Vrsta pitanja'}">
                    <dx-radio-group
                    [(value)]="oblikPitanja"
                    [dataSource]="vrstaPitanja"
                    displayExpr="naziv"
                    valueExpr="id"
                    layout="horizontal"
                    [disabled]="true">
                    </dx-radio-group>
            </dxi-item>
        </dxi-item>
        </dx-form>
    </div>
    </dx-popup>

        <!-- Prikazi odgovor KVIZ -->
        <dx-popup
        title="Prikaz odgovora"
        [(visible)]="prikaziOdgovorPopup"
        (onHidden)="popupHiddenOdgovorUredi()"
        [width]="600"
        [height]="'auto'"
        [position]="{ my: 'center', at: 'center', of: window }">
            <div>
                <!-- KVIZ -->
                <div id="tabsKviz" *ngIf="oblikPitanja == 0">
                    <dx-tabs
                        *ngIf="vrstaOdgovora != null && oblikPitanja == 0"
                        [dataSource]="odgovoriTabsABCDprikaz"
                        [selectedIndex]="odabraniOdgovor"
                        (onItemClick)="odgovoriTabOdabirABCD($event)"
                    ></dx-tabs>
                    <!-- Odgovor A prikazi -->
                    <div *ngIf="odabraniOdgovor == 0">
                        <dx-form
                        *ngIf="imaOdgovora == true"
                        [(formData)]="jedanOdgovorABCD[0]"
                        [colCount]="1">
                            <!-- Tekst -->
                            <dxi-item itemType="group" [colCount]="1" [colSpan]="2" *ngIf="vrstaOdgovora == 0 && idUploadDokumentOdgovorPrikaz == null">
                                <dxi-item
                                    dataField="ispo_tekst"
                                    [editorOptions]="{stylingMode: 'filled'}"
                                    editorType="dxTextArea"
                                    disabled="true">
                                    <dxo-label [visible]="false"></dxo-label>
                                </dxi-item>
                                <dxi-item itemType="group">
                                    <dx-check-box
                                        *ngIf="idUploadDokumentOdgovorPrikaz || odgovorPrikaz"
                                        [(value)]="tocanIlinetocan"
                                        [width]="150"
                                        text="Točan odgovor"
                                        disabled="true">
                                    </dx-check-box>
                                </dxi-item>
                            </dxi-item>
                            <!-- Slika -->
                            <dxi-item itemType="group" *ngIf="vrstaOdgovora == 1 && idUploadDokumentOdgovorPrikaz != null" [colCount]="2" [colSpan]="2">
                                <dxi-item>
                                    <p><a href="{{datotekeURL}}+{{idUploadDokumentOdgovorPrikaz}}+&access_token={{access_token}}" target="_blank"><img src="{{datotekeURL}}+{{idUploadDokumentOdgovorPrikaz}}+&access_token={{access_token}}" height="100" width="auto"></a></p>
                                </dxi-item>
                                <dxi-item>
                                    <dx-check-box 
                                        *ngIf="idUploadDokumentOdgovorPrikaz || odgovorPrikaz"
                                        [(value)]="tocanIlinetocan"
                                        [width]="150"
                                        text="Točan odgovor"
                                        disabled="true">
                                    </dx-check-box>
                                </dxi-item>
                            </dxi-item>
                        </dx-form>
                    </div>
                    <!-- Odgovor B prikazi -->
                    <div *ngIf="odabraniOdgovor == 1">
                        <dx-form
                        *ngIf="imaOdgovora == true"
                        [(formData)]="jedanOdgovorABCD[1]"
                        [colCount]="1">
                            <!-- Tekst -->
                            <dxi-item itemType="group" [colCount]="1" [colSpan]="2" *ngIf="vrstaOdgovora == 0 && idUploadDokumentOdgovorPrikaz == null">
                                <dxi-item
                                    dataField="ispo_tekst"
                                    [editorOptions]="{stylingMode: 'filled'}"
                                    editorType="dxTextArea"
                                    disabled="true">
                                    <dxo-label [visible]="false"></dxo-label>
                                </dxi-item>
                                <dxi-item itemType="group">
                                    <dx-check-box
                                        *ngIf="idUploadDokumentOdgovorPrikaz || odgovorPrikaz"
                                        [(value)]="tocanIlinetocan"
                                        [width]="150"
                                        text="Točan odgovor"
                                        disabled="true">
                                    </dx-check-box>
                                </dxi-item>
                            </dxi-item>
                            <!-- Slika -->
                            <dxi-item itemType="group" *ngIf="vrstaOdgovora == 1 && idUploadDokumentOdgovorPrikaz != null" [colCount]="2" [colSpan]="2">
                                <dxi-item>
                                    <p><a href="{{datotekeURL}}+{{idUploadDokumentOdgovorPrikaz}}+&access_token={{access_token}}" target="_blank"><img src="{{datotekeURL}}+{{idUploadDokumentOdgovorPrikaz}}+&access_token={{access_token}}" height="100" width="auto"></a></p>
                                </dxi-item>
                                <dxi-item>
                                    <dx-check-box
                                        *ngIf="idUploadDokumentOdgovorPrikaz || odgovorPrikaz"
                                        [(value)]="tocanIlinetocan"
                                        [width]="150"
                                        text="Točan odgovor"
                                        disabled="true">
                                    </dx-check-box>
                                </dxi-item>
                            </dxi-item>
                        </dx-form>
                    </div>
                    <!-- Odgovor C prikazi -->
                    <div *ngIf="odabraniOdgovor == 2">
                        <dx-form
                        *ngIf="imaOdgovora == true"
                        [(formData)]="jedanOdgovorABCD[2]"
                        [colCount]="1">
                            <!-- Tekst -->
                            <dxi-item itemType="group" [colCount]="1" [colSpan]="2" *ngIf="vrstaOdgovora == 0 && idUploadDokumentOdgovorPrikaz == null">
                                <dxi-item
                                    dataField="ispo_tekst"
                                    [editorOptions]="{stylingMode: 'filled'}"
                                    editorType="dxTextArea"
                                    disabled="true">
                                    <dxo-label [visible]="false"></dxo-label>
                                </dxi-item>
                                <dxi-item itemType="group">
                                    <dx-check-box 
                                        *ngIf="idUploadDokumentOdgovorPrikaz || odgovorPrikaz"
                                        [(value)]="tocanIlinetocan"
                                        [width]="150"
                                        text="Točan odgovor"
                                        disabled="true">
                                    </dx-check-box>
                                </dxi-item>
                            </dxi-item>
                            <!-- Slika -->
                            <dxi-item itemType="group" *ngIf="vrstaOdgovora == 1 && idUploadDokumentOdgovorPrikaz != null" [colCount]="2" [colSpan]="2">
                                <dxi-item>
                                    <p><a href="{{datotekeURL}}+{{idUploadDokumentOdgovorPrikaz}}+&access_token={{access_token}}" target="_blank"><img src="{{datotekeURL}}+{{idUploadDokumentOdgovorPrikaz}}+&access_token={{access_token}}" height="100" width="auto"></a></p>
                                </dxi-item>
                                <dxi-item>
                                    <dx-check-box 
                                        *ngIf="idUploadDokumentOdgovorPrikaz || odgovorPrikaz"
                                        [(value)]="tocanIlinetocan" 
                                        [width]="150"
                                        text="Točan odgovor"
                                        disabled="true">
                                    </dx-check-box>
                                </dxi-item>
                            </dxi-item>
                        </dx-form>
                    </div>
                    <!-- Odgovor D prikazi -->
                    <div *ngIf="odabraniOdgovor == 3">
                        <dx-form
                        *ngIf="imaOdgovora == true"
                        [(formData)]="jedanOdgovorABCD[3]"
                        [colCount]="1">
                            <!-- Tekst -->
                            <dxi-item itemType="group" [colCount]="1" [colSpan]="2" *ngIf="vrstaOdgovora == 0 && idUploadDokumentOdgovorPrikaz == null">
                                <dxi-item
                                    dataField="ispo_tekst"
                                    [editorOptions]="{stylingMode: 'filled'}"
                                    editorType="dxTextArea"
                                    disabled="true">
                                    <dxo-label [visible]="false"></dxo-label>
                                </dxi-item>
                                <dxi-item itemType="group">
                                    <dx-check-box
                                        *ngIf="idUploadDokumentOdgovorPrikaz || odgovorPrikaz"
                                        [(value)]="tocanIlinetocan"
                                        [width]="150"
                                        text="Točan odgovor"
                                        disabled="true">
                                    </dx-check-box>
                                </dxi-item>
                            </dxi-item>
                            <!-- Slika -->
                            <dxi-item itemType="group" *ngIf="vrstaOdgovora == 1 && idUploadDokumentOdgovorPrikaz != null" [colCount]="2" [colSpan]="2">
                                <dxi-item>
                                    <p><a href="{{datotekeURL}}+{{idUploadDokumentOdgovorPrikaz}}+&access_token={{access_token}}" target="_blank"><img src="{{datotekeURL}}+{{idUploadDokumentOdgovorPrikaz}}+&access_token={{access_token}}" height="100" width="auto"></a></p>
                                </dxi-item>
                                <dxi-item>
                                    <dx-check-box
                                        *ngIf="idUploadDokumentOdgovorPrikaz || odgovorPrikaz"
                                        [(value)]="tocanIlinetocan"
                                        [width]="150"
                                        text="Točan odgovor"
                                        disabled="true">
                                    </dx-check-box>
                                </dxi-item>
                            </dxi-item>
                        </dx-form>
                    </div>
                </div>
            </div>
        </dx-popup>
    
        <!-- Prikaži odgovor TN-->
        <dx-popup
        title="Prikaži odgovor"
        [(visible)]="prikaziOdgovorPopupTN"
        (onHidden)="popupHiddenOdgovorUrediTN()"
        [width]="600"
        [height]="'auto'"
        [position]="{ my: 'center', at: 'center', of: window }">
            <div id="tabsTocnoNetocno">
                <dx-tabs
                    [dataSource]="odgovoriTabsTocnoNetocnoPrikaz"
                    [selectedIndex]="odabraniOdgovor"
                    (onItemClick)="odgovoriTabOdabirTN($event)"
                ></dx-tabs> <!-- *ngIf="vrstaOdgovora != null" -->
                <p>
                <!-- Odgovor A prikazi -->
                <div *ngIf="odabraniOdgovor == 0 && imaOdgovora == true">
                    <dx-form
                    id="formOdgovorUredi"
                    [(formData)]="jedanOdgovorTN[0]"
                    [colCount]="1">
                        <dxi-item itemType="group">
                            <dxi-item
                                dataField="ispo_tekst"
                                [editorOptions]="{stylingMode: 'filled'}"
                                editorType="dxTextArea"
                                disabled="true">
                                <dxo-label [visible]="false"></dxo-label>
                                <dxi-validation-rule
                                    type="required"
                                    message="Odgovor A je obavezno polje!">
                                </dxi-validation-rule>
                            </dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group">
                            <dx-check-box
                                [(value)]="tocanIlinetocan"
                                [width]="150"
                                text="Točan odgovor"
                                disabled="true">
                            </dx-check-box>
                        </dxi-item>
                    </dx-form>
                </div>
                <!-- Odgovor B prikazi -->
                <div *ngIf="odabraniOdgovor == 1 && imaOdgovora == true">
                    <dx-form
                    id="formOdgovorUredi"
                    [(formData)]="jedanOdgovorTN[1]"
                    [colCount]="1">
                        <dxi-item itemType="group">
                            <dxi-item
                                dataField="ispo_tekst"
                                [editorOptions]="{stylingMode: 'filled'}"
                                editorType="dxTextArea"
                                disabled="true">
                                <dxo-label [visible]="false"></dxo-label>
                                <dxi-validation-rule
                                    type="required"
                                    message="Odgovor B je obavezno polje!">
                                </dxi-validation-rule>
                            </dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group">
                            <dx-check-box
                                [(value)]="tocanIlinetocan"
                                [width]="150"
                                text="Točan odgovor"
                                disabled="true">
                            </dx-check-box>
                        </dxi-item>
                    </dx-form>
                </div>
            </div>
        </dx-popup>