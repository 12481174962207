<dx-validation-group>
  <div class="login-header">
    <div class="title">{{appInfo.title}}</div>
    <span style="float: right; margin-right: 15px; font-size: 11px;">v.140323</span>
  </div>
  <div class="dx-field">
    <dx-text-box [(value)]="login" placeholder="Korisničko ime" width="100%">
      <dx-validator>
        <dxi-validation-rule type="required" message="Korisničko ime je obavezno polje!"></dxi-validation-rule>
      </dx-validator>
    </dx-text-box>
  </div>
  <div class="dx-field">
    <dx-text-box [(mode)]="passwordMode" [(value)]="password" placeholder="Lozinka" width="100%">
      <dx-validator>
        <dxi-validation-rule type="required" message="Lozinka je obavezno polje!"></dxi-validation-rule>
      </dx-validator>
    </dx-text-box>
    <dx-button *ngIf="passwordMode == 'text'" icon="doesnotcontain" (onClick)="prikaziLozinku()" hint="Prikaži lozinku"></dx-button>
    <dx-button *ngIf="passwordMode == 'password'" icon="startswith" (onClick)="prikaziLozinku()" hint="Prikaži lozinku"></dx-button>
  </div>
  <div class="dx-field">
    <dx-button type="default" text="Login" (onClick)="onLoginClick($event)" width="100%"></dx-button>
  </div>
  <div class="dx-field">
    <a routerLink="/reset-lozinke">Zaboravljena lozinka?</a>
  </div>
</dx-validation-group>