<div class="content-block dx-card">
    <!-- <div class="form-avatar">
      <img src="https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/{{employee.Picture}}"/>
    </div> -->
    <h2 class="content-block">{{imePrezime}}</h2>
</div>

<div class="content-block dx-card responsive-paddings">
    <!-- Forma -->
    <dx-form id="form"
      [formData]="korisnikDetalji"
      labelLocation="top"
      [colCountByScreen]="colCountByScreen"
      [showColonAfterLabel]="true"
    >
      <dxi-item
        dataField="kor_korisnickoIme"
        [label]="{text: 'Korisničko ime'}"
        [editorOptions]="{readOnly:true, stylingMode:'filled'}">
      </dxi-item>
      <dxi-item
        dataField="kor_kvalifikacije"
        [label]="{text: 'Kvalifikacije'}"
        [editorOptions]="{readOnly:true, stylingMode:'filled'}" 
        *ngIf="pravaApi == 2">
      </dxi-item>
      <dxi-item
        dataField="kor_oib"
        [label]="{text: 'OIB'}"
        [editorOptions]="{readOnly:true, stylingMode:'filled'}">
      </dxi-item>
      <dxi-item
        dataField="kor_telefon"
        [label]="{text: 'Telefon'}"
        [editorOptions]="{readOnly:true, stylingMode:'filled'}">
      </dxi-item>
      <dxi-item
        dataField="kor_email"
        [label]="{text: 'E-mail'}"
        [editorOptions]="{readOnly:true, stylingMode:'filled'}">
      </dxi-item>
      <dxi-item
        dataField="kor_napomena"
        [label]="{text: 'Napomena'}"
        [editorOptions]="{readOnly:true, stylingMode:'filled'}">
      </dxi-item>
    </dx-form>
</div>