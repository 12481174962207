import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ApiService } from './../../shared/services/api.service';

import { custom } from 'devextreme/ui/dialog';
import { encodeHtml } from 'devextreme/core/utils/string';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-predmeti',
  templateUrl: './predmeti.component.html',
  styleUrls: ['./predmeti.component.scss']
})

export class PredmetiComponent implements OnInit {

  access_token: any = localStorage.getItem('access_token');
  prava = localStorage.getItem('kor_prava');

  naslov: string;
  window: any;
  dataSource = [];
  priority: any[];
  mozeSve: boolean;
  superadmin = false;
  mozePredavac: boolean = false;

  constructor(private _router: Router, 
              public _api: ApiService) 
  { this.onShowing = this.onShowing.bind(this); }

  ngOnInit() {
    this.popisPredmeta();
    this.provjeraPrava();
  }

  provjeraPrava(){
    if(this.prava == '0' || this.prava == '1'){
      this.mozeSve = true;
    } else if (this.prava == '2'){
      this.mozePredavac = true;
    } else {
      this.mozeSve = false;
    }
    // if(this.prava === '0'){
    //   this.superadmin = true;
    // } else {
    //   this.superadmin = false;
    // }
  }

  popisPredmeta(){
    let podaciZaSlanje = {
      "idkorisnik": localStorage.getItem('idkorisnik'), 
      "prava": +localStorage.getItem('kor_prava'),
      "access_token": this.access_token
    };
    this._api.predmetPopisMoj(podaciZaSlanje).subscribe(res=>{
      // console.log(res);
      // console.log(res.predmeti);
      if(res.success == true){
        this.dataSource = res.predmeti;
      } else {
         notify(res.message, "error");
      }
    });
  }

  urediPredmet(e){
      // if (e.rowType == "data" && e.column.dataField === "pred_naziv") {
        this._router.navigate(['uredi-predmet/' + e.data.idpredmet]);
      // }
  }

  onRowInserted(e){
    let predmetZaSlanje = {
      "naziv": e.data['pred_naziv'],
      "idkorisnik": localStorage.getItem('idkorisnik'),
      "predavac": [],
      "access_token": this.access_token
    };
    // console.log(predmetZaSlanje);
    this._api.predmetDodaj(predmetZaSlanje).subscribe(res=>{
      // console.log(res);
      if(res.success == true){
        this.popisPredmeta();
        // notify(res.message, "success"); // API vraćao ovakvu poruku: "Dodan predmet i predavači"
        notify("Predmet uspješno kreiran!", "success");
      } else {
        notify(res.message, "error");
        this.popisPredmeta();
      }
    });
  }

  onInitNewRow(e){
    this.naslov = 'Kreiranje predmeta';
  }

  // Uređivanje - prebaciti na ekran Uredi-Predmet

  // onRowUpdated(e){
  //   let predmetZaEdit = {
  //     "idpredmet": e.data['idpredmet'],
  //     "naziv": e.data['pred_naziv'],
  //     "access_token": localStorage.getItem('access_token')
  //   };
  //   this._api.predmetUredi(predmetZaEdit).subscribe(res=>{
  //     if(res.success == true){
  //       notify(res.message, "success");
  //       this.popisPredmeta();
  //     } else {
  //       notify(res.message, "error");
  //     }
  //   });
  // }

  onShowing(e){
    e.component.option("title", this.naslov);
  }

  // Brisanje

  brisanjePredmeta(e){
    let encodedMessage = encodeHtml("Jeste li sigurni da želite obrisati predmet?");
      let myDialog = custom({
          title: "Brisanje predmeta",
          messageHtml: encodedMessage,
          buttons: [{
              text: "OBRIŠI",
              onClick: (e) => {
                  return { buttonText: e.component.option("text") }
              }
          },{
            text: "ODUSTANI",
            onClick: (e) => {
                return { buttonText: e.component.option("text") }
            }
        }]
      });
      myDialog.show().then((dialogResult) => {
          if(dialogResult.buttonText === 'OBRIŠI'){
            this._api.predmetObrisi({"access_token": this.access_token, "idpredmet": e.data.idpredmet}).subscribe(res => {
              if(res.success == true){
                notify(res.message, "success");
                this.popisPredmeta();
              } else {
                notify(res.message, "error");
              }    
            })     
          }else{}  
      });
  }

}