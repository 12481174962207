import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ApiService } from 'src/app/shared/services/api.service';

import notify from 'devextreme/ui/notify';

 @Component({
  templateUrl: 'pocetna.component.html',
  styleUrls: [ './pocetna.component.scss' ]
})

export class PocetnaComponent implements OnInit {

  access_token: any = localStorage.getItem('access_token');

  ucionice = [];
  prava: string;
  poruka: string;
  korisnikID: any;

  constructor(
    private _api: ApiService,
    private _router: Router
  ) {
    this.korisnikID = parseInt(localStorage.getItem('idkorisnik'));   
  }

  ngOnInit(){
    this.provjeraPrava();
  }

  provjeraPrava(){
    this.prava = localStorage.getItem('kor_prava');
    if(this.prava == '0'){
      this.poruka = "SuperAdministrator - početna";
      this.ucionicePopis();
    } else if (this.prava == '1') {
      this.poruka = "Administrator - početna";
      this.ucionicePopis();
    } else if (this.prava == '2') {
      this.poruka = "Predavač - početna";
      this.ucionicePredavac();
    } else {
      this.poruka = "Polaznik - početna";
      this.ucioniceJednogPolaznika();
    }    
  }

  jednaUcionica(e){
    let nazivUcionice = e.uci_naziv.replace('/','%2F');
    if(this.prava === '1' && e.administrira === 1){
      this._router.navigate(['uredi-ucionicu/' + e.iducionica]);
    } else if (this.prava === '1' && e.administrira === 0) {
      this._router.navigate(['jedna-ucionica/' + e.iducionica + '/' + nazivUcionice]);
    } else if(this.prava === '0'){
      this._router.navigate(['uredi-ucionicu/' + e.iducionica]);
    } else if (this.prava === '3'){
      if(e.uci_aktivna === 1){
        this._router.navigate(['ucionica-polaznik/' + e.iducionica + '/' + nazivUcionice]);
      } else {
        notify("Učionica je zatvorena!", "info", 1000);
      }
    } else if (this.prava === '2'){
      if(e.uci_aktivna === 1){
        this._router.navigate(['ucionica-predavac/' + e.iducionica]);
      } else {
        notify("Učionica je zatvorena!", "info", 1000);
      }
    }
  }

  ucionicePopis(){
    this._api.ucionicaPopis({"access_token": this.access_token, "idkorisnik": this.korisnikID, "prava": +this.prava}).subscribe(res => {    
      if(res.success == true){
        for(let i = 0; i < res.ucionice.length; i++){
           if(res.ucionice[i].uci_aktivna){
            this.ucionice.push(res.ucionice[i]);
           }
        }
      } else {
        notify(res.message, "error", 1000);
      }
    })
  }

  ucioniceJednogPolaznika(){
    this._api.ucionicaPopisPolaznik({"access_token": this.access_token, "idkorisnik": this.korisnikID}).subscribe(res => {  
      if(res.success == true){
        for(let i = 0; i < res.ucionice.length; i++){
          // if(res.ucionice[i].uci_aktivna){
           this.ucionice.push(res.ucionice[i]);
          // }
        }
      } else {
        notify(res.message, "error", 1000);
      }
    })
  }
  
  ucionicePredavac(){
    this._api.ucionicaPopisZaPredavaca({"access_token": this.access_token, "idkorisnik": this.korisnikID}).subscribe(res => {
      if(res.success == true){
        for(let i = 0; i < res.ucionice.length; i++){
          //Predavač će na početnoj vidjeti samo popis aktivnih učionica u kojima trentuno predaje
          //Sve ostale učionice u kojima je predavao može vidjeti na ekranu Učionice
          if(res.ucionice[i].uci_aktivna){
           this.ucionice.push(res.ucionice[i]);
          }
        }
      } else {
        notify(res.message, "error", 1000);
      }
    })
  }

}