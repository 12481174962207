<div class="content-block dx-card responsive-paddings">
    <dx-data-grid
        [showRowLines]="false"
        class="wide-card"
        [hoverStateEnabled]="true"
        [wordWrapEnabled]="true"
        [dataSource]="kvizovi"
        [columnAutoWidth]="true"
        [columnHidingEnabled]="true"
        (onCellHoverChanged)="hover($event)"
        (onCellClick)="cellClickEvent($event)"
        (onToolbarPreparing)="onToolbarPreparing($event)"
        keyExpr="idkviz">

    <dxo-paging [pageSize]="10"></dxo-paging>
    <dxo-pager [showPageSizeSelector]="true" [showInfo]="true"></dxo-pager>
    <dxo-search-panel [visible]="true"></dxo-search-panel>

        <dxi-column dataField="nazivKviza" caption="Naziv kviza" ></dxi-column>
        <dxi-column [visible]="vrstaKviza == '1'" dataField="postotakRjesenosti" caption="Postotak riješenosti" alignment="center" cellTemplate="datum"></dxi-column>
        <div *dxTemplate="let options of 'datum'">
            <p *ngIf="options.data.postotakRjesenosti">{{options.data.postotakRjesenosti}}%</p>
            <p>{{ options.data.rjesenoNaDatum | date: 'dd.MM.yyyy.' }}</p>
        
        </div>
      
        <dxi-column dataField="brojPitanja" caption="Broj pitanja" alignment="center"></dxi-column>

        <div *dxTemplate="let data of 'naslovTablice'">
            <div class="naslov">
                    <h2 class="name">{{ naslov }}</h2>
            </div>
        </div>

    </dx-data-grid>

<dx-popup
    [width]="700"
    [maxHeight]="500"
    title="{{ nazivPredmeta }}"
    [(visible)]="kvizPopup">

<dxi-toolbar-item
        widget="dxButton"
        location="after"
        cssClass="infoButton"
        [options]="{
            type:'success',
            icon: 'info',
            onClick: notifikacija }">
</dxi-toolbar-item>

    <dx-scroll-view height="90%" class="full-height-scrollable">
        <!-- <p class="note">Pažnja: Neka pitanja mogu imati višestruke odgovore.</p> -->

            <div class="podnaslov">
                <p>{{ prviBroj }}/{{ ukupnoPitanja }} pitanja</p>
            </div>

            <!--------------------------PITANJE--------------------------->
            <div *ngIf="pitanjeSlika">
                <p>{{ prviBroj }}. {{ pitanjeKviz }}</p>
                <img class="kvizSlika" src="{{datotekeURL}}+{{slika}}+&access_token={{access_token}}" (click)="slikaPrikazi(slika)">
            </div>

            <div *ngIf="!pitanjeSlika">
                <p>{{ prviBroj }}. {{ pitanjeKviz }}</p>
            </div>

            <!--------------------------ODGOVORI--------------------------->
        <p>Odaberite odgovor:</p>
        <div class="odgovori">
            <div *ngFor="let odgovor of ponudjeniOdgovori; let i = index;">           
                <span class="slova"> {{slovo[i]}}</span>
                <input type="checkbox" class="check" name="check" [value]="odgovor.idkvizodgovor" (change)="checboxClicked($event, odgovor)">
           
                <span *ngIf="!odgovor.iduploaddokument">{{ odgovor.pitanje }}</span>
              
                <span *ngIf="odgovor.iduploaddokument">
                    <img id="odgovor{{odgovor.idkvizodgovor}}" class="slikatumbnail" src="{{datotekeURL}}+{{odgovor.iduploaddokument}}+&access_token={{access_token}}" (click)="slikaPrikazi(odgovor.iduploaddokument)">
                </span>

                <dx-popover *ngIf="odgovor.iduploaddokument"
                    target="#odgovor{{odgovor.idkvizodgovor}}"
                    position="top"
                    showEvent="mouseenter"
                    hideEvent="mouseleave"
                    contentTemplate="popoverContent"
                    [height]="'auto'">
                    <dxo-animation>
                        <dxo-show
                            type="pop"
                            [from]="{ scale: 0 }"
                            [to]="{ scale: 1 }"></dxo-show>
                        <dxo-hide
                            type="fade"
                            [from]="1"
                            [to]="0"></dxo-hide>
                    </dxo-animation>
                    <div *dxTemplate="let data of 'popoverContent'">
                            <img class="odgovorSlika" src="{{datotekeURL}}+{{odgovor.iduploaddokument}}+&access_token={{access_token}}">                     
                    </div>
                </dx-popover>
            </div>
        </div>
      
          
    </dx-scroll-view>
    <dx-button id="boja" [style.background-color]="boja" *ngIf="prviBroj != 1" icon="info" type="default"
               (mouseenter)="toggleDefault()" (mouseleave)="toggleDefault()">
    </dx-button>
    <p  class="porukaPrethodnoPitanje">{{ porukaOTocnosti }}</p>

    <dx-button class="btnNext" *ngIf="!prikaziZavrsniGumb" icon="arrowright" text="Sljedeće pitanje" type="default" (onClick)="next()"></dx-button>
    <dx-button class="btnNext" *ngIf="prikaziZavrsniGumb" type="default" (onClick)="posaljiOdgovore()">Završi kviz</dx-button>

</dx-popup>

<dx-popup
    [width]="300"
    [height]="'auto'"
    [showTitle]="true"
    [showCloseButton]="true"
    title="Kviz završen"
    [(visible)]="kvizZavrsenPopup">
    <p class="poruka">{{ porukaOKvizu }}</p>
</dx-popup>