<div class="content-block" id="ispiti" *ngIf="pocetakIspita">
    <div class="klasa">
        <p>Pitanja:</p>
        <div *ngFor="let pitanje of pitanjaArray; let i = index" class="navigacija">
            <dx-button
                stylingMode="contained"
                [text]="i+1"
                type="normal"
                [width]="40"
                (onClick)="clickPitanje(i, pitanje)"
                [ngClass]="[i == elementIndex ? 'aktivan' : 'neaktivan' ]"
                [disabled]="pitanje.aktivno === false"
                >

                <!--       [ngClass]="[i == elementIndex ? 'aktivan' : 'neaktivan' ]"   -->
                <!--     [disabled]="pitanje.idispitpitanje === rjesenjaKorisnika[index].ispitpitanje_idispitpitanje"     -->
            </dx-button>
        </div>
        <dx-button type="danger" text="Završi ispit" [width]="140" (onClick)="zavrsiIspit($event)"></dx-button>
    </div>
  
    <!-- <p class="countdown">{{ odbrojavanje | date: 'hh:mm:ss' }}</p>   -->
    <p class="countdown">{{ odbrojavanje }}</p>
<div class="pitanja">
    <p class="nazivIspita">{{ naslovIspita }}</p>
    <!-- <p class="pitanjeNaslov">{{ naslovPitanja }}</p> -->
</div>
<div class="odgovoriCss">
    <!--------------------------PITANJE--------------------------->
    <div *ngIf="pitanjeSlika">
        <p>{{ naslovPitanja }} {{ pitanjeIspit }}</p>
        <img class="kvizSlika" src="{{datotekeURL}}+{{slika}}+&access_token={{access_token}}" (click)="slikaPrikazi(slika)">
    </div>

    <div *ngIf="!pitanjeSlika">
        <p>{{ naslovPitanja }} {{ pitanjeIspit }}</p>
    </div>

        <!--------------------------ODGOVORI--------------------------->
        <p *ngIf="oblik === 'kviz'">Odaberite odgovor: </p>
        <div class="slikeOdgovori">
            <div *ngFor="let odgovor of ponudjeniOdgovori; let i = index;">
                <span class="slova">{{slovo[i]}}</span>
                <input type="checkbox" class="check" name="check" [value]="odgovor.idispitodgovor" (change)="checboxClicked($event, odgovor)"> 
           
                <span *ngIf="!odgovor.uploaddokument_iduploaddokument">{{ odgovor.ispo_tekst }}</span>
              
                <span *ngIf="odgovor.uploaddokument_iduploaddokument">
                    <img id="odgovor{{odgovor.idispitodgovor}}" class="slikatumbnail" src="{{datotekeURL}}+{{odgovor.uploaddokument_iduploaddokument}}+&access_token={{access_token}}" (click)="slikaPrikazi(odgovor.uploaddokument_iduploaddokument)">
                </span>

                <dx-popover *ngIf="odgovor.uploaddokument_iduploaddokument"
                    target="#odgovor{{odgovor.idispitodgovor}}"
                    position="top"
                    showEvent="mouseenter"
                    hideEvent="mouseleave"
                    contentTemplate="popoverContent"
                    [height]="'auto'">
                    <dxo-animation>
                        <dxo-show
                            type="pop"
                            [from]="{ scale: 0 }"
                            [to]="{ scale: 1 }"></dxo-show>
                        <dxo-hide
                            type="fade"
                            [from]="1"
                            [to]="0"></dxo-hide>
                    </dxo-animation>
                    <div *dxTemplate="let data of 'popoverContent'">
                            <img class="odgovorSlika" src="{{datotekeURL}}+{{odgovor.uploaddokument_iduploaddokument}}+&access_token={{access_token}}">                     
                    </div>
                </dx-popover>
            </div>
        </div>
      
        <!---------- ako je oblik pitanja esej -------------->
        <div *ngIf="oblik === 'esej'">
            <dx-text-area
                stylingMode='outlined'
                [maxLength]="500"
                [(value)]="jedanOdgovor">
            </dx-text-area>
        </div>

        <!---------- ako je oblik pitanja nadopuna -------------->
        <div *ngIf="oblik === 'nadopuna'">
            <p>Napomena: Ako postoji više odgovora odvojite ih zarezom.</p>
            <dx-text-box stylingMode='filled' [(value)]="jedanOdgovor"></dx-text-box>
        </div>

        <!---------- ako je oblik pitanja upload -------------->
        <div *ngIf="oblik === 'upload'">
            <dx-file-uploader
                #notesUploader
                uploadUrl="{{uploadURL}}"
                [multiple]="false"
                accept="image/*,application/pdf"
                [(value)]="value"
                uploadMode="instantly"
                (onUploaded)="onUploaded($event)">
            </dx-file-uploader>
        </div>

        <dx-button *ngIf="!odgovoreno && !istekloVrijeme" class="button" type="default" text="Spremi odgovor" (onClick)="posaljiOdgovor($event, idispitpitanje, oblik, jedanOdgovor )"></dx-button>  

</div>
</div>

<dx-popup
    [width]="600"
    [height]="'auto'"
    [showTitle]="true"
    [showCloseButton]="false"
    title="Ispit"
    [(visible)]="ispitPocinjePopup">
    <dxi-toolbar-item
        widget="dxButton"
        location="after"
        [options]="{
            icon: 'close',
            onClick: onClose
        }">
    </dxi-toolbar-item>
    <p>
        Klikom na "Započni" počet će teći vrijeme trajanja Vašeg ispita koje je ograničeno.
        Odgovarate jedno po jedno pitanje. Prelaskom na novo pitanje, prethodno pitanje se zaključava te na njega nemate prava više odgovarati.
        Ukoliko ste razumijeli sve navedeno, možete krenuti s pisanjem ispita.
    </p>
  
        <dx-button  class="btnNext" type="default" text="Započni ispit" (onClick)="pokreniIspit()"></dx-button>
</dx-popup>

<!---- kraj ispita ----->
<dx-popup
    [width]="600"
    [height]="'auto'"
    [showTitle]="true"
    title="Ispit"
    [showCloseButton]="false"
    [(visible)]="krajIspitaPopup">

<p>
    Isteklo je vrijeme ispita. Vaš ispit je završen.
</p>
<dx-button class="ok" type="default" text="OK" (onClick)="ispitZavrsen()"></dx-button>
</dx-popup>

<!-- odgovorio na sva pitanja -->
<dx-popup
    [width]="600"
    [height]="'auto'"
    [showTitle]="true"
    title="Ispit"
    [showCloseButton]="false"
    [(visible)]="zavrsiPopup">

<p>
    Završili ste ispit.
</p>
<dx-button class="ok" type="default" text="OK" (onClick)="zavrsiIspitBtn()"></dx-button>
</dx-popup>


<!-- 
      [ngClass]="[index == 1 ? 'aktivan' : 'neaktivan']" 
      [style.background]="boja"   
-->