import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ApiService } from './../../shared/services/api.service';

import notify from 'devextreme/ui/notify';
import { DxTabsModule, DxSelectBoxModule } from 'devextreme-angular';

@Component({
  selector: 'app-korisnici',
  templateUrl: './korisnici.component.html',
  styleUrls: ['./korisnici.component.scss']
})

export class KorisniciComponent implements OnInit {

  prava = localStorage.getItem('kor_prava');
  korisnikID = parseInt(localStorage.getItem('idkorisnik'));
  access_token: any = localStorage.getItem('access_token');

  allowAdding: boolean = false;
  allowUpdating: boolean = false;
  window: any;
  naslov: string;
  odabraniTab: number = 0;
  hideCheckbox: boolean = false;
  prikaziDetaljeProfila: boolean;
  popupPodaci: any;
  editing: boolean = false;
  dataSource = [];
  // columnNumber: number; onInitNewRow = 1; onEditingStart = 2;
  widthPopup: number = 500;
  maxHeight: number = 600;
  tabs = [
    { id: 0, text: "Super Administrator" },
    { id: 1, text: "Administrator" },
    { id: 2, text: "Predavač" },
    { id: 3, text: "Polaznik" }
  ];

  constructor(
    public _api: ApiService,
    private _router: Router
  ){ 
    this.onShowing = this.onShowing.bind(this); 
    // this.provjeraOIB = this.provjeraOIB.bind(this);
    this.provjeraEMAIL = this.provjeraEMAIL.bind(this);
    this.validateRange = this.validateRange.bind(this);
  }

  ngOnInit() {
    this.popisKorisnika(this.odabraniTab);
    this.provjeraPrava();
  }

  validateRange(e){
    var isValid = true;
    if(e.value){
      if(e.value.length != 11){
        isValid = false;
      }
    }
    return isValid;
  }

  provjeraPrava(){
    if(this.prava == '0'){
      this.allowAdding = true;
      this.allowUpdating = true;
    } else {
      if (this.odabraniTab == 2 || this.odabraniTab == 3 && this.prava == '1') {
        this.allowAdding = true;
        this.allowUpdating = true;
      } else {
        this.allowAdding = false;
        this.allowUpdating = false;
      }
    }
  }

  popisKorisnika(razinaPrava){
    this._api.korisnikPopis({"prava":razinaPrava, "access_token": this.access_token}).subscribe(res=>{
      if(res.success == true){
        this.dataSource = res.korisnici;
      } else {
        notify(res.message, "error", 1000);
      }
    });
  }

  selectTab($event){
    this.odabraniTab = $event.itemIndex;
    // console.log(this.odabraniTab);
    this.popisKorisnika(this.odabraniTab);
    //Omogućeno dodavanje i uređivanje ovisno o pravima 
    this.provjeraPrava();
  }

  cellHover(e){
    if (e.rowType === "data" && e.column.dataField === "kor_punoIme"){
      if (e.eventType == 'mouseover'){
        e.cellElement.style.fontWeight = "bold";
        e.cellElement.style.fontSize = "18px";
        e.cellElement.style.cursor = "pointer";
      } else {
        e.cellElement.style.fontWeight = "normal";
        e.cellElement.style.fontSize = "14px";
      }
    }
    if (e.rowType === "data" && e.column.dataField === "kor_email"){
      if (e.eventType == 'mouseover'){
        e.cellElement.style.fontWeight = "bold";
        e.cellElement.style.fontSize = "18px";
        e.cellElement.style.cursor = "pointer";
      } else {
        e.cellElement.style.fontWeight = "normal";
        e.cellElement.style.fontSize = "14px";
      }
    }
  }

  onShowing(e){
    e.component.option("title", this.naslov);
  }

  onInitNewRow(e){
    // this.widthPopup = 500;
    // this.maxHeight = 600;
    this.hideCheckbox = false;
    if(this.odabraniTab == 0){
      this.naslov = 'Kreiranje super administratora';
    } else if (this.odabraniTab == 1){
      this.naslov = 'Kreiranje administratora';
    } else if (this.odabraniTab == 2){
      this.naslov = 'Kreiranje predavača';
    } else {
      this.naslov = 'Kreiranje polaznika';
    }
  }

  //Slanje korisnika na API - dodavanje
  onRowInserted(e){
    // console.log(e);
    let korisnikZaSlanje = {
        "punoIme": e.data['kor_punoIme'],
        "oib": e.data['kor_oib'],
        "email": e.data['kor_email'],
        "telefon": e.data['kor_telefon'],
        "kvalifikacije": e.data['kor_kvalifikacije'],
        "prava": this.odabraniTab,
        "napomena": e.data['kor_napomena'],
        "kreirao": localStorage.getItem('idkorisnik'),
        "aktivan": true,
        "access_token": this.access_token
    };
    this._api.korisnikDodaj(korisnikZaSlanje).subscribe(res=>{
      if(res.success == true){
        this.popisKorisnika(this.odabraniTab);
      } else {
        notify(res.message, "error", 1000);
      }
    });
  }

  onEditingStart(e){
    this.editing = true;
    // this.widthPopup = 800;
    // this.maxHeight = 1000;
    this.hideCheckbox = true;
    if(this.odabraniTab == 0){
      this.naslov = 'Uređivanje super administratora';
    } else if (this.odabraniTab == 1){
      this.naslov = 'Uređivanje administratora';
    } else if (this.odabraniTab == 2){
      this.naslov = 'Uređivanje predavača';
    } else {
      this.naslov = 'Uređivanje polaznika';
    }
  }

  onRowUpdated(e){
    let korisnikZaEdit = {
      "idkorisnik": e.data['idkorisnik'],
      "punoIme": e.data['kor_punoIme'],
      "oib": e.data['kor_oib'],
      "email": e.data['kor_email'],
      "telefon": e.data['kor_telefon'],
      "kvalifikacije": e.data['kor_kvalifikacije'],
      "napomena": e.data['kor_napomena'],
      "access_token": this.access_token
    };
    this._api.korisnikUredi(korisnikZaEdit).subscribe(res=>{
      if(res.success == true){
        notify(res.message, "success", 1000);
        this.popisKorisnika(this.odabraniTab);
      } else {
        notify(res.message, "error", 1000);
      }
    });
  }

  //OIB validacija
  // provjeraOIB(e){
  //   return new Promise((resolve) => {
  //     this._api.korisnikProvjeraOIB({"oib":e.value}).subscribe(res=>{
  //       if(res.success == true){
  //         resolve(true);
  //       } else {
  //         resolve(false);
  //       }
  //     });
  //   });
  // }

  provjeraEMAIL(e){
    return new Promise((resolve) => {
      this._api.korisnikProvjeraEmail({"email":e.value, "access_token": this.access_token}).subscribe(res=>{
        if(res.success == true){
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  prikazProfila(e){
    // console.log(e);
    if (e.rowType == "data" && e.column.dataField === "kor_punoIme") {
      this._router.navigate(['korisnik/' + e.data.idkorisnik]);
     // this.prikaziDetaljeProfila = true;
      // this.popupPodaci = e.data;
    } else if (e.rowType == "data" && e.column.dataField === "kor_aktivan") {
      if(this.prava == '0' && this.allowAdding || this.prava == '1' && this.allowAdding){
        if(e.data['idkorisnik'] != this.korisnikID){
          this._api.korisnikAktivacija({"idkorisnik":e.data.idkorisnik, "access_token": this.access_token}).subscribe(res=>{
            // console.log(res);
            if(res.success == true){
              notify(res.message, "success", 1000);
              this.popisKorisnika(this.odabraniTab);
            } else {
              notify(res.message, "error", 1000);
            }
          });
        } else {
          notify("Ne možete deaktivirati svoj profil!", "error", 1000);
        }
      } else {
        notify("Nemate potrebna prava za de/aktivaciju!", "error", 1000);
      }
    }
  }

}