import { Component, NgModule, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DxListModule } from 'devextreme-angular/ui/list';
import { DxContextMenuModule } from 'devextreme-angular/ui/context-menu';

@Component({
  selector: 'app-user-panel',
  templateUrl: 'user-panel.component.html',
  styleUrls: ['./user-panel.component.scss']
})

export class UserPanelComponent {
  @Input()
  menuItems: any;

  @Input()
  menuMode: string;

  imePrezime: string;

  constructor() { this.prikazKorisnickogImena(); }

  prikazKorisnickogImena(){
    this.imePrezime = localStorage.getItem('kor_punoIme');
    // if(localStorage.getItem('kor_punoIme').length > 20){
    //   this.imePrezime = localStorage.getItem('kor_punoIme').substring(0, 17) + '...';
    // } else {
    //   this.imePrezime = localStorage.getItem('kor_punoIme');
    // }
  }

}

@NgModule({
  imports: [
    DxListModule,
    DxContextMenuModule,
    CommonModule
  ],
  declarations: [ UserPanelComponent ],
  exports: [ UserPanelComponent ]
})
export class UserPanelModule { }
