import { Component, OnInit } from '@angular/core';

import { ApiService } from './../../shared/services/api.service';

import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-sektori',
  templateUrl: './sektori.component.html',
  styleUrls: ['./sektori.component.scss']
})

export class SektoriComponent implements OnInit {

  access_token: any = localStorage.getItem('access_token');

  window: any;
  dataSource = [];
  naslov: string;

  constructor(
    public _api: ApiService
  ) { this.onShowing = this.onShowing.bind(this); }

  ngOnInit() {
    this.dohvatiPopisSektora();
  }

  dohvatiPopisSektora(){
    this._api.sektorPopis({"access_token": this.access_token}).subscribe(res=>{
      // console.log(res);
      // console.log(res.sektori);
      if(res.success == true){
        this.dataSource = res.sektori;
      } else {
        notify(res.message, "error", 1000);
      }
    });
  }

  onShowing(e){  
    // console.log(e);
    e.component.option("title", this.naslov);
  }

  onInitNewRow(e){
    this.naslov = 'Kreiranje sektora';
  }

  onEditingStart(e){
    this.naslov = 'Uređivanje sektora';
  }

  onRowInserted(e){
    // console.log('onRowInserted');
    // console.log(e);
    let sektorZaSlanje = {
        "naziv": e.data['ssek_naziv'],
        "opis": e.data['ssek_opis'],
        "access_token": this.access_token
    };
    // console.log(sektorZaSlanje);
    this._api.sektorDodaj(sektorZaSlanje).subscribe(res=>{
      // console.log(res);
      if(res.success == true){
        this.dohvatiPopisSektora();
      } else {
        notify(res.message, "error", 1000);
      }
    });
  }

  onRowUpdated(e){
    // console.log(e);
    let sektorZaEdit = {
        "idsektor": e.data['idsifarniksektor'],
        "naziv": e.data['ssek_naziv'],
        "opis": e.data['ssek_opis'],
        "access_token": this.access_token
    };
    // console.log(sektorZaEdit);
    this._api.sektorUredi(sektorZaEdit).subscribe(res=>{
      // console.log(res);
      if(res.success == true){
        notify(res.message, "success", 1000);
        this.dohvatiPopisSektora();
      } else {
        notify(res.message, "error", 1000);
      }
    });
  }

}