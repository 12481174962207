export const navigationSuperAdministrator = [
  {
    text: 'Početna',
    path: '/pocetna',
    icon: 'home'
  },
  {
    text: 'Korisnici',
    path: '/korisnici',
    icon: 'user'
  },
  {
    text: 'Šifarnik',
    icon: 'folder',
    items: [
      {
        text: 'Gradovi',
        path: '/gradovi'
      },   
      {
        text: 'Sektori',
        path: '/sektori'
      },
      {
        text: 'Vrste programa',
        path: '/vrste-programa'
      },
      {
        text: 'Programi',
        path: '/programi'
      }
    ]
  },
  {
    text: 'Predmeti',
    path: '/predmeti',
    icon: 'activefolder'
  },
  {
    text: 'Učionice',
    path: '/ucionice',
    icon: 'contentlayout'
  }
];

export const navigationAdministrator = [
  {
    text: 'Početna',
    path: '/pocetna',
    icon: 'home'
  },
  {
    text: 'Korisnici',
    path: '/korisnici',
    icon: 'user'
  },
  {
    text: 'Predmeti',
    path: '/predmeti',
    icon: 'activefolder'
  },
  {
    text: 'Učionice',
    path: '/ucionice',
    icon: 'contentlayout'
  }
];

export const navigationPredavac = [
  {
    text: 'Početna',
    path: '/pocetna',
    icon: 'home'
  },
  {
    text: 'Predmeti',
    path: '/predmeti',
    icon: 'activefolder'
  },
  {
    text: 'Učionice',
    path: '/ucionice-predavac',
    icon: 'contentlayout'
  }
];

export const navigationPolaznik = [
  {
    text: 'Početna',
    path: '/pocetna',
    icon: 'home'
  },
  // {
  //   text: 'Predmeti',
  //   path: '/predmeti',
  //   icon: 'activefolder'
  // },
  // {
  //   text: 'Učionice',
  //   path: '/ucionica-polaznik',
  //   icon: 'contentlayout'
  // }
];