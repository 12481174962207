import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuardService } from './shared/services';
import { DxDataGridModule, DxFormModule } from 'devextreme-angular';

import { LoginFormComponent } from './shared/components';
import { PocetnaComponent } from './pages/pocetna/pocetna.component';
import { KorisniciComponent } from './pages/korisnici/korisnici.component';
import { PredmetiComponent } from './pages/predmeti/predmeti.component';
import { UcioniceComponent } from './pages/ucionice/ucionice.component';
import { UrediUcionicuComponent } from './pages/ucionice/uredi-ucionicu/uredi-ucionicu.component';
import { JednaUcionicaComponent } from './pages/ucionice/jedna-ucionica/jedna-ucionica.component';
import { UrediPredmetComponent } from './pages/predmeti/uredi-predmet/uredi-predmet.component';
import { ResetLozinkeComponent } from './shared/components/reset-lozinke/reset-lozinke.component';
import { MojProfilComponent } from './pages/moj-profil/moj-profil.component';
import { SektoriComponent } from './pages/sektori/sektori.component';
import { VrsteProgramaComponent } from './pages/vrste-programa/vrste-programa.component';
import { GradoviComponent } from './pages/gradovi/gradovi.component';
import { KorisnikComponent } from './pages/korisnik/korisnik.component';
import { ProgramiComponent } from './pages/programi/programi.component';
import { UcionicaPolaznikComponent } from './pages/polaznik/ucionica-polaznik/ucionica-polaznik.component';
import { IspitiComponent } from './pages/polaznik/ispiti/ispiti.component';
import { KvizoviComponent } from './pages/polaznik/kvizovi/kvizovi.component';
import { UcionicaPredavacComponent } from './pages/predavač/ucionica-predavac/ucionica-predavac.component';
import { UcionicePredavacComponent } from './pages/predavač/ucionice-predavac/ucionice-predavac.component';

const routes: Routes = [
  {
    path: 'pocetna',
    component: PocetnaComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'login',
    component: LoginFormComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'reset-lozinke',
    component: ResetLozinkeComponent
  },
  {
    path: 'korisnici',
    component: KorisniciComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'korisnik/:id',
    component: KorisnikComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'predmeti',
    component: PredmetiComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'uredi-predmet/:id',
    component: UrediPredmetComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'ucionice',
    component: UcioniceComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'ucionica-polaznik/:id/:naziv',
    component: UcionicaPolaznikComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'jedna-ucionica/:id/:naziv',
    component: JednaUcionicaComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'uredi-ucionicu/:id',
    component: UrediUcionicuComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'ucionica-predavac/:id',
    component: UcionicaPredavacComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'ucionice-predavac',
    component: UcionicePredavacComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'sektori',
    component: SektoriComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'vrste-programa',
    component: VrsteProgramaComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'moj-profil',
    component: MojProfilComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'gradovi',
    component: GradoviComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'programi',
    component: ProgramiComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'ispiti/:id/:idpredmet/:ispitnirok',
    component: IspitiComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'kvizovi/:id/:idpredmet/:vrsta',
    component: KvizoviComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: '**',
    redirectTo: 'pocetna',
    canActivate: [ AuthGuardService ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true }), DxDataGridModule, DxFormModule],
  providers: [AuthGuardService],
  exports: [RouterModule]
})
export class AppRoutingModule { }