import { Component, OnInit } from '@angular/core';

import { ApiService } from './../../shared/services/api.service';

import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-gradovi',
  templateUrl: './gradovi.component.html',
  styleUrls: ['./gradovi.component.scss']
})

export class GradoviComponent implements OnInit {

  access_token: any = localStorage.getItem('access_token');

  window: any;
  dataSource = [];
  naslov: string;

  constructor(
    public _api: ApiService
  ) { this.onShowing = this.onShowing.bind(this); }

  ngOnInit() {
    this.dohvatiGradove();
  }

  dohvatiGradove(){
    this._api.gradPopis({"access_token": this.access_token}).subscribe(res=>{
      if(res.success == true){
        this.dataSource = res.gradovi;
      } else {
        notify(res.message, "error", 1000);
      }
    });
  }

  onShowing(e){
    e.component.option("title", this.naslov);
  }

  onInitNewRow(e){
    this.naslov = 'Dodaj grad';
  }

  onEditingStart(e){
    this.naslov = 'Uredi grad';
  }

  onRowInserted(e){
    let gradZaSlanje = {
        "naziv": e.data['grad_naziv'],
        "access_token": this.access_token
    };
    this._api.gradDodaj(gradZaSlanje).subscribe(res=>{
      if(res.success == true){
        this.dohvatiGradove();
      } else {
        notify(res.message, "error", 1000);
      }
    });
  }

  onRowUpdated(e){
    let gradZaEdit = {
        "idgrad": e.data['idsifarnikgrad'],
        "naziv": e.data['grad_naziv'],
        "access_token": this.access_token
    };
    this._api.gradUredi(gradZaEdit).subscribe(res=>{
      if(res.success == true){
        notify(res.message, "success", 1000);
        this.dohvatiGradove();
      } else {
        notify(res.message, "error", 1000);
      }
    });
  }

}