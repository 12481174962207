<h2 class="content-block" *ngIf="!prikaziEdit">{{predmetDetalji['predmetNaziv']}} <i class="dx-icon-edit urediIkona" (click)="prikaziUrediNaziv()"></i></h2> 

<div class="content-block" *ngIf="prikaziEdit">
        <dx-form
        [formData]="predmetDetalji" 
        [showColonAfterLabel]="true"
        (onFieldDataChanged)="naziv_fieldDataChanged($event)">
            <dxi-item itemType="group" [colCount]="3">
                <dxi-item
                    [label]="{text: 'Naziv predmeta'}"
                    dataField="predmetNaziv"
                    [editorOptions]="{stylingMode: 'filled'}">
                    <dxi-validation-rule 
                        type="required"
                        message="Naziv je obavezno polje!">
                    </dxi-validation-rule>
                </dxi-item>
                <dxi-item>
                    <dx-button
                    stylingMode="contained"
                    text="Spremi"
                    icon="check"
                    [height]="50"
                    [width]="120"
                    (onClick)="urediNaziv()"
                    style="margin-right: 5px; margin-top: 35px">
                    </dx-button>
                    <dx-button
                    style="margin-top: 35px"
                    stylingMode="contained"
                    text="Odustani"
                    icon="close"
                    [height]="50"
                    [width]="130"
                    (onClick)="prikaziUrediNaziv()">
                    </dx-button>
                </dxi-item>
            </dxi-item>
        </dx-form>
</div>

<div class="content-block dx-card responsive-paddings">
    <div id="tabs content-block">
        <dx-tabs
            #apiTabs
            [dataSource]="tabs"
            [selectedIndex]="0"
            (onItemClick)="selectTab($event)"
        ></dx-tabs>
    </div>

    <!-- Predavači -------------------------------------------------------------------------------------------------------------------------------->
    <dx-data-grid *ngIf="odabraniTab == 0"
    class="wide-card"
    [hoverStateEnabled]="true"
    [wordWrapEnabled]="true"
    [dataSource]="predavaci"
    [rowAlternationEnabled]="false"
    [showRowLines]="false"
    [showColumnLines]="false"
    [showBorders]="false"
    [columnAutoWidth]="true"
    [columnHidingEnabled]="true"
    (onCellClick)="oznaciPredavaca($event)"
    >

    <dxo-paging [pageSize]="10"></dxo-paging>
    <dxo-pager [showPageSizeSelector]="true" [showInfo]="true"></dxo-pager>
    <!-- <dxo-filter-row [visible]="true"></dxo-filter-row> -->
    <dxo-search-panel [visible]="true"></dxo-search-panel>

    <dxo-editing
    mode="popup"
    [allowUpdating]="false"
    [allowDeleting]="false"
    [allowAdding]="false">
    </dxo-editing> 

    <!-- <dxi-column dataField="idkorisnik" [width]="70" [allowFiltering]="false" alignment="center"></dxi-column> -->
    <dxi-column dataField="kor_punoIme" caption="Ime i prezime"></dxi-column>
    <dxi-column dataField="kor_kvalifikacije" caption="Kvalifikacije"></dxi-column>
    <dxi-column dataField="naPredmetu" caption="Dodijeljen" [width]="150" [formItem]="{ visible: false }" cellTemplate="cellTemplate" alignment="center">
        <div *dxTemplate="let data of 'cellTemplate'">
            <dx-switch [value]="data.value" [disabled]="prava == 2"></dx-switch>
        </div>
    </dxi-column>

    </dx-data-grid>

    <!-- Materijali ------------------------------------------------------------------------------------------------------------------------------>
    <div *ngIf="odabraniTab == 1">
        <div id="tabs content-block">
            <dx-tabs
                #apiTabs
                [dataSource]="materijaliTabs"
                [selectedIndex]="0"
                (onItemClick)="odaberiTabMaterijala($event)"
            ></dx-tabs>
        </div>
        <!-- Globalni materijali -->
        <dx-data-grid
        *ngIf="odabraniMaterijal == 0"
        class="wide-card"
        [hoverStateEnabled]="true"
        [wordWrapEnabled]="true"
        [dataSource]="globalniMaterijali"
        [rowAlternationEnabled]="false"
        [showRowLines]="false"
        [showColumnLines]="false"
        [showBorders]="false"
        [columnAutoWidth]="true"
        [columnHidingEnabled]="true"
        (onToolbarPreparing)="onToolbarPreparing($event)"
        (onInitNewRow)="onInitNewRow($event)"
        (onEditingStart)="onEditingStart($event)"
        (onRowUpdated)="onRowUpdated($event)"
        (onRowRemoved)="onRowRemoved($event)"
        (onCellHoverChanged)="hover($event)"
        (onCellClick)="cellClickEvent($event)"
        (onCellPrepared)="onCellPrepared($event)"
        >

        <dxo-paging [pageSize]="10"></dxo-paging>
        <dxo-pager [showPageSizeSelector]="true" [showInfo]="true"></dxo-pager>

        <dxo-search-panel [visible]="true"></dxo-search-panel>

        <dxo-editing
        mode="popup"
        [allowUpdating]="allowUpdatingGlobalno"
        [allowDeleting]="allowDeletingGlobalno"
        [allowAdding]="false"> <!-- allowAddingGlobalno -->

        <dxo-popup
        [closeOnOutsideClick]="true"
        [onShowing]="onShowing"
        title=""
        [showTitle]="true"
        [width]="500"
        [maxHeight]="280"
        [position]="{ my: 'center', at: 'center', of: window }">
        </dxo-popup>

        <!-- [toolbarItems]="[  
            {  
            widget: 'dxButton',  
            location: 'after',  
            options: {  
            text: 'SPREMI',  
            icon: 'save'  
            },  
            toolbar: 'bottom',  
            onClick: onClick  
            },
            {  
            widget: 'dxButton',  
            location: 'after',  
            options: {  
              text: 'ODUSTANI'
            },  
            toolbar: 'bottom',  
            onClick: onClick  
            }
        ]"  -->

        <dxo-form>
            <dxi-item itemType="group" [colCount]="1" [colSpan]="2">
                <dxi-item
                    dataField="pmat_naziv"
                    [editorOptions]="{stylingMode: 'filled'}">
                    <dxi-validation-rule 
                        type="required"
                        message="Naziv je obavezno polje!">
                    </dxi-validation-rule>
                </dxi-item>
            </dxi-item>
        </dxo-form>

        </dxo-editing> 

        <dxi-column dataField="idpredmetmaterijal" caption="ID" [visible]="false"></dxi-column>
        <dxi-column dataField="pmat_naziv" caption="Naziv dokumenta"></dxi-column>
        <dxi-column dataField="materijal" caption="Materijal" [formItem]="{ visible: false }"></dxi-column>
        <dxi-column dataField="dodao" caption="Dodao" [formItem]="{ visible: false }"></dxi-column>

        <!-- Toolbar -->
        <div *dxTemplate="let data of 'videoIliupload'">
            <!-- <dx-toolbar (onClick)="onInitNewRow($event)">
                <dxi-item widget="dxButton" 
                          locateInMenu="always" 
                          [options]="saveButtonOptions"
                          (onClick)="test($event)">
                </dxi-item>
                <dxi-item widget="dxButton" 
                          locateInMenu="always" 
                          [options]="settingsButtonOptions">
                </dxi-item>
            </dx-toolbar> -->
            <div *ngIf="allowAddingGlobalno">
                <dx-button icon="upload" id="uploadMaterijal" name="uploadMaterijal" hint="Upload dokumenta" (onClick)="prikazUploadForme($event)"></dx-button>
                <dx-button icon="photo" id="videoMaterijal" name="videoMaterijal" hint="Dodavanje videozapisa" (onClick)="prikazVideoForme($event)"></dx-button>
            </div>
        </div>

        </dx-data-grid>

        <!-- Materijali predavača -->
        <dx-data-grid
        *ngIf="odabraniMaterijal == 1 && materijalPredavaca == false"
        class="wide-card"
        [hoverStateEnabled]="true"
        [wordWrapEnabled]="true"
        [dataSource]="materijaliPredavaca"
        [rowAlternationEnabled]="false"
        [showRowLines]="false"
        [showColumnLines]="false"
        [showBorders]="false"
        [columnAutoWidth]="true"
        [columnHidingEnabled]="true"
        (onInitNewRow)="onInitNewRow($event)"
        (onEditingStart)="onEditingStart($event)"
        (onRowUpdated)="onRowUpdated($event)"
        (onRowRemoved)="onRowRemoved($event)"
        (onCellHoverChanged)="hover($event)"
        (onCellClick)="cellClickEvent($event)"
        (onCellPrepared)="onCellPrepared($event)"
        >

        <dxo-paging [pageSize]="10"></dxo-paging>
        <dxo-pager [showPageSizeSelector]="true" [showInfo]="true"></dxo-pager>

        <dxo-search-panel [visible]="true"></dxo-search-panel>

        <dxo-editing
        mode="popup"
        [allowUpdating]="allowUpdatingPredavac || allowUpdatingGlobalno"
        [allowDeleting]="allowDeletingPredavac || allowDeletingGlobalno"
        [allowAdding]="allowAddingPredavac">

        <dxo-popup
        [closeOnOutsideClick]="true"
        [onShowing]="onShowing"
        title=""
        [showTitle]="true"
        [width]="500"
        [maxHeight]="250"
        [position]="{ my: 'center', at: 'center', of: window }">
        </dxo-popup>

        <dxo-form>
            <dxi-item itemType="group" [colCount]="1" [colSpan]="2">
                <dxi-item
                    dataField="pmpred_naziv"
                    [editorOptions]="{stylingMode: 'filled'}">
                    <dxi-validation-rule
                        type="required"
                        message="Naziv je obavezno polje!">
                    </dxi-validation-rule>
                </dxi-item>
            </dxi-item>
        </dxo-form>

        </dxo-editing> 

        <dxi-column dataField="idpredmetmaterijal" caption="ID" [visible]="false"></dxi-column>
        <dxi-column dataField="pmpred_naziv" caption="Naziv dokumenta"></dxi-column>
        <dxi-column dataField="materijal" caption="Materijal" [formItem]="{ visible: false }"></dxi-column>
        <dxi-column dataField="dodao" caption="Dodao" [formItem]="{ visible: false }"></dxi-column>
        <dxi-column dataField="ud_iddokument" caption="Globalno" [width]="150" [formItem]="{ visible: false }" cellTemplate="cellTemplate" alignment="center">
            <div *dxTemplate="let data of 'cellTemplate'">
                <dx-button icon="movetofolder" (onClick)="prebaciGlobalno(data)" class="globalizacija"></dx-button> 
            </div>
        </dxi-column>

        </dx-data-grid>

        <!-- Moji materijali -->
        <dx-data-grid
        *ngIf="odabraniMaterijal == 1 && materijalPredavaca == true"
        class="wide-card"
        [hoverStateEnabled]="true"
        [wordWrapEnabled]="true"
        [dataSource]="materijaliPredavaca"
        [rowAlternationEnabled]="false"
        [showRowLines]="false"
        [showColumnLines]="false"
        [showBorders]="false"
        [columnAutoWidth]="true"
        (onToolbarPreparing)="onToolbarPreparing($event)"
        [columnHidingEnabled]="true"
        (onInitNewRow)="onInitNewRow($event)"
        (onEditingStart)="onEditingStart($event)"
        (onRowUpdated)="onRowUpdated($event)"
        (onRowRemoved)="onRowRemoved($event)"
        (onCellHoverChanged)="hover($event)"
        (onCellClick)="cellClickEvent($event)"
        (onCellPrepared)="onCellPrepared($event)"
        >

        <dxo-paging [pageSize]="10"></dxo-paging>
        <dxo-pager [showPageSizeSelector]="true" [showInfo]="true"></dxo-pager>

        <dxo-search-panel [visible]="true"></dxo-search-panel>

        <dxo-editing
        mode="popup"
        [allowUpdating]="allowUpdatingPredavac || allowUpdatingGlobalno"
        [allowDeleting]="allowDeletingPredavac || allowDeletingGlobalno"
        [allowAdding]="false"> <!-- allowAddingPredavac -->

        <dxo-popup
        [closeOnOutsideClick]="true"
        [onShowing]="onShowing"
        title=""
        [showTitle]="true"
        [width]="500"
        [maxHeight]="400"
        [position]="{ my: 'center', at: 'center', of: window }">
        </dxo-popup>

        <dxo-form>
            <dxi-item itemType="group" [colCount]="1" [colSpan]="2">
                <dxi-item
                    dataField="pmpred_naziv"
                    [editorOptions]="{stylingMode: 'filled'}">
                    <dxi-validation-rule
                        type="required"
                        message="Naziv je obavezno polje!">
                    </dxi-validation-rule>
                </dxi-item>
            </dxi-item>
        </dxo-form>

        </dxo-editing>

        <dxi-column dataField="idpredmetmaterijal" caption="ID" [visible]="false"></dxi-column>
        <dxi-column dataField="pmpred_naziv" caption="Naziv dokumenta"></dxi-column>
        <dxi-column dataField="materijal" caption="Materijal" [formItem]="{ visible: false }"></dxi-column>
        <dxi-column dataField="dodao" caption="Dodao" [visible]="false" [formItem]="{ visible: false }"></dxi-column>

         <!-- Toolbar -->
         <div *dxTemplate="let data of 'videoIliupload'">
            <!-- <dx-toolbar (onClick)="onInitNewRow($event)">
                <dxi-item widget="dxButton"
                          locateInMenu="always"
                          [options]="saveButtonOptions"
                          (onClick)="test($event)">
                </dxi-item>
                <dxi-item widget="dxButton"
                          locateInMenu="always"
                          [options]="settingsButtonOptions">
                </dxi-item>
            </dx-toolbar> -->
            <div *ngIf="allowAddingPredavac">
                <dx-button icon="upload" id="uploadMaterijal" name="uploadMaterijal" hint="Upload dokumenta" (onClick)="prikazUploadForme($event)"></dx-button>
                <dx-button icon="photo" id="videoMaterijal" name="videoMaterijal" hint="Dodavanje videozapisa" (onClick)="prikazVideoForme($event)"></dx-button>
            </div>
        </div>

        </dx-data-grid>

    </div>

      <!-- Upload materijala -->
      <dx-popup
      title="Upload materijala"
      [(visible)]="dodajUpload"
      (onHidden)="popup_hidden($event)"
      [width]="500"
      [height]="'auto'"
      [position]="{ my: 'center', at: 'center', of: window }">
        <!-- <dxi-toolbar-item
        widget="dxButton"
        location="after"
        toolbar="bottom"
        [options]="{
            text: 'DODAJ',
            onClick: uploadMaterijal,
            icon: 'save'
        }">
        </dxi-toolbar-item> -->

            <dx-form
                validationGroup="formPopupUpload"
                [showColonAfterLabel]="true"
                (onFieldDataChanged)="form_fieldDataChanged($event)"> <!-- [(formData)]="materijalNaziv" -->
                <dxi-item itemType="group" [colCount]="1" [colSpan]="2">
                    <dxi-item
                        dataField="pmat_naziv"
                        [label]="{text: 'Naziv'}"
                        [editorOptions]="{stylingMode: 'filled'}">
                        <dxi-validation-rule
                            type="required"
                            message="Naziv je obavezno polje!">
                        </dxi-validation-rule>
                    </dxi-item>
                    <dxi-item>
                        <div *dxTemplate="let data of 'upload'">
                            <dx-file-uploader
                            #notesUploader
                            uploadUrl="{{uploadURL}}"
                            [multiple]="false"
                            accept="image/*,application/pdf"
                            [(value)]="value"
                            uploadMode="instantly"
                            (onUploaded)="onUploaded($event)"
                            ></dx-file-uploader>
                        </div>
                    </dxi-item>
                    <dxi-item itemType="empty"></dxi-item>
                </dxi-item>
                <dxi-item class="spremiOkvir">
                    <dx-button stylingMode="text" type="default" (onClick)="uploadMaterijal(value)" class="spremi">SPREMI</dx-button>
                </dxi-item>
            </dx-form>

        </dx-popup>

        <!-- Dodavanje video materijala -->
        <dx-popup
        title="Dodavanje Vimeo video materijala"
        [(visible)]="dodajVideo"
        (onHidden)="popup_hidden($event)"
        [width]="500"
        [maxHeight]="400"
        [position]="{ my: 'center', at: 'center', of: window }">
        <dx-form
            validationGroup="formPopupVimeo"
            [showColonAfterLabel]="true"
            (onFieldDataChanged)="video_fieldDataChanged($event)">
            <dxi-item itemType="group" [colCount]="1" [colSpan]="2">
                <dxi-item
                    dataField="pmat_naziv"
                    [label]="{text: 'Naziv'}"
                    [editorOptions]="{stylingMode: 'filled'}">
                    <dxi-validation-rule 
                        type="required"
                        message="Naziv je obavezno polje!">
                    </dxi-validation-rule>
                </dxi-item>
                <dxi-item
                    dataField="materijal"
                    [label]="{text: 'Šifra videa'}"
                    [editorOptions]="{stylingMode: 'filled'}">
                    <dxi-validation-rule 
                        type="required"
                        message="Šifra videa je obavezno polje!">
                    </dxi-validation-rule>
                </dxi-item>
            </dxi-item>
            <dxi-item class="spremiOkvir">
                <dx-button stylingMode="text" type="default" (onClick)="dodajVideoMaterijal()" class="spremi">SPREMI</dx-button>
            </dxi-item>
        </dx-form>
        </dx-popup>

        <!-- Prikaz video zapisa -->
        <dx-popup
        title="Prikaz video zapisa"
        [(visible)]="prikaziVideo"
        [width]="500"
        [maxHeight]="400"
        [position]="{ my: 'center', at: 'center', of: window }">
        <iframe [src]="vimeoURL | safe" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
        </dx-popup>

    <!-- Kvizovi -------------------------------------------------------------------------------------------------------------------------------->
    <app-kviz *ngIf="odabraniTab == 2"></app-kviz>

    <!-- Ispiti -------------------------------------------------------------------------------------------------------------------------------->
    <app-ispit-predmet *ngIf="odabraniTab == 3"></app-ispit-predmet>
        
</div>