import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ApiService } from './../../shared/services/api.service';

import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-moj-profil',
  templateUrl: './moj-profil.component.html',
  styleUrls: ['./moj-profil.component.scss']
})

export class MojProfilComponent implements OnInit {

  access_token: any = localStorage.getItem('access_token');

  korisnik: any;
  disableEdit: boolean = true;
  novaLozinka = "";
  defaultVisible = false;
  pravaApi: number;

  constructor(
    public _api: ApiService,
    private _router: Router
  ) {}

  ngOnInit() {
    this.dohvatiDetalje();
  }

  dohvatiDetalje(){
    this._api.korisnikDohvati({"idkorisnik":localStorage.getItem('idkorisnik'), "access_token": this.access_token}).subscribe(res=>{
      if(res.success == true){
        this.korisnik = res.korisnik[0];
        this.pravaApi = this.korisnik['kor_prava'];
      } else {
         notify(res.message, "error", 1000);
      }
    });
  }

  urediProfil(form){
    let podaciProfilaEdit = {
        "idkorisnik": form.idkorisnik,
        "punoIme": form.kor_punoIme,
        "email": form.kor_email,
        "telefon": form.kor_telefon,
        "oib": form.kor_oib,
        "lozinka": form.novaLozinka,
        "kvalifikacije": form.kor_kvalifikacije || null,
        "access_token": localStorage.getItem('access_token')
    };
    this._api.korisnikIzmjenaProfila(podaciProfilaEdit).subscribe(res=>{
      if(res.success == true){
        notify(res.message, "success", 1000);
        this._router.navigate(['/pocetna']);
        // this.dohvatiDetalje();
      } else {
        notify(res.message, "error", 1000);
      }
    });
  }

  toggleDefault() {
    this.defaultVisible = !this.defaultVisible;
  }

}