import { PocetnaComponent } from './pages/pocetna/pocetna.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { SideNavOuterToolbarModule, SideNavInnerToolbarModule, SingleCardModule } from './layouts';
import { FooterModule, LoginFormModule, ResetLozinkeModule } from './shared/components';
import { AppRoutingModule } from './app-routing.module';

import { AuthService, ScreenService, AppInfoService } from './shared/services';
import { SafePipe } from './shared/services/safe-url.pipe';
import { IdPredmetService } from './shared/services/id-predmet.service';

import { KorisniciComponent } from './pages/korisnici/korisnici.component';
import { PredmetiComponent } from './pages/predmeti/predmeti.component';
import { UcioniceComponent } from './pages/ucionice/ucionice.component';
import { JednaUcionicaComponent } from './pages/ucionice/jedna-ucionica/jedna-ucionica.component';
import { UrediUcionicuComponent } from './pages/ucionice/uredi-ucionicu/uredi-ucionicu.component';
import { UrediPredmetComponent } from './pages/predmeti/uredi-predmet/uredi-predmet.component';
import { MojProfilComponent } from './pages/moj-profil/moj-profil.component';
import { SektoriComponent } from './pages/sektori/sektori.component';
import { VrsteProgramaComponent } from './pages/vrste-programa/vrste-programa.component';
import { GradoviComponent } from './pages/gradovi/gradovi.component';
import { KorisnikComponent } from './pages/korisnik/korisnik.component';
import { ProgramiComponent } from './pages/programi/programi.component';
import { UcionicaPolaznikComponent } from './pages/polaznik/ucionica-polaznik/ucionica-polaznik.component';
import { IspitiComponent } from './pages/polaznik/ispiti/ispiti.component';
import { KvizoviComponent } from './pages/polaznik/kvizovi/kvizovi.component';
import { KvizComponent } from './pages/predmeti/kviz/kviz.component';
import { IspitPredmetComponent } from './pages/predmeti/ispit-predmet/ispit-predmet.component';
import { UcionicaPredavacComponent } from './pages/predavač/ucionica-predavac/ucionica-predavac.component';
import { UcionicePredavacComponent } from './pages/predavač/ucionice-predavac/ucionice-predavac.component';

import { DevExtremeModule } from 'devextreme-angular';
import { locale } from "devextreme/localization";
import { ChatComponent } from './pages/chat/chat.component';
import { IdUcionicaService } from './shared/services/id-ucionica.service';

locale("hr-HR");

@NgModule({
  declarations: [
    AppComponent,
    KorisniciComponent,
    PredmetiComponent,
    UcioniceComponent,
    JednaUcionicaComponent,
    UrediPredmetComponent,
    MojProfilComponent,
    SektoriComponent,
    VrsteProgramaComponent,
    GradoviComponent,
    KorisnikComponent,
    ProgramiComponent,
    UrediUcionicuComponent,
    PocetnaComponent,
    SafePipe,
    UcionicaPolaznikComponent,
    IspitiComponent,
    KvizoviComponent,
    KvizComponent,
    IspitPredmetComponent,
    UcionicaPredavacComponent,
    UcionicePredavacComponent,
    ChatComponent
  ],
  imports: [
    BrowserModule,
    SideNavOuterToolbarModule,
    SideNavInnerToolbarModule,
    SingleCardModule,
    FooterModule,
    LoginFormModule,
    ResetLozinkeModule,
    AppRoutingModule,
    DevExtremeModule,
    HttpClientModule,
    CommonModule,
    FormsModule
  ],
  providers: [AuthService, ScreenService, AppInfoService, IdPredmetService, IdUcionicaService],
  bootstrap: [AppComponent]
})
export class AppModule { }
