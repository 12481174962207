import { Component, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';

import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxCheckBoxModule } from 'devextreme-angular/ui/check-box';
import { DxTextBoxModule } from 'devextreme-angular/ui/text-box';
import { DxValidatorModule } from 'devextreme-angular/ui/validator';
import { DxValidationGroupModule } from 'devextreme-angular/ui/validation-group';

import notify from 'devextreme/ui/notify';

import { ApiService } from './../../services/api.service';

@Component({
  selector: 'app-reset-lozinke',
  templateUrl: './reset-lozinke.component.html',
  styleUrls: ['./reset-lozinke.component.scss']
})
export class ResetLozinkeComponent {

  email = '';
  disabledButton: boolean = false;

  constructor(public _api: ApiService,
              private _router: Router) { }

  onResetClick(args) {
    if (!args.validationGroup.validate().isValid) {
      return;
    }
    if(this.email){
      this.disabledButton = true;
      this._api.korisnikZaboravljenaLozinka({'email': this.email}).subscribe((res)=>{
        // console.log(res);
        if(res.success == true){
          notify(res.message, "success", 2000);
          this._router.navigate(['login']);
        } else {
          notify(res.message, "error", 1000);
        }
      });
    }
    // args.validationGroup.reset();
  }
}
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DxButtonModule,
    DxCheckBoxModule,
    DxTextBoxModule,
    DxValidatorModule,
    DxValidationGroupModule
  ],
  declarations: [ ResetLozinkeComponent ],
  exports: [ ResetLozinkeComponent ]
})
export class ResetLozinkeModule { }
