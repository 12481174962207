<dx-data-grid
class="wide-card"
[hoverStateEnabled]="true"
[wordWrapEnabled]="true"
[dataSource]="kvizovi"
[rowAlternationEnabled]="false"
[showRowLines]="false"
[showColumnLines]="false"
[showBorders]="false"
[columnAutoWidth]="true"
[columnHidingEnabled]="true"
[masterDetail]="{ enabled: true, template: 'popisPitanja' }"
(onInitNewRow)="onInitNewRowKviz($event)"
(onRowInserted)="onRowInsertedKviz($event)"
(onEditingStart)="onEditingStartKviz($event)"
(onRowUpdated)="onRowUpdatedKviz($event)"
(onRowRemoved)="onRowRemovedKviz($event)"
(onCellClick)="prikazPitanjaJednogKviza($event)"
(onRowExpanding)="onRowExpanding($event)"
>

<!--  (onCellClick)="prikazPitanjaJednogKviza($event)" -->

<dxo-paging [pageSize]="10"></dxo-paging>
<dxo-pager [showPageSizeSelector]="true" [showInfo]="true"></dxo-pager>
<dxo-search-panel [visible]="true"></dxo-search-panel>

<dxo-editing
mode="popup"
[allowUpdating]="true"
[allowDeleting]="true"
[allowAdding]="true">

<dxo-popup
[closeOnOutsideClick]="true"
[onShowing]="onShowing"
title=""
[showTitle]="true"
[width]="500"
[maxHeight]="280"
[position]="{ my: 'center', at: 'center', of: window }">
</dxo-popup>

<dxo-form
    [showColonAfterLabel]="true">
    <dxi-item itemType="group" [colCount]="1" [colSpan]="2">
        <dxi-item
            dataField="kviz_naziv"
            [editorOptions]="{stylingMode: 'filled'}">
            <dxi-validation-rule
                type="required"
                message="Naziv je obavezno polje!">
            </dxi-validation-rule>
        </dxi-item>
    </dxi-item>
</dxo-form>

</dxo-editing> 

<!-- <dxi-column dataField="idkviz" [width]="70" [allowFiltering]="false" alignment="center"></dxi-column> -->
<dxi-column dataField="kviz_naziv" caption="Naziv kviza"></dxi-column>
<dxi-column dataField="brojPitanja" caption="Pitanja" alignment="center" [width]="150" [formItem]="{ visible: false }"></dxi-column>

<div *dxTemplate="let pitanje of 'popisPitanja'">
    <dx-data-grid
        [dataSource]="pitanja"
        [rowAlternationEnabled]="false"
        [showRowLines]="false"
        [showColumnLines]="false"
        [showBorders]="false"
        [columnAutoWidth]="true"
        (onToolbarPreparing)="onToolbarPreparing($event)"
        (onCellClick)="cellClickPitanje($event)"
        (onCellHoverChanged)="hoverPitanje($event)">
            <!-- <dxi-column dataField="kpit_vrsta" [width]="50" caption="" [formItem]="{ visible: false }" cellTemplate="vrstaPitanjaTemplate">
                <div *dxTemplate="let data of 'vrstaPitanjaTemplate'">  
                    <dx-button *ngIf="data.value == 1" class="ikona" icon="image"></dx-button>   
                    <dx-button *ngIf="data.value == 0" class="ikona" icon="startswith"></dx-button>
                </div> 
            </dxi-column> -->
            <dxi-column dataField="kpit_naziv" caption="Pitanja i odgovori"></dxi-column>
            <dxi-column type="buttons" [width]="110"><dxi-button template="buttonTemplate"></dxi-button></dxi-column>
            <div *dxTemplate="let buttonInfo of 'buttonTemplate'">
                <dx-button icon="edit" type="back" (click)="odgovoriEditPopup(buttonInfo)"></dx-button>
                <dx-button icon="trash" type="back" (click)="brisanjePitanja(buttonInfo)"></dx-button>
            </div>

        <!-- Toolbar -->
        <div *dxTemplate="let data of 'videoIliupload'">
            <div><strong>Vrsta pitanja:</strong> <!-- *ngIf="allowAddingGlobalno" -->
                <dx-button icon="startswith" hint="Tekstualno pitanje" (onClick)="onInitNewRowTekstualnoPitanje($event)"></dx-button>
                <dx-button icon="image" hint="Grafičko pitanje" (onClick)="onInitNewRowGrafickoPitanje($event)"></dx-button>
            </div>
        </div>
    </dx-data-grid>       
</div>

</dx-data-grid>

<!-- Dodavanje tekstualnog pitanja u kviz -->
<dx-popup
title="Kreiranje tekstualnog pitanja"
[(visible)]="dodajTekstualnoPitanje"
(onHidden)="popupHiddenPitanjeTekst($event)"
[width]="600"
[height]="'auto'"
[position]="{ my: 'top', at: 'top', of: window }">
<div *dxTemplate="let data of 'content'">
   <dx-form
    validationGroup="kvizPopup"
    [showColonAfterLabel]="true"
    (onFieldDataChanged)="pitanje_fieldDataChanged($event)">
    <dxi-item itemType="group" [colCount]="1" [colSpan]="1">
            <dxi-item
            dataField="pitanje"
            [label]="{text: 'Sadržaj pitanja'}"
            [editorOptions]="{stylingMode: 'filled'}"
            [disabled]="kreiranoPitanje">
            <dxi-validation-rule 
                type="required"
                message="Sadržaj pitanja je obavezno polje!">
            </dxi-validation-rule>
            </dxi-item>
        <dxi-item style="float: right;" *ngIf="!kreiranoPitanje">
            <dx-button stylingMode="text" type="default" (onClick)="kreirajTekstualnoPitanje($event)" class="spremi">SPREMI PITANJE</dx-button>
        </dxi-item>
    </dxi-item>
    </dx-form>
    <div *ngIf="vrstaOdgovora == null && kreiranoPitanje == true">
            <p>Vrsta odgovora:</p>
            <ul class="kocke">
                <li class="dx-card" (click)="odabirVrsteOdgovora('tekst')">
                    <dx-button icon="startswith" class="slika"></dx-button>
                    <p class="opis">Tekst</p>
                </li>
                <li class="dx-card" (click)="odabirVrsteOdgovora('slika')">
                    <dx-button icon="image" class="slika"></dx-button>
                    <p class="opis">Slika</p>
                </li>
            </ul>
    </div>
    <!-- Tekstualna vrsta odgovora -->
    <div *ngIf="vrstaOdgovora == 0 && kreiranoPitanje == true">
        <strong>Odgovori:</strong>
        <hr>
        <div id="tabs">
            <dx-tabs
                [dataSource]="odgovoriTabs"
                [selectedIndex]="odabraniOdgovor"
                (onItemClick)="odgovoriTabOdabir($event)"
            ></dx-tabs>
        </div>
        <!-- Odgovor A tekst -->
        <div *ngIf="odabraniOdgovor == 0">
            <dx-form
            id="formOdgovor"
            [colCount]="1"
            (onFieldDataChanged)="odgovor_fieldDataChanged($event)">
                <dxi-item itemType="group">
                    <dxi-item
                        dataField="odgovorABCD"
                        [editorOptions]="{stylingMode: 'filled'}"
                        editorType="dxTextArea">
                        <dxo-label [visible]="false"></dxo-label>
                        <dxi-validation-rule
                            type="required"
                            message="Odgovor A je obavezno polje!">
                        </dxi-validation-rule>
                    </dxi-item>
                </dxi-item>
                <dxi-item itemType="group">
                    <dx-check-box
                        [(value)]="checkBoxABCD"
                        [width]="150"
                        text="Točan odgovor">
                    </dx-check-box>
                </dxi-item>
                <dxi-item style="float: right;">
                    <dx-button stylingMode="text" type="default" (onClick)="spremiOdgovorTekst($event)" class="spremi">SPREMI</dx-button>
                </dxi-item>
            </dx-form>
        </div>
        <!-- Odgovor B tekst -->
        <div *ngIf="odabraniOdgovor == 1">
            <dx-form
            id="formOdgovor"
            [colCount]="1"
            (onFieldDataChanged)="odgovor_fieldDataChanged($event)">
                <dxi-item itemType="group">
                    <dxi-item
                        dataField="odgovorABCD"
                        [editorOptions]="{stylingMode: 'filled'}"
                        editorType="dxTextArea">
                        <dxo-label [visible]="false"></dxo-label>
                        <dxi-validation-rule
                            type="required"
                            message="Odgovor B je obavezno polje!">
                        </dxi-validation-rule>
                    </dxi-item>
                </dxi-item>
                <dxi-item itemType="group">
                    <dx-check-box
                        [(value)]="checkBoxABCD" 
                        [width]="150"
                        text="Točan odgovor">
                    </dx-check-box>
                </dxi-item>
                <dxi-item style="float: right;">
                    <dx-button stylingMode="text" type="default" (onClick)="spremiOdgovorTekst($event)" class="spremi">SPREMI</dx-button>
                </dxi-item>
            </dx-form>
        </div>
        <!-- Odgovor C tekst -->
        <div *ngIf="odabraniOdgovor == 2">
            <dx-form
            id="formOdgovor"
            [colCount]="1"
            (onFieldDataChanged)="odgovor_fieldDataChanged($event)">
                <dxi-item itemType="group">
                    <dxi-item
                        dataField="odgovorABCD"
                        [editorOptions]="{stylingMode: 'filled'}"
                        editorType="dxTextArea">
                        <dxo-label [visible]="false"></dxo-label>
                        <dxi-validation-rule
                            type="required"
                            message="Odgovor C je obavezno polje!">
                        </dxi-validation-rule>
                    </dxi-item>
                </dxi-item>
                <dxi-item itemType="group">
                    <dx-check-box
                        [(value)]="checkBoxABCD"
                        [width]="150"
                        text="Točan odgovor">
                    </dx-check-box>
                </dxi-item>
                <dxi-item style="float: right;">
                    <dx-button stylingMode="text" type="default" (onClick)="spremiOdgovorTekst($event)" class="spremi">SPREMI</dx-button>
                </dxi-item>
            </dx-form>
        </div>
        <!-- Odgovor D tekst-->
        <div *ngIf="odabraniOdgovor == 3">
            <dx-form
            id="formOdgovor"
            [colCount]="1"
            (onFieldDataChanged)="odgovor_fieldDataChanged($event)">
                <dxi-item itemType="group">
                    <dxi-item
                        dataField="odgovorABCD"
                        [editorOptions]="{stylingMode: 'filled'}"
                        editorType="dxTextArea">
                        <dxo-label [visible]="false"></dxo-label>
                        <dxi-validation-rule 
                            type="required"
                            message="Odgovor D je obavezno polje!">
                        </dxi-validation-rule>
                    </dxi-item>
                </dxi-item>
                <dxi-item itemType="group">
                    <dx-check-box
                        [(value)]="checkBoxABCD"
                        [width]="150"
                        text="Točan odgovor">
                    </dx-check-box>
                </dxi-item>
                <dxi-item style="float: right;">
                    <dx-button stylingMode="text" type="default" (onClick)="spremiOdgovorTekst($event)" class="spremi">SPREMI</dx-button>
                </dxi-item>
            </dx-form>
        </div>
    </div>
    <!-- Grafička vrsta odgovora -->
    <div *ngIf="vrstaOdgovora == 1 && kreiranoPitanje == true">
        <strong>Odgovori:</strong>
        <hr>
        <div id="tabs">
            <dx-tabs
                [dataSource]="odgovoriTabs"
                [selectedIndex]="odabraniOdgovor"
                (onItemClick)="odgovoriTabOdabir($event)"
            ></dx-tabs>
        </div>
        <!-- Odgovor A slika -->
        <div *ngIf="odabraniOdgovor == 0">
            <dx-form
            id="formOdgovor"
            [colCount]="1"
            (onFieldDataChanged)="odgovor_fieldDataChanged($event)">
                <dxi-item itemType="group">
                    <dx-file-uploader
                        #notesUploader
                        uploadUrl="{{uploadURL}}"
                        [multiple]="false"
                        accept="image/*"
                        [(value)]="value"
                        uploadMode="instantly"
                        (onUploaded)="onUploadedOdgovor($event)"
                    ></dx-file-uploader>
                </dxi-item>
                <dxi-item itemType="group">
                    <dx-check-box
                        [(value)]="checkBoxABCD"
                        [width]="150" 
                        text="Točan odgovor">
                    </dx-check-box>
                </dxi-item>
                <dxi-item style="float: right;">
                    <dx-button stylingMode="text" type="default" (onClick)="spremiOdgovorSlika($event)" class="spremi">SPREMI</dx-button>
                </dxi-item>
            </dx-form>
        </div>
        <!-- Odgovor B slika -->
        <div *ngIf="odabraniOdgovor == 1">
            <dx-form
            id="formOdgovor"
            [colCount]="1"
            (onFieldDataChanged)="odgovor_fieldDataChanged($event)">
                <dxi-item itemType="group">
                    <dx-file-uploader
                        #notesUploader
                        uploadUrl="{{uploadURL}}"
                        [multiple]="false"
                        accept="image/*"
                        [(value)]="value"
                        uploadMode="instantly"
                        (onUploaded)="onUploadedOdgovor($event)"
                    ></dx-file-uploader>
                </dxi-item>
                <dxi-item itemType="group">
                    <dx-check-box
                        [(value)]="checkBoxABCD"
                        [width]="150"
                        text="Točan odgovor">
                    </dx-check-box>
                </dxi-item>
                <dxi-item style="float: right;">
                    <dx-button stylingMode="text" type="default" (onClick)="spremiOdgovorSlika($event)" class="spremi">SPREMI</dx-button>
                </dxi-item>
            </dx-form>
        </div>
        <!-- Odgovor C slika -->
        <div *ngIf="odabraniOdgovor == 2">
            <dx-form
            id="formOdgovor"
            [colCount]="1"
            (onFieldDataChanged)="odgovor_fieldDataChanged($event)">
                <dxi-item itemType="group">
                    <dx-file-uploader
                        #notesUploader
                        uploadUrl="{{uploadURL}}"
                        [multiple]="false"
                        accept="image/*"
                        [(value)]="value"
                        uploadMode="instantly"
                        (onUploaded)="onUploadedOdgovor($event)"
                    ></dx-file-uploader>
                </dxi-item>
                <dxi-item itemType="group">
                    <dx-check-box
                        [(value)]="checkBoxABCD"
                        [width]="150"
                        text="Točan odgovor">
                    </dx-check-box>
                </dxi-item>
                <dxi-item style="float: right;">
                    <dx-button stylingMode="text" type="default" (onClick)="spremiOdgovorSlika($event)" class="spremi">SPREMI</dx-button>
                </dxi-item>
            </dx-form>
        </div>
        <!-- Odgovor D slika -->
        <div *ngIf="odabraniOdgovor == 3">
            <dx-form
            id="formOdgovor"
            [colCount]="1"
            (onFieldDataChanged)="odgovor_fieldDataChanged($event)">
                <dxi-item itemType="group">
                    <dx-file-uploader
                        #notesUploader
                        uploadUrl="{{uploadURL}}"
                        [multiple]="false"
                        accept="image/*"
                        [(value)]="value"
                        uploadMode="instantly"
                        (onUploaded)="onUploadedOdgovor($event)"
                    ></dx-file-uploader>
                </dxi-item>
                <dxi-item itemType="group">
                    <dx-check-box
                        [(value)]="checkBoxABCD"
                        [width]="150"
                        text="Točan odgovor">
                    </dx-check-box>
                </dxi-item>
                <dxi-item style="float: right;">
                    <dx-button stylingMode="text" type="default" (onClick)="spremiOdgovorSlika($event)" class="spremi">SPREMI</dx-button>
                </dxi-item>
            </dx-form>
        </div>
    </div>
</div>
</dx-popup>

<!-- Dodavanje grafičkog pitanja u kviz -->
<dx-popup
title="Kreiranje grafičkog pitanja"
[(visible)]="dodajGrafickoPitanje"
(onHidden)="popupHiddenPitanjeSlika($event)"
[width]="600"
[height]="'auto'"
[position]="{ my: 'top', at: 'top', of: window }">
<div *dxTemplate="let data of 'content'">
   <dx-form
    validationGroup="kvizPopup"
    [showColonAfterLabel]="true"
    (onFieldDataChanged)="pitanje_fieldDataChanged($event)">
    <dxi-item itemType="group" [colCount]="1" [colSpan]="1">
        <dxi-item
            dataField="pitanje"
            [label]="{text: 'Sadržaj pitanja'}"
            [editorOptions]="{stylingMode: 'filled'}"
            [disabled]="kreiranoPitanje">
            <dxi-validation-rule 
                type="required"
                message="Sadržaj pitanja je obavezno polje!">
            </dxi-validation-rule>
        </dxi-item>
        <dxi-item>
        <div *dxTemplate="let data of 'upload'">
            <div *ngIf="kreiranoPitanje">
                <p>Slika: <a href="{{datotekeURL}}+{{idUploadDokument}}+&access_token={{access_token}}" target="_blank">{{nazivDatoteke}}</a></p>
            </div>
            <dx-file-uploader
               *ngIf="!kreiranoPitanje"
                #notesUploader
                uploadUrl="{{uploadURL}}"
                [multiple]="false"
                accept="image/*"
                [(value)]="value"
                uploadMode="instantly"
                (onUploaded)="onUploadedPitanje($event)"
            ></dx-file-uploader>
        </div>
        </dxi-item>
        <dxi-item style="float: right;" *ngIf="!kreiranoPitanje">
            <dx-button stylingMode="text" type="default" (onClick)="kreirajGrafickoPitanje($event)" class="spremi">SPREMI PITANJE</dx-button>
        </dxi-item>
    </dxi-item>
    </dx-form>
    <div *ngIf="vrstaOdgovora == null && kreiranoPitanje == true">
        <p>Vrsta odgovora:</p>
        <ul class="kocke">
            <li class="dx-card" (click)="odabirVrsteOdgovora('tekst')">
                <dx-button icon="startswith" class="slika"></dx-button>
                <p class="opis">Tekst</p>
            </li>
            <li class="dx-card" (click)="odabirVrsteOdgovora('slika')">
                <dx-button icon="image" class="slika"></dx-button>
                <p class="opis">Slika</p>
            </li>
        </ul>
    </div>
    <!-- Tekstualna vrsta odgovora -->
    <div *ngIf="vrstaOdgovora == 0">
        <strong>Odgovori:</strong>
        <hr>
        <div id="tabs">
            <dx-tabs
                [dataSource]="odgovoriTabs"
                [selectedIndex]="odabraniOdgovor"
                (onItemClick)="odgovoriTabOdabir($event)"
            ></dx-tabs>
        </div>
        <!-- Odgovor A tekst -->
        <div *ngIf="odabraniOdgovor == 0">
            <dx-form
            id="formOdgovor"
            [colCount]="1"
            (onFieldDataChanged)="odgovor_fieldDataChanged($event)">
                <dxi-item itemType="group">
                    <dxi-item
                        dataField="odgovorABCD" 
                        [label]="{text: 'Odgovor A'}"
                        [editorOptions]="{stylingMode: 'filled'}"
                        editorType="dxTextArea">
                        <dxi-validation-rule 
                            type="required"
                            message="Odgovor A je obavezno polje!">
                        </dxi-validation-rule>
                    </dxi-item>
                </dxi-item>
                <dxi-item itemType="group">
                    <dx-check-box
                        [(value)]="checkBoxABCD"
                        [width]="150"
                        text="Točan odgovor">
                    </dx-check-box>
                </dxi-item>
                <dxi-item style="float: right;">
                    <dx-button stylingMode="text" type="default" (onClick)="spremiOdgovorTekst($event)" class="spremi">SPREMI</dx-button>
                </dxi-item>
            </dx-form>
        </div>
        <!-- Odgovor B tekst -->
        <div *ngIf="odabraniOdgovor == 1">
            <dx-form
            id="formOdgovor"
            [colCount]="1"
            (onFieldDataChanged)="odgovor_fieldDataChanged($event)">
                <dxi-item itemType="group">
                    <dxi-item
                        dataField="odgovorABCD"
                        [label]="{text: 'Odgovor B'}"
                        [editorOptions]="{stylingMode: 'filled'}"
                        editorType="dxTextArea">
                        <dxi-validation-rule
                            type="required"
                            message="Odgovor B je obavezno polje!">
                        </dxi-validation-rule>
                    </dxi-item>
                </dxi-item>
                <dxi-item itemType="group">
                    <dx-check-box
                        [(value)]="checkBoxABCD"
                        [width]="150"
                        text="Točan odgovor">
                    </dx-check-box>
                </dxi-item>
                <dxi-item style="float: right;">
                    <dx-button stylingMode="text" type="default" (onClick)="spremiOdgovorTekst($event)" class="spremi">SPREMI</dx-button>
                </dxi-item>
            </dx-form>
        </div>
        <!-- Odgovor C tekst -->
        <div *ngIf="odabraniOdgovor == 2">
            <dx-form
            id="formOdgovor"
            [colCount]="1"
            (onFieldDataChanged)="odgovor_fieldDataChanged($event)">
                <dxi-item itemType="group">
                    <dxi-item
                        dataField="odgovorABCD"
                        [label]="{text: 'Odgovor C'}"
                        [editorOptions]="{stylingMode: 'filled'}"
                        editorType="dxTextArea">
                        <dxi-validation-rule 
                            type="required"
                            message="Odgovor C je obavezno polje!">
                        </dxi-validation-rule>
                    </dxi-item>
                </dxi-item>
                <dxi-item itemType="group">
                    <dx-check-box
                        [(value)]="checkBoxABCD"
                        [width]="150"
                        text="Točan odgovor">
                    </dx-check-box>
                </dxi-item>
                <dxi-item style="float: right;">
                    <dx-button stylingMode="text" type="default" (onClick)="spremiOdgovorTekst($event)" class="spremi">SPREMI</dx-button>
                </dxi-item>
            </dx-form>
        </div>
        <!-- Odgovor D tekst-->
        <div *ngIf="odabraniOdgovor == 3">
            <dx-form
            id="formOdgovor"
            [colCount]="1"
            (onFieldDataChanged)="odgovor_fieldDataChanged($event)">
                <dxi-item itemType="group">
                    <dxi-item
                        dataField="odgovorABCD"
                        [label]="{text: 'Odgovor D'}"
                        [editorOptions]="{stylingMode: 'filled'}"
                        editorType="dxTextArea">
                        <dxi-validation-rule 
                            type="required"
                            message="Odgovor D je obavezno polje!">
                        </dxi-validation-rule>
                    </dxi-item>
                </dxi-item>
                <dxi-item itemType="group">
                    <dx-check-box
                        [(value)]="checkBoxABCD"
                        [width]="150"
                        text="Točan odgovor">
                    </dx-check-box>
                </dxi-item>
                <dxi-item style="float: right;">
                    <dx-button stylingMode="text" type="default" (onClick)="spremiOdgovorTekst($event)" class="spremi">SPREMI</dx-button>
                </dxi-item>
            </dx-form>
        </div>
    </div>
    <!-- Grafička vrsta odgovora -->
    <div *ngIf="vrstaOdgovora == 1">
        <strong>Odgovori:</strong>
        <hr>
        <div id="tabs">
            <dx-tabs
                [dataSource]="odgovoriTabs"
                [selectedIndex]="odabraniOdgovor"
                (onItemClick)="odgovoriTabOdabir($event)"
            ></dx-tabs>
        </div>
        <!-- Odgovor A slika -->
        <div *ngIf="odabraniOdgovor == 0">
            <dx-form
            id="formOdgovor"
            [colCount]="1"
            (onFieldDataChanged)="odgovor_fieldDataChanged($event)">
                <dxi-item itemType="group">
                    <dx-file-uploader
                        #notesUploader
                        uploadUrl="{{uploadURL}}"
                        [multiple]="false"
                        accept="image/*"
                        [(value)]="value"
                        uploadMode="instantly"
                        (onUploaded)="onUploadedOdgovor($event)"
                    ></dx-file-uploader>
                </dxi-item>
                <dxi-item itemType="group">
                    <dx-check-box
                        [(value)]="checkBoxABCD"
                        [width]="150"
                        text="Točan odgovor">
                    </dx-check-box>
                </dxi-item>
                <dxi-item style="float: right;">
                    <dx-button stylingMode="text" type="default" (onClick)="spremiOdgovorSlika($event)" class="spremi">SPREMI</dx-button>
                </dxi-item>
            </dx-form>
        </div>
        <!-- Odgovor B slika -->
        <div *ngIf="odabraniOdgovor == 1">
            <dx-form
            id="formOdgovor"
            [colCount]="1"
            (onFieldDataChanged)="odgovor_fieldDataChanged($event)">
                <dxi-item itemType="group">
                    <dx-file-uploader
                        #notesUploader
                        uploadUrl="{{uploadURL}}"
                        [multiple]="false"
                        accept="image/*"
                        [(value)]="value"
                        uploadMode="instantly"
                        (onUploaded)="onUploadedOdgovor($event)"
                    ></dx-file-uploader>
                </dxi-item>
                <dxi-item itemType="group">
                    <dx-check-box
                        [(value)]="checkBoxABCD"
                        [width]="150"
                        text="Točan odgovor">
                    </dx-check-box>
                </dxi-item>
                <dxi-item style="float: right;">
                    <dx-button stylingMode="text" type="default" (onClick)="spremiOdgovorSlika($event)" class="spremi">SPREMI</dx-button>
                </dxi-item>
            </dx-form>
        </div>
        <!-- Odgovor C slika -->
        <div *ngIf="odabraniOdgovor == 2">
            <dx-form
            id="formOdgovor"
            [colCount]="1"
            (onFieldDataChanged)="odgovor_fieldDataChanged($event)">
                <dxi-item itemType="group">
                    <dx-file-uploader
                        #notesUploader
                        uploadUrl="{{uploadURL}}"
                        [multiple]="false"
                        accept="image/*"
                        [(value)]="value"
                        uploadMode="instantly"
                        (onUploaded)="onUploadedOdgovor($event)"
                    ></dx-file-uploader>
                </dxi-item>
                <dxi-item itemType="group">
                    <dx-check-box
                        [(value)]="checkBoxABCD"
                        [width]="150"
                        text="Točan odgovor">
                    </dx-check-box>
                </dxi-item>
                <dxi-item style="float: right;">
                    <dx-button stylingMode="text" type="default" (onClick)="spremiOdgovorSlika($event)" class="spremi">SPREMI</dx-button>
                </dxi-item>
            </dx-form>
        </div>
        <!-- Odgovor D slika -->
        <div *ngIf="odabraniOdgovor == 3">
            <dx-form
            id="formOdgovor"
            [colCount]="1"
            (onFieldDataChanged)="odgovor_fieldDataChanged($event)">
                <dxi-item itemType="group">
                    <dx-file-uploader
                        #notesUploader
                        uploadUrl="{{uploadURL}}"
                        [multiple]="false"
                        accept="image/*"
                        [(value)]="value"
                        uploadMode="instantly"
                        (onUploaded)="onUploadedOdgovor($event)"
                    ></dx-file-uploader>
                </dxi-item>
                <dxi-item itemType="group">
                    <dx-check-box 
                        [(value)]="checkBoxABCD" 
                        [width]="150" 
                        text="Točan odgovor">
                    </dx-check-box>
                </dxi-item>
                <dxi-item style="float: right;">
                    <dx-button stylingMode="text" type="default" (onClick)="spremiOdgovorSlika($event)" class="spremi">SPREMI</dx-button>
                </dxi-item>
            </dx-form>
        </div>
    </div>
</div>
</dx-popup>

<!-- Prikaz pitanja i odgovora -->
<dx-popup
title="{{pitanjePrikazTekst}}"
[(visible)]="prikazPitanjaOdgovora"
(onHidden)="popupHiddenPitanjeOdgovor($event)"
[width]="600"
[height]="'auto'"
[position]="{ my: 'top', at: 'top', of: window }">
    <div *dxTemplate="let data of 'content'">
        <div *ngIf="vrstaPitanja == 0">
            <p><strong>Pitanje</strong>: {{pitanjePrikazTekst}}</p>
        </div>
        <div *ngIf="vrstaPitanja == 1">
            <p>Pitanje (slika): <a href="{{datotekeURL}}+{{idUploadDokument}}+&access_token={{access_token}}" target="_blank">{{pitanjePrikazTekst}}</a></p>
            <!-- <a href="{{datotekeURL}}+{{idUploadDokument}}+&access_token={{access_token}}" target="_blank"><img src="{{datotekeURL}}+{{idUploadDokument}}+&access_token={{access_token}}" height="150" width="auto"></a> -->
        </div>
        <hr>
        <div *ngIf="imaOdgovora">
            <div id="tabs">
                <dx-tabs
                    [dataSource]="odgovoriTabsPrikaz"
                    [selectedIndex]="odabraniOdgovor"
                    (onItemClick)="odgovoriTabPrikaz($event)"
                ></dx-tabs>
            </div>
            <!-- Odgovor A prikaz -->
            <div *ngIf="odabraniOdgovor == 0">
                <dx-form
                [(formData)]="nizOdgovori[0]"
                [colCount]="1">
                    <dxi-item itemType="group">
                        <dxi-item
                            *ngIf="vrstaOdgovora == 0"
                            dataField="kodg_tekst"
                            [label]="{text: 'Odgovor A'}"
                            [editorOptions]="{stylingMode: 'filled'}"
                            editorType="dxTextArea"
                            [disabled]="true">
                        </dxi-item>
                        <p *ngIf="vrstaOdgovora == 1 && idUploadDokumentOdgovorPrikaz"><a href="{{datotekeURL}}+{{idUploadDokumentOdgovorPrikaz}}+&access_token={{access_token}}" target="_blank">
                            <img src="{{datotekeURL}}+{{idUploadDokumentOdgovorPrikaz}}+&access_token={{access_token}}" height="250" width="auto"></a></p>
                    </dxi-item>
                    <dxi-item itemType="group">
                        <dx-check-box
                            *ngIf="idUploadDokumentOdgovorPrikaz || odgovorPrikaz"
                            [(value)]="tocanIlinetocan"
                            [width]="150"
                            text="Točan odgovor"
                            [disabled]="true">
                        </dx-check-box>
                    </dxi-item>
                </dx-form>
            </div>
            <!-- Odgovor B prikaz -->
            <div *ngIf="odabraniOdgovor == 1">
                <dx-form
                [(formData)]="nizOdgovori[1]"
                [colCount]="1">
                    <dxi-item itemType="group">
                        <dxi-item
                            *ngIf="vrstaOdgovora == 0"
                            dataField="kodg_tekst"
                            [label]="{text: 'Odgovor B'}"
                            [editorOptions]="{stylingMode: 'filled'}"
                            editorType="dxTextArea"
                            [disabled]="true">
                        </dxi-item>
                        <p *ngIf="vrstaOdgovora == 1 && idUploadDokumentOdgovorPrikaz"><a href="{{datotekeURL}}+{{idUploadDokumentOdgovorPrikaz}}+&access_token={{access_token}}" target="_blank"><img src="{{datotekeURL}}+{{idUploadDokumentOdgovorPrikaz}}+&access_token={{access_token}}" height="250" width="auto"></a></p>
                    </dxi-item>
                    <dxi-item itemType="group">
                        <dx-check-box
                            *ngIf="idUploadDokumentOdgovorPrikaz || odgovorPrikaz"
                            [(value)]="tocanIlinetocan"
                            [width]="150"
                            text="Točan odgovor"
                            [disabled]="true">
                        </dx-check-box>
                    </dxi-item>
                </dx-form>
            </div>
            <!-- Odgovor C prikaz -->
            <div *ngIf="odabraniOdgovor == 2">
                <dx-form
                [(formData)]="nizOdgovori[2]"
                [colCount]="1">
                    <dxi-item itemType="group">
                        <dxi-item
                            *ngIf="vrstaOdgovora == 0"
                            dataField="kodg_tekst"
                            [label]="{text: 'Odgovor C'}"
                            [editorOptions]="{stylingMode: 'filled'}"
                            editorType="dxTextArea"
                            [disabled]="true">
                        </dxi-item>
                        <p *ngIf="vrstaOdgovora == 1 && idUploadDokumentOdgovorPrikaz"><a href="{{datotekeURL}}+{{idUploadDokumentOdgovorPrikaz}}+&access_token={{access_token}}" target="_blank"><img src="{{datotekeURL}}+{{idUploadDokumentOdgovorPrikaz}}+&access_token={{access_token}}" height="250" width="auto"></a></p>
                    </dxi-item>
                    <dxi-item itemType="group">
                        <dx-check-box
                            *ngIf="idUploadDokumentOdgovorPrikaz || odgovorPrikaz"
                            [(value)]="tocanIlinetocan"
                            [width]="150"
                            text="Točan odgovor"
                            [disabled]="true">
                        </dx-check-box>
                    </dxi-item>
                </dx-form>
            </div>
            <!-- Odgovor D prikaz -->
            <div *ngIf="odabraniOdgovor == 3">
                <dx-form
                [(formData)]="nizOdgovori[3]"
                [colCount]="1">
                    <dxi-item itemType="group">
                        <dxi-item
                            *ngIf="vrstaOdgovora == 0"
                            dataField="kodg_tekst"
                            [label]="{text: 'Odgovor D'}"
                            [editorOptions]="{stylingMode: 'filled'}"
                            editorType="dxTextArea"
                            [disabled]="true">
                        </dxi-item>
                        <p *ngIf="vrstaOdgovora == 1 && idUploadDokumentOdgovorPrikaz"><a href="{{datotekeURL}}+{{idUploadDokumentOdgovorPrikaz}}+&access_token={{access_token}}" target="_blank"><img src="{{datotekeURL}}+{{idUploadDokumentOdgovorPrikaz}}+&access_token={{access_token}}" height="250" width="auto"></a></p>
                    </dxi-item>
                    <dxi-item itemType="group">
                        <dx-check-box
                            *ngIf="idUploadDokumentOdgovorPrikaz || odgovorPrikaz"
                            [(value)]="tocanIlinetocan"
                            [width]="150"
                            text="Točan odgovor"
                            [disabled]="true">
                        </dx-check-box>
                    </dxi-item>
                </dx-form>
            </div>
        </div>
    </div>
</dx-popup>

<!-- Uređivanje pitanja i odgovora -->
<dx-popup
title="Uređivanje pitanja i odgovora"
[(visible)]="editingPopup"
(onHidden)="popupHiddenPitanjeOdgovor($event)"
[width]="600"
[height]="'auto'"
[position]="{ my: 'top', at: 'top', of: window }">
<div *dxTemplate="let data of 'content'">
    <div *ngIf="vrstaPitanja == 0">
        <!-- <div>
            <p><strong>Pitanje</strong>: {{pitanjePrikazTekst}}</p>
        </div> -->
        <div>
            <dx-form
            validationGroup="kvizPopup"
            [showColonAfterLabel]="true"
            [(formData)]="cijeloPitanje"
            (onFieldDataChanged)="pitanje_fieldDataChanged($event)">
            <dxi-item itemType="group" [colCount]="1" [colSpan]="1">
                    <dxi-item
                    dataField="kpit_naziv"
                    [label]="{text: 'Sadržaj pitanja'}"
                    [editorOptions]="{stylingMode: 'filled'}"
                    [disabled]="kreiranoPitanje">
                    <dxi-validation-rule 
                        type="required"
                        message="Sadržaj pitanja je obavezno polje!">
                    </dxi-validation-rule>
                    </dxi-item>
                <dxi-item style="float: right;" *ngIf="!kreiranoPitanje">
                    <dx-button stylingMode="text" type="default" (onClick)="urediTekstualnoPitanje($event)" class="spremi">UREDI PITANJE</dx-button>
                </dxi-item>
            </dxi-item>
            </dx-form>
        </div>
    </div>
    <div *ngIf="vrstaPitanja == 1">
        <dx-form
            validationGroup="formPopupUpload"
            [showColonAfterLabel]="true"
            [(formData)]="cijeloPitanje"
            (onFieldDataChanged)="pitanje_fieldDataChanged($event)">
            <dxi-item itemType="group" [colCount]="1" [colSpan]="2">
                <dxi-item
                dataField="kpit_naziv"
                [label]="{text: 'Sadržaj pitanja'}"
                [editorOptions]="{stylingMode: 'filled'}"
                [disabled]="kreiranoPitanje">
                </dxi-item>
            </dxi-item>
            <dxi-item itemType="group" [colCount]="2" [colSpan]="2">
                <dxi-item>
                    <p><a href="{{datotekeURL}}+{{idUploadDokument}}+&access_token={{access_token}}" target="_blank"><img src="{{datotekeURL}}+{{idUploadDokument}}+&access_token={{access_token}}" height="100" width="auto"></a></p>
                </dxi-item>
                <dxi-item>
                    <div *dxTemplate="let data of 'upload'">
                        <dx-file-uploader
                        #notesUploader
                        uploadUrl="{{uploadURL}}"
                        [multiple]="false"
                        accept="image/*,application/pdf"
                        [(value)]="value"
                        uploadMode="instantly"
                        (onUploaded)="onEditedPitanje($event)"
                        ></dx-file-uploader>
                    </div>
                </dxi-item>
                <dxi-item itemType="empty"></dxi-item>
            </dxi-item>
            <dxi-item class="spremiOkvir">
                <dx-button stylingMode="text" type="default" (onClick)="urediGrafickoPitanje(value)" class="spremi">UREDI PITANJE</dx-button>
            </dxi-item>
        </dx-form>
    </div>
    <hr>
    <div *ngIf="imaOdgovora">
        <div id="tabs">
            <dx-tabs
                [dataSource]="odgovoriTabsPrikaz"
                [selectedIndex]="odabraniOdgovor"
                (onItemClick)="odgovoriTabPrikaz($event)"
            ></dx-tabs>
        </div>
        <!-- Odgovor A uredi -->
        <div *ngIf="odabraniOdgovor == 0">
            <dx-form
            *ngIf="!nemaOdgovora"
            [(formData)]="nizOdgovori[0]"
            (onFieldDataChanged)="odgovorEdit_fieldDataChanged($event)"
            [colCount]="1">
                <!-- Tekst -->
                <dxi-item itemType="group" [colCount]="1" [colSpan]="2" *ngIf="vrstaOdgovora == 0">
                    <dxi-item
                        dataField="kodg_tekst"
                        [editorOptions]="{stylingMode: 'filled'}"
                        editorType="dxTextArea">
                        <dxo-label [visible]="false"></dxo-label>
                    </dxi-item>
                    <dxi-item itemType="group">
                        <dx-check-box
                            *ngIf="idUploadDokumentOdgovorPrikaz || odgovorPrikaz"
                            [(value)]="tocanIlinetocan"
                            [width]="150"
                            text="Točan odgovor">
                        </dx-check-box>
                    </dxi-item>
                    <dxi-item style="float: right;">
                        <dx-button stylingMode="text" type="default" (onClick)="urediOdgovorTekst($event)" class="spremi">UREDI ODGOVOR</dx-button>
                    </dxi-item>
                </dxi-item>
                <!-- Slika -->
                <dxi-item itemType="group" *ngIf="vrstaOdgovora == 1 && idUploadDokumentOdgovorPrikaz" [colCount]="2" [colSpan]="2">
                    <dxi-item>
                        <p><a href="{{datotekeURL}}+{{idUploadDokumentOdgovorPrikaz}}+&access_token={{access_token}}" target="_blank"><img src="{{datotekeURL}}+{{idUploadDokumentOdgovorPrikaz}}+&access_token={{access_token}}" height="100" width="auto"></a></p>
                    </dxi-item>
                    <dxi-item>
                        <div *dxTemplate="let data of 'upload'">
                            <dx-file-uploader
                            #notesUploader
                            uploadUrl="{{uploadURL}}"
                            [multiple]="false"
                            accept="image/*,application/pdf"
                            [(value)]="value"
                            uploadMode="instantly"
                            (onUploaded)="onEditedOdgovor($event)"
                            ></dx-file-uploader>
                        </div>
                    </dxi-item>
                    <dxi-item>
                        <dx-check-box
                            *ngIf="idUploadDokumentOdgovorPrikaz || odgovorPrikaz"
                            [(value)]="tocanIlinetocan"
                            [width]="150"
                            text="Točan odgovor">
                        </dx-check-box>
                    </dxi-item>
                    <dxi-item style="float: right;">
                        <dx-button stylingMode="text" type="default" (onClick)="urediGrafickiOdgovor(value)" class="spremi">UREDI ODGOVOR</dx-button>
                    </dxi-item>
                </dxi-item>
            </dx-form>
            <!-- Nema postojećeg odgovora A - dodaj tekstualni -->
            <dx-form
            *ngIf="nemaOdgovora"
            id="formOdgovor"
            [colCount]="1"
            (onFieldDataChanged)="odgovor_fieldDataChanged($event)">
                <dxi-item itemType="group">
                    <dxi-item
                        dataField="odgovorABCD"
                        [editorOptions]="{stylingMode: 'filled'}"
                        editorType="dxTextArea">
                        <dxo-label [visible]="false"></dxo-label>
                        <dxi-validation-rule
                            type="required"
                            message="Odgovor A je obavezno polje!">
                        </dxi-validation-rule>
                    </dxi-item>
                </dxi-item>
                <dxi-item itemType="group">
                    <dx-check-box
                        [(value)]="checkBoxABCD"
                        [width]="150"
                        text="Točan odgovor">
                    </dx-check-box>
                </dxi-item>
                <dxi-item style="float: right;">
                    <dx-button stylingMode="text" type="default" (onClick)="spremiOdgovorTekst($event)" class="spremi">DODAJ ODGOVOR</dx-button>
                </dxi-item>
            </dx-form>
        </div>
        <!-- Odgovor B uredi -->
        <div *ngIf="odabraniOdgovor == 1">
            <dx-form 
            *ngIf="!nemaOdgovora"
            [(formData)]="nizOdgovori[1]"
            (onFieldDataChanged)="odgovorEdit_fieldDataChanged($event)">
            <!-- Tekst -->
            <dxi-item itemType="group" [colCount]="1" [colSpan]="2" *ngIf="vrstaOdgovora == 0">
                <dxi-item
                    dataField="kodg_tekst"
                    [editorOptions]="{stylingMode: 'filled'}"
                    editorType="dxTextArea">
                    <dxo-label [visible]="false"></dxo-label>
                </dxi-item>
                <dxi-item itemType="group">
                    <dx-check-box 
                        *ngIf="idUploadDokumentOdgovorPrikaz || odgovorPrikaz"
                        [(value)]="tocanIlinetocan"
                        [width]="150"
                        text="Točan odgovor">
                    </dx-check-box>
                </dxi-item>
                <dxi-item style="float: right;">
                    <dx-button stylingMode="text" type="default" (onClick)="urediOdgovorTekst($event)" class="spremi">UREDI ODGOVOR</dx-button>
                </dxi-item>
            </dxi-item>
            <!-- Slika -->
            <dxi-item itemType="group" *ngIf="vrstaOdgovora == 1 && idUploadDokumentOdgovorPrikaz" [colCount]="2" [colSpan]="2">
                <dxi-item>
                    <p><a href="{{datotekeURL}}+{{idUploadDokumentOdgovorPrikaz}}+&access_token={{access_token}}" target="_blank"><img src="{{datotekeURL}}+{{idUploadDokumentOdgovorPrikaz}}+&access_token={{access_token}}" height="100" width="auto"></a></p>
                </dxi-item>
                <dxi-item>
                    <div *dxTemplate="let data of 'upload'">
                        <dx-file-uploader
                        #notesUploader
                        uploadUrl="{{uploadURL}}"
                        [multiple]="false"
                        accept="image/*,application/pdf"
                        [(value)]="value"
                        uploadMode="instantly"
                        (onUploaded)="onEditedOdgovor($event)"
                        ></dx-file-uploader>
                    </div>
                </dxi-item>
                <dxi-item>
                    <dx-check-box
                        *ngIf="idUploadDokumentOdgovorPrikaz || odgovorPrikaz"
                        [(value)]="tocanIlinetocan"
                        [width]="150"
                        text="Točan odgovor">
                    </dx-check-box>
                </dxi-item>
                <dxi-item style="float: right;">
                    <dx-button stylingMode="text" type="default" (onClick)="urediGrafickiOdgovor(value)" class="spremi">UREDI ODGOVOR</dx-button>
                </dxi-item>
            </dxi-item>
            </dx-form>
            <!-- Nema postojećeg odgovora B - dodaj tekstualni -->
            <dx-form
            *ngIf="nemaOdgovora"
            id="formOdgovor"
            [colCount]="1"
            (onFieldDataChanged)="odgovor_fieldDataChanged($event)">
                <dxi-item itemType="group">
                    <dxi-item
                        dataField="odgovorABCD"
                        [editorOptions]="{stylingMode: 'filled'}"
                        editorType="dxTextArea">
                        <dxo-label [visible]="false"></dxo-label>
                        <dxi-validation-rule
                            type="required"
                            message="Odgovor B je obavezno polje!">
                        </dxi-validation-rule>
                    </dxi-item>
                </dxi-item>
                <dxi-item itemType="group">
                    <dx-check-box
                        [(value)]="checkBoxABCD"
                        [width]="150"
                        text="Točan odgovor">
                    </dx-check-box>
                </dxi-item>
                <dxi-item style="float: right;">
                    <dx-button stylingMode="text" type="default" (onClick)="spremiOdgovorTekst($event)" class="spremi">DODAJ ODGOVOR</dx-button>
                </dxi-item>
            </dx-form>
        </div>
        <!-- Odgovor C uredi -->
        <div *ngIf="odabraniOdgovor == 2">
            <dx-form
            *ngIf="!nemaOdgovora"
            [(formData)]="nizOdgovori[2]"
            (onFieldDataChanged)="odgovorEdit_fieldDataChanged($event)">
                <!-- Tekst -->
                <dxi-item itemType="group" [colCount]="1" [colSpan]="2" *ngIf="vrstaOdgovora == 0">
                    <dxi-item
                        dataField="kodg_tekst"
                        [editorOptions]="{stylingMode: 'filled'}"
                        editorType="dxTextArea">
                        <dxo-label [visible]="false"></dxo-label>
                    </dxi-item>
                    <dxi-item itemType="group">
                        <dx-check-box
                            *ngIf="idUploadDokumentOdgovorPrikaz || odgovorPrikaz"
                            [(value)]="tocanIlinetocan"
                            [width]="150"
                            text="Točan odgovor">
                        </dx-check-box>
                    </dxi-item>
                    <dxi-item style="float: right;">
                        <dx-button stylingMode="text" type="default" (onClick)="urediOdgovorTekst($event)" class="spremi">UREDI ODGOVOR</dx-button>
                    </dxi-item>
                </dxi-item>
                <!-- Slika -->
                <dxi-item itemType="group" *ngIf="vrstaOdgovora == 1 && idUploadDokumentOdgovorPrikaz" [colCount]="2" [colSpan]="2">
                    <dxi-item>
                        <p><a href="{{datotekeURL}}+{{idUploadDokumentOdgovorPrikaz}}+&access_token={{access_token}}" target="_blank"><img src="{{datotekeURL}}+{{idUploadDokumentOdgovorPrikaz}}+&access_token={{access_token}}" height="100" width="auto"></a></p>
                    </dxi-item>
                    <dxi-item>
                        <div *dxTemplate="let data of 'upload'">
                            <dx-file-uploader
                            #notesUploader
                            uploadUrl="{{uploadURL}}"
                            [multiple]="false"
                            accept="image/*,application/pdf"
                            [(value)]="value"
                            uploadMode="instantly"
                            (onUploaded)="onEditedOdgovor($event)"
                            ></dx-file-uploader>
                        </div>
                    </dxi-item>
                    <dxi-item>
                        <dx-check-box
                            *ngIf="idUploadDokumentOdgovorPrikaz || odgovorPrikaz"
                            [(value)]="tocanIlinetocan"
                            [width]="150"
                            text="Točan odgovor">
                        </dx-check-box>
                    </dxi-item>
                    <dxi-item style="float: right;">
                        <dx-button stylingMode="text" type="default" (onClick)="urediGrafickiOdgovor(value)" class="spremi">UREDI ODGOVOR</dx-button>
                    </dxi-item>
                </dxi-item>
            </dx-form>
            <!-- Nema postojećeg odgovora C - dodaj tekstualni -->
            <dx-form
            *ngIf="nemaOdgovora"
            id="formOdgovor"
            [colCount]="1"
            (onFieldDataChanged)="odgovor_fieldDataChanged($event)">
                <dxi-item itemType="group">
                    <dxi-item
                        dataField="odgovorABCD"
                        [editorOptions]="{stylingMode: 'filled'}"
                        editorType="dxTextArea">
                        <dxo-label [visible]="false"></dxo-label>
                        <dxi-validation-rule 
                            type="required"
                            message="Odgovor C je obavezno polje!">
                        </dxi-validation-rule>
                    </dxi-item>
                </dxi-item>
                <dxi-item itemType="group">
                    <dx-check-box
                        [(value)]="checkBoxABCD"
                        [width]="150" 
                        text="Točan odgovor">
                    </dx-check-box>
                </dxi-item>
                <dxi-item style="float: right;">
                    <dx-button stylingMode="text" type="default" (onClick)="spremiOdgovorTekst($event)" class="spremi">DODAJ ODGOVOR</dx-button>
                </dxi-item>
            </dx-form>
        </div>
        <!-- Odgovor D uredi -->
        <div *ngIf="odabraniOdgovor == 3">
            <dx-form
            *ngIf="!nemaOdgovora"
            [(formData)]="nizOdgovori[3]"
            (onFieldDataChanged)="odgovorEdit_fieldDataChanged($event)">
                <!-- Tekst -->
                <dxi-item itemType="group" [colCount]="1" [colSpan]="2" *ngIf="vrstaOdgovora == 0">
                    <dxi-item
                        dataField="kodg_tekst"
                        [editorOptions]="{stylingMode: 'filled'}"
                        editorType="dxTextArea">
                        <dxo-label [visible]="false"></dxo-label>
                    </dxi-item>
                    <dxi-item itemType="group">
                        <dx-check-box 
                            *ngIf="idUploadDokumentOdgovorPrikaz || odgovorPrikaz"
                            [(value)]="tocanIlinetocan"
                            [width]="150"
                            text="Točan odgovor">
                        </dx-check-box>
                    </dxi-item>
                    <dxi-item style="float: right;">
                        <dx-button stylingMode="text" type="default" (onClick)="urediOdgovorTekst($event)" class="spremi">UREDI ODGOVOR</dx-button>
                    </dxi-item>
                </dxi-item>
                <!-- Slika -->
                <dxi-item itemType="group" *ngIf="vrstaOdgovora == 1 && idUploadDokumentOdgovorPrikaz" [colCount]="2" [colSpan]="2">
                    <dxi-item>
                        <p><a href="{{datotekeURL}}+{{idUploadDokumentOdgovorPrikaz}}+&access_token={{access_token}}" target="_blank"><img src="{{datotekeURL}}+{{idUploadDokumentOdgovorPrikaz}}+&access_token={{access_token}}" height="100" width="auto"></a></p>
                    </dxi-item>
                    <dxi-item>
                        <div *dxTemplate="let data of 'upload'">
                            <dx-file-uploader
                            #notesUploader
                            uploadUrl="{{uploadURL}}"
                            [multiple]="false"
                            accept="image/*,application/pdf"
                            [(value)]="value"
                            uploadMode="instantly"
                            (onUploaded)="onEditedOdgovor($event)"
                            ></dx-file-uploader>
                        </div>
                    </dxi-item>
                    <dxi-item>
                        <dx-check-box
                            *ngIf="idUploadDokumentOdgovorPrikaz || odgovorPrikaz"
                            [(value)]="tocanIlinetocan"
                            [width]="150"
                            text="Točan odgovor">
                        </dx-check-box>
                    </dxi-item>
                    <dxi-item style="float: right;">
                        <dx-button stylingMode="text" type="default" (onClick)="urediGrafickiOdgovor(value)" class="spremi">UREDI ODGOVOR</dx-button>
                    </dxi-item>
                </dxi-item>
            </dx-form>
            <!-- Nema postojećeg odgovora D - dodaj tekstualni -->
            <dx-form
            *ngIf="nemaOdgovora"
            id="formOdgovor"
            [colCount]="1"
            (onFieldDataChanged)="odgovor_fieldDataChanged($event)">
                <dxi-item itemType="group">
                    <dxi-item
                        dataField="odgovorABCD"
                        [editorOptions]="{stylingMode: 'filled'}"
                        editorType="dxTextArea">
                        <dxo-label [visible]="false"></dxo-label>
                        <dxi-validation-rule
                            type="required"
                            message="Odgovor D je obavezno polje!">
                        </dxi-validation-rule>
                    </dxi-item>
                </dxi-item>
                <dxi-item itemType="group">
                    <dx-check-box
                        [(value)]="checkBoxABCD"
                        [width]="150"
                        text="Točan odgovor">
                    </dx-check-box>
                </dxi-item>
                <dxi-item style="float: right;">
                    <dx-button stylingMode="text" type="default" (onClick)="spremiOdgovorTekst($event)" class="spremi">DODAJ ODGOVOR</dx-button>
                </dxi-item>
            </dx-form>
        </div>
    </div>
    <div *ngIf="!imaOdgovora">
        <div *ngIf="vrstaOdgovora == null">
            <p>Vrsta odgovora:</p>
            <ul class="kocke">
                <li class="dx-card" (click)="odabirVrsteOdgovora('tekst')">
                    <dx-button icon="startswith" class="slika"></dx-button>
                    <p class="opis">Tekst</p>
                </li>
                <li class="dx-card" (click)="odabirVrsteOdgovora('slika')">
                    <dx-button icon="image" class="slika"></dx-button>
                    <p class="opis">Slika</p>
                </li>
            </ul>
        </div>
         <!-- Tekstualna vrsta odgovora -->
    <div *ngIf="vrstaOdgovora == 0">
        <strong>Odgovori:</strong>
        <hr>
        <div id="tabs">
            <dx-tabs
                [dataSource]="odgovoriTabs"
                [selectedIndex]="odabraniOdgovor"
                (onItemClick)="odgovoriTabOdabir($event)"
            ></dx-tabs>
        </div>
        <!-- Odgovor A tekst -->
        <div *ngIf="odabraniOdgovor == 0">
            <dx-form
            id="formOdgovor"
            [colCount]="1"
            (onFieldDataChanged)="odgovor_fieldDataChanged($event)">
                <dxi-item itemType="group">
                    <dxi-item
                        dataField="odgovorABCD"
                        [editorOptions]="{stylingMode: 'filled'}"
                        editorType="dxTextArea">
                        <dxo-label [visible]="false"></dxo-label>
                        <dxi-validation-rule
                            type="required"
                            message="Odgovor A je obavezno polje!">
                        </dxi-validation-rule>
                    </dxi-item>
                </dxi-item>
                <dxi-item itemType="group">
                    <dx-check-box
                        [(value)]="checkBoxABCD"
                        [width]="150"
                        text="Točan odgovor">
                    </dx-check-box>
                </dxi-item>
                <dxi-item style="float: right;">
                    <dx-button stylingMode="text" type="default" (onClick)="spremiOdgovorTekst($event)" class="spremi">SPREMI</dx-button>
                </dxi-item>
            </dx-form>
        </div>
        <!-- Odgovor B tekst -->
        <div *ngIf="odabraniOdgovor == 1">
            <dx-form
            id="formOdgovor"
            [colCount]="1"
            (onFieldDataChanged)="odgovor_fieldDataChanged($event)">
                <dxi-item itemType="group">
                    <dxi-item
                        dataField="odgovorABCD"
                        [editorOptions]="{stylingMode: 'filled'}"
                        editorType="dxTextArea">
                        <dxo-label [visible]="false"></dxo-label>
                        <dxi-validation-rule 
                            type="required"
                            message="Odgovor B je obavezno polje!">
                        </dxi-validation-rule>
                    </dxi-item>
                </dxi-item>
                <dxi-item itemType="group">
                    <dx-check-box
                        [(value)]="checkBoxABCD"
                        [width]="150"
                        text="Točan odgovor">
                    </dx-check-box>
                </dxi-item>
                <dxi-item style="float: right;">
                    <dx-button stylingMode="text" type="default" (onClick)="spremiOdgovorTekst($event)" class="spremi">SPREMI</dx-button>
                </dxi-item>
            </dx-form>
        </div>
        <!-- Odgovor C tekst -->
        <div *ngIf="odabraniOdgovor == 2">
            <dx-form
            id="formOdgovor"
            [colCount]="1"
            (onFieldDataChanged)="odgovor_fieldDataChanged($event)">
                <dxi-item itemType="group">
                    <dxi-item
                        dataField="odgovorABCD"
                        [editorOptions]="{stylingMode: 'filled'}"
                        editorType="dxTextArea">
                        <dxo-label [visible]="false"></dxo-label>
                        <dxi-validation-rule 
                            type="required"
                            message="Odgovor C je obavezno polje!">
                        </dxi-validation-rule>
                    </dxi-item>
                </dxi-item>
                <dxi-item itemType="group">
                    <dx-check-box
                        [(value)]="checkBoxABCD"
                        [width]="150"
                        text="Točan odgovor">
                    </dx-check-box>
                </dxi-item>
                <dxi-item style="float: right;">
                    <dx-button stylingMode="text" type="default" (onClick)="spremiOdgovorTekst($event)" class="spremi">SPREMI</dx-button>
                </dxi-item>
            </dx-form>
        </div>
        <!-- Odgovor D tekst-->
        <div *ngIf="odabraniOdgovor == 3">
            <dx-form
            id="formOdgovor"
            [colCount]="1"
            (onFieldDataChanged)="odgovor_fieldDataChanged($event)">
                <dxi-item itemType="group">
                    <dxi-item
                        dataField="odgovorABCD"
                        [editorOptions]="{stylingMode: 'filled'}"
                        editorType="dxTextArea">
                        <dxo-label [visible]="false"></dxo-label>
                        <dxi-validation-rule
                            type="required"
                            message="Odgovor D je obavezno polje!">
                        </dxi-validation-rule>
                    </dxi-item>
                </dxi-item>
                <dxi-item itemType="group">
                    <dx-check-box
                        [(value)]="checkBoxABCD"
                        [width]="150"
                        text="Točan odgovor">
                    </dx-check-box>
                </dxi-item>
                <dxi-item style="float: right;">
                    <dx-button stylingMode="text" type="default" (onClick)="spremiOdgovorTekst($event)" class="spremi">SPREMI</dx-button>
                </dxi-item>
            </dx-form>
        </div>
    </div>
    <!-- Grafička vrsta odgovora -->
    <div *ngIf="vrstaOdgovora == 1">
        <strong>Odgovori:</strong>
        <hr>
        <div id="tabs">
            <dx-tabs
                [dataSource]="odgovoriTabs"
                [selectedIndex]="odabraniOdgovor"
                (onItemClick)="odgovoriTabOdabir($event)"
            ></dx-tabs>
        </div>
        <!-- Odgovor A slika -->
        <div *ngIf="odabraniOdgovor == 0">
            <dx-form
            id="formOdgovor"
            [colCount]="1"
            (onFieldDataChanged)="odgovor_fieldDataChanged($event)">
                <dxi-item itemType="group">
                    <dx-file-uploader
                        #notesUploader
                        uploadUrl="{{uploadURL}}"
                        [multiple]="false"
                        accept="image/*"
                        [(value)]="value"
                        uploadMode="instantly"
                        (onUploaded)="onUploadedOdgovor($event)"
                    ></dx-file-uploader>
                </dxi-item>
                <dxi-item itemType="group">
                    <dx-check-box
                        [(value)]="checkBoxABCD"
                        [width]="150"
                        text="Točan odgovor">
                    </dx-check-box>
                </dxi-item>
                <dxi-item style="float: right;">
                    <dx-button stylingMode="text" type="default" (onClick)="spremiOdgovorSlika($event)" class="spremi">SPREMI</dx-button>
                </dxi-item>
            </dx-form>
        </div>
        <!-- Odgovor B slika -->
        <div *ngIf="odabraniOdgovor == 1">
            <dx-form
            id="formOdgovor"
            [colCount]="1"
            (onFieldDataChanged)="odgovor_fieldDataChanged($event)">
                <dxi-item itemType="group">
                    <dx-file-uploader
                        #notesUploader
                        uploadUrl="{{uploadURL}}"
                        [multiple]="false"
                        accept="image/*"
                        [(value)]="value"
                        uploadMode="instantly"
                        (onUploaded)="onUploadedOdgovor($event)"
                    ></dx-file-uploader>
                </dxi-item>
                <dxi-item itemType="group">
                    <dx-check-box
                        [(value)]="checkBoxABCD"
                        [width]="150"
                        text="Točan odgovor">
                    </dx-check-box>
                </dxi-item>
                <dxi-item style="float: right;">
                    <dx-button stylingMode="text" type="default" (onClick)="spremiOdgovorSlika($event)" class="spremi">SPREMI</dx-button>
                </dxi-item>
            </dx-form>
        </div>
        <!-- Odgovor C slika -->
        <div *ngIf="odabraniOdgovor == 2">
            <dx-form
            id="formOdgovor"
            [colCount]="1"
            (onFieldDataChanged)="odgovor_fieldDataChanged($event)">
                <dxi-item itemType="group">
                    <dx-file-uploader
                        #notesUploader
                        uploadUrl="{{uploadURL}}"
                        [multiple]="false"
                        accept="image/*"
                        [(value)]="value"
                        uploadMode="instantly"
                        (onUploaded)="onUploadedOdgovor($event)"
                    ></dx-file-uploader>
                </dxi-item>
                <dxi-item itemType="group">
                    <dx-check-box
                        [(value)]="checkBoxABCD"
                        [width]="150"
                        text="Točan odgovor">
                    </dx-check-box>
                </dxi-item>
                <dxi-item style="float: right;">
                    <dx-button stylingMode="text" type="default" (onClick)="spremiOdgovorSlika($event)" class="spremi">SPREMI</dx-button>
                </dxi-item>
            </dx-form>
        </div>
        <!-- Odgovor D slika -->
        <div *ngIf="odabraniOdgovor == 3">
            <dx-form
            id="formOdgovor"
            [colCount]="1"
            (onFieldDataChanged)="odgovor_fieldDataChanged($event)">
                <dxi-item itemType="group">
                    <dx-file-uploader
                        #notesUploader
                        uploadUrl="{{uploadURL}}"
                        [multiple]="false"
                        accept="image/*"
                        [(value)]="value"
                        uploadMode="instantly"
                        (onUploaded)="onUploadedOdgovor($event)"
                    ></dx-file-uploader>
                </dxi-item>
                <dxi-item itemType="group">
                    <dx-check-box
                        [(value)]="checkBoxABCD"
                        [width]="150"
                        text="Točan odgovor">
                    </dx-check-box>
                </dxi-item>
                <dxi-item style="float: right;">
                    <dx-button stylingMode="text" type="default" (onClick)="spremiOdgovorSlika($event)" class="spremi">SPREMI</dx-button>
                </dxi-item>
            </dx-form>
        </div>
    </div>
    </div>
</div>
</dx-popup>