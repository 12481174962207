import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ApiService } from 'src/app/shared/services/api.service';

import DataSource from 'devextreme/data/data_source';
import notify from 'devextreme/ui/notify';
import validationEngine from "devextreme/ui/validation_engine";

@Component({
  selector: 'app-ucionice',
  templateUrl: './ucionice.component.html',
  styleUrls: ['./ucionice.component.scss']
})

export class UcioniceComponent implements OnInit {

  prava = +localStorage.getItem('kor_prava');
  korisnikID = parseInt(localStorage.getItem('idkorisnik'));
  access_token: any = localStorage.getItem('access_token');
  
  naslov: any;
  window: any;
  ucionice: any;
  programi: any;
  gradovi: any;
  odabraniPredmeti:any;
  odabraniGrad: any;
  dodajPopup = false;
  administratori: any;
  administratoriString: string;
  treeBoxValue = [];
  idAdministratori = [];
  podaciUcionice={};
  idPrograma: any;
  polaznici: any;
  idPolaznika = [];
  polazniciString: string;
  treeBoxValuePolaznik = [];
  idPolaznici = [];
 
  constructor(
    public _api: ApiService,
    private _router: Router
  ) {
    
    this.onShowing = this.onShowing.bind(this); 
    this.onGradSelected = this.onGradSelected.bind(this);
    this.onProgramSelected = this.onProgramSelected.bind(this);

   }

   ngOnInit() {
    // this.provjeraPrava();
    this.ucionicePopis();
    this.programiPopis();
    this.dohvatiGradove();
    this.popisKorisnika();
    this.testPolaznici();
  }

  // provjeraPrava(){
  //   if(this.prava == 0 || this.prava == 1){
  //     this.ucionicePopis();
  //   } else if (this.prava == 2) {
  //     this.ucionicePredavac();  
  //   } else {
  //   }
  // }


  onShowing(e){
    e.component.option("title", this.naslov);
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        locateInMenu:'auto',
        location: 'after',
        template: "dodaj",
      }
    )
  }

  onInitNewRow(e){;
    this.naslov = 'Dodavanje učionice';
    this.dodajPopup = true;
    this.idAdministratori = [];
    //Ako je administrator automatski ga dodaj u array
    if(this.prava == 1){
      this.idAdministratori.push(this.korisnikID);
    }
  }

  /**++++++++++++++++++++++++ autocomplete ++++++++++++++++++++++++ */
  polaznikTrazi(e){
    // console.log(e);
    if(e.value){
      if (e.value.length >= 3) {
        // console.log({"prava": 3, ime: e.value});        
        this._api.korisnikPopisPretrazivanje({prava: 3, ime: e.value, "access_token": this.access_token}).subscribe(res => {
          //  console.log(res);
           this.polaznici = res.korisnici;
        });
      }
    }
  }

  popup_hidden(e: any){
    validationEngine.resetGroup('formaDodavanje');
    this.idAdministratori = [];
    this.treeBoxValue = [];
    this.administratoriString = JSON.stringify(this.treeBoxValue).replace(/]|[[]/g, '').replace(/"/g,' ');

    this.idPolaznici = [];
    this.treeBoxValuePolaznik = [];
    this.polazniciString = JSON.stringify(this.treeBoxValuePolaznik).replace(/]|[[]/g, '').replace(/"/g,' ');
  }

  jednaUcionica(e){
    // (this.agent === true || this.administrator === true){
    if(this.prava  !=2){
      if (e.rowType == "data" && e.column.dataField === "uci_naziv") {
         this._router.navigate(['jedna-ucionica/' + e.data.iducionica + '/' + e.data.uci_naziv]);
      }
    }
    // }
  }

  deAktivacijaUcionice(idUcionica){
    this._api.ucionicaDeAktivacija({"iducionica": idUcionica, "access_token": this.access_token}).subscribe(res=>{
      if(res.success == true){
        notify(res.message, "success");
        this.ucionicePopis();
      } else {
        notify(res.message, "error");
        this.ucionicePopis();
      }
    });
  }

  cellHover(e){
    /////// Ako nije predavač
    if(this.prava != 2 ){
      if (e.rowType === "data" && e.column.dataField === "uci_naziv"){
          if (e.eventType == 'mouseover'){
            e.cellElement.style.fontWeight = "bold";
            e.cellElement.style.fontSize = "18px";
            e.cellElement.style.cursor = "pointer";
          } else {
            e.cellElement.style.fontWeight = "normal";
            e.cellElement.style.fontSize = "14px";
          }
      }
    }
  }

  ucionicePopis(){
    this._api.ucionicaPopis({"access_token": this.access_token, "idkorisnik": this.korisnikID, "prava": this.prava}).subscribe(res => {  
      if(res.success == true){
        this.ucionice = res.ucionice;
      } else {
        notify(res.message, "error");
      }
    });
  }

  programiPopis(){
    this._api.programPopis({"access_token": this.access_token}).subscribe(res => {
      if(res.success == true){
        this.programi = new DataSource({
          store: {
              data: res.program,
              type: 'array',
              key: 'idprogram'
          },
          group: "Category"
      });
      } else {
        notify(res.message, "error");
      }
    });
  }

  popisKorisnika(){
    this._api.korisnikPopis({"prava": 1, "access_token": this.access_token}).subscribe(res=>{
      if(res.success == true){
        this.administratori = res.korisnici;
      } else {
         notify(res.message, "error");
      }
    });
  }

  testPolaznici(){
    this._api.korisnikPopis({"prava": 3, "access_token": this.access_token}).subscribe(res=>{
      // console.log(res.korisnici);
      if(res.success == true){
        this.polaznici = res.korisnici;
      } else {
        notify(res.message, "error");
      }
    });
  }

  spremiUcionicu(e){
   var validacija = validationEngine.validateGroup('formaDodavanje');
  //  console.log(validacija);
   var podaci = {
     "access_token": this.access_token,
     "idgrad": this.odabraniGrad,
     "kreirao": +localStorage.getItem('idkorisnik'),
     "godina": e.uci_obrazovnaGodina,
     "naziv": e.uci_naziv,
     "administratori": this.idAdministratori,
     "idprogram": this.idPrograma,
    //  "polaznici": this.idPolaznici
   };
  // console.log(podaci);
    if(validacija.isValid === true){
      this._api.ucionicaDodaj(podaci).subscribe(res => {
        // console.log(res);
        if(res.success == true){
          this.ucionicePopis();
          notify(res.message, "success");
          this.dodajPopup = false;
        } else {
          notify(res.message, "error");
        }
      });
    }
  }

  urediUcionicu(e){
    // console.log(e);
    // console.log(e.data.iducionica);
      this._router.navigate(['uredi-ucionicu/' + e.data.iducionica]);
  }

  ucionicePredavac(){
    this._api.ucionicaPopisZaPredavaca({"access_token": this.access_token, "idkorisnik": this.korisnikID}).subscribe(res => {
      if(res.success == true){
        this.ucionice = res.ucionice;
      } else {
        notify(res.message, "error");
      }
      // console.log(this.ucionice);
    });
  }

  dohvatiGradove(){
    this._api.gradPopis({"access_token": this.access_token}).subscribe(res=>{
      if(res.success == true){
        this.gradovi = new DataSource({
          store: {
              data: res.gradovi,
              type: 'array',
              key: 'idsifarnikgrad'
          },
          group: "Category"
      });
      } else {
        notify(res.message, "error");
      }
    });
  }

  onProgramSelected(e){
    // console.log(e);
    this.idPrograma = e.value;
  }

  onGradSelected(e){
    // console.log(e);
    this.odabraniGrad = e.value; 
  }

  onAdminSelected(e){
    // console.log(e);
    if(e.removedItems.length != 0){
      let index = this.treeBoxValue.indexOf(e.removedItems[0].kor_punoIme);
      if (index > -1) {
        this.treeBoxValue.splice(index, 1);
      }
    }
    if (e.addedItems.length != 0){
      this.treeBoxValue.push(e.addedItems[0].kor_punoIme);
    }
    // console.log(this.treeBoxValue);
    this.administratoriString = JSON.stringify(this.treeBoxValue).replace(/]|[[]/g, '').replace(/"/g,' ');
    if(e.removedItems.length != 0){
      let index = this.idAdministratori.indexOf(e.removedItems[0].idkorisnik);
      if (index > -1) {
        this.idAdministratori.splice(index, 1);
      }
    }
    if (e.addedItems.length != 0){
      this.idAdministratori.push(e.addedItems[0].idkorisnik);
    }
  }

  /** --------------------onPolaznikSelected------------------------  */
  onPolaznikSelected(e){
    if(e.removedItems.length != 0){
      let index = this.treeBoxValuePolaznik.indexOf(e.removedItems[0].kor_punoIme);
      if (index > -1) {
        this.treeBoxValuePolaznik.splice(index, 1);
      }
    }
    if (e.addedItems.length != 0){
      this.treeBoxValuePolaznik.push(e.addedItems[0].kor_punoIme);
    }
    this.polazniciString = JSON.stringify(this.treeBoxValuePolaznik).replace(/]|[[]/g, '').replace(/"/g,' ');
    if(e.removedItems.length != 0){
      let index = this.idPolaznici.indexOf(e.removedItems[0].idkorisnik);
      if (index > -1) {
        this.idPolaznici.splice(index, 1);
      }
    }
    if (e.addedItems.length != 0){
      this.idPolaznici.push(e.addedItems[0].idkorisnik);
    }
  }
}