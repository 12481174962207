<h2 class="content-block">Učionice</h2>

<div class="content-block dx-card responsive-paddings">

    <dx-data-grid
        [showRowLines]="false"
        class="wide-card"
        [hoverStateEnabled]="true"
        [wordWrapEnabled]="true"
        [dataSource]="ucionice"
        [columnAutoWidth]="true"
        [columnHidingEnabled]="true"
        (onCellHoverChanged)="cellHover($event)"
        (onCellClick)="jednaUcionica($event)"
        (onInitNewRow)="onInitNewRow($event)"
        keyExpr="iducionica"
        (onToolbarPreparing)="onToolbarPreparing($event)">

    <dxo-paging [pageSize]="10"></dxo-paging>
    <dxo-pager [showPageSizeSelector]="true" [showInfo]="true"></dxo-pager>
    <dxo-search-panel [visible]="true"></dxo-search-panel>
    <dxi-column dataField="uci_naziv" caption="Naziv učionice"></dxi-column>
    <dxi-column dataField="brojPolaznika" alignment="center"></dxi-column>
    <dxi-column dataField="uci_obrazovnaGodina" [visible]="false"></dxi-column>
    <dxi-column dataField="uci_createdBy" [visible]="false"></dxi-column>

    <dxi-column type="buttons" [width]="110"><dxi-button template="buttonActiveTemplate"></dxi-button></dxi-column>
    <div *dxTemplate="let buttonInfo of 'buttonActiveTemplate'">
        <div *ngIf="prava == 0">
            <dx-button id="aktivna" *ngIf="buttonInfo.data.uci_aktivna == 1" (onClick)="deAktivacijaUcionice(buttonInfo.data.iducionica)">Aktivna</dx-button>
            <dx-button id="neaktivna" *ngIf="buttonInfo.data.uci_aktivna == 0" (onClick)="deAktivacijaUcionice(buttonInfo.data.iducionica)">Završena</dx-button>
        </div>
        <div *ngIf="prava == 1">
            <dx-button id="aktivna" *ngIf="buttonInfo.data.uci_aktivna == 1 && buttonInfo.data.administrira === 1" (onClick)="deAktivacijaUcionice(buttonInfo.data.iducionica)">Aktivna</dx-button>
            <dx-button id="neaktivna" *ngIf="buttonInfo.data.uci_aktivna == 0 && buttonInfo.data.administrira === 1" (onClick)="deAktivacijaUcionice(buttonInfo.data.iducionica)">Neaktivna</dx-button>
        </div>
    </div>
    <dxi-column type="buttons" [width]="110" ><dxi-button template="buttonTemplate"></dxi-button></dxi-column>
    <div *dxTemplate="let buttonInfo of 'buttonTemplate'">
        <div *ngIf="buttonInfo.data.uci_aktivna == 1">
            <dx-button *ngIf="prava == 0 || buttonInfo.data.administrira === 1" icon="edit" id="edit" (click)="urediUcionicu(buttonInfo)"></dx-button>
        </div>
    </div>
    <div *dxTemplate="let data of 'dodaj'">
        <dx-button icon="add" hint="Dodavanje učionice" (onClick)="onInitNewRow($event)"></dx-button>
    </div>
    </dx-data-grid>

    <!-------------------------------- Kreiranje učionice------------------------------->
    <dx-popup
        title="Dodavanje učionice"
        [(visible)]="dodajPopup"
        (onHidden)="popup_hidden($event)"
        [width]="800"
        [height]="'auto'"
        [position]="{ my: 'center', at: 'center', of: window }">

    <dx-form
        [(formData)]="podaciUcionice"
        validationGroup="formaDodavanje"
        [showColonAfterLabel]="true">
        <dxi-item itemType="group" [colCount]="2" [colSpan]="2">
            <dxi-item dataField="uci_naziv" [label]="{text: 'Naziv'}" [colSpan]="2" [editorOptions]="{stylingMode:'filled' }">
                <dxi-validation-rule type="required" message="Naziv je obavezno polje!"></dxi-validation-rule>
            </dxi-item>
            <dxi-item [label]="{text: 'Program'}" [colSpan]="2">
                <dxi-validation-rule type="required" message="Program je obavezno polje!"></dxi-validation-rule>
                <div *dxTemplate="let data of 'program'">
                    <dx-select-box
                        stylingMode='filled'
                        [dataSource]="programi"
                        valueExpr="idprogram"
                        displayExpr="programNaziv"
                        [searchEnabled]="true"
                        (onValueChanged)="onProgramSelected($event)"
                        [grouped]="true">
                    </dx-select-box>
                </div>
            </dxi-item>
            <dxi-item dataField="uci_obrazovnaGodina" [label]="{text: 'Godina'}" [editorOptions]="{stylingMode:'filled' }">
                <dxi-validation-rule  type="pattern"  pattern="^[^\.]+$"  message="Godinu unesite bez točke!"></dxi-validation-rule>
            </dxi-item>
            <dxi-item [label]="{text: 'Grad'}">
                <div *dxTemplate="let data of 'program'">
                    <dx-select-box
                        stylingMode='filled'
                        [dataSource]="gradovi"
                        valueExpr="idsifarnikgrad"
                        displayExpr="grad_naziv"
                        [searchEnabled]="true"
                        (onValueChanged)="onGradSelected($event)"
                        [grouped]="true">
                    </dx-select-box>
                </div>
            </dxi-item>
   

        <!--------------- multiple Administratori --------------->
            <!-- <dxi-item [label]="{text: 'Administratori'}" [colSpan]="2">
                <dx-drop-down-box
                    stylingMode='filled'
                    placeholder="Odabir..."
                    [(value)]="administratoriString"
                    [dataSource]="administratori">
                    <dx-list
                        [(selectedItemKeys)]="idAdministratori"
                        [searchEnabled]="true"
                        searchExpr="kor_punoIme"
                        searchMode="contains"
                        [showSelectionControls]="true"
                        [dataSource]="administratori"
                        displayExpr="kor_punoIme"
                        selectionMode="multiple"
                        (onSelectionChanged)="onAdminSelected($event)">
                    </dx-list>
                </dx-drop-down-box>
            </dxi-item> -->

       <!--------------- multiple polaznici --------------->
            <!-- <dxi-item [label]="{text: 'Polaznici'}" [colSpan]="2">
                <dx-drop-down-box   
                    stylingMode='filled'               
                    placeholder="Odabir..."
                    [(value)]="polazniciString"
                    [dataSource]="polaznici">                        
                    <dx-list
                        [(selectedItemKeys)]="idPolaznici"
                        [searchEnabled]="true"
                        searchExpr="kor_punoIme"
                        searchMode="contains"
                        [showSelectionControls]="true"
                        [dataSource]="polaznici"
                        displayExpr="kor_punoIme"
                        selectionMode="multiple"
                        (onSelectionChanged)="onPolaznikSelected($event)">
                    </dx-list>
                </dx-drop-down-box>
            </dxi-item> -->

        </dxi-item>
        <dxi-item style="float: right;">
            <dx-button id="spremi" stylingMode="text" type="default" (onClick)="spremiUcionicu(podaciUcionice)" class="spremi">SPREMI</dx-button>
        </dxi-item>
    </dx-form>
    </dx-popup>
</div>