import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ApiService } from 'src/app/shared/services';

import * as moment from 'moment';

import notify from 'devextreme/ui/notify';
import { IdUcionicaService } from 'src/app/shared/services/id-ucionica.service';

@Component({
  selector: 'app-jedna-ucionica',
  templateUrl: './jedna-ucionica.component.html',
  styleUrls: ['./jedna-ucionica.component.scss']
})

export class JednaUcionicaComponent implements OnInit {

  access_token: any = localStorage.getItem('access_token');

  datotekeURL = this._api.datotekeURL;
  ucionicaPrikaz: any;
  ucionicaID: any;
  ucionicaNaziv: string;
  adminPrikazi = [];
  predmetPrikazi = [];
  prikaziTab = false;
  predmetiIzUcionice: any;
  rokovi: any;
  glavniTabovi = [
    { id: 0, text: 'Osnovni podaci' },
    { id: 1, text: 'Osobe' },
    { id: 2, text: 'Predmeti' },
    { id: 3, text: 'Ispitni rokovi' },
    { id: 4, text: 'Predavanja i prisutnost' },
    { id: 5, text: 'Obavijesti' },
    { id: 6, text: 'Chat' }
  ];
  polaznici: any;
  predmetID: any;
  odabranGlavniTab: number = 0;
  nazivPredmeta: any;
  predavaciPopup = false;
  polazniciPopup = false;
  materijaliPopup = false;
  // predavacPrikazi = [];
  // polazniciPredmeta = [];
  globalniMaterijali: any;
  vimeoURL: string;
  prikaziVideo: boolean = false;
  jedanKvizPopup = false;
  kvizovi: any;
  kvizoviPopup = false;
  kviz: any;
  kvizPopup: boolean = false;
  pitanjeSlika: boolean = false;
  pitanjaZaKviz: any;
  ukupnoPitanja: number;
  pitanjeKviz: any;
  slika: any;
  prikaziZavrsniGumb: any;
  odgovoriKviz: any;
  ponudjeniOdgovori: any;
  prviBroj: number = 1;
  index: number = 0;
  vrstaKviza: any;
  odgovoriZaProvjeru = [];
  slovo = ['A)', 'B)', 'C)', 'D)'];
  kvizNaziv: any;
  obavijesti = [];
  danas = new Date().toISOString();
  danasnjiDan: any;
  polazniciIspitniRok = [];
  IDucionicaPredavanje: number;
  jedanIspitPopup = false;
  pitanjaZaIspit = [{
    'idispitpitanje': null,
    'ispp_naziv': null,
    'ispp_oblik': null,
    'ispp_tekst': null,
    'ispp_vrsta': null,
    'uploaddokument_iduploaddokument': null,
    'isprje_tocan': null
  }];
  odgovoriIspit = [{
    'idispitodgovor': null,
    'ispitpitanje_idispitpitanje': null,
    'ispo_tekst': null,
    'ispo_tocan': null,
    'ispo_vrsta': null,
    'uploaddokument_iduploaddokument': null,
    'korisnik_tocan_odgovor': null,
    'idispitrjeseno': null
  }];
  korisnikoviOdgovori= [];
  polaznikImePrezime: string;
  IDispitROK: number;
  IDispit: number;
  polazniciPredavanjaPrisutnost = [];
  predavanja = [];
  ocjenePopup: boolean = false;
  polazniciPrikazi: any;
  sviPredavaciNaPredmetu: any;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _idUcionicaService: IdUcionicaService,
    private _router: Router, public _api: ApiService
  ) {
    // this.danasnjiDan = moment(this.danas).format();
    this.danasnjiDan = this.danas + 1;
  }

  ngOnInit() {
    this.ucionicaID = this._activatedRoute.snapshot.params['id'];
    this.ucionicaNaziv = this._activatedRoute.snapshot.params['naziv'].replace('%2F','/');
    // console.log(this.ucionicaID)
    this.dohvatiJednuUcionicu();
  }

  dohvatiJednuUcionicu(){
    this.adminPrikazi = [];
    this.predmetPrikazi = [];
    this._api.ucionicaJedna({"access_token": this.access_token, "iducionica": this.ucionicaID}).subscribe(res =>{
      // console.log(res)
      if(res.success == true){
        this.ucionicaPrikaz = res.ucionica;
        this.adminPrikazi = res.administratori;
        this.predmetPrikazi = res.predmeti;
        this.polaznici = res.polaznici;
        // this.predavaci = res.predavaci;        
        this.predmetiIzUcionice = res.predmeti;
      } else {
         notify(res.message, "error", 1000);
      }
    });
  }

  oznaciPrisutnost(e){
    // console.log(e);
  }

  odaberiTab(e){
      this.odabranGlavniTab = e.itemIndex;
      if(this.odabranGlavniTab === 3){
        this.dohvatiIspitneRokove();
      } else if (this.odabranGlavniTab === 4){
        this.popisPredavanja();
      } else if (this.odabranGlavniTab === 5){
        this.popisObavijesti();
      } else if (this.odabranGlavniTab === 6){
        this._idUcionicaService.posaljiIdUcionica(this.ucionicaID);
      }
  }

  predmetOdabran(e){
    // console.log(e.value);
    this.predmetID = e.value;
    if(e.value){
      this.prikaziTab = true;
    } else {
      this.prikaziTab = false;
    }
  }

  predavaciNaPredmetu(e, idpredmet, pred_naziv){
    this.predavaciPopup = true;
    this.predmetID = idpredmet;
    this.nazivPredmeta = pred_naziv;
    this.dohvatiPredavace();
  }

  polazniciNaPredmetu(e, idpredmet, pred_naziv){
    this.polazniciPopup = true;
    this.predmetID = idpredmet;
    this.nazivPredmeta = pred_naziv;
    this.dohvatiPolaznike();
  }

  materijaliNaPredmetu(e, idpredmet, pred_naziv){
    this.materijaliPopup = true;
    this.predmetID = idpredmet;
    this.nazivPredmeta = pred_naziv;
    this.dohvatiGlobalneMaterijale();
  }

  kvizoviNaPredmetu(e, idpredmet, pred_naziv){
    this.kvizoviPopup = true;
    this.predmetID = idpredmet;
    this.nazivPredmeta = pred_naziv;
    this.popisKvizova();
  }

  hover(e){
    if (e.rowType === "data" && e.column.dataField === "pmat_naziv" ){
      if (e.eventType == 'mouseover'){
        e.cellElement.style.fontWeight = "bold";
        e.cellElement.style.fontSize = "18px";
        e.cellElement.style.cursor = 'pointer';
      } else {
        e.cellElement.style.fontWeight = "normal";
        e.cellElement.style.fontSize = "14px";
        }
    }
  }
  
  cellClickEvent(e){
    if (e.rowType == "data" && e.column.dataField === "pmat_naziv") {
      if (e.data.pmat_vrsta == "video" || e.data.pmpred_vrsta == "video") {
        this.vimeoURL = "https://player.vimeo.com/video/"+e.data.pmat_link;
        this.prikaziVideo = true;
      } else {
        var url = (this.datotekeURL + e.data.uploaddokument_iduploaddokument + "&access_token=" + this.access_token);
        window.open(url, "_blank");
      }
    }
  }
  
  onCellPrepared(e){
    if (e.rowType === "data" && e.column.dataField === "pmat_naziv") {
        e.cellElement.style.color = '#03a9f4';
        e.cellElement.style.textDecoration = 'underline';
    }
  }

  hoverKviz(e){
    if (e.rowType === "data" && e.column.dataField === "nazivKviza"){
      if (e.eventType == 'mouseover'){
        e.cellElement.style.fontWeight = "bold";
        e.cellElement.style.fontSize = "18px";
        e.cellElement.style.cursor = 'pointer';
      } else {
        e.cellElement.style.fontWeight = "normal";
        e.cellElement.style.fontSize = "14px";
        }
    }
  }

  cellClickKviz(e){
    if (e.rowType == "data" && e.column.dataField === "nazivKviza") {
      this.jedanKvizPopup = true;
      this._api.kvizJedan({"access_token": this.access_token, "idkviz": e.data.idkviz}).subscribe(res => {
        // console.log(res);
        if(res.success === true){
          this.pitanjaZaKviz = res.pitanja;
          this.odgovoriKviz = res.odgovori;
          this.ukupnoPitanja = res.pitanja.length;
          this.kvizNaziv = res.kviz[0].kviz_naziv;
         this.pitanjaKviz();
        } else {
          notify(res.message, 'error', 1000);
        }
      });
    }
  }

  slikaPrikazi(slika){
    var prikaz = this.datotekeURL + slika + "&access_token=" + this.access_token;
    window.open(prikaz, "_blank");
  }

 // pitanja sa odgovorima za kviz
 pitanjaKviz(){
  for (let i = 0; i < this.pitanjaZaKviz.length; i++){
     if(this.pitanjaZaKviz[i].kpit_vrsta === 0){
      this.pitanjeSlika = false;
     } else {
      this.pitanjeSlika = true;
     }
    }
  }

  //**------------------------------- ISPITNI ROKOVI ------------------------------------------ */ // TODO: ispitni rokovi
  onIspitniRokExpanding(e){
    e.component.collapseAll(-1);
    this.IDispitROK = e.key.idispitrok;
    this.IDispit = e.key.idispit;
    this._api.ispitniRokKorisnici({"idispitrok": this.IDispitROK, "access_token": this.access_token}).subscribe(res => {
      if(res.success == true){
        this.polazniciIspitniRok = res.ispitniRokKorisnici;
      } else {
        notify(res.message, "error");
      }
    });
  }
  
  onRowPreparedIspitniRok(e){
    if (e.rowType == 'data') {
       if(e.data.krajIspita != undefined || e.data.krajIspita != null){
          let ispitVrijeme = moment(e.data.krajIspita).format();
          if (ispitVrijeme < this.danasnjiDan){
          e.rowElement.style.background = '#EEEEEE';
          }
        }
    }
  }

  prikaziIspitPolaznika(e){
    this.pitanjaZaIspit = [];
    this.odgovoriIspit = [];
    this.korisnikoviOdgovori = [];
    if (e.rowType == "data" && e.column.cellTemplate === "ispitPolaznika") {
        this.jedanIspitPopup = true;
        this.polaznikImePrezime = e.data.kor_punoIme;
        //Pozivanje API-ja sa detaljima ispita jednog polaznika
        let podaciIspitPolaznik = {
          "idkorisnik": e.data.idkorisnik,
          "idispitrok": this.IDispitROK,
          "idispit": this.IDispit,
          "access_token": this.access_token
        };
        this._api.ispitJedanZaOcjenjivanje(podaciIspitPolaznik).subscribe(res=>{
          // console.log(res)
          // this.pitanjaZaIspit = res.pitanja;
          for(let k = 0; k < res.pitanja.length; k++){
          this.pitanjaZaIspit.push({
                  'idispitpitanje': res.pitanja[k].idispitpitanje,
                  'ispp_naziv': res.pitanja[k].ispp_naziv,
                  'ispp_oblik': res.pitanja[k].ispp_oblik,
                  'ispp_tekst': res.pitanja[k].ispp_tekst,
                  'ispp_vrsta': res.pitanja[k].ispp_vrsta,
                  'uploaddokument_iduploaddokument': res.pitanja[k].uploaddokument_iduploaddokument,
                  'isprje_tocan': null
                })
          }
          // this.odgovoriIspit = res.odgovori;
          // this.korisnikoviOdgovori = res.korisnikoviOdgovori;
          // OBLIKA PITANJA 0 i 1
          for(let i = 0; i < res.odgovori.length; i++){
            for(let j = 0; j <  this.pitanjaZaIspit.length; j++){
              //Kvizaško pitanje
              if(this.pitanjaZaIspit[j].ispp_oblik == 0 && this.pitanjaZaIspit[j].idispitpitanje == res.odgovori[i].ispitpitanje_idispitpitanje){
                this.odgovoriIspit.push({
                  'idispitodgovor': res.odgovori[i].idispitodgovor,
                  'ispitpitanje_idispitpitanje': res.odgovori[i].ispitpitanje_idispitpitanje,
                  'ispo_tekst': res.odgovori[i].ispo_tekst,
                  'ispo_tocan': res.odgovori[i].ispo_tocan,
                  'ispo_vrsta': res.odgovori[i].ispo_vrsta,
                  'uploaddokument_iduploaddokument': res.odgovori[i].uploaddokument_iduploaddokument,
                  'korisnik_tocan_odgovor': null,
                  "idispitrjeseno": null,
                });
                for(let x = 0; x < res.korisnikoviOdgovori.length; x++){
                  if(res.odgovori[i].idispitodgovor == res.korisnikoviOdgovori[x].ispitodgovor_idispitodgovor){
                    this.odgovoriIspit[i].korisnik_tocan_odgovor = true;
                    this.odgovoriIspit[i].idispitrjeseno = res.korisnikoviOdgovori[x].idispitrjeseno;
                    this.pitanjaZaIspit[x].isprje_tocan = res.korisnikoviOdgovori[x].isprje_tocan;
                  }                 
                }
              }
              //Točno/netočno
              if(this.pitanjaZaIspit[j].ispp_oblik == 1 && this.pitanjaZaIspit[j].idispitpitanje == res.odgovori[i].ispitpitanje_idispitpitanje){
                this.odgovoriIspit.push({
                  'idispitodgovor': res.odgovori[i].idispitodgovor,
                  'ispitpitanje_idispitpitanje': res.odgovori[i].ispitpitanje_idispitpitanje,
                  'ispo_tekst': res.odgovori[i].ispo_tekst,
                  'ispo_tocan': res.odgovori[i].ispo_tocan,
                  'ispo_vrsta': res.odgovori[i].ispo_vrsta,
                  'uploaddokument_iduploaddokument': res.odgovori[i].uploaddokument_iduploaddokument,
                  'korisnik_tocan_odgovor': null,
                  "idispitrjeseno": null,
                });
                for(let x = 0; x < res.korisnikoviOdgovori.length; x++){
                  if(res.odgovori[i].idispitodgovor == res.korisnikoviOdgovori[x].ispitodgovor_idispitodgovor){
                    this.odgovoriIspit[i].korisnik_tocan_odgovor = true;
                    this.odgovoriIspit[i].idispitrjeseno = res.korisnikoviOdgovori[x].idispitrjeseno;
                    this.pitanjaZaIspit[x].isprje_tocan = res.korisnikoviOdgovori[x].isprje_tocan;
                  }
                }
                // this.odgovoriIspit.push(res.odgovori[i]);
              }
            }            
          
          }
          // OBLIKA PITANJA 2, 3 i 4
          for(let i = 0; i < res.korisnikoviOdgovori.length; i++){
            for(let j = 0; j < this.pitanjaZaIspit.length; j++){
                //Esej
                if(this.pitanjaZaIspit[j].ispp_oblik == 2 && this.pitanjaZaIspit[j].idispitpitanje == res.korisnikoviOdgovori[i].ispitpitanje_idispitpitanje){
                    this.korisnikoviOdgovori.push(res.korisnikoviOdgovori[i]);
                    this.pitanjaZaIspit[j].isprje_tocan = res.korisnikoviOdgovori[i].isprje_tocan;
                }
                //Nadopunjavanje
                if(this.pitanjaZaIspit[j].ispp_oblik == 3 && this.pitanjaZaIspit[j].idispitpitanje == res.korisnikoviOdgovori[i].ispitpitanje_idispitpitanje){
                    this.korisnikoviOdgovori.push(res.korisnikoviOdgovori[i]);
                    this.pitanjaZaIspit[j].isprje_tocan = res.korisnikoviOdgovori[i].isprje_tocan;
                }
                //Upload
                if(this.pitanjaZaIspit[j].ispp_oblik == 4 && this.pitanjaZaIspit[j].idispitpitanje == res.korisnikoviOdgovori[i].ispitpitanje_idispitpitanje){
                    this.korisnikoviOdgovori.push(res.korisnikoviOdgovori[i]);
                    this.pitanjaZaIspit[j].isprje_tocan = res.korisnikoviOdgovori[i].isprje_tocan;
                }
            }
          };
          // console.log(this.pitanjaZaIspit);
        });
    }
  }

  //**------------------------------- PREDAVANJA I PRISUTNOST ------------------------------------------ */
  onPredavanjeExpanding(e){
    e.component.collapseAll(-1);
    this.IDucionicaPredavanje = e.key.iducionicapredavanje;
    this.polazniciPrisutnost(e.key.iducionicapredavanje);
  }

  popisPredavanja(){
    this._api.ucionicaPredavanjePopis({"iducionica":this.ucionicaID, "access_token": this.access_token}).subscribe(res=>{
      if(res.success == true){
        this.predavanja = res.predavanja;
      } else {
        notify(res.message, "error");
      }
      // console.log(res)
    });
  }

  polazniciPrisutnost(iducionicapredavanje){
    this._api.ucionicaPredavanjePopisPrisutnost({"iducionica":this.ucionicaID, "iducionicapredavanje": iducionicapredavanje, "access_token": this.access_token}).subscribe(res=>{
      if(res.success == true){
        this.polazniciPredavanjaPrisutnost = res.polaznici;
      } else {
        notify(res.message, "error");
      }
    });
  }

  onRowPreparedPredavanja(e){
    if (e.rowType == 'data') {
      // console.log(e);
      let d = e.values[2].toISOString();
      let b = moment(d).format();
      if (b <= this.danasnjiDan){
      // e.rowElement.style.textDecoration = 'line-through';
      e.rowElement.style.background = '#EEEEEE';
      }
    }
  }

  dohvatiPredavace(){
    this._api.predmetPredavaci({"access_token": this.access_token, "idpredmet": this.predmetID, "iducionica": this.ucionicaID}).subscribe(res=>{
      // console.log(res);
      if(res.success == true){
        this.sviPredavaciNaPredmetu = res.predavaci;
      } else {
        notify(res.message, "error", 1000);
      }
    });
  }

  dohvatiPolaznike(){
    this._api.predmetPolaznici({"access_token": this.access_token, "idpredmet": this.predmetID, "iducionica": this.ucionicaID}).subscribe(res=>{
      // console.log(res);
      if(res.success == true){
        this.polazniciPrikazi = res.polaznici;
      } else {
        notify(res.message, "error", 1000);
      }
    });
  }

  dohvatiGlobalneMaterijale(){
    this._api.predmetMaterijaliZaUcionicu({"access_token": this.access_token, "idpredmet": this.predmetID, "iducionica": this.ucionicaID}).subscribe(res => {
      // console.log(res);
      this.globalniMaterijali = res.predmeti;
    });
  }

  popisKvizova(){
    this._api.kvizPopisZaKorisnika({"access_token": this.access_token, "idpredmet": this.predmetID, "idkorisnik": +localStorage.getItem('idkorisnik')}).subscribe(res => {
      // console.log(res); 
      this.kvizovi = res.kvizovi;
    });
  }

  dohvatiIspitneRokove(){
    this._api.ispitniRokoviPopisZaUcionicu({"access_token": this.access_token, "iducionica": this.ucionicaID}).subscribe(res => {
      // console.log(res);
      if(res.success == true){
        this.rokovi = res.ispitniRokovi;
      } else {
        notify(res.message, "error", 1000);
      }
    });
  }

  /* Obavijesti */ 

  popisObavijesti(){
    this._api.ucionicaObavijestPopis({"iducionica": this.ucionicaID, "access_token": this.access_token}).subscribe(res=>{
      if(res.success == true){
        this.obavijesti = res['obavijesti'];
      } else {
        notify(res.message, "error");
      }
    });
  }

//** ---------- Ocjene na predmetu za polaznika ------------- */
  ocjenaNaPredmetu(e, idpredmet, pred_naziv){
    this.ocjenePopup = true;
    this.predmetID = idpredmet;
    this.nazivPredmeta = pred_naziv;
    this.dohvatiPolaznike();
  }

}