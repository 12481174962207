<h2 class="content-block">Predmeti</h2>

<div class="content-block dx-card responsive-paddings">

    <dx-data-grid
    class="wide-card"
    [hoverStateEnabled]="true"
    [wordWrapEnabled]="true"
    [dataSource]="dataSource"
    [rowAlternationEnabled]="false"
    [showRowLines]="false"
    [showColumnLines]="false"
    [showBorders]="false"
    [columnAutoWidth]="true"
    [columnHidingEnabled]="true"
    (onRowInserted)="onRowInserted($event)"
    (onInitNewRow)="onInitNewRow($event)"
    >

    <dxo-paging [pageSize]="10"></dxo-paging>
    <dxo-pager [showPageSizeSelector]="true" [showInfo]="true"></dxo-pager>
    <!-- <dxo-filter-row [visible]="true"></dxo-filter-row> -->
    <dxo-search-panel [visible]="true"></dxo-search-panel>

    <dxo-editing
    mode="popup"
    [allowUpdating]="mozeSve"
    [allowDeleting]="false"
    [allowAdding]="mozeSve">

    <!-- 
    [allowDeleting]="superadmin"
    [allowAdding]="superadmin" 
    -->

    <dxo-popup
    [closeOnOutsideClick]="true"
    [onShowing]="onShowing"
    title=""
    [showTitle]="true"
    [width]="500"
    [maxHeight]="280"
    [position]="{ my: 'center', at: 'center', of: window }">
    </dxo-popup>

    <dxo-form
        [showColonAfterLabel]="true">
        <dxi-item itemType="group" [colCount]="1" [colSpan]="2">
            <dxi-item
                dataField="pred_naziv"
                [editorOptions]="{stylingMode: 'filled'}">
                <dxi-validation-rule
                    type="required"
                    message="Naziv je obavezno polje!">
                </dxi-validation-rule>
            </dxi-item>
        </dxi-item>
    </dxo-form>

    </dxo-editing> 

    <dxi-column dataField="idpredmet" caption="ID" [visible]="false" [width]="70" [allowFiltering]="false" alignment="center" [formItem]="{ visible: false }"></dxi-column>
    <dxi-column dataField="pred_naziv" caption="Naziv predmeta" [allowSorting]="false"></dxi-column>
    <dxi-column type="buttons" [width]="110"><dxi-button template="buttonTemplate"></dxi-button></dxi-column>
    <div *dxTemplate="let buttonInfo of 'buttonTemplate'">
        <dx-button *ngIf="mozeSve || mozePredavac" icon="edit" id="edit" type="back" (click)="urediPredmet(buttonInfo)"></dx-button>
        <dx-button *ngIf="mozeSve" icon="trash" id="trash" type="back" (click)="brisanjePredmeta(buttonInfo)"></dx-button>
    </div>

    </dx-data-grid>
</div>