<h2 class="content-block">{{ucionicaNaziv}}</h2> 

<div class="content-block dx-card">
    <div id="tabs content-block">
        <dx-tabs
            class="tab"
            id="mainTabs"
            [dataSource]="glavniTabovi"
            [selectedIndex]="0"
            (onItemClick)="odaberiTab($event)">
        </dx-tabs>
    </div>

<!--------------------------- TAB1:  OSNOVNI PODACI -------------------------->
<div class="widget-container2" *ngIf="odabranGlavniTab === 0">
    <div class="responsive-paddings" id="osnovno">
        <dx-form
            labelLocation="top"
            [formData]="ucionicaPrikaz"
            [showColonAfterLabel]="true">
            <dxi-item itemType="group" [colCount]="2" [colSpan]="2">
                <dxi-item dataField="uci_naziv" [colSpan]="1" [label]="{text: 'Naziv'}"
                    [editorOptions]="{readOnly: true, stylingMode:'filled'}">
                </dxi-item>
                <dxi-item dataField="uci_obrazovnaGodina" [colSpan]="1" [label]="{text: 'Godina'}"
                    [editorOptions]="{readOnly: true, stylingMode:'filled'}">
                </dxi-item>
                <dxi-item [label]="{text: 'Grad'}" dataField="grad_naziv"
                    [editorOptions]="{readOnly: true, stylingMode:'filled'}">
                </dxi-item>
                <dxi-item [label]="{text: 'Program'}" dataField="prog_naziv"
                    [editorOptions]="{readOnly: true, stylingMode:'filled'}">
                </dxi-item>
            </dxi-item>
        </dx-form>
    </div>
</div>

<!--------------------------- TAB2: OSOBE-------------------------->
<div class="widget-container" *ngIf="odabranGlavniTab === 1">
        <div id="administratori">
            <dx-data-grid
                [showRowLines]="false"
                class="wide-card"
                [hoverStateEnabled]="true"
                [wordWrapEnabled]="true"
                [dataSource]="adminPrikazi"
                [columnAutoWidth]="true"
                [columnHidingEnabled]="true">
                <dxo-search-panel [visible]="true"></dxo-search-panel>
                    <dxi-column dataField="kor_punoIme" caption="Administratori"></dxi-column>
            </dx-data-grid>
        </div>
        <div id="polaznici">
            <dx-data-grid
                [showRowLines]="false"
                class="wide-card"
                [hoverStateEnabled]="true"
                [wordWrapEnabled]="true"
                [dataSource]="polaznici"
                [columnAutoWidth]="true"
                [columnHidingEnabled]="true">
                <dxo-search-panel [visible]="true"></dxo-search-panel>
                    <dxi-column dataField="kor_punoIme" caption="Polaznici"></dxi-column>
            </dx-data-grid>
        </div>
</div>

<!--------------------------- TAB3: PREDMETI -------------------------->
    <dx-data-grid *ngIf="odabranGlavniTab === 2"
    [showRowLines]="false"
    class="wide-card"
    [hoverStateEnabled]="true"
    [wordWrapEnabled]="true"
    [dataSource]="predmetPrikazi"
    [columnAutoWidth]="true"
    [columnHidingEnabled]="true">
    <dxo-search-panel [visible]="true"></dxo-search-panel>
        <dxi-column dataField="pred_naziv" caption="Predmet"></dxi-column>
        <dxi-column type="buttons" [width]="110"><dxi-button template="buttonPredavacTemplate"></dxi-button></dxi-column>
        <div *dxTemplate="let buttonInfo of 'buttonPredavacTemplate'">
            <dx-button class="btnPredavac" (onClick)="predavaciNaPredmetu($event, buttonInfo.data.idpredmet, buttonInfo.data.pred_naziv)">Predavači</dx-button> 
        </div>
        <dxi-column type="buttons" [width]="110"><dxi-button template="buttonPolaznikTemplate"></dxi-button></dxi-column>
        <div *dxTemplate="let buttonInfo of 'buttonPolaznikTemplate'">
            <dx-button class="btnPolaznik" (onClick)="polazniciNaPredmetu($event, buttonInfo.data.idpredmet, buttonInfo.data.pred_naziv)">Polaznici</dx-button> 
        </div>
        <dxi-column type="buttons" [width]="110"><dxi-button template="buttonMaterijalTemplate"></dxi-button></dxi-column>
        <div *dxTemplate="let buttonInfo of 'buttonMaterijalTemplate'">
            <dx-button class="btnMaterijali" (onClick)="materijaliNaPredmetu($event, buttonInfo.data.idpredmet, buttonInfo.data.pred_naziv)">Materijali</dx-button> 
        </div>
        <dxi-column type="buttons" [width]="110"><dxi-button template="buttonKvizTemplate"></dxi-button></dxi-column>
        <div *dxTemplate="let buttonInfo of 'buttonKvizTemplate'">
            <dx-button class="btnKvizovi" (onClick)="kvizoviNaPredmetu($event, buttonInfo.data.idpredmet, buttonInfo.data.pred_naziv)">Kvizovi</dx-button> 
        </div>
        <dxi-column type="buttons" [width]="110"><dxi-button template="buttonOcjenaTemplate"></dxi-button></dxi-column>
        <div *dxTemplate="let buttonInfo of 'buttonOcjenaTemplate'">
            <dx-button class="btnOcjene" (onClick)="ocjenaNaPredmetu($event, buttonInfo.data.idpredmet, buttonInfo.data.pred_naziv)">Ocjene</dx-button> 
        </div>
    </dx-data-grid>
 
<!--------------------------- TAB4: ISPITNI ROKOVI -------------------------->
    <dx-data-grid *ngIf="odabranGlavniTab === 3"
            [showRowLines]="false"
            [hoverStateEnabled]="true"
            [wordWrapEnabled]="true"
            [dataSource]="rokovi"
            [columnAutoWidth]="true"
            [columnHidingEnabled]="true"
            (onRowExpanding)="onIspitniRokExpanding($event)"
            [masterDetail]="{ enabled: true, template: 'popisPolaznika' }"
            (onRowPrepared)="onRowPreparedIspitniRok($event)">

        <dxo-paging [pageSize]="10"></dxo-paging>
        <dxo-pager [showPageSizeSelector]="true" [showInfo]="true"></dxo-pager>
        <dxo-search-panel [visible]="true"></dxo-search-panel>
        <dxo-header-filter [visible]="true"></dxo-header-filter>
            <dxi-column dataField="isprok_naziv" caption="Naziv roka"></dxi-column>
            <dxi-column dataField="pred_naziv" caption="Predmet" ></dxi-column>
            <dxi-column dataField="isp_naziv" caption="Naziv ispita"></dxi-column>
            <dxi-column dataField="isprok_pocetak" caption="Početak" dataType="date" alignment="center"></dxi-column>
            <dxi-column dataField="isprok_kraj" caption="Kraj" dataType="date" alignment="center"></dxi-column>
            <dxi-column dataField="isprok_trajanje" caption="Trajanje ispita (min)" alignment="center"></dxi-column>
            <!-- Popis polaznika -->
            <div *dxTemplate="let pitanje of 'popisPolaznika'">
                <dx-data-grid
                    [dataSource]="polazniciIspitniRok"
                    [rowAlternationEnabled]="false"
                    [showRowLines]="false"
                    [showColumnLines]="false"
                    [showBorders]="false"
                    [columnAutoWidth]="true">
                        <dxi-column dataField="idkorisnik" caption="ID" [formItem]="{ visible: false }" [visible]="false"></dxi-column>
                        <dxi-column dataField="kor_punoIme" caption="Ime i prezime"></dxi-column>
                        <dxi-column caption="Ispit" cellTemplate="ispitPolaznika" alignment="center" [width]="110">
                            <div *dxTemplate="let cell of 'ispitPolaznika'">
                            <dx-button icon="columnchooser" class="kviz" (onClick)="prikaziIspitPolaznika(cell)"></dx-button>
                            </div>
                        </dxi-column>
                        <dxi-column dataField="ispocj_ocjena" caption="Ocjena" width="150" alignment="center"></dxi-column>
                </dx-data-grid>
            </div>
    </dx-data-grid>

<!--------------------------- TAB5: PREDAVANJA I PRISUTNOST -------------------------->
    <dx-data-grid *ngIf="odabranGlavniTab === 4"
        [showRowLines]="false"
        class="wide-card"
        [hoverStateEnabled]="true"
        [wordWrapEnabled]="true"
        [dataSource]="predavanja"
        [columnAutoWidth]="true"
        [columnHidingEnabled]="true"
        [masterDetail]="{ enabled: true, template: 'popisPolaznika' }"
        (onRowPrepared)="onRowPreparedPredavanja($event)"
        (onRowExpanding)="onPredavanjeExpanding($event)"
        >
        <dxo-search-panel [visible]="true"></dxo-search-panel>
        <dxi-column dataField="ucip_naziv" caption="Naziv" [editorOptions]="{stylingMode: 'filled'}"></dxi-column>
        <dxi-column dataField="ucip_vrijeme" caption="Datum i vrijeme" dataType="datetime"></dxi-column>
        <dxi-column dataField="ucip_trajanje" caption="Trajanje (min)" alignment="center"></dxi-column>
        <dxi-column dataField="ucip_meetingID" caption="Meeting ID"></dxi-column>
        <dxi-column dataField="ucip_lozinka" caption="Lozinka"></dxi-column>
        <dxi-column dataField="predmet_idpredmet" caption="Predmet">
            <dxo-lookup
                [dataSource]="predmetPrikazi"
                valueExpr="idpredmet"
                displayExpr="pred_naziv">
            </dxo-lookup>
        </dxi-column>
            <!-- Popis polaznika -->
            <div *dxTemplate="let pitanje of 'popisPolaznika'">
                <dx-data-grid
                [dataSource]="polazniciPredavanjaPrisutnost"
                [rowAlternationEnabled]="false"
                [showRowLines]="false"
                [showColumnLines]="false"
                [showBorders]="false"
                [columnAutoWidth]="true">
                    <dxi-column dataField="kor_punoIme" caption="Ime i prezime"></dxi-column>
                    <dxi-column dataField="prisutan" caption="Prisutan" dataType="boolean" width="150" [formItem]="{visible: false}" cellTemplate="cellTemplate">
                        <div *dxTemplate="let data of 'cellTemplate'">
                            <dx-check-box class="checkPrikaz" readOnly="true" [value]="data.value"></dx-check-box>
                        </div>
                    </dxi-column>
            </dx-data-grid>
            </div>
    </dx-data-grid>

<!---------------------------------- TAB6: OBAVIJESTI --------------------------------------------------->
        <dx-data-grid *ngIf="odabranGlavniTab === 5"
        [showRowLines]="false"
        class="wide-card"
        [hoverStateEnabled]="true"
        [wordWrapEnabled]="true"
        [dataSource]="obavijesti"
        [columnAutoWidth]="true"
        [columnHidingEnabled]="true">
        <dxo-search-panel [visible]="true"></dxo-search-panel>
            <dxi-column dataField="uciob_naslov" caption="Naslov"></dxi-column>
            <dxi-column dataField="uciob_tekst" caption="Sadržaj"></dxi-column>
            <dxi-column dataField="kor_punoIme" caption="Autor" width="200"></dxi-column>
            <dxi-column dataField="createdAt" caption="Objavljeno" dataType="date" width="140"></dxi-column>
        </dx-data-grid>

<!------------------------- Predavači na predmetu--------------------------->
<dx-popup
    [width]="600"
    [height]="'auto'"
    title="{{nazivPredmeta}} - PREDAVAČI"
    [(visible)]="predavaciPopup">
    <!-- <p>Označite predavača za ovaj predmet: </p> -->
    <dx-scroll-view height="90%" class="full-height-scrollable">
        <dx-data-grid
        [dataSource]="sviPredavaciNaPredmetu"
        [wordWrapEnabled]="true">

       <dxi-column dataField="kor_punoIme" caption="Ime i prezime"></dxi-column>
       <dxi-column caption="U učionici" dataField="uUcionici" cellTemplate="checkbox" alignment="center">
           <div *dxTemplate="let data of 'checkbox'">
               <dx-check-box  disabled="true" [value]="data.value"></dx-check-box>
           </div>
       </dxi-column>
        </dx-data-grid>
    </dx-scroll-view>
</dx-popup>

<!------------------------- Polaznici na predmetu--------------------------->
<dx-popup
    [width]="600"
    [height]="'auto'"
    title="{{nazivPredmeta}} - POLAZNICI"
    [(visible)]="polazniciPopup">
    <dx-scroll-view height="90%" class="full-height-scrollable">
        <dx-data-grid
            [dataSource]="polazniciPrikazi"
            [wordWrapEnabled]="true">

        <dxi-column dataField="kor_punoIme" caption="Ime i prezime"></dxi-column>
        <dxi-column caption="Ne polaže" dataField="zavrsio" cellTemplate="checkbox" alignment="center">
            <div *dxTemplate="let data of 'checkbox'">
                <dx-check-box  disabled="true" [value]="data.value"></dx-check-box>
            </div>
        </dxi-column>
            </dx-data-grid>
        </dx-scroll-view>
</dx-popup>

<!------------------------- Materijali na predmetu--------------------------->
<dx-popup
    [width]="800"
    [height]="'auto'"
    title="{{nazivPredmeta}} - MATERIJALI"
    [(visible)]="materijaliPopup">
    <dx-scroll-view height="90%" class="full-height-scrollable">
    <dx-data-grid
        [dataSource]="globalniMaterijali"
        (onCellHoverChanged)="hover($event)"
        (onCellClick)="cellClickEvent($event)"
        (onCellPrepared)="onCellPrepared($event)"
        [wordWrapEnabled]="true">

        <dxi-column dataField="idpredmetmaterijal" caption="ID" [visible]="false"></dxi-column>
        <dxi-column dataField="pmat_naziv" caption="Naziv dokumenta"></dxi-column>
        <!-- <dxi-column dataField="materijal" caption="Materijal" ></dxi-column> -->

        <dxi-column caption="Na predmetu" cellTemplate="checkbox" dataField="uUcionici" alignment="center">
            <div *dxTemplate="let data of 'checkbox'">
                <dx-check-box disabled="true" [(value)]="data.value"></dx-check-box>
            </div>
        </dxi-column>
        <!-- <dxi-column dataField="dodao" caption="Dodao"></dxi-column> -->

    </dx-data-grid>
    </dx-scroll-view>
</dx-popup>


<!------------------------- Kvizovi na predmetu--------------------------->
<dx-popup
    [width]="800"
    [height]="'auto'"
    title="{{nazivPredmeta}} - KVIZOVI"
    [(visible)]="kvizoviPopup">
    <dx-scroll-view height="90%" class="full-height-scrollable">
    <dx-data-grid
        [showRowLines]="false"
        [hoverStateEnabled]="true"
        [wordWrapEnabled]="true"
        [dataSource]="kvizovi"
        [columnAutoWidth]="true"
        [columnHidingEnabled]="true"
        (onCellHoverChanged)="hoverKviz($event)"
        (onCellClick)="cellClickKviz($event)"
        keyExpr="idkviz">

    <dxo-paging [pageSize]="10"></dxo-paging>
    <dxo-pager [showPageSizeSelector]="true" [showInfo]="true"></dxo-pager>

        <dxi-column dataField="nazivKviza" caption="Naziv kviza" ></dxi-column>
        <dxi-column dataField="brojPitanja" caption="Broj pitanja" alignment="center"></dxi-column>
    </dx-data-grid>
    </dx-scroll-view>

</dx-popup>

<!------------------------- Prikaz jednog kviza --------------------------->
<dx-popup
    [width]="800"
    [height]="600"
    [title]="kvizNaziv"
    [(visible)]="jedanKvizPopup">
    
    <dx-scroll-view height="90%" class="full-height-scrollable">
      <table>

        <ng-container *ngFor="let pitanja of pitanjaZaKviz; let i = index">
            <tr><p class="pitanje">{{ i+1 }}. {{ pitanja.kpit_naziv }}</p></tr>
            <img *ngIf="pitanja.iduploaddokument" class="kvizSlika" src="{{datotekeURL}}+{{pitanja.iduploaddokument}}+&access_token={{access_token}}" (click)="slikaPrikazi(pitanja.iduploaddokument)">

              <tr *ngFor="let odgovor of odgovoriKviz; let index= index">
                 <div *ngIf="odgovor.kvizpitanje_idkvizpitanje === pitanja.idkvizpitanje">
                <td>
                    <!-- <span class="slova"> {{slovo[index]}}</span>           -->
               <!-- {{slovo}} -->
               <!-- {{index}} -->
                <input type="checkbox" class="check" name="check" [value]="odgovor.idkvizodgovor" [checked]="odgovor.kodg_tocan == '1'" onclick="return false;"> 
                    <span *ngIf="!odgovor.iduploaddokument">{{ odgovor.pitanje }}</span>
                    <span *ngIf="odgovor.iduploaddokument">
                        <img id="odgovor{{odgovor.idkvizodgovor}}" class="slikatumbnail" src="{{datotekeURL}}+{{odgovor.iduploaddokument}}+&access_token={{access_token}}" (click)="slikaPrikazi(odgovor.iduploaddokument)">
                    </span>
                    <dx-popover *ngIf="odgovor.iduploaddokument"
                        target="#odgovor{{odgovor.idkvizodgovor}}"
                        position="top"
                        showEvent="mouseenter"
                        hideEvent="mouseleave"
                        contentTemplate="popoverContent"
                        [height]="'auto'">
                    <dxo-animation>
                        <dxo-show
                            type="pop"
                            [from]="{ scale: 0 }"
                            [to]="{ scale: 1 }"></dxo-show>
                        <dxo-hide
                            type="fade"
                            [from]="1"
                            [to]="0"></dxo-hide>
                    </dxo-animation>
                    <div *dxTemplate="let data of 'popoverContent'">
                            <img class="odgovorSlika" src="{{datotekeURL}}+{{odgovor.iduploaddokument}}+&access_token={{access_token}}">                     
                    </div>
                </dx-popover>
                </td>
                </div>
            </tr>
        
        </ng-container>
        </table>
    </dx-scroll-view>

</dx-popup>

<!------------------------- Prikaz jednog ispita --------------------------->
<dx-popup
    [width]="800"
    [height]="600"
    [title]="polaznikImePrezime"
    [(visible)]="jedanIspitPopup">
    <dx-scroll-view height="90%" class="full-height-scrollable">
        <table cellspacing="0" class="klasa">
            <ng-container *ngFor="let pitanja of pitanjaZaIspit; let i = index">
                <tr><p class="pitanje">{{ i+1 }}. {{ pitanja.ispp_tekst }}</p></tr>
                <dx-check-box
                    class="checkbox"
                    [value]="pitanja.isprje_tocan"
                    readOnly="true"
                    text="Točno">
                </dx-check-box>
                <img *ngIf="pitanja.uploaddokument_iduploaddokument" class="kvizSlika" src="{{datotekeURL}}+{{pitanja.uploaddokument_iduploaddokument}}+&access_token={{access_token}}" (click)="slikaPrikazi(pitanja.uploaddokument_iduploaddokument)">
                <div *ngIf="pitanja.ispp_oblik == 0 || pitanja.ispp_oblik == 1">
               
                    <tr *ngFor="let odgovor of odgovoriIspit; let index= index">
                        <div *ngIf="odgovor.ispitpitanje_idispitpitanje === pitanja.idispitpitanje">
                            <td>
                                <input type="checkbox" class="check" name="check" [value]="odgovor.idispitodgovor" [checked]="odgovor.korisnik_tocan_odgovor" onclick="return false;">
                                <span *ngIf="!odgovor.uploaddokument_iduploaddokument" [ngClass]="[odgovor.ispo_tocan === 1 ? 'tocan' : 'netocan']">{{ odgovor.ispo_tekst }}</span>
                                <a href="{{datotekeURL}}+{{odgovor.uploaddokument_iduploaddokument}}+&access_token={{access_token}}" target="_blank"><img *ngIf="odgovor.uploaddokument_iduploaddokument" class="kvizSlika" src="{{datotekeURL}}+{{odgovor.uploaddokument_iduploaddokument}}+&access_token={{access_token}}"></a>
                                <!-- <span><a href="{{datotekeURL}}+{{odgovor.uploaddokument_iduploaddokument}}+&access_token={{access_token}}" target="_blank"><p *ngIf="odgovor.uploaddokument_iduploaddokument">Preuzmi odgovor</p></a></span> -->
                            </td>
                        </div>
                    </tr>
                </div>
                <div *ngIf="pitanja.ispp_oblik == 2 || pitanja.ispp_oblik == 3">
                    <tr *ngFor="let odgovor of korisnikoviOdgovori; let index= index">
                        <div *ngIf="odgovor.ispitpitanje_idispitpitanje === pitanja.idispitpitanje">
                            <span>{{ odgovor.isprje_tekst }}</span>
                        </div>
                    </tr>
                </div>
                <div *ngIf="pitanja.ispp_oblik == 4">
                    <tr *ngFor="let odgovor of korisnikoviOdgovori; let index= index">
                        <div *ngIf="odgovor.ispitpitanje_idispitpitanje === pitanja.idispitpitanje">
                            <a href="{{datotekeURL}}+{{odgovor.uploaddokument_iduploaddokument}}+&access_token={{access_token}}" target="_blank"><p *ngIf="odgovor.uploaddokument_iduploaddokument">Preuzmi odgovor</p></a>
                        </div>
                    </tr>
                </div>
            </ng-container>
        </table>
    </dx-scroll-view>
</dx-popup>



<!------- OCJENE SVAKOG POLAZNIKA NA PREDMETU ------->
<dx-popup
    [width]="800"
    [height]="'auto'"
    title="{{nazivPredmeta}} - Ocjene polaznika"
    [(visible)]="ocjenePopup">
    <dx-scroll-view height="90%" class="full-height-scrollable">
    <dx-data-grid
        [dataSource]="polazniciPrikazi"
        (onCellHoverChanged)="hover($event)"
        (onCellClick)="cellClickEvent($event)"
        (onCellPrepared)="onCellPrepared($event)"
        [wordWrapEnabled]="true">

        <dxi-column dataField="korisnik_idkorisnik" caption="ID" [visible]="false"></dxi-column>
        <dxi-column dataField="kor_punoIme" caption="Ime i prezime"></dxi-column>
        <dxi-column caption="Ocjene polaznika"  alignment="center" dataField="uppz_ocjena" width="170"></dxi-column>
    </dx-data-grid>
    </dx-scroll-view>
</dx-popup>

<!-- CHAT -->
<app-chat *ngIf="odabranGlavniTab == 6"></app-chat>

</div>