import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ApiService } from 'src/app/shared/services/api.service';

import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-ucionice-predavac',
  templateUrl: './ucionice-predavac.component.html',
  styleUrls: ['./ucionice-predavac.component.scss']
})

export class UcionicePredavacComponent implements OnInit {

  prava = +localStorage.getItem('kor_prava');
  korisnikID = parseInt(localStorage.getItem('idkorisnik'));
  access_token: any = localStorage.getItem('access_token');

  ucionice: any;

  constructor(
    public _api: ApiService,
    private _router: Router
  ) { }

  ngOnInit() {
    this.ucionicePredavac();
  }

  urediUcionicu(e){ 
    this._router.navigate(['ucionica-predavac/' + e.data.iducionica]);
  }

  ucionicePredavac(){
    this._api.ucionicaPopisZaPredavaca({"access_token": this.access_token, "idkorisnik": this.korisnikID}).subscribe(res => {
      if(res.success == true){
        // console.log(res);
        this.ucionice = res.ucionice;
      } else {
        notify(res.message, "error", 1000);
      }
    });
  }

}