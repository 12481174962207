import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ApiService } from './../../shared/services/api.service';

import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-korisnik',
  templateUrl: './korisnik.component.html',
  styleUrls: ['./korisnik.component.scss']
})

export class KorisnikComponent implements OnInit {

  access_token: any = localStorage.getItem('access_token');

  korisnikID: number;
  korisnikDetalji: any;
  colCountByScreen: object;
  pravaApi: number;
  imePrezime: string;

  constructor(
    private _activatedRoute: ActivatedRoute,
    public _api: ApiService
  ) { 
              
    this.colCountByScreen = {
          xs: 1,
          sm: 2,
          md: 2,
          lg: 3
    };

  }

  ngOnInit() {
    this.korisnikID = this._activatedRoute.snapshot.params['id'];
    this.dohvatiDetalje(this.korisnikID);
  }

  dohvatiDetalje(korisnikID){
    this._api.korisnikDohvati({"idkorisnik":korisnikID, "access_token": this.access_token}).subscribe(res=>{
      if(res.success == true){
        this.korisnikDetalji = res.korisnik[0];
        this.imePrezime = this.korisnikDetalji['kor_punoIme'];
        this.pravaApi = this.korisnikDetalji['kor_prava'];
      } else {
        notify(res.message, "error", 1000);
      }
    });
  }

}