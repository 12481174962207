import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { ApiService } from 'src/app/shared/services';
import { IdUcionicaService } from './../../shared/services/id-ucionica.service';

import { custom } from 'devextreme/ui/dialog';
import { encodeHtml } from 'devextreme/core/utils/string';
import notify from 'devextreme/ui/notify';

import { io } from "socket.io-client";

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {
  
  @ViewChild("novaPoruka") private poruka: ElementRef;
  @ViewChild('myList') private myScrollContainer: ElementRef;
  access_token: any = localStorage.getItem('access_token');
  prava = +localStorage.getItem('kor_prava');
  idkorisnik = localStorage.getItem('idkorisnik');

  datotekeURL = this._api.datotekeURL;

  zakljucajGumb: boolean = false;
  sadrzajPoruke: string;
  odabraniChat: any = 0;
  uploadDatoteke: boolean = false;
  chatNaziv: string;
  chatPopup: boolean = false;
  chatDetalji = { 'chatNaziv': '' };
  chatPopis: any[] = [];
  chatPoruke: any[] = [];
  datotekaChat: any;
  chatID: any;
  ucionicaID: any;
  stariNazivDatoteke: string;

  socket = io(this._api.apiChat, {
    transports: ['websocket'],
    autoConnect: false
  });

  subscription: any;

  constructor(
    public _api: ApiService,
    private _idUcionicaService: IdUcionicaService
  ) { }

  ngOnInit() {
    this.subscription = this._idUcionicaService.currentMessage.subscribe((message) => {
      if(message){
        this.ucionicaID = +message;
      }
    });
    this.datotekaChat = null;
    this.sadrzajPoruke = null;
    this.popisRazgovora(this.ucionicaID);
    this.osvjeziChat();
  }

  osvjeziChat(){
    this.socket.open();
    this.socket.on("noviChat", (res) => {
      // console.log(res);
      if(res.success == true){
        this.chatPopis.push(
            {
              'chatID': res.data[0].chatID,
              'ime': res.data[0].ime,
              'kor_punoIme': res.data[0].kor_punoIme
            }
        );
      }
    });
    this.socket.on("obrisiChat", (res) => {
      // console.log(res);
      this.chatPopis = this.chatPopis.filter(x => x.chatID !== res);
      // if(res.success == true){
      //   this.chatPopis = this.chatPopis.filter(x => x.chatID !== res);
      // }
    });
    this.socket.on("novaPoruka", (res) => {
      // console.log(res);
      if(res.success == true){
        if(res.data[0].chat_idchat == this.chatID){
          this.chatPoruke.push(
            {
              'chat_idchat': res.data[0].chat_idchat,
              'datum_kreiranja': res.data[0].datum_kreiranja,
              'dokument_iddokument': res.data[0].dokument_iddokument,
              'idporuka': res.data[0].idporuka,
              'ime_datoteke': res.data[0].ime_datoteke,
              'kor_punoIme': res.data[0].kor_punoIme,
              'korisnik_idkorisnik': res.data[0].korisnik_idkorisnik,
              'poruka': res.data[0].poruka
            }
         );
         let _this = this;
         setTimeout(() => {
            _this.scrollToBottom();
         }, 500);
        }
      }
    });
    this.socket.on("obrisiPoruku", (res) => {
      // console.log(res.data);
      if(res.success == true){
        if(res.data.chatID == this.chatID){
          this.chatPoruke = this.chatPoruke.filter(x => x.idporuka !== res.data.idporuka);
        }
      }
    });
  }

  popisRazgovora(ucionicaID){
    this.chatPopis = [];
    this._api.chatPopis({"access_token": this.access_token, "iducionica": ucionicaID}).subscribe(res => {
      // console.log(res);
      if(res.success == true){
        this.chatPopis = res.data;
        // console.log(this.chatPopis[0].chatID);
        if(this.chatPopis.length > 0){
          this.odaberiJedanRazgovor(0, this.chatPopis[0].chatID);
          this.chatID = this.chatPopis[0].chatID;
        } else {
          this.chatID = null;
        }
      } else {
        notify(res.message, "error", 1000);
      }     
    });
    let _this = this;
    setTimeout(() => {
      _this.scrollToBottom();
    }, 100);
  }

  chatAdd(){
    this.chatPopup = true;
  }

  chat_fieldDataChanged($event){
    // console.log($event.value);
    this.chatNaziv = $event.value;
  }

  kreirajChat(){
    let podaciZaKreiranjeChata = {
      'idkorisnik': localStorage.getItem('idkorisnik'),
      'iducionica': this.ucionicaID,
      'ime': this.chatNaziv,
      'access_token': this.access_token
    };
    if(this.chatNaziv){
       this._api.chatKreiraj(podaciZaKreiranjeChata).subscribe(res => {
        // console.log(res);
        if(res.success == true){
          // this.chatPopis.push(
          //   {
          //     'chatID': res.data.chatID,
          //     'idkorisnik': localStorage.getItem('idkorisnik'),
          //     'iducionica': this.ucionicaID,
          //     'ime': this.chatNaziv,
          //     'kor_punoIme': localStorage.getItem('kor_punoIme')
          //   }
          // );
          // console.log(this.chatPopis);
          notify(res.message, "success");
          this.chatPopup = false;
          this.chatNaziv = null;
          this.chatDetalji = { 'chatNaziv': null };
          this.odaberiJedanRazgovor(this.chatPopis.length, {'chatID': res.data.chatID});
        } else {
          notify(res.message, "error");
        }
      });
    } else {
      alert('Unesite naziv!');
    }
  }

  handleKeyUp(e, data){
    if(e.keyCode === 13){
       this.handleSubmit(e, data);
    }
 }

  handleSubmit(e, podaci){
    e.preventDefault();
    this.posaljiPoruku(podaci)
  }

  odaberiJedanRazgovor(i, chat){
    // console.log(i);
    // console.log(chat);
    setTimeout(() => {  
      this.poruka.nativeElement.focus();
    }, 0)  
    // console.log(i);
    // console.log(chat);
    this.datotekaChat = null;
    this.sadrzajPoruke = null;
    this.chatPoruke = [];
    this.odabraniChat = i;
    this.chatID = chat.chatID;
    this._api.dohvatiPoruke({'idchat': chat.chatID || chat, 'access_token': this.access_token}).subscribe(res => {
      // console.log(res);
      if(res.success == true){
        setTimeout(() => {  
          this.poruka.nativeElement.focus();
        }, 0);
        for(let i = 0; i < res.data.length; i++){
          this.chatPoruke.push({
            'datum_kreiranja': res.data[i].datum_kreiranja,
            'dokument_iddokument': res.data[i].dokument_iddokument,
            'idporuka': res.data[i].idporuka,
            'ime_datoteke': res.data[i].ime_datoteke,
            'kor_punoIme': res.data[i].kor_punoIme,
            'korisnik_idkorisnik': res.data[i].korisnik_idkorisnik,
            'poruka': res.data[i].poruka == undefined || res.data[i].poruka == "undefined" ? null : this.replaceURLs(res.data[i].poruka)
          });
        }
        let _this = this;
        setTimeout(() => {
          _this.scrollToBottom();
        }, 100);
      } else {
        notify(res.message, "error");
        this.chatPoruke = [];
      }
    });
  }

  valueChange($event){
    // console.log($event);
  }

  uploadPocetak(event){
    this.datotekaChat = null;
    // console.log(event.srcElement.files);
    // console.log(event.srcElement.files[0]);
    this.datotekaChat = event.srcElement.files;
    // console.log(this.datotekaChat);
  }

  chatUpload(){
    this.datotekaChat;
    // this.uploadDatoteke = true;
  }

  chatUploadUndo(){
    // this.uploadDatoteke = false;
    this.datotekaChat = null;
  }

  posaljiPoruku(sadrzajPoruke){
    // console.log(sadrzajPoruke);
    // console.log(this.datotekaChat);
    this.zakljucajGumb = true;
    if (this.datotekaChat == null && sadrzajPoruke != null){
      let provjeraPraznog = sadrzajPoruke.trim().length === 0;
      if(!provjeraPraznog){
        // console.log("NEMA datoteke & IMA sadržaj poruke");
        let formData:FormData = new FormData();
        formData.append('idkorisnik', localStorage.getItem('idkorisnik'));
        formData.append('idchat', this.chatID);
        formData.append('poruka', sadrzajPoruke);
        formData.append('access_token', this.access_token);
        formData.append('nazivtablice', 'chat');
        // console.log(formData);
        this._api.chatPoruka(formData).subscribe(res=>{
          // console.log(res);
          if(res.success == true){
            let _this = this;
            setTimeout(() => {
              _this.zakljucajGumb = false;
              _this.sadrzajPoruke = null;
              _this.scrollToBottom();
            }, 500);
          } else {
            notify(res.message, "error", 1000);
          }
        });
      } else {
        this.zakljucajGumb = false;
        notify("Poruka mora sadržavati barem jedan znak!", "error", 1000);
      }
    } else if (this.datotekaChat != null && sadrzajPoruke != null) {
      let provjeraPraznog = sadrzajPoruke.trim().length === 0;
      if(!provjeraPraznog){ 
        // console.log("IMA datoteke & IMA sadržaj poruke");
        let file = this.datotekaChat[0];
        let formData:FormData = new FormData();
        formData.append('datoteka', file, file.name);
        formData.append('idkorisnik', localStorage.getItem('idkorisnik'));
        formData.append('idchat', this.chatID);
        formData.append('poruka', sadrzajPoruke);
        formData.append('access_token', this.access_token);
        formData.append('nazivtablice', 'chat');
        // console.log(formData);
        this._api.chatPoruka(formData).subscribe(res=>{
          // console.log(res);
          if(res.success == true){
            let _this = this;
            setTimeout(() => {
              _this.zakljucajGumb = false;
              _this.sadrzajPoruke = null;
              _this.datotekaChat = null;
              // _this.uploadDatoteke = false;
              _this.scrollToBottom();
            }, 500);
          } else {
            notify(res.message, "error", 1000);
          }
        });
      } else {
        this.zakljucajGumb = false;
        notify("Poruka mora sadržavati barem jedan znak!", "error", 1000);
      }
    } else if (this.datotekaChat != null && sadrzajPoruke == null) {
      // console.log("IMA datoteke & NEMA sadržaj poruke");
      let file = this.datotekaChat[0];
      let formData:FormData = new FormData();
      if(this.stariNazivDatoteke == this.datotekaChat[0].name){
        //Onemogući ponovni upload iste datoteke
      } else {
        formData.append('datoteka', file, file.name);
        formData.append('idkorisnik', localStorage.getItem('idkorisnik'));
        formData.append('idchat', this.chatID);
        formData.append('access_token', this.access_token);
        formData.append('nazivtablice', 'chat');
        // console.log(formData);
        this._api.chatPoruka(formData).subscribe(res=>{
          // console.log(res);
          if(res.success == true){
            this.stariNazivDatoteke = file.name;
            let _this = this;
            setTimeout(() => {
              _this.zakljucajGumb = false;
              _this.datotekaChat = null;
              // _this.uploadDatoteke = false;
              _this.scrollToBottom();
            }, 500);
          } else {
            notify(res.message, "error", 1000);
          }
        });
      }
    } else {
      notify("Dodajte poruku i/ili datoteku!", "error", 1000);
      let _this = this;
      setTimeout(() => {
        _this.zakljucajGumb = false;
        _this.datotekaChat = null;
        _this.sadrzajPoruke = null;
        // _this.uploadDatoteke = false;
      }, 1001);
    }
  }

  brisanjeChata(chat){
    // console.log(chat);
    let encodedMessage = encodeHtml("Jeste li sigurni da želite obrisati ovaj razgovor?");
      let myDialog = custom({
          title: chat.naziv,
          messageHtml: encodedMessage,
          buttons: [{
              text: "OBRIŠI",
              onClick: (e) => {
                  return { buttonText: e.component.option("text") }
              }
          },{
            text: "ODUSTANI",
            onClick: (e) => {
                return { buttonText: e.component.option("text") }
            }
        }]
      });
      myDialog.show().then((dialogResult) => {
          if(dialogResult.buttonText === 'OBRIŠI'){
            let podaciZaBrisanje = {
              'idchat': chat.chatID,
              'access_token': this.access_token
            };
            this._api.chatObrisi(podaciZaBrisanje).subscribe(res => {
              // console.log(res);
              if(res.success == true){
                this.chatPopis = this.chatPopis.filter(x => x.chatID !== chat.chatID);
                this.chatPoruke = [];
              } else {
                notify(res.message, "error");
              }
            });
          } 
      });
  }

  brisanjePoruke(poruka){
    // console.log(poruka);
    let encodedMessage = encodeHtml("Jeste li sigurni da želite obrisati ovu poruku?");
      let myDialog = custom({
          title: "Brisanje poruke",
          messageHtml: encodedMessage,
          buttons: [{
              text: "OBRIŠI",
              onClick: (e) => {
                  return { buttonText: e.component.option("text") }
              }
          },{
            text: "ODUSTANI",
            onClick: (e) => {
                return { buttonText: e.component.option("text") }
            }
        }]
      });
      myDialog.show().then((dialogResult) => {
          if(dialogResult.buttonText === 'OBRIŠI'){
            let podaciZaBrisanjePoruke = {
              'idporuka': poruka.idporuka,
              'access_token': this.access_token
            };
            this._api.obrisiPoruku(podaciZaBrisanjePoruke).subscribe(res => {
              // console.log(res);
              if(res.success == true){
                this.chatPoruke = this.chatPoruke.filter(x => x.idporuka !== poruka.idporuka);
              } else {
                notify(res.message, "error");
              }
            });
          } 
      });
  }

  otvoriMaterijalChat(poruka){
    var url = (this.datotekeURL + poruka.dokument_iddokument + "&access_token=" + this.access_token);
    window.open(url, "_blank");
  }

  replaceURLs(message) {
    if(!message) return;
    var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return message.replace(urlRegex, function (url) {
      var hyperlink = url;
      if (!hyperlink.match('^https?:\/\/')) {
        hyperlink = 'http://' + hyperlink;
      }
      return '<a href="' + hyperlink + '" target="_blank" rel="noopener noreferrer">' + url + '</a>'
    });
  }

  scrollToBottom() {
    this.myScrollContainer.nativeElement.scrollTo({left: 0 , top: this.myScrollContainer.nativeElement.scrollHeight, behavior: 'smooth'});
    // this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
  }

  ngOnDestroy(){
    this.socket.off();
  }

}