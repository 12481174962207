<h2 class="content-block">Programi</h2>

<div class="content-block dx-card responsive-paddings">
    <dx-data-grid 
    class="wide-card"
    [hoverStateEnabled]="true"
    [wordWrapEnabled]="true"
    [dataSource]="dataSource"
    [rowAlternationEnabled]="false"
    [showRowLines]="false"
    [showColumnLines]="false"
    [showBorders]="false"
    [columnAutoWidth]="true"
    [columnHidingEnabled]="true"
    (onToolbarPreparing)="onToolbarPreparing($event)"
    (onInitNewRow)="onInitNewRow($event)"
    >

        <dxo-paging [pageSize]="10"></dxo-paging>
        <dxo-pager [showPageSizeSelector]="true" [showInfo]="true"></dxo-pager>
        <!-- <dxo-filter-row [visible]="true"></dxo-filter-row> -->
        <dxo-search-panel [visible]="true"></dxo-search-panel>

        <dxi-column dataField="idprogram" caption="ID" [visible]="false" [width]="70" [allowFiltering]="false" alignment="center" [formItem]="{ visible: false }"></dxi-column>
        <dxi-column dataField="programNaziv" caption="Program" [allowSorting]="false"></dxi-column>
        <dxi-column dataField="sektorNaziv" caption="Sektor" [allowSorting]="true"></dxi-column>
        <dxi-column dataField="vrstaPrograma" caption="Vrsta programa" [allowSorting]="true"></dxi-column>
        <dxi-column type="buttons" [width]="110"><dxi-button template="buttonTemplate"></dxi-button></dxi-column>
        <div *dxTemplate="let buttonInfo of 'buttonTemplate'">         
            <dx-button icon="edit" id="edit" type="back" (click)="showEditPopup(buttonInfo)"></dx-button> 
            <dx-button icon="trash" id="trash" type="back" (click)="brisanjePrograma(buttonInfo)"></dx-button>         
        </div>

        <!-- Toolbar -->
        <div *dxTemplate="let data of 'kreiranjePrograma'">
            <div>
                <dx-button icon="add" id="kreiranjePrograma" name="kreiranjePrograma" hint="Kreiranje programa" (onClick)="prikazFormeZaKreiranjePrograma(data)"></dx-button>
            </div>
        </div>

    </dx-data-grid>

    <!-- Kreiranje programa -->
    <dx-popup
      title="Kreiranje programa"
      [(visible)]="kProgram"
      (onHidden)="popupHiddenKreiranje($event)"
      [width]="600"
      [height]="600"
      [position]="{ my: 'center', at: 'center', of: window }">
        <dx-form
            id="form"
            [showColonAfterLabel]="true"
            validationGroup="formaKreiranjePrograma"
            (onFieldDataChanged)="form_fieldDataChangedKreiraj($event)">
            <dxi-item itemType="group" [colCount]="1" [colSpan]="1">
                <dxi-item
                    dataField="programNaziv"
                    [label]="{text: 'Naziv'}"
                    [editorOptions]="{stylingMode: 'filled'}">
                    <dxi-validation-rule
                        type="required"
                        message="Naziv programa je obavezno polje!">
                    </dxi-validation-rule>
                </dxi-item>
                <dxi-item
                    [label]="{text: 'Sektor'}">
                    <dx-select-box
                        stylingMode='filled'
                        [dataSource]="sektori"
                        valueExpr="idsifarniksektor"
                        displayExpr="ssek_naziv"
                        [searchEnabled]="true"
                        (onValueChanged)="onSektorSelected($event)"
                        [grouped]="true">
                    </dx-select-box>
                    <dxi-validation-rule
                        type="required"
                        message="Sektor je obavezno polje!">
                    </dxi-validation-rule>
                </dxi-item>
                <dxi-item [label]="{text: 'Vrsta programa'}">
                    <dx-select-box
                        stylingMode='filled'
                        [dataSource]="vrstaPrograma"
                        valueExpr="idsifarnikvrstaprogram"
                        displayExpr="svpro_naziv"
                        [searchEnabled]="true"
                        (onValueChanged)="onVrstaProgramaSelected($event)"
                        [grouped]="true">
                    </dx-select-box>
                    <dxi-validation-rule
                        type="required"
                        message="Vrsta programa je obavezno polje!">
                    </dxi-validation-rule>
                </dxi-item>
                <dxi-item [label]="{text: 'Predmeti'}">
                    <dx-drop-down-box
                        placeholder="Odabir..."
                        [(value)]="predmetiOdabraniNaziviString"
                        [dataSource]="predmeti"
                        stylingMode='filled'>
                            <dx-list
                                [height]="'400'"
                                [selectedItemKeys]="idPredmeti"
                                [searchEnabled]="true"
                                searchExpr="pred_naziv"
                                searchMode="contains"
                                [dataSource]="predmeti"
                                displayExpr="pred_naziv"
                                selectionMode="multiple"
                                [showSelectionControls]="true"
                                (onSelectionChanged)="onPredmetSelected($event)"
                                >
                            </dx-list>
                    </dx-drop-down-box>
                </dxi-item>
            </dxi-item>
            <dxi-item style="float: right;">
                <dx-button stylingMode="text" type="default" (onClick)="kreirajProgram(programNaziv)" class="spremi">SPREMI</dx-button>
            </dxi-item>
        </dx-form>
    </dx-popup>

    <!-- Uređivanje programa -->
    <dx-popup
      title="Uređivanje programa"
      [(visible)]="editingPopup"
      (onHidden)="popupHiddenEdit($event)"
      [width]="600"
      [height]="600"
      [position]="{ my: 'center', at: 'center', of: window }">
      <dx-form
            id="editForm"
            [showColonAfterLabel]="true"
            validationGroup="formaEditPrograma"
            (onFieldDataChanged)="form_fieldDataChangedUredi($event)">
            <dxi-item itemType="group" [colCount]="1" [colSpan]="1">
                <dxi-item
                    dataField="programNaziv"
                    [editorOptions]="{ disabled: false, value: programNaziv, stylingMode: 'filled' }"
                    [label]="{text: 'Naziv'}">
                    <dxi-validation-rule
                        type="required"
                        message="Naziv programa je obavezno polje!">
                    </dxi-validation-rule>
                </dxi-item>
                <dxi-item [label]="{text: 'Sektor'}">
                    <dx-select-box
                        stylingMode='filled'
                        [value]="sektorZaEdit"
                        [dataSource]="sektori"
                        valueExpr="idsifarniksektor"
                        displayExpr="ssek_naziv"
                        [searchEnabled]="true"
                        (onValueChanged)="onSektorSelected($event)"
                        [grouped]="true">
                    </dx-select-box>
                    <dxi-validation-rule
                        type="required"
                        message="Sektor je obavezno polje!">
                    </dxi-validation-rule>
                </dxi-item>
                <dxi-item [label]="{text: 'Vrsta programa'}">
                    <dx-select-box
                        stylingMode='filled'
                        [value]="vrstaProgramaZaEdit"
                        [dataSource]="vrstaPrograma"
                        valueExpr="idsifarnikvrstaprogram"
                        displayExpr="svpro_naziv"
                        [searchEnabled]="true"
                        (onValueChanged)="onVrstaProgramaSelected($event)"
                        [grouped]="true">
                    </dx-select-box>
                    <dxi-validation-rule
                        type="required"
                        message="Vrsta programa je obavezno polje!">
                    </dxi-validation-rule>
                </dxi-item>
                <dxi-item [label]="{text: 'Predmeti'}">
                    <dx-drop-down-box
                        placeholder="Odabir..."
                        [(value)]="predmetiOdabraniNaziviStringEdit"
                        [dataSource]="predmeti"
                        stylingMode='filled'>
                            <dx-list
                                [height]="400"
                                keyExpr="idpredmet"
                                [(selectedItemKeys)]="idPredmetiEdit"
                                [searchEnabled]="true"
                                searchExpr="pred_naziv"
                                searchMode="contains"
                                [showSelectionControls]="true"
                                [dataSource]="predmeti"
                                displayExpr="pred_naziv"
                                selectionMode="multiple"
                                (onItemClick)="onPredmetSelectedEdit($event)">
                            </dx-list>
                    </dx-drop-down-box>
                </dxi-item>
            </dxi-item>
            <dxi-item style="float: right;">
                <dx-button stylingMode="text" type="default" (onClick)="urediProgram(programNaziv)" class="spremi">UREDI</dx-button>
            </dxi-item>
        </dx-form>
    </dx-popup>
    

</div>