import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { style } from '@angular/animations';

import { ApiService } from 'src/app/shared/services';

import { of, timer } from 'rxjs';
import { findIndex, takeWhile, tap } from 'rxjs/operators';
import { element } from 'protractor';

import { custom } from 'devextreme/ui/dialog';
import { encodeHtml } from 'devextreme/core/utils/string';
import notify from 'devextreme/ui/notify';
import { DxFileUploaderComponent } from 'devextreme-angular/ui/file-uploader';

@Component({
  selector: 'app-ispiti',
  templateUrl: './ispiti.component.html',
  styleUrls: ['./ispiti.component.scss']
})

export class IspitiComponent implements OnInit {

  access_token: any = localStorage.getItem('access_token');

  @ViewChild('notesUploader') uploader: DxFileUploaderComponent;
  datotekeURL = this._api.datotekeURL;
  uploadURL = this._api.uploadURL;
  ucionicaID: any;
  pitanja = [];
  odgovori = [];
  naslovPitanja: any;
  prikaziText = false;
  // boja: any;
  elementIndex = 0;
  pocetakIspita: boolean = false;
  ispitPocinjePopup: boolean = false;
  idrok: any;
  ispitniRok: any;
  odbrojavanje: any;
  naslovIspita: any;
  pitanjeSlika: boolean = false;
  pitanjeIspit: any;
  ponudjeniOdgovori: any;
  slika: any;
  slovo = ['A)', 'B)', 'C)', 'D)'];
  oblik: any;
  jedanOdgovor: any;
  value: any[] = [];
  isChecked = false;
  odgovoriZaProvjeru = [];
  odgovoreno: boolean = false;
  odgovorenaPitanjaID: any;
  idispitrezultat: any;
  idispitpitanje: any;
  podaciOdgovor: any;
  krajIspitaPopup: boolean = false;
  ispitNemaPitanja: boolean = false;
  rjesenjaKorisnika = [];
  start = sessionStorage.getItem('start')
  istekloVrijeme: boolean = false;
  pitanjaArray = [];
  idUploadDokument: any;
  IDpredmet: any;
  odgovorioSve: boolean = false;
  zavrsiPopup: boolean = false;

  constructor(
    private _api: ApiService,
    private _activatedRoute: ActivatedRoute,
    private location: Location
  ) {
    this.idrok = this._activatedRoute.snapshot.params['ispitnirok'];
    this.ucionicaID = this._activatedRoute.snapshot.params['id'];
  }
  
  pokreniIspit(){
    this.pitanjaArray = [];
    let podaci = {
      "access_token": this.access_token,
      "trajanje": +this.ispitniRok.isprok_trajanje,
      "idkorisnik": +localStorage.getItem('idkorisnik'),
      "idispit": this.ispitniRok.ispit_idispit,
      "iducionica": this.ispitniRok.ucionica_iducionica,
      "idispitrok": this.ispitniRok.idispitrok,
    }
   this._api.pocetakIspita(podaci).subscribe(res => {
      // console.log(res)
      if(res.success === true){
      //// ako ispit nema pitanja, izbacuje ga van
          if(res.pitanja.length == 0){
            notify('Ispit nema kreiranih pitanja', "error", 1000);
            this.location.back();
          } else {
            /** prikaži poruku o početku ispita ako je ispit pokrenut prvi put */
              if(this.ispitPocinjePopup){
                notify(res.message, 'success', 1000);
              }
              this.pocetakIspita = true;
              this.ispitPocinjePopup = false;
              this.naslovIspita = res.ispit[0].isp_naziv;
              this.pitanja = res.pitanja;
              this.odgovori = res.odgovori;
              this.rjesenjaKorisnika = res.rjesenjaKorisnika;
              this.idispitrezultat = res.idispitrezultat;

              // this.clickPitanje(0, '');    // otvara prvo pitanje

              for(let i = 0; i < res.pitanja.length; i++){
                this.pitanjaArray.push({
                  idispitpitanje: res.pitanja[i].idispitpitanje,
                  ispp_naziv: res.pitanja[i].ispp_naziv,
                  ispp_oblik: res.pitanja[i].ispp_oblik,
                  ispp_tekst: res.pitanja[i].ispp_tekst,
                  ispp_vrsta: res.pitanja[i].ispp_vrsta,
                  uploaddokument_iduploaddokument: res.pitanja[i].uploaddokument_iduploaddokument,
                  aktivno: true
                })
              }
              
              // console.log(this.pitanjaArray);
              this.rjesenja();

                ////////////// PRIKAZ TRJANJA ISPITA //////////////           
                // let kraj = res.krajIspita;
                // let sada = new Date().toISOString();
                // let sada = new Date().getTimezoneOffset().toLocaleString():
                let kraj = new Date(res.krajIspita).getTime();
                let sada = new Date().getTime();
                // console.log('sada ispit ' + sada);
                // console.log('kraj ispit ' + kraj);              
               if(kraj < sada){
                //  console.log('ISPIT JE PROŠAO');
                 this.istekloVrijeme = true;            
               } else {
                //  console.log('ISPIT još traje');
               }
                // var krajIspita = new Date(res.krajIspita).getTime();
                // var trenutno = new Date(Date.now()).getTime();
                var krajIspita = new Date(res.krajIspita).getTime();
                var trenutno = new Date().getTime();
                var razlika = (krajIspita - trenutno) / 1000 ;

                // console.log('sada ' + trenutno);
                // console.log('kraj ' + krajIspita);
               
                   timer(1000, 1000)
                     .pipe(
                       takeWhile( () => razlika > 0 ),
                       tap(() => razlika--),
                     )
                     .subscribe( () => {
                      this.odbrojavanje = this.secondsToTime(razlika);
                      if(razlika <= 0){
                        this.krajIspitaPopup = true;
                        this.istekloVrijeme = true;
                        // console.log(razlika);
                      } else {
                        this.krajIspitaPopup = false;
                        this.istekloVrijeme = false;
                      }
                   } )
////////////////////////////////////////////////////////
          }
      } else {
        notify(res.message, "error", 1000);
        this.location.back();
      }
   })   
  }


  rjesenja(){
    if(this.rjesenjaKorisnika){
      for(let i = 0; i < this.rjesenjaKorisnika.length; i++){
        for(let j = 0; j < this.pitanjaArray.length; j++){
          if(this.rjesenjaKorisnika[i].ispitpitanje_idispitpitanje === this.pitanjaArray[j].idispitpitanje){
            this.pitanjaArray[j].aktivno = false;
          }
        }
      }
    } else {
      this.pitanjaArray = this.pitanjaArray;
    }
    //  console.log(this.pitanjaArray);
      var elementi = this.pitanjaArray.findIndex(x => x.aktivno === true);
      // console.log(elementi);  
      if(elementi >= 0){
        this.clickPitanje(elementi, '');
      } else {
      this.zavrsiPopup = true;
        //  this.ispitZavrsen();
        // this.zavrsiIspit();    // ako odgovaram pa da ide popup
      }
  }

  secondsToTime(e){
    var h = Math.floor(e / 3600).toString().padStart(2,'0'),
        m = Math.floor(e % 3600 / 60).toString().padStart(2,'0'),
        s = Math.floor(e % 60).toString().padStart(2,'0');
    return h + ':' + m + ':' + s;
  }

  pitanjaIOdgovoriIspita(index){
    // this.odgovoriZaProvjeru = [];   
    // console.log(this.pitanjaArray[index].ispp_oblik);
    if(this.pitanjaArray[index].ispp_oblik === 0 ||this.pitanjaArray[index].ispp_oblik === 1){
      this.oblik = 'kviz';
    } else if(this.pitanjaArray[index].ispp_oblik === 2){
      this.oblik = 'esej';
    } else if(this.pitanjaArray[index].ispp_oblik === 3){
      this.oblik = 'nadopuna';
    } else {
      this.oblik = 'upload';
    }

    this.ponudjeniOdgovori = [];
    if(this.pitanjaArray[index].ispp_vrsta === 0){
          this.pitanjeSlika = false;
          this.pitanjeIspit = this.pitanjaArray[index].ispp_tekst;   
          this.idispitpitanje = this.pitanjaArray[index].idispitpitanje;

          /**----------- odgovori ako nema slike ---------------- */
          for(let j = 0; j < this.odgovori.length; j++){
            if(this.odgovori[j].ispitpitanje_idispitpitanje === this.pitanjaArray[index].idispitpitanje){
                this.ponudjeniOdgovori.push(this.odgovori[j]);
             }
            }
            // console.log(this.ponudjeniOdgovori);
    } else if (this.pitanjaArray[index].ispp_vrsta === 1){
               this.pitanjeSlika = true;
               this.pitanjeIspit = this.pitanjaArray[index].ispp_tekst;
               this.idispitpitanje = this.pitanjaArray[index].idispitpitanje;
               this.slika = this.pitanjaArray[index].uploaddokument_iduploaddokument;
          /**----------- odgovori ako ima slike---------------- */
              for(let j = 0; j < this.odgovori.length; j++){
                if(this.odgovori[j].ispitpitanje_idispitpitanje === this.pitanjaArray[index].idispitpitanje){
                   this.ponudjeniOdgovori.push(this.odgovori[j]);
                }
            }
            //  console.log(this.ponudjeniOdgovori);
    }  else {
       notify('Nema pitanja u kvizu', 'error');
    }
  }

  clickPitanje(i, pitanje){
    // console.log(pitanje);
    // console.log(i);
     this.pitanjaIOdgovoriIspita(i);
     this.elementIndex = i;
    //  console.log(this.elementIndex);
    i++;
    this.naslovPitanja = 'Pitanje ' + i + '.';
    this.prikaziText = true;
    this.odgovoriZaProvjeru = [];
    this.odgovoreno = false;
  }

  checboxClicked(e, data){
    if(e.target.checked == true){
    // console.log(data)
    this.odgovorenaPitanjaID = data.ispitpitanje_idispitpitanje;
    // this.tocniOdgovori.push(data.idkvizodgovor);
    this.odgovoriZaProvjeru.push(data.idispitodgovor);
    // this.pitanjeID = data.kvizpitanje_idkvizpitanje;
    } else {
      // let index = this.tocniOdgovori.indexOf(data.idispitodgovor);
      // if (index > -1) {
      //   this.tocniOdgovori.splice(index, 1);
      // }
      let i = this.odgovoriZaProvjeru.indexOf(data.idispitodgovor);
      if (i > -1) {
        this.odgovoriZaProvjeru.splice(i, 1);
      }
    }
    // console.log(this.odgovoriZaProvjeru);
  }


  zavrsiIspit(){
    let encodedMessage = encodeHtml("Jeste li sigurni da želite završiti ispit?");
    let myDialog = custom({
        title: "Završi ispit",
        messageHtml: encodedMessage,
        buttons: [{
            text: "DA",
            onClick: (e) => {
                return { buttonText: e.component.option("text") }
            }
        },{
            text: "NE",
            onClick: (e) => {
                return { buttonText: e.component.option("text") }
            }
        }]
    });
    myDialog.show().then((dialogResult) => {
       if(dialogResult.buttonText === 'DA'){    
         this.zavrsiIspitBtn();
       }
    });
  }

  zavrsiIspitBtn(){
    let podaci = {
      "access_token": this.access_token,
      "idkorisnik": localStorage.getItem('idkorisnik'),
      "idispit": this.ispitniRok.ispit_idispit,
      "iducionica": this.ispitniRok.ucionica_iducionica,
      "idispitrok": this.ispitniRok.idispitrok,
    };
    // console.log(podaci)
    this._api.zavrsiIspit(podaci).subscribe(res => {
      // console.log(res)
      if(res.success === true){
        notify(res.message, 'success', 1000);
        this.ispitZavrsen();
      } else {
        notify(res.message, 'error', 1000);
      }
    });
  }

  ispitZavrsen(){
    this.location.back();
  }

  /**  upload odgovora */
  onUploaded(e){
    // console.log(e);
    let file = e.file;
    let formData:FormData = new FormData();
    formData.append('datoteka', file, file.name);
    formData.append('predmet', this.IDpredmet);
    formData.append('iddokument', 'ispitPitanje');
    formData.append('idkorisnik', localStorage.getItem('idkorisnik'));
    formData.append('nazivtablice', 'ispitPitanje');
    formData.append('access_token', this.access_token);
    this._api.uploadDatotekaKviz(formData).subscribe(res=>{
      if(res.success == true){
        if(res.iduploaddokument){
          this.idUploadDokument = res.iduploaddokument;
        }
      } else {
        notify(res.message, "error");
      }
    });
  }
  
  onClose = () => {
    this.location.back();
  }

  slikaPrikazi(slika){
    var prikaz = this.datotekeURL + slika + "&access_token=" + this.access_token;
    window.open(prikaz, "_blank");
  }

  dohvatiIspitniRok(){
    this._api.jedanIspitniRok({"access_token": this.access_token, "idispitrok": this.idrok }).subscribe(res => {
      // console.log(res);
       this.ispitniRok = res.ispitniRok[0];
       this.IDpredmet = res.ispitniRok[0].predmet_idpredmet;
       if(this.start === '1'){
          this.pokreniIspit();
        } else {
          this.ispitPocinjePopup = true;
        }
    });
  }

  ngOnInit() {
    this.dohvatiIspitniRok();
  }

  posaljiOdgovor(e, idispitpitanje, oblik,jedanOdgovor){
    //  console.log(this.odgovoriZaProvjeru.length);
    this.jedanOdgovor = jedanOdgovor;
    // console.log(jedanOdgovor);
      
    /** dodati uvjet ako nije kviz */
    if(this.oblik === 'kviz'){
          if(this.odgovoriZaProvjeru.length > 0){
            this.isChecked = true;
            this.odgovoreno = true;
            this.podaciOdgovor = {
              "access_token": this.access_token,
              "idkorisnik": +localStorage.getItem('idkorisnik'),
              "idispitpitanje": idispitpitanje,
              "idispit": this.ispitniRok.ispit_idispit,
              "idispitodgovor": this.odgovoriZaProvjeru,
              "idispitrezultat": this.idispitrezultat,
              "idispitrok": +this.idrok
            }
            //  console.log(this.podaciOdgovor)
            this.spremiUBazu();
          } else {
            this.isChecked = false;
            notify('Potrebno je označiti barem jedan odgovor.', 'error', 1000);
          }
    
    } else if (this.oblik === 'esej' || this.oblik === 'nadopuna'){
          if(this.jedanOdgovor != undefined || this.jedanOdgovor != null){
            this.odgovoreno = true;
            this.podaciOdgovor = {
              "access_token": this.access_token,
              "idkorisnik": +localStorage.getItem('idkorisnik'),
              "idispitpitanje": idispitpitanje,
              "idispit": this.ispitniRok.ispit_idispit,
              "tekst": this.jedanOdgovor,  // ovo je esejski odgovor
              "idispitrezultat": this.idispitrezultat,
              "idispitodgovor": [],
              "idispitrok": +this.idrok
            }
            //  console.log(this.podaciOdgovor);
            this.spremiUBazu();  
          } else {
            notify('Potrebno je odgovoriti na ovo pitanje.', 'error', 1000);
          }

  /// ako je upload 
        } else {
          this.podaciOdgovor = {
            "access_token": this.access_token,
            "idkorisnik": +localStorage.getItem('idkorisnik'),
            "idispitpitanje": idispitpitanje,
            "idispit": this.ispitniRok.ispit_idispit,
            "iduploaddokument": this.idUploadDokument,
            "idispitrezultat": this.idispitrezultat,
            "idispitodgovor": [],
            "idispitrok": +this.idrok
          }
          //  console.log(this.podaciOdgovor);
          this.spremiUBazu();
        }

  }

  spremiUBazu(){
      this._api.odgovaranjeIspit(this.podaciOdgovor).subscribe(res => {
        // console.log(res)
        if(res.success === true){
          notify(res.message, 'success', 1000);
          this.odgovoreno = false;
          this.jedanOdgovor = null;
          for(let i = 0; i < this.pitanjaArray.length; i++){
            if(this.pitanjaArray[i].idispitpitanje === res.pitanje)
            this.pitanjaArray[i].aktivno = false;
            this.rjesenja();
          }
        } else {
          notify(res.message, 'error', 1000);
        }
      });
  }

  ngOnDestroy(){
    sessionStorage.removeItem('start');
  }

}