import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  access_token = localStorage.getItem('access_token');

  // apiUrl: string = "https://lab.fortuno.hr:3028/api/";
  // datotekeURL: string = "https://lab.fortuno.hr:3028/api/predmetMaterijalDohvati/?idmaterijal=";
  // uploadURL: string = "https://lab.fortuno.hr:3028/api/uploadDatoteka";
  // apiChat: string = "https://lab.fortuno.hr:3028";

  apiUrl: string = "https://e-learning.uciliste-studium.hr:3028/api/";
  datotekeURL: string = "https://e-learning.uciliste-studium.hr:3028/api/predmetMaterijalDohvati/?idmaterijal=";
  uploadURL: string = "https://e-learning.uciliste-studium.hr:3028/api/uploadDatoteka";
  apiChat: string = "https://e-learning.uciliste-studium.hr:3028/";

  constructor(private _http: HttpClient) { }

  // Prijava - Zaboravljena Lozinka

  login(data:any){
    return this._http.post(this.apiUrl + 'korisnikPrijava', data)    
    .pipe(map((res: any) => res ));
  }

  korisnikZaboravljenaLozinka(data:any){
    return this._http.post(this.apiUrl + 'korisnikZaboravljenaLozinka', data)    
    .pipe(map((res: any) => res ));
  }

  //Korisnici

  korisnikDodaj(data:any){
    return this._http.post(this.apiUrl + 'korisnikDodaj', data)    
    .pipe(map((res: any) => res ));
  }

  korisnikDohvati(data:any){
    return this._http.post(this.apiUrl + 'korisnikDohvati', data)    
    .pipe(map((res: any) => res ));
  }

  korisnikUredi(data:any){
    return this._http.post(this.apiUrl + 'korisnikUredi', data)    
    .pipe(map((res: any) => res ));
  }

  korisnikIzmjenaProfila(data:any){
    return this._http.post(this.apiUrl + 'korisnikIzmjenaProfila', data)    
    .pipe(map((res: any) => res ));
  }

  korisnikProvjeraOIB(data:any){
    return this._http.post(this.apiUrl + 'korisnikProvjeraOIB', data)    
    .pipe(map((res: any) => res ));
  }

  korisnikProvjeraEmail(data:any){
    return this._http.post(this.apiUrl + 'korisnikProvjeraEmail', data)    
    .pipe(map((res: any) => res ));
  }

  korisnikPopis(data:any){
    return this._http.post(this.apiUrl + 'korisnikPopis', data)    
    .pipe(map((res: any) => res ));
  }

  korisnikAktivacija(data:any){
    return this._http.post(this.apiUrl + 'korisnikAktivacija', data)    
    .pipe(map((res: any) => res ));
  }

  // Sektori

  sektorPopis(data:any){
    return this._http.post(this.apiUrl + 'sektorPopis', data)    
    .pipe(map((res: any) => res ));
  }

  sektorDodaj(data:any){
    return this._http.post(this.apiUrl + 'sektorDodaj', data)    
    .pipe(map((res: any) => res ));
  }

  sektorUredi(data:any){
    return this._http.post(this.apiUrl + 'sektorUredi', data)    
    .pipe(map((res: any) => res ));
  }

  //Vrste programa

  vrstaProgramaPopis(data:any){
    return this._http.post(this.apiUrl + 'vrstaProgramaPopis', data)    
    .pipe(map((res: any) => res ));
  }

  vrstaProgramaDodaj(data:any){
    return this._http.post(this.apiUrl + 'vrstaProgramaDodaj', data)    
    .pipe(map((res: any) => res ));
  }

  vrstaProgramaUredi(data:any){
    return this._http.post(this.apiUrl + 'vrstaProgramaUredi', data)    
    .pipe(map((res: any) => res ));
  }

  // Gradovi

  gradPopis(data:any){
    return this._http.post(this.apiUrl + 'gradPopis', data)    
    .pipe(map((res: any) => res ));
  }

  gradDodaj(data:any){
    return this._http.post(this.apiUrl + 'gradDodaj', data)    
    .pipe(map((res: any) => res ));
  }

  gradUredi(data:any){
    return this._http.post(this.apiUrl + 'gradUredi', data)    
    .pipe(map((res: any) => res ));
  }

  // Predmeti

  predmetPopis(data:any){
    return this._http.post(this.apiUrl + 'predmetPopis', data)    
    .pipe(map((res: any) => res ));
  }

  predmetDodaj(data:any){
    return this._http.post(this.apiUrl + 'predmetDodaj', data)    
    .pipe(map((res: any) => res ));
  }

  predmetUredi(data:any){
    return this._http.post(this.apiUrl + 'predmetUredi', data)    
    .pipe(map((res: any) => res ));
  }

  predmetJedan(data:any){
    return this._http.post(this.apiUrl + 'predmetJedan', data)    
    .pipe(map((res: any) => res ));
  }

  predmetPopisMoj(data:any){
    return this._http.post(this.apiUrl + 'predmetPopisMoj', data)    
    .pipe(map((res: any) => res ));
  }
  
  predmetObrisi(data:any){
    return this._http.post(this.apiUrl + 'predmetObrisi', data)    
    .pipe(map((res: any) => res ));
  }

  //Materijali

  uploadDatoteka(data:any){
    return this._http.post(this.apiUrl + 'uploadDatoteka', data)    
    .pipe(map((res: any) => res ));
  }

  predmetMaterijalPopis(data:any){
    return this._http.post(this.apiUrl + 'predmetMaterijalPopis', data)    
    .pipe(map((res: any) => res ));
  }

  predmetMaterijalUredi(data:any){
    return this._http.post(this.apiUrl + 'predmetMaterijalUredi', data)    
    .pipe(map((res: any) => res ));
  }

  predmetMaterijalObrisi(data:any){
    return this._http.post(this.apiUrl + 'predmetMaterijalObrisi', data)    
    .pipe(map((res: any) => res ));
  }

  predmetMaterijalDodajVideo(data:any){
    return this._http.post(this.apiUrl + 'predmetMaterijalDodajVideo', data)    
    .pipe(map((res: any) => res ));
  }

  predmetMaterijalGlobaliziraj(data:any){
    return this._http.post(this.apiUrl + 'predmetMaterijalGlobaliziraj', data)    
    .pipe(map((res: any) => res ));
  }

  //Program

  programPopis(data:any){
    return this._http.post(this.apiUrl + 'programPopis', data)    
    .pipe(map((res: any) => res ));
  }

  programObrisi(data:any){
    return this._http.post(this.apiUrl + 'programObrisi', data)    
    .pipe(map((res: any) => res ));
  }

  programUredi(data:any){
    return this._http.post(this.apiUrl + 'programUredi', data)    
    .pipe(map((res: any) => res ));
  }

  programJedan(data:any){
    return this._http.post(this.apiUrl + 'programJedan', data)    
    .pipe(map((res: any) => res ));
  }

  programDodaj(data:any){
    return this._http.post(this.apiUrl + 'programDodaj', data)    
    .pipe(map((res: any) => res ));
  }

  //Kvizovi

  kvizPopis(data:any){
    return this._http.post(this.apiUrl + 'kvizPopis', data)    
    .pipe(map((res: any) => res ));
  }

  kvizDodaj(data:any){
    return this._http.post(this.apiUrl + 'kvizDodaj', data)    
    .pipe(map((res: any) => res ));
  }

  kvizUredi(data:any){
    return this._http.post(this.apiUrl + 'kvizUredi', data)    
    .pipe(map((res: any) => res ));
  }

  kvizObrisi(data:any){
    return this._http.post(this.apiUrl + 'kvizObrisi', data)    
    .pipe(map((res: any) => res ));
  }

  kvizJedan(data:any){
    return this._http.post(this.apiUrl + 'kvizJedan', data)    
    .pipe(map((res: any) => res ));
  }

  kvizPitanjeDodaj(data:any){
    return this._http.post(this.apiUrl + 'kvizPitanjeDodaj', data)    
    .pipe(map((res: any) => res ));
  }

  kvizObrisiPitanje(data:any){
    return this._http.post(this.apiUrl + 'kvizObrisiPitanje', data)    
    .pipe(map((res: any) => res ));
  }

  uploadDatotekaKviz(data:any){
    return this._http.post(this.apiUrl + 'uploadDatotekaKviz', data)    
    .pipe(map((res: any) => res ));
  }

  kvizOdgovorDodaj(data:any){
    return this._http.post(this.apiUrl + 'kvizOdgovorDodaj', data)    
    .pipe(map((res: any) => res ));
  }

  kvizPitanjeSaOdgovorima(data:any){
    return this._http.post(this.apiUrl + 'kvizPitanjeSaOdgovorima', data)    
    .pipe(map((res: any) => res ));
  }

  kvizPitanjeUredi(data:any){
    return this._http.post(this.apiUrl + 'kvizPitanjeUredi', data)    
    .pipe(map((res: any) => res ));
  }

  kvizOdgovorUredi(data:any){
    return this._http.post(this.apiUrl + 'kvizOdgovorUredi', data)    
    .pipe(map((res: any) => res ));
  }

  ispitOdgovorUredi(data:any){
    return this._http.post(this.apiUrl + 'ispitOdgovorUredi', data)    
    .pipe(map((res: any) => res ));
  }

  //Ispit

  ispitPopis(data:any){
    return this._http.post(this.apiUrl + 'ispitPopis', data)    
    .pipe(map((res: any) => res ));
  }

  ispitDodaj(data:any){
    return this._http.post(this.apiUrl + 'ispitDodaj', data)    
    .pipe(map((res: any) => res ));
  }

  ispitUredi(data:any){
    return this._http.post(this.apiUrl + 'ispitUredi', data)    
    .pipe(map((res: any) => res ));
  }

  ispitDeAktivacija(data:any){
    return this._http.post(this.apiUrl + 'ispitDeAktivacija', data)    
    .pipe(map((res: any) => res ));
  }

  ispitJedan(data:any){
    return this._http.post(this.apiUrl + 'ispitJedan', data)    
    .pipe(map((res: any) => res ));
  }

  ispitPitanjeDodaj(data:any){
    return this._http.post(this.apiUrl + 'ispitPitanjeDodaj', data)    
    .pipe(map((res: any) => res ));
  }

  ispitPitanjeUredi(data:any){
    return this._http.post(this.apiUrl + 'ispitPitanjeUredi', data)    
    .pipe(map((res: any) => res ));
  }

  ispitPitanjeObrisi(data:any){
    return this._http.post(this.apiUrl + 'ispitPitanjeObrisi', data)    
    .pipe(map((res: any) => res ));
  }

  ispitOdgovorDodaj(data:any){
    return this._http.post(this.apiUrl + 'ispitOdgovorDodaj', data)    
    .pipe(map((res: any) => res ));
  }

  ispitOcjeni(data:any){
    return this._http.post(this.apiUrl + 'ispitOcjeni', data)    
    .pipe(map((res: any) => res ));
  }

  //Učionica

  ucionicaPopisZaPredavaca(data:any){
    return this._http.post(this.apiUrl + 'ucionicaPopisZaPredavaca', data)    
    .pipe(map((res: any) => res ));
  }

  ucionicaPopis(data:any){
    return this._http.post(this.apiUrl + 'ucionicaPopis', data)    
    .pipe(map((res: any) => res ));
  }

  ucionicaJedna(data:any){
    return this._http.post(this.apiUrl + 'ucionicaJedna', data)    
    .pipe(map((res: any) => res ));
  }

  ucionicaDodaj(data:any){
    return this._http.post(this.apiUrl + 'ucionicaDodaj', data)    
    .pipe(map((res: any) => res ));
  }

  ucionicaObrisi(data:any){
    return this._http.post(this.apiUrl + 'ucionicaObrisi', data)    
    .pipe(map((res: any) => res ));
  }

  ucionicaAdministratorIzmjena(data:any){
    return this._http.post(this.apiUrl + 'ucionicaAdministratorIzmjena', data)    
    .pipe(map((res: any) => res ));
  }

  ucionicaPredavacIzmjena(data:any){
    return this._http.post(this.apiUrl + 'ucionicaPredavacIzmjena', data)    
    .pipe(map((res: any) => res ));
  }

  ucionicaPolaznikIzmjena(data:any){
    return this._http.post(this.apiUrl + 'ucionicaPolaznikIzmjena', data)    
    .pipe(map((res: any) => res ));
  }

  ucionicaPredmetIzmjena(data:any){
    return this._http.post(this.apiUrl + 'ucionicaPredmetIzmjena', data)    
    .pipe(map((res: any) => res ));
  }

  ucionicaUredi(data:any){
    return this._http.post(this.apiUrl + 'ucionicaUredi', data)    
    .pipe(map((res: any) => res ));
  }

  korisnikPopisPretrazivanje(data:any){
    return this._http.post(this.apiUrl + 'korisnikPopisPretrazivanje', data)    
    .pipe(map((res: any) => res ));
  }

  ucionicaJednaProsireno(data:any){
    return this._http.post(this.apiUrl + 'ucionicaJednaProsireno', data)    
    .pipe(map((res: any) => res ));
  }

  predmetPolaznici(data:any){
    return this._http.post(this.apiUrl + 'predmetPolaznici', data)    
    .pipe(map((res: any) => res ));
  }

  predmetPredavaci(data:any){
    return this._http.post(this.apiUrl + 'predmetPredavaci', data)    
    .pipe(map((res: any) => res ));
  }

  ucionicaZavrsenPredmetIzmjena(data:any){
    return this._http.post(this.apiUrl + 'ucionicaZavrsenPredmetIzmjena', data)
    .pipe(map((res: any) => res ));
  }

  predmetOcjeni(data:any){
    return this._http.post(this.apiUrl + 'predmetOcjeni', data)
    .pipe(map((res: any) => res ));
  }


  // Ispitni rokovi

  jedanIspitniRok(data:any){
    return this._http.post(this.apiUrl + 'jedanIspitniRok', data)
    .pipe(map((res: any) => res ));
  }
  ispitniRokDodaj(data:any){
    return this._http.post(this.apiUrl + 'ispitniRokDodaj', data)
    .pipe(map((res: any) => res ));
  }

  ispitniRokUredi(data:any){
    return this._http.post(this.apiUrl + 'ispitniRokUredi', data)
    .pipe(map((res: any) => res ));
  }

  ispitniRokoviPopisZaUcionicu(data:any){
    return this._http.post(this.apiUrl + 'ispitniRokoviPopisZaUcionicu', data)
    .pipe(map((res: any) => res ));
  }

  ispitniRokoviPopisZaPredmet(data:any){
    return this._http.post(this.apiUrl + 'ispitniRokoviPopisZaPredmet', data)
    .pipe(map((res: any) => res ));
  }

  ispitJedanZaOcjenjivanje(data:any){
    return this._http.post(this.apiUrl + 'ispitJedanZaOcjenjivanje', data)
    .pipe(map((res: any) => res ));
  }
  ispitOdgovorTocan(data:any){
    return this._http.post(this.apiUrl + 'ispitOdgovorTocan', data)
    .pipe(map((res: any) => res ));
  }

   // Pregled jednog polaznika
   zavrsiIspit(data:any){
    return this._http.post(this.apiUrl + 'zavrsiIspit', data)
    .pipe(map((res: any) => res ));
  }

  ispitniRokoviPopisKorisnikZaUcionicu(data:any){
    return this._http.post(this.apiUrl + 'ispitniRokoviPopisKorisnikZaUcionicu', data)
    .pipe(map((res: any) => res ));
  }

  ucionicaPopisPolaznik(data:any){
    return this._http.post(this.apiUrl + 'ucionicaPopisPolaznik', data)
    .pipe(map((res: any) => res ));
  }

  predmetPopisZaUcionice(data:any){
    return this._http.post(this.apiUrl + 'predmetPopisZaUcionice', data)
    .pipe(map((res: any) => res ));
  }

  ucionicaMaterijali(data:any){
    return this._http.post(this.apiUrl + 'ucionicaMaterijali', data)
    .pipe(map((res: any) => res ));
  }

  predmetMaterijaliZaUcionicu(data:any){
    return this._http.post(this.apiUrl + 'predmetMaterijaliZaUcionicu', data)
    .pipe(map((res: any) => res ));
  }

  ucionicaGlobalniMaterijaliIzmjena(data:any){
    return this._http.post(this.apiUrl + 'ucionicaGlobalniMaterijaliIzmjena', data)
    .pipe(map((res: any) => res ));
  }

  kvizPopisZaKorisnika(data:any){
    return this._http.post(this.apiUrl + 'kvizPopisZaKorisnika', data)
    .pipe(map((res: any) => res ));
  }

  kvizRjesi(data:any){
    return this._http.post(this.apiUrl + 'kvizRjesi', data)
    .pipe(map((res: any) => res ));
  }

  kvizPitanjeTocan(data:any){
    return this._http.post(this.apiUrl + 'kvizPitanjeTocan', data)
    .pipe(map((res: any) => res ));
  }

  predmetPredavaceviMaterijaliZaUcionicu(data:any){
    return this._http.post(this.apiUrl + 'predmetPredavaceviMaterijaliZaUcionicu', data)
    .pipe(map((res: any) => res ));
  }

  ucionicaPredavaceviMaterijaliIzmjena(data:any){
    return this._http.post(this.apiUrl + 'ucionicaPredavaceviMaterijaliIzmjena', data)
    .pipe(map((res: any) => res ));
  }

  ucionicaDeAktivacija(data:any){
    return this._http.post(this.apiUrl + 'ucionicaDeAktivacija', data)
    .pipe(map((res: any) => res ));
  }

  // Obavijesti

  ucionicaObavijestDodaj(data:any){
    return this._http.post(this.apiUrl + 'ucionicaObavijestDodaj', data)
    .pipe(map((res: any) => res ));
  }

  ucionicaObavijestPopis(data:any){
    return this._http.post(this.apiUrl + 'ucionicaObavijestPopis', data)
    .pipe(map((res: any) => res ));
  }

  ucionicaObavijestUredi(data:any){
    return this._http.post(this.apiUrl + 'ucionicaObavijestUredi', data)
    .pipe(map((res: any) => res ));
  }

  ucionicaObavijestObrisi(data:any){
    return this._http.post(this.apiUrl + 'ucionicaObavijestObrisi', data)
    .pipe(map((res: any) => res ));
  }

  // Polaznik - ispiti

  odgovaranjeIspit(data:any){
    return this._http.post(this.apiUrl + 'odgovaranjeIspit', data)
    .pipe(map((res: any) => res ));
  }

  pocetakIspita(data:any){
    return this._http.post(this.apiUrl + 'pocetakIspita', data)
    .pipe(map((res: any) => res ));
  }

  ispitniRokKorisnici(data:any){
    return this._http.post(this.apiUrl + 'ispitniRokKorisnici', data)
    .pipe(map((res: any) => res ));
  }

  // Predavanja

  ucionicaPredavanjePopis(data:any){
    return this._http.post(this.apiUrl + 'ucionicaPredavanjePopis', data)
    .pipe(map((res: any) => res ));
  }

  ucionicaPredavanjeObrisi(data:any){
    return this._http.post(this.apiUrl + 'ucionicaPredavanjeObrisi', data)
    .pipe(map((res: any) => res ));
  }

  ucionicaKreirajPredavanje(data:any){
    return this._http.post(this.apiUrl + 'ucionicaKreirajPredavanje', data)
    .pipe(map((res: any) => res ));
  }

  ucionicaPredavanjePopisPrisutnost(data:any){
    return this._http.post(this.apiUrl + 'ucionicaPredavanjePopisPrisutnost', data)
    .pipe(map((res: any) => res ));
  }

  ucionicaPredavanjePrisutan(data:any){
    return this._http.post(this.apiUrl + 'ucionicaPredavanjePrisutan', data)
    .pipe(map((res: any) => res ));
  }

  ucionicaPredavanjePopisPrisutnostKorisnik(data:any){
    return this._http.post(this.apiUrl + 'ucionicaPredavanjePopisPrisutnostKorisnik', data)
    .pipe(map((res: any) => res ));
  }

  ucionicaUrediPredavanje(data:any){
    return this._http.post(this.apiUrl + 'ucionicaUrediPredavanje', data)
    .pipe(map((res: any) => res ));
  }

  // Chat - Razgovor

  chatKreiraj(data:any){
    return this._http.post(this.apiUrl + 'chatKreiraj', data)
    .pipe(map((res: any) => res ));
  }

  chatPopis(data:any){
    return this._http.post(this.apiUrl + 'chatPopis', data)
    .pipe(map((res: any) => res ));
  }

  chatObrisi(data:any){
    return this._http.post(this.apiUrl + 'chatObrisi', data)
    .pipe(map((res: any) => res ));
  }

  chatPoruka(data:any){
    return this._http.post(this.apiUrl + 'chatPoruka', data)
    .pipe(map((res: any) => res ));
  }

  dohvatiPoruke(data:any){
    return this._http.post(this.apiUrl + 'dohvatiPoruke', data)
    .pipe(map((res: any) => res ));
  }

  obrisiPoruku(data:any){
    return this._http.post(this.apiUrl + 'obrisiPoruku', data)
    .pipe(map((res: any) => res ));
  }

  // Popis kvizova u ucionici za nekog polaznika
  rjKvizPopis(data:any){
    return this._http.post(this.apiUrl + 'rjKvizPopis', data)
    .pipe(map((res: any) => res ));
  }

  // obrisiPoruku(data:any){
  //   return this._http.post(this.apiUrl + 'obrisiPoruku', data)
  //   .pipe(map((res: any) => res ));
  // }

}