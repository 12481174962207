import { Component, OnInit } from '@angular/core';

import { ApiService } from './../../shared/services/api.service';

import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-vrste-programa',
  templateUrl: './vrste-programa.component.html',
  styleUrls: ['./vrste-programa.component.scss']
})

export class VrsteProgramaComponent implements OnInit {

  access_token: any = localStorage.getItem('access_token');

  window: any;
  dataSource = [];
  naslov: string;

  constructor(
    public _api: ApiService
  ) { this.onShowing = this.onShowing.bind(this); }

  ngOnInit() {
    this.dohvatiVrstePrograma();
  }

  dohvatiVrstePrograma(){
    this._api.vrstaProgramaPopis({"access_token": this.access_token}).subscribe(res=>{
      // console.log(res);
      // console.log(res.vrstaPrograma);
      if(res.success == true){
        this.dataSource = res.vrstaPrograma;
      } else {
        notify(res.message, "error", 1000);
      }
    });
  }

  onShowing(e){  
    // console.log(e);
    e.component.option("title", this.naslov);
  }

  onInitNewRow(e){
    this.naslov = 'Kreiranje vrste programa';
  }

  onEditingStart(e){
    this.naslov = 'Uređivanje vrste programa';
  }

  onRowInserted(e){
    // console.log('onRowInserted');
    // console.log(e);
    let vrstaProgramaZaSlanje = {
        "naziv": e.data['svpro_naziv'],
        "opis": e.data['svpro_opis'],
        "access_token": this.access_token
    };
    // console.log(vrstaProgramaZaSlanje);
    this._api.vrstaProgramaDodaj(vrstaProgramaZaSlanje).subscribe(res=>{
      // console.log(res);
      if(res.success == true){
        this.dohvatiVrstePrograma();
      } else {
        notify(res.message, "error", 1000);
      }
    });
  }

  onRowUpdated(e){
    // console.log(e);
    let vrstaProgramaZaEdit = {
        "idvrstaprogram": e.data['idsifarnikvrstaprogram'],
        "naziv": e.data['svpro_naziv'],
        "opis": e.data['svpro_opis'],
        "access_token": this.access_token
    };
    // console.log(vrstaProgramaZaEdit);
    this._api.vrstaProgramaUredi(vrstaProgramaZaEdit).subscribe(res=>{
      // console.log(res);
      if(res.success == true){
        notify(res.message, "success", 1000);
        this.dohvatiVrstePrograma();
      } else {
        notify(res.message, "error", 1000);
      }
    });
  }

}