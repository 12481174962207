<h2 class="content-block">Učionice</h2>

<div class="content-block dx-card responsive-paddings">

    <dx-data-grid
        [showRowLines]="false"
        class="wide-card"
        [hoverStateEnabled]="true"
        [wordWrapEnabled]="true"
        [dataSource]="ucionice"
        [columnAutoWidth]="true"
        [columnHidingEnabled]="true"
        keyExpr="iducionica">

    <dxo-paging [pageSize]="10"></dxo-paging>
    <dxo-pager [showPageSizeSelector]="true" [showInfo]="true"></dxo-pager>
    <dxo-search-panel [visible]="true"></dxo-search-panel>
    <dxi-column dataField="uci_naziv" caption="Naziv učionice"></dxi-column>
    <dxi-column dataField="brojPolaznika" alignment="center"></dxi-column>
    <dxi-column dataField="uci_obrazovnaGodina" [visible]="false"></dxi-column>
    <dxi-column dataField="uci_createdBy" [visible]="false"></dxi-column>
    
    <dxi-column type="buttons" [width]="110"><dxi-button template="buttonActiveTemplate"></dxi-button></dxi-column>
    <div *dxTemplate="let buttonInfo of 'buttonActiveTemplate'">
        <div>
            <dx-button id="aktivna" *ngIf="buttonInfo.data.uci_aktivna == 1">Aktivna</dx-button>
            <dx-button id="neaktivna" *ngIf="buttonInfo.data.uci_aktivna == 0">Završena</dx-button>
        </div>
    </div>
    <dxi-column type="buttons" [width]="110"><dxi-button template="buttonTemplate"></dxi-button></dxi-column>
    <div *dxTemplate="let buttonInfo of 'buttonTemplate'">
            <dx-button icon="edit" id="edit" (click)="urediUcionicu(buttonInfo)" *ngIf="buttonInfo.data.uci_aktivna == 1"></dx-button>
    </div>
    </dx-data-grid>
</div>