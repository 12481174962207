import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ApiService } from 'src/app/shared/services';

import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-kvizovi',
  templateUrl: './kvizovi.component.html',
  styleUrls: ['./kvizovi.component.scss']
})

export class KvizoviComponent implements OnInit {

  access_token: any = localStorage.getItem('access_token');

  datotekeURL = this._api.datotekeURL;
  kvizovi: any;
  ucionicaID: any;
  predmetID: any;
  kvizPopup: boolean = false;
  nazivPredmeta: any;
  window: any;
  pitanjaZaKviz: any;
  ukupnoPitanja: number;
  pitanjeKviz: any;
  pitanjeSlika: boolean = false;
  slika: any;
  prikaziZavrsniGumb: any;
  odgovoriKviz: any;
  ponudjeniOdgovori: any;
  prviBroj: number = 1;
  index: number = 0;
  vrstaKviza: any;
  tocniOdgovori = [];
  naslov: any;
  kvizID: any;
  kvizZavrsenPopup: boolean = false;
  porukaOKvizu: any;
  odgovoriZaProvjeru = [];
  boja: any;
  pitanjeID: any;
  tooltip = false;
  porukaOTocnosti: string;
  isChecked = false;
  slovo = ['A)', 'B)', 'C)', 'D)'];

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _api: ApiService
  ) {
    this.ucionicaID = this._activatedRoute.snapshot.params['id'];
    this.predmetID = this._activatedRoute.snapshot.params['idpredmet'];
    this.vrstaKviza = +this._activatedRoute.snapshot.params['vrsta'];

    if(this.vrstaKviza === 0){
      this.naslov = 'Kvizovi za vježbu';
    } else {
      this.naslov = 'Završni kvizovi';
    }
    
   }

   notifikacija = () => {
    notify('Pažnja: Neka pitanja mogu imati višestruke odgovore.', 'info', 1000);
   }

  ngOnInit() {
     this.popisKvizova();
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
    location: 'before',
    template: 'naslovTablice',
    })
  }

  toggleDefault() {
    this.tooltip = !this.tooltip;
  }

  hover(e){
    if (e.rowType === "data" && e.column.dataField === "nazivKviza" ){
      if (e.eventType == 'mouseover'){
        e.cellElement.style.fontWeight = "bold";
        e.cellElement.style.fontSize = "18px";
        e.cellElement.style.cursor = 'pointer';
      } else {
        e.cellElement.style.fontWeight = "normal";
        e.cellElement.style.fontSize = "14px";
      }
    }
  }
  
  cellClickEvent(e){
    // console.log(e.data);
    if (e.rowType == "data" && e.column.dataField === "nazivKviza") {
      this.kvizID = e.data.idkviz;
      this.nazivPredmeta = e.data.nazivKviza;
      //// info
      this._api.kvizJedan({"access_token": this.access_token, "idkviz": e.data.idkviz}).subscribe(res => {
        // console.log(res);
        if(res.success === true){
          this.pitanjaZaKviz = res.pitanja;
          this.odgovoriKviz = res.odgovori;
          this.ukupnoPitanja = res.pitanja.length;
        } else {
          notify(res.message, 'error', 1000);
        }
        if (e.data.postotakRjesenosti === null){
            if(e.data.brojPitanja > 0){
              this.prvoPitanje();
            } else {
              notify ('Ovaj kviz nema pitanja!', 'error', 1000);
            }
        } else {
          notify ('Ovaj kviz ste već riješili!', 'info', 1000);
        }
      })
    }
  }

 prvoPitanje(){
  this.porukaOTocnosti = null;
  this.tocniOdgovori = [];
  this.ponudjeniOdgovori = [];
    this.index = 0;
    this.prviBroj = 1;
    this.kvizPopup = true;
    // console.log(this.ukupnoPitanja);
    // console.log(this.prviBroj);
    this.prikaziZavrsniGumb = false;
    if(this.prviBroj === this.ukupnoPitanja){
       this.prikaziZavrsniGumb = true;
    }

    // for(let i = 0; i < this.pitanjaZaKviz.length; i++){
      if(this.pitanjaZaKviz[0].kpit_vrsta === 0){
        this.pitanjeSlika = false;
        this.pitanjeKviz = this.pitanjaZaKviz[0].kpit_naziv;
        // this.pitanjeID = this.pitanjaZaKviz[0].idkvizpitanje;
        this.ponudjeniOdgovori = [];
        /**----------- odgovori ako nema slike ---------------- */
            for(let j = 0; j < this.odgovoriKviz.length; j++){
              if(this.odgovoriKviz[j].kvizpitanje_idkvizpitanje === this.pitanjaZaKviz[0].idkvizpitanje)
                this.ponudjeniOdgovori.push(this.odgovoriKviz[j]);
              }
          // console.log(this.pitanjeKviz);

      } else if (this.pitanjaZaKviz[0].kpit_vrsta === 1){
          this.pitanjeSlika = true;
          this.pitanjeKviz = this.pitanjaZaKviz[0].kpit_naziv;
          // this.pitanjeID = this.pitanjaZaKviz[0].idkvizpitanje;
          this.slika = this.pitanjaZaKviz[0].iduploaddokument;
       
         /**----------- odgovori ako ima slike ---------------- */
            for(let j = 0; j < this.odgovoriKviz.length; j++){
              if(this.odgovoriKviz[j].kvizpitanje_idkvizpitanje === this.pitanjaZaKviz[0].idkvizpitanje)
              this.ponudjeniOdgovori.push(this.odgovoriKviz[j]);
             }
      } else {
        this.kvizPopup = false;
        notify('Dogodila se pogreška', 'error');
      }
    // }
  }

  sljedecePitanje(){
    this.odgovoriZaProvjeru = [];
    this.ponudjeniOdgovori = [];
    this.prviBroj++;
    this.index++; 
  if(this.ukupnoPitanja < this.prviBroj){
    this.kvizPopup = false;
   }
  else { 
  if(this.pitanjaZaKviz[this.index].kpit_vrsta === 0 && (this.prviBroj <= this.ukupnoPitanja)){
        this.pitanjeSlika = false;
        this.pitanjeKviz = this.pitanjaZaKviz[this.index].kpit_naziv;
        // this.pitanjeID = this.pitanjaZaKviz[this.index].idkvizpitanje;
        /**----------- odgovori ako nema slike ---------------- */
        for(let j = 0; j < this.odgovoriKviz.length; j++){
          if(this.odgovoriKviz[j].kvizpitanje_idkvizpitanje === this.pitanjaZaKviz[this.index].idkvizpitanje){
              this.ponudjeniOdgovori.push(this.odgovoriKviz[j]);
           }
          }
          this.prviBroj = this.prviBroj;
          if (this.ukupnoPitanja === this.prviBroj){
              this.prikaziZavrsniGumb = true;
          }

  } else if (this.pitanjaZaKviz[this.index].kpit_vrsta === 1  && this.prviBroj <= this.ukupnoPitanja){
             this.pitanjeSlika = true;
             this.pitanjeKviz = this.pitanjaZaKviz[this.index].kpit_naziv;
            //  this.pitanjeID = this.pitanjaZaKviz[this.index].idkvizpitanje;
             this.slika = this.pitanjaZaKviz[this.index].iduploaddokument;

        /**----------- odgovori ako ima slike---------------- */
            for(let j = 0; j < this.odgovoriKviz.length; j++){
              if(this.odgovoriKviz[j].kvizpitanje_idkvizpitanje === this.pitanjaZaKviz[this.index].idkvizpitanje){
                 this.ponudjeniOdgovori.push(this.odgovoriKviz[j]);
              }
          }
            this.prviBroj = this.prviBroj;
          if (this.ukupnoPitanja === this.prviBroj){
            this.prikaziZavrsniGumb = true;
          }
          //  console.log(this.ponudjeniOdgovori) 
  }  else {
        this.kvizPopup = false;
        notify('Nema pitanja u kvizu', 'error');
      }
    }
  }

  checboxClicked(e, data){
    // console.log(e);
    if(e.target.checked == true){
    // console.log(data);
    this.tocniOdgovori.push(data.idkvizodgovor);
    this.odgovoriZaProvjeru.push(data.idkvizodgovor);
    this.pitanjeID = data.kvizpitanje_idkvizpitanje;
    } else {
      let index = this.tocniOdgovori.indexOf(data.idkvizodgovor);
      if (index > -1) {
        this.tocniOdgovori.splice(index, 1);
      }
      let i = this.odgovoriZaProvjeru.indexOf(data.idkvizodgovor);
      if (i > -1) {
        this.odgovoriZaProvjeru.splice(i, 1);
      }
    }
    // console.log(this.odgovoriZaProvjeru);
  }

  prethodniOdgovor(){
    this._api.kvizPitanjeTocan({"access_token": this.access_token, "idpitanje": this.pitanjeID, "idodgovori": this.tocniOdgovori}).subscribe(res => {
      // console.log(res)
      if(res.success === true){
        this.boja = 'green';
        this.porukaOTocnosti = res.message;
      } else {
        this.boja = 'red';
        this.porukaOTocnosti = res.message;
      }
    });
  }

  next(){
    // console.log(this.odgovoriZaProvjeru.length)
    if(this.odgovoriZaProvjeru.length > 0){
      this.isChecked = true;
          this.sljedecePitanje();
          this.prethodniOdgovor();
    } else {
      this.isChecked = false;
      notify('Potrebno je označiti barem jedan odgovor.', 'error', 1000);
    }
  //  console.log(this.isChecked);
  }

  posaljiOdgovore(){
    var podaci = {
      "access_token": this.access_token,
      "odgovori": this.tocniOdgovori,
      "idkviz": this.kvizID,
      "idpredmet": +this.predmetID,
      "iducionica": +this.ucionicaID,
      "idkorisnik": +localStorage.getItem('idkorisnik')
    };
    if(this.odgovoriZaProvjeru.length > 0){
      this.isChecked = true;
        if(this.vrstaKviza === 1){
          this._api.kvizRjesi(podaci).subscribe(res => {
            // console.log(res)
            if(res.success === true){
              this.kvizZavrsenPopup = true;
              this.porukaOKvizu = res.message;
              this.kvizPopup = false;
              this.popisKvizova();
              notify('Kviz završen', 'success');
            }
          })
        } else {
          notify('Kviz završen', 'success');
          this.kvizPopup = false;
        }
    } else {
      this.isChecked = false;
      // this.kvizPopup = false;
      notify('Potrebno je označiti barem jedan odgovor.', 'error', 1000);
    }

    // console.log(this.odgovoriZaProvjeru.length)

    ///////////////////////////////
    // this.kvizPopup = false;
    // notify('Kviz završen', 'success');

  /** ------------ pošalji odgovore samo ako je riječ o završnom kvizu ------------------- */
    // if(this.vrstaKviza === 1){
    //     this._api.kvizRjesi(podaci).subscribe(res => {
    //       console.log(res)
    //       if(res.success === true){
    //         this.kvizZavrsenPopup = true;
    //         this.porukaOKvizu = res.message;
    //         this.kvizPopup = false;
    //         this.popisKvizova();
    //       }
    //     })
    //   }
  }


  // onClick: () => {
  //   alert('Pažnja: Neka pitanja mogu imati višestruke odgovore.')
  // }

  slikaPrikazi(slika){
    var prikaz = this.datotekeURL + slika + "&access_token=" + this.access_token;
    window.open(prikaz, "_blank");
  }

  popisKvizova(){
    this._api.kvizPopisZaKorisnika({"access_token": this.access_token, "idpredmet": this.predmetID, "idkorisnik": +localStorage.getItem('idkorisnik')}).subscribe(res => {
      // console.log(res);
      this.kvizovi = res.kvizovi;
    })
  }

}