import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';

import { ApiService } from './../../../shared/services/api.service';
import { IdPredmetService } from 'src/app/shared/services/id-predmet.service';

import notify from 'devextreme/ui/notify';
import { custom } from 'devextreme/ui/dialog';
import { encodeHtml } from 'devextreme/core/utils/string';
import { DxFileUploaderComponent } from 'devextreme-angular/ui/file-uploader';
import validationEngine from "devextreme/ui/validation_engine";

@Component({
  selector: 'app-ispit-predmet',
  templateUrl: './ispit-predmet.component.html',
  styleUrls: ['./ispit-predmet.component.scss']
})
export class IspitPredmetComponent implements OnInit, OnDestroy {

  access_token: any = localStorage.getItem('access_token');
  idKorisnik: any = +localStorage.getItem('idkorisnik');

  @ViewChild('notesUploader') uploader: DxFileUploaderComponent;
  datotekeURL = this._api.datotekeURL;
  uploadURL = this._api.uploadURL;
  window: any;
  predmetID: any;
  ispiti = [];
  pitanja = [];
  odgovori = [];
  naslov: string;
  subscription: any;
  dodajPitanjePopup: boolean = false;
  urediPitanjePopup: boolean = false;
  prikaziPitanjePopup: boolean = false;
  dodajOdgovorPopup: boolean = false;
  dodajOdgovorPopupTN: boolean = false;
  value: any[] = [];
  sadrzajPitanja: string;
  idUploadDokument: number = null;
  pitanjeVrsta: number;
  idIspit: number;
  idIspitPitanje: number;
  idIspitOdgovor: number;
  pitanjeZaSlanje: any;
  // idPitanja: number;
  pitanjeZaEdit: any;
  oblikPitanja: number;
  pitanjeImaDokument: boolean = false;
  idUploadDokumentPitanjePrikaz: number;
  pitanjeNazivEdit: string;
  odabraniOdgovor: number;
  checkBoxTN: boolean = false;
  odgovorTN: string;
  urediOdgovorPopup: boolean = false;
  urediOdgovorPopupTN: boolean = false;
  prikaziOdgovorPopup: boolean = false;
  prikaziOdgovorPopupTN: boolean = false;
  jedanOdgovorTN = [];
  jedanOdgovorABCD = [];
  imaOdgovora: boolean = false;
  tocanIlinetocan: boolean = false;
  vrstaOdgovora: number = null;
  kreiranoPitanje: boolean = false;
  checkBoxABCD: boolean = false;
  odgovorABCD: string;
  idUploadDokumentOdgovorPrikaz: number;
  odgovorPrikaz: string;
  odgovoriTabsABCD = [
    { id: 0, text: 'A', disabled: false },
    { id: 1, text: 'B', disabled: true },
    { id: 2, text: 'C', disabled: true },
    { id: 3, text: 'D', disabled: true }
  ];
  odgovoriTabsABCDprikaz = [
    { id: 0, text: 'A' },
    { id: 1, text: 'B' },
    { id: 2, text: 'C' },
    { id: 3, text: 'D' }
  ];
  odgovoriTabsTocnoNetocno = [
    { id: 0, text: 'A', disabled: false },
    { id: 1, text: 'B', disabled: true }
  ];
  odgovoriTabsTocnoNetocnoPrikaz = [
    { id: 0, text: 'A' },
    { id: 1, text: 'B' }
  ];
  vrstaPitanja = [
    {"id": 0, "naziv": "Kviz"}, 
    {"id": 1, "naziv": "Točno/netočno"},
    {"id": 2, "naziv": "Otvoreno pitanje/esej"},
    {"id": 3, "naziv": "Nadopunjavanje"},
    {"id": 4, "naziv": "Upload datoteke"}
  ];

  constructor(
    public _api: ApiService,
    private _idPredmetService: IdPredmetService
  ) { this.onShowing = this.onShowing.bind(this); }

  ngOnInit() {
    //ID učionica
    this.subscription = this._idPredmetService.currentMessage.subscribe((message) => {
      if(message){
        this.predmetID = +message;
        this.dohvatiPopisIspita();
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onShowing(e){
    e.component.option("title", this.naslov);
  }

  // ISPIT

  onToolbarPreparing(e) { 
    e.toolbarOptions.items.unshift(
      {
        locateInMenu:'auto',
        location: 'after',
        template: "toolbarCustom",
      }
    )
  }

  onRowExpanding(e){
    this.idIspit = e.key.idispit;
    e.component.collapseAll(-1);
  }

  dohvatiPopisIspita(){
    this._api.ispitPopis({"idpredmet": this.predmetID, "access_token": this.access_token}).subscribe(res=>{
      if(res.success == true){
        this.ispiti = res.ispiti;
      } else {
        notify(res.message, "error");
      }
    });
  }

  onInitNewRowIspit(){
    this.naslov = 'Dodaj ispit';
  }

  onRowInsertedIspit(e){
    let ispitZaSlanje = {
      "naziv": e.data['isp_naziv'],
      "kreirao": +localStorage.getItem('idkorisnik'),
      "idpredmet": +this.predmetID,
      "access_token": this.access_token
    };
    this._api.ispitDodaj(ispitZaSlanje).subscribe(res=>{
      if(res.success == true){
        this.dohvatiPopisIspita();
        notify(res.message, "success");
      } else {
        notify(res.message, "error");
      }
    });
  }

  onEditingStartIspit(){
    this.naslov = 'Uredi ispit';
  }

  onRowUpdatedIspit(e){
    let ispitZaEdit = {
      "idispit": e.data['idispit'],
      "uredio": +localStorage.getItem('idkorisnik'),
      "naziv": e.data['isp_naziv'],
      "access_token": this.access_token
    };
    this._api.ispitUredi(ispitZaEdit).subscribe(res=>{
      if(res.success == true){
        this.dohvatiPopisIspita();
        notify(res.message, "success");
      } else {
        notify(res.message, "error");
      }
    });
  }

  deAktivacija(e){
    if (e.rowType == "data" && e.column.dataField === "isp_aktivan") {
        this._api.ispitDeAktivacija({"idispit":e.data.idispit, "access_token": this.access_token}).subscribe(res=>{
          if(res.success == true){
            notify(res.message, "success");
            this.dohvatiPopisIspita();
            } else {
            notify(res.message, "error");
          }
        });
    }
  }

  // PITANJA

  onInitNewRowPitanje(e){
    this.dodajPitanjePopup = true;
  }

  popupHiddenPitanje(){
    this.dodajPitanjePopup = false;
    validationEngine.resetGroup('pitanjePopup');
  }

  onUploadedPitanje(e){
    let file = e.file;
    let formData:FormData = new FormData();
    formData.append('datoteka', file, file.name);
    formData.append('predmet', this.predmetID);
    formData.append('iddokument', this.sadrzajPitanja);
    formData.append('naziv', "-" || file.name);
    formData.append('idkorisnik', this.idKorisnik);
    formData.append('nazivtablice', 'materijali');
    formData.append('access_token', this.access_token);
    // this.kreiranoPitanje = true;
    this._api.uploadDatotekaKviz(formData).subscribe(res=>{
      if(res.success == true){
        if(res.iduploaddokument){
          this.idUploadDokument = res.iduploaddokument;
        }
      } else {
        notify(res.message, "error");
      }
    });
  }

  brisanjePitanja(e){
    let encodedMessage = encodeHtml("Jeste li sigurni da želite obrisati pitanje?");
      let myDialog = custom({
          title: "Brisanje pitanja",
          messageHtml: encodedMessage,
          buttons: [{
              text: "OBRIŠI",
              onClick: (e) => {
                  return { buttonText: e.component.option("text") }
              }
          },{
            text: "ODUSTANI",
            onClick: (e) => {
                return { buttonText: e.component.option("text") }
            }
        }]
      });
      myDialog.show().then((dialogResult) => {
          if(dialogResult.buttonText === 'OBRIŠI'){
            this._api.ispitPitanjeObrisi({"idispitpitanje":e.data.idispitpitanje, "access_token": this.access_token}).subscribe(res=>{
              if(res.success == true){
                notify(res.message, "success");
                this.dohvatiPopisIspita();
              } else {
                notify(res.message, "error");
              }
            });      
          }
      });
  }

  urediPitanje(e){
    if(this.pitanjeZaEdit.uploaddokument_iduploaddokument){
      let podaciEditSlanje = {
        "naziv": this.pitanjeNazivEdit,
        "vrsta": 1,
        "tekst": this.pitanjeNazivEdit,
        "idispitpitanje": this.idIspitPitanje,
        "iduploaddokument": this.idUploadDokument || this.idUploadDokumentPitanjePrikaz,
        "oblik": this.oblikPitanja,
        "access_token": this.access_token
      };
      this._api.ispitPitanjeUredi(podaciEditSlanje).subscribe(res=>{
        if(res.success == true){
          notify(res.message, "success");
          this.urediPitanjePopup = false;
          this.uploader.instance.reset();
          this.prikazPitanjaOdgovoraJednogIspitaNakonEdita(this.idIspit);
        } else {
          notify(res.message, "error");
        }
      });
    } else {
      let podaciEditSlanje = {
        "naziv": this.pitanjeNazivEdit,
        "vrsta": 0,
        "tekst": this.pitanjeNazivEdit,
        "idispitpitanje": this.idIspitPitanje,
        "oblik": this.oblikPitanja,
        "access_token": this.access_token
      };
      this._api.ispitPitanjeUredi(podaciEditSlanje).subscribe(res=>{
        if(res.success == true){
          notify(res.message, "success");
          this.urediPitanjePopup = false;
          this.prikazPitanjaOdgovoraJednogIspitaNakonEdita(this.idIspit);
        } else {
          notify(res.message, "error");
        }
      });
    } 
  }

  pitanjeEdit_fieldDataChanged(e){
    this.pitanjeNazivEdit = e.value;
  }

  pitanjeEditPopup(e){
    this.pitanjeZaEdit = e.data;
    this.pitanjeNazivEdit = e.data.ispp_tekst;
    this.oblikPitanja = e.data.ispp_oblik;
    this.idIspitPitanje = e.data.idispitpitanje;
    this.idUploadDokumentPitanjePrikaz = e.data.uploaddokument_iduploaddokument;
    // this.idPitanja = e.data.idispitpitanje;
    if(this.pitanjeZaEdit.uploaddokument_iduploaddokument){
      this.pitanjeImaDokument = true;
    } else {
      this.pitanjeImaDokument = false;
    }
    this.urediPitanjePopup = true;
  }

  popupHiddenPitanjeEdit(){
    this.pitanjeImaDokument = null;
    this.pitanjeNazivEdit = null;
    this.idUploadDokumentPitanjePrikaz = null;
    validationEngine.resetGroup('pitanjePopupEdit');
  }

  //PREGLEDAVANJE DETALJA ZAKLJUČANOG PITANJA

  pitanjePregledPopup(e){
    this.pitanjeZaEdit = e.data;
    this.pitanjeNazivEdit = e.data.ispp_tekst;
    this.oblikPitanja = e.data.ispp_oblik;
    this.idIspitPitanje = e.data.idispitpitanje;
    this.idUploadDokumentPitanjePrikaz = e.data.uploaddokument_iduploaddokument;
    // this.idPitanja = e.data.idispitpitanje;
    if(this.pitanjeZaEdit.uploaddokument_iduploaddokument){
      this.pitanjeImaDokument = true;
    } else {
      this.pitanjeImaDokument = false;
    }
    this.prikaziPitanjePopup = true;
  }

  prikaziOdgovorTN = (e) => {
    // this.prikaziPitanjePopup = false;
    this.prikaziOdgovorPopupTN = true;
    this.jedanOdgovorTN = [];
    for(let i = 0; i < this.odgovori.length; i++){
      if(this.odgovori[i].ispitpitanje_idispitpitanje == this.idIspitPitanje){
        if(this.odgovori[i].ispitpitanje_idispitpitanje){
          this.imaOdgovora = true;
          this.odabraniOdgovor = 0;
          this.vrstaOdgovora = this.odgovori[0].ispo_vrsta;
          this.jedanOdgovorTN.push(this.odgovori[i]);
          // break;
        } else {
          this.imaOdgovora = false;
        }
      } 
    }
  }

  prikaziOdgovorABCD = () => {
    // this.prikaziPitanjePopup = false;
    this.prikaziOdgovorPopup = true;
    this.jedanOdgovorABCD = [];
    for(let i = 0; i < this.odgovori.length; i++){
      if(this.odgovori[i].ispitpitanje_idispitpitanje == this.idIspitPitanje){
        if(this.odgovori[i].ispitpitanje_idispitpitanje){
          this.imaOdgovora = true;
          this.jedanOdgovorABCD.push(this.odgovori[i]);
            //Inicijalno učitavanje odgovora prije nego se počne klikati po tabovima
            this.idUploadDokumentOdgovorPrikaz = this.jedanOdgovorABCD[0].uploaddokument_iduploaddokument;
            this.odgovorPrikaz = this.jedanOdgovorABCD[0].ispo_tekst;
            this.tocanIlinetocan = (this.jedanOdgovorABCD[0].ispo_tocan == "1" ? true : false);
            this.idIspitOdgovor = this.jedanOdgovorABCD[0].idispitodgovor;
            this.vrstaOdgovora = this.jedanOdgovorABCD[0].ispo_vrsta;
        } else {
          this.imaOdgovora = false;
        }
      }
    }
  }

  // TOČNO - NETOČNO

  odgovoriTabOdabirTN(e){
    this.odabraniOdgovor = e.itemData['id']; 
    if(this.odabraniOdgovor == 0){
      if(this.jedanOdgovorTN[0]){
        this.imaOdgovora = true;
        this.tocanIlinetocan = (this.odgovori[0].ispo_tocan == 1 ? true : false);
        this.idIspitOdgovor = this.odgovori[0].idispitodgovor;
        this.sadrzajPitanja = this.odgovori[0].ispo_tekst;
      } else {
        notify("Nema odgovora A");
        this.imaOdgovora = false;
      }
    } else if (this.odabraniOdgovor == 1){
      if(this.jedanOdgovorTN[1]){
        this.imaOdgovora = true;
        this.tocanIlinetocan = (this.odgovori[1].ispo_tocan == 1 ? true : false);
        this.idIspitOdgovor = this.odgovori[1].idispitodgovor;
        this.sadrzajPitanja = this.odgovori[1].ispo_tekst;
      } else {
        notify("Nema odgovora B");
        this.imaOdgovora = false;
      }
    }
  }

  spremiOdgovorTN(e){
    let tocnoNetocnoOdgovor = {
      "vrsta": 0,
      "tekst": this.odgovorTN,
      "tocan": this.checkBoxTN,
      "idispit": this.idIspit,
      "idispitpitanje": this.idIspitPitanje,
      "access_token": this.access_token
    };
    this._api.ispitOdgovorDodaj(tocnoNetocnoOdgovor).subscribe(res=>{
      if(res.success == true){
        this.odabraniOdgovor = this.odabraniOdgovor+1;
        if(this.odabraniOdgovor == 1){
          this.odgovoriTabsTocnoNetocno = [
            { id: 0, text: 'A', disabled: true },
            { id: 1, text: 'B', disabled: false }
          ]
        } else if (this.odabraniOdgovor == 2){
          this.odgovoriTabsTocnoNetocno = [
            { id: 0, text: 'A', disabled: false },
            { id: 1, text: 'B', disabled: true }
          ]
          this.dodajOdgovorPopupTN = false;
          this.odabraniOdgovor = 0;
          this.dohvatiPopisIspita();
        }
          notify(res.message, "success");
          this.checkBoxTN = false;
      } else {
          notify(res.message, "error");
      }
    });
  }

  odgovorTN_fieldDataChanged(e){
    this.odgovorTN = e.value;
  }

  prikaziOdgovorTNzaEdit = (e) => {
    this.urediPitanjePopup = false;
    this.urediOdgovorPopupTN = true;
    this.jedanOdgovorTN = [];
    for(let i = 0; i < this.odgovori.length; i++){
      if(this.odgovori[i].ispitpitanje_idispitpitanje == this.idIspitPitanje){
        if(this.odgovori[i].ispitpitanje_idispitpitanje){
          this.imaOdgovora = true;
          this.odabraniOdgovor = 0;
          this.vrstaOdgovora = this.odgovori[0].ispo_vrsta;
          this.jedanOdgovorTN.push(this.odgovori[i]);
          // break;
        } else {
          this.imaOdgovora = false;
        }
      } 
    }
  }

  urediOdgovorTN(e){
    let podaciZaEdit = {
      "vrsta": 0,
      "tekst": this.odgovorTN,
      "tocan": (this.tocanIlinetocan == true ? 1 : 0),
      "idispitodgovor": this.idIspitOdgovor,
      "access_token": this.access_token
    };
    this._api.ispitOdgovorUredi(podaciZaEdit).subscribe(res=>{
      if(res.success == true){
        notify(res.message, "success");
      } else {
        notify(res.message, "error");
      }
    });
  }

  popupHiddenOdgovorUrediTN(){
    this.odabraniOdgovor = 0;
    // this.vrstaOdgovora = null;
  }

  popupHiddenOdgovorTN(){
    this.dodajOdgovorPopupTN = false;
    this.odabraniOdgovor = 0;
  }

  odgovorTNUredi_fieldDataChanged(e){
    this.odgovorTN = e.value;
  }

  // KVIZ

  prikazPitanjaOdgovoraJednogIspita(e){
    this.odgovori = [];
    this._api.ispitJedan({"idispit": e.data.idispit, "access_token": this.access_token}).subscribe(res=>{
      // console.log(res);
      if(res.success == true){
        this.pitanja = res.pitanja;
        this.odgovori = res.odgovori;
      } else {
        notify(res.message, "error");
      }
    });
  }

  prikazPitanjaOdgovoraJednogIspitaNakonEdita(idispit){
    this._api.ispitJedan({"idispit": idispit, "access_token": this.access_token}).subscribe(res=>{
      if(res.success == true){
        this.pitanja = res.pitanja;
      } else {
        notify(res.message, "error");
      }
    });
  }

  pitanje_fieldDataChanged(e){
    this.sadrzajPitanja = e.value;
  }

  onValueChanged(e){
    this.pitanjeVrsta = e.value;
  }

  kreirajPitanje($event){
    if(this.idUploadDokument){
      this.pitanjeZaSlanje = {
        "vrsta": 1,
        "tekst": this.sadrzajPitanja,
        "idispit": this.idIspit,
        "iduploaddokument": this.idUploadDokument,
        "oblik": this.pitanjeVrsta, /*0-kviz,1-točno/netočno,2-esej,3-nadopunjavanje,4-upload */
        "access_token": this.access_token
      };
      this._api.ispitPitanjeDodaj(this.pitanjeZaSlanje).subscribe(res=>{
        if(res.success == true){
          this.kreiranoPitanje = true;
          notify(res.message, "success");
          if(this.pitanjeVrsta == 2 || this.pitanjeVrsta == 3 || this.pitanjeVrsta == 4){
            this.dodajPitanjePopup = false;
          } else if (this.pitanjeVrsta == 0) {
            // Kviz odgovor dodaj
            this.idIspitPitanje = res.idpitanja;
            this.dodajPitanjePopup = false;
            this.dodajOdgovorPopup = true;
          } else {
            // TN odgovor dodaj
            this.idIspitPitanje = res.idpitanja;
            this.dodajPitanjePopup = false;
            this.dodajOdgovorPopupTN = true;
          }
          this.idUploadDokument = null;
          this.uploader.instance.reset();
          validationEngine.resetGroup('pitanjePopup');
          this.prikazPitanjaOdgovoraJednogIspitaNakonEdita(this.idIspit);
        } else {
          this.kreiranoPitanje = false;
          notify(res.message, "error");
        }
      });
    } else {
      //Tekstualno pitanje
      this.pitanjeZaSlanje = {
        "vrsta": 0,
        "tekst": this.sadrzajPitanja,
        "idispit": this.idIspit,
        "oblik": this.pitanjeVrsta, /*0-kviz,1-točno/netočno,2-esej,3-nadopunjavanje,4-upload */
        "access_token": this.access_token
      };
      this._api.ispitPitanjeDodaj(this.pitanjeZaSlanje).subscribe(res=>{
        if(res.success == true){
          this.kreiranoPitanje = true;
          notify(res.message, "success");
          if(this.pitanjeVrsta == 2 || this.pitanjeVrsta == 3 || this.pitanjeVrsta == 4){
            this.dodajPitanjePopup = false;
          } else if (this.pitanjeVrsta == 0) {
            // Kviz odgovor dodaj
            this.idIspitPitanje = res.idpitanja;
            this.dodajPitanjePopup = false;
            this.dodajOdgovorPopup = true;
          } else {
            // TN odgovor dodaj
            this.idIspitPitanje = res.idpitanja;
            this.dodajPitanjePopup = false;
            this.dodajOdgovorPopupTN = true;
          }
          validationEngine.resetGroup('pitanjePopup');
          this.prikazPitanjaOdgovoraJednogIspitaNakonEdita(this.idIspit);
        } else {
          this.kreiranoPitanje = false;
          notify(res.message, "error");
        }
      });
    }
  }

  ispitOdgovorUrediGrafickiABCD(e){
    let podaciZaEdit = {
      "vrsta": 1,
      "tekst": "", /* this.odgovorPrikaz ne postoji za vrsta 1*/
      "tocan": (this.tocanIlinetocan == true ? 1 : 0),
      "idispitodgovor": this.idIspitOdgovor,
      "iduploaddokument": this.idUploadDokumentOdgovorPrikaz,
      "access_token": this.access_token
    };
    this._api.ispitOdgovorUredi(podaciZaEdit).subscribe(res=>{
      if(res.success == true){
        notify(res.message, "success");
        this.uploader.instance.reset();
      } else {
        notify(res.message, "error");
      }
    });
  }

  odabirVrsteOdgovora(vrsta){
    if(vrsta == 'tekst'){
      this.vrstaOdgovora = 0;
    } else {
      this.vrstaOdgovora = 1;
    }
  }

  odgovoriTabOdabirABCD(e){
    this.odabraniOdgovor = e.itemData['id']; 
    if(this.odabraniOdgovor == 0){
      if(this.jedanOdgovorABCD[0]){
        this.imaOdgovora = true;
        this.idUploadDokumentOdgovorPrikaz = this.jedanOdgovorABCD[0].uploaddokument_iduploaddokument;
        this.odgovorPrikaz = this.jedanOdgovorABCD[0].ispo_tekst;
        this.tocanIlinetocan = (this.jedanOdgovorABCD[0].ispo_tocan == "1" ? true : false);
        this.idIspitOdgovor = this.jedanOdgovorABCD[0].idispitodgovor;
        this.vrstaOdgovora = this.jedanOdgovorABCD[0].ispo_vrsta;
      } else {
        notify("Nema odgovora A");
        this.imaOdgovora = false;
        this.idUploadDokumentOdgovorPrikaz = null;
        this.odgovorPrikaz = null;
        this.tocanIlinetocan = null;
      }
    } else if (this.odabraniOdgovor == 1){
      if(this.jedanOdgovorABCD[1]){
        this.imaOdgovora = true;
        this.idUploadDokumentOdgovorPrikaz = this.jedanOdgovorABCD[1].uploaddokument_iduploaddokument;
        this.odgovorPrikaz = this.jedanOdgovorABCD[1].ispo_tekst;
        this.tocanIlinetocan = (this.jedanOdgovorABCD[1].ispo_tocan == "1" ? true : false);
        this.idIspitOdgovor = this.jedanOdgovorABCD[1].idispitodgovor;
        this.vrstaOdgovora = this.jedanOdgovorABCD[1].ispo_vrsta;
      } else {
        notify("Nema odgovora B");
        this.imaOdgovora = false;
        this.idUploadDokumentOdgovorPrikaz = null;
        this.odgovorPrikaz = null;
        this.tocanIlinetocan = null;
      }
    } else if (this.odabraniOdgovor == 2){
      if(this.jedanOdgovorABCD[2]){
        this.imaOdgovora = true;
        this.idUploadDokumentOdgovorPrikaz = this.jedanOdgovorABCD[2].uploaddokument_iduploaddokument;
        this.odgovorPrikaz = this.jedanOdgovorABCD[2].ispo_tekst;
        this.tocanIlinetocan = (this.jedanOdgovorABCD[2].ispo_tocan == "1" ? true : false);
        this.idIspitOdgovor = this.jedanOdgovorABCD[2].idispitodgovor;
        this.vrstaOdgovora = this.jedanOdgovorABCD[2].ispo_vrsta;
      } else {
        notify("Nema odgovora C");
        this.imaOdgovora = false;
        this.idUploadDokumentOdgovorPrikaz = null;
        this.odgovorPrikaz = null;
        this.tocanIlinetocan = null;
      }
    } else if (this.odabraniOdgovor == 3){
      if(this.jedanOdgovorABCD[3]){
        this.imaOdgovora = true;
        this.idUploadDokumentOdgovorPrikaz = this.jedanOdgovorABCD[3].uploaddokument_iduploaddokument;
        this.odgovorPrikaz = this.jedanOdgovorABCD[3].ispo_tekst;
        this.tocanIlinetocan = (this.jedanOdgovorABCD[3].ispo_tocan == "1" ? true : false);
        this.idIspitOdgovor = this.jedanOdgovorABCD[3].idispitodgovor;
        this.vrstaOdgovora = this.jedanOdgovorABCD[3].ispo_vrsta;
      } else {
        notify("Nema odgovora D");
        this.imaOdgovora = false;
        this.idUploadDokumentOdgovorPrikaz = null;
        this.odgovorPrikaz = null;
        this.tocanIlinetocan = null;
      }
    }
  }

  odgovorABCD_fieldDataChanged(e){
    this.odgovorABCD = e.value;
  }

  spremiOdgovorTekstABCD(e){
    if(this.odgovorABCD){
      let odgovorZaSlanje = {
        "vrsta": 0,
        "tekst": this.odgovorABCD,
        "tocan": this.checkBoxABCD,
        "idispit": this.idIspit,
        "idispitpitanje": this.idIspitPitanje,
        "access_token": this.access_token
      };
      this._api.ispitOdgovorDodaj(odgovorZaSlanje).subscribe(res=>{
        if(res.success == true){
          this.odabraniOdgovor = this.odabraniOdgovor+1;
              if(this.odabraniOdgovor == 1){
                this.odgovoriTabsABCD = [
                  { id: 0, text: 'A', disabled: true },
                  { id: 1, text: 'B', disabled: false },
                  { id: 2, text: 'C', disabled: true },
                  { id: 3, text: 'D', disabled: true }
                ]
              } else if (this.odabraniOdgovor == 2){
                this.odgovoriTabsABCD = [
                  { id: 0, text: 'A', disabled: true },
                  { id: 1, text: 'B', disabled: true },
                  { id: 2, text: 'C', disabled: false },
                  { id: 3, text: 'D', disabled: true }
                ]
              } else if (this.odabraniOdgovor == 3){
                this.odgovoriTabsABCD = [
                  { id: 0, text: 'A', disabled: true },
                  { id: 1, text: 'B', disabled: true },
                  { id: 2, text: 'C', disabled: true },
                  { id: 3, text: 'D', disabled: false }
                ]
              } else {
                this.dodajOdgovorPopup = false;
                this.odabraniOdgovor = 0;
              }
          notify(res.message, "success");
          this.checkBoxABCD = false;
        } else {
          notify(res.message, "error");
        }
      });
    } else {
      notify("Unesite odgovor!", "error");
    }
  }

  ispitOdgovorUrediTekstABCD(e){
    let podaciZaEdit = {
      "vrsta": 0,
      "tekst": this.odgovorPrikaz,
      "tocan": (this.tocanIlinetocan == true ? 1 : 0),
      "idispitodgovor": this.idIspitOdgovor,
      "access_token": this.access_token
    };
    this._api.ispitOdgovorUredi(podaciZaEdit).subscribe(res=>{
      if(res.success == true){
        notify(res.message, "success");
      } else {
        notify(res.message, "error");
      }
    });
  }

  popupHiddenOdgovorUredi(){
    // console.log('popupHiddenOdgovorUredi');
    // this.vrstaOdgovora = null;
  }

  popupHiddenOdgovor(){
    this.dodajOdgovorPopup = false;
    // console.log('popupHiddenOdgovor');
  }

  odgovorUredi_fieldDataChanged(e){
    this.sadrzajPitanja = e.value;
    // this.vrstaOdgovora = null;
  }

  onUploadedOdgovor(e){
    let file = e.file;
    let formData:FormData = new FormData();
    formData.append('datoteka', file, file.name);
    formData.append('predmet', this.predmetID);
    formData.append('iddokument', this.odgovorABCD);
    formData.append('naziv', "-" || file.name);
    formData.append('idkorisnik', localStorage.getItem('idkorisnik'));
    formData.append('nazivtablice', 'materijali');
    formData.append('access_token', this.access_token);
    this._api.uploadDatotekaKviz(formData).subscribe(res=>{
      if(res.success == true){
        if(res.iduploaddokument){
          this.idUploadDokument = res.iduploaddokument;
        }
      } else {
        notify(res.message, "error");
      }
    });
  }

  spremiOdgovorSlikaABCD(e){
    let odgovorZaSlanje = {
      "vrsta": 1,
      "tekst": this.odgovorABCD,
      "tocan": this.checkBoxABCD,
      "idispit": this.idIspit,
      "idispitpitanje": this.idIspitPitanje,
      "iduploaddokument": this.idUploadDokument,
      "access_token": this.access_token
    };
    this._api.ispitOdgovorDodaj(odgovorZaSlanje).subscribe(res=>{
      if(res.success == true){
        this.uploader.instance.reset();
        this.odabraniOdgovor = this.odabraniOdgovor+1;
            if(this.odabraniOdgovor == 1){
              this.odgovoriTabsABCD = [
                { id: 0, text: 'A', disabled: true },
                { id: 1, text: 'B', disabled: false },
                { id: 2, text: 'C', disabled: true },
                { id: 3, text: 'D', disabled: true }
              ]
            } else if (this.odabraniOdgovor == 2){
              this.odgovoriTabsABCD = [
                { id: 0, text: 'A', disabled: true },
                { id: 1, text: 'B', disabled: true },
                { id: 2, text: 'C', disabled: false },
                { id: 3, text: 'D', disabled: true }
              ]
            } else if (this.odabraniOdgovor == 3){
              this.odgovoriTabsABCD = [
                { id: 0, text: 'A', disabled: true },
                { id: 1, text: 'B', disabled: true },
                { id: 2, text: 'C', disabled: true },
                { id: 3, text: 'D', disabled: false }
              ]
            } else {
              this.dodajOdgovorPopup = false;
            }
        notify(res.message, "success");
        this.checkBoxABCD = false;
      } else {
        notify(res.message, "error");
      }
    });
  }

  prikaziOdgovorABCDzaEdit = () => {
    // console.log('prikaziOdgovorABCDzaEdit');
    this.urediPitanjePopup = false;
    this.urediOdgovorPopup = true;
    this.jedanOdgovorABCD = [];
    for(let i = 0; i < this.odgovori.length; i++){
      if(this.odgovori[i].ispitpitanje_idispitpitanje == this.idIspitPitanje){
        if(this.odgovori[i].ispitpitanje_idispitpitanje){
          this.imaOdgovora = true;
          this.jedanOdgovorABCD.push(this.odgovori[i]);
            //Inicijalno učitavanje odgovora prije nego se počne klikati po tabovima
            this.idUploadDokumentOdgovorPrikaz = this.jedanOdgovorABCD[0].uploaddokument_iduploaddokument;
            this.odgovorPrikaz = this.jedanOdgovorABCD[0].ispo_tekst;
            this.tocanIlinetocan = (this.jedanOdgovorABCD[0].ispo_tocan == "1" ? true : false);
            this.idIspitOdgovor = this.jedanOdgovorABCD[0].idispitodgovor;
            this.vrstaOdgovora = this.jedanOdgovorABCD[0].ispo_vrsta;
        } else {
          this.imaOdgovora = false;
        }
      }
    }
  }

  odgovorEdit_fieldDataChanged(e){
    this.odgovorPrikaz = e.value;
  }

  onEditedOdgovor(e){
    let file = e.file;
    let formData:FormData = new FormData();
    formData.append('datoteka', file, file.name);
    formData.append('predmet', this.predmetID);
    formData.append('iddokument', file.name);
    formData.append('naziv', file.name);
    formData.append('idkorisnik', this.idKorisnik);
    formData.append('nazivtablice', 'materijali');
    formData.append('access_token', this.access_token);
    // this.kreiranoPitanje = true;
    this._api.uploadDatotekaKviz(formData).subscribe(res=>{
      if(res.success == true){
        if(res.iduploaddokument){
          this.idUploadDokumentOdgovorPrikaz = res.iduploaddokument;
          // this.uploader.instance.reset();
        }
      } else {
        notify(res.message, "error");
      }
    });
  }

}