<h2 class="content-block">Sektori</h2>

<div class="content-block dx-card responsive-paddings">

    <dx-data-grid
    class="wide-card"
    [hoverStateEnabled]="true"
    [wordWrapEnabled]="true"
    [dataSource]="dataSource"
    [rowAlternationEnabled]="false"
    [showRowLines]="false"
    [showColumnLines]="false"
    [showBorders]="false"
    [columnAutoWidth]="true"
    [columnHidingEnabled]="true"
    (onRowInserted)="onRowInserted($event)"
    (onRowUpdated)="onRowUpdated($event)"
    (onInitNewRow)="onInitNewRow($event)"
    (onEditingStart)="onEditingStart($event)"
    >

        <dxo-paging [pageSize]="10"></dxo-paging>
        <dxo-pager [showPageSizeSelector]="true" [showInfo]="true"></dxo-pager>
        <!-- <dxo-filter-row [visible]="true"></dxo-filter-row> -->
        <dxo-search-panel [visible]="true"></dxo-search-panel>

        <dxo-editing
        mode="popup"
        [allowUpdating]="true"
        [allowDeleting]="false"
        [allowAdding]="true">

        <dxo-popup
        [closeOnOutsideClick]="true"
        [onShowing]="onShowing"
        title=""
        [showTitle]="true"
        [width]="500"
        [maxHeight]="450"
        [position]="{ my: 'center', at: 'center', of: window }">
        </dxo-popup>

        <dxo-form
            [showColonAfterLabel]="true">
            <dxi-item itemType="group" [colCount]="1" [colSpan]="2">
                <dxi-item
                    dataField="ssek_naziv"
                    [editorOptions]="{stylingMode: 'filled'}">
                    <dxi-validation-rule
                        type="required"
                        message="Naziv je obavezno polje!">
                    </dxi-validation-rule>
                </dxi-item>
                <dxi-item dataField="ssek_opis" editorType="dxTextArea" [editorOptions]="{stylingMode: 'filled'}">
                </dxi-item>
            </dxi-item>
        </dxo-form>

        </dxo-editing>

        <!-- <dxi-column dataField="idsifarniksektor" [width]="70" [allowFiltering]="false" alignment="center"></dxi-column> -->
        <dxi-column dataField="ssek_naziv" caption="Naziv"></dxi-column>
        <dxi-column dataField="ssek_opis" caption="Opis"></dxi-column>

    </dx-data-grid>
</div>