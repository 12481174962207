<h2 class="content-block">{{ucionicaNaziv}}</h2> 

<div class="content-block dx-card">
    <div id="tabs content-block">
        <dx-tabs
            id="mainTabs"
            [dataSource]="glavniTabovi"
            [selectedIndex]="0"
            (onItemClick)="odaberiTab($event)">
        </dx-tabs>
    </div>

<!--------------------------- TAB1: OSNOVNI PODACI -------------------------->
<div class="widget-container2" *ngIf="odabranGlavniTab === 0">
    <div class="responsive-paddings" id="osnovno"> <!-- content-block -->
        <dx-form
            [formData]="ucionica"
            [showColonAfterLabel]="true"
            validationGroup="formaEdit">
            <dxi-item itemType="group" [colCount]="2" [colSpan]="2">
                <dxi-item dataField="uci_naziv" [colSpan]="1" [label]="{text: 'Naziv'}" [editorOptions]="{stylingMode:'filled'}">
                    <dxi-validation-rule type="required" message="Naziv je obavezno polje!"></dxi-validation-rule>
                </dxi-item>
                <dxi-item [label]="{text: 'Program'}" [colSpan]="1" dataField="prog_naziv" [editorOptions]="{readOnly: true, stylingMode:'filled'}">
                    <dxi-validation-rule type="required" message="Program je obavezno polje!"></dxi-validation-rule>
                </dxi-item>
                <dxi-item dataField="uci_obrazovnaGodina" [label]="{text: 'Godina'}" [editorOptions]="{ stylingMode:'filled'}">
                    <dxi-validation-rule  type="pattern" pattern="^[^\.]+$" message="Godinu unesite bez točke!"></dxi-validation-rule>
                </dxi-item>
                <dxi-item [label]="{text: 'Grad'}" dataField="grad_naziv">
                    <div *dxTemplate="let data of 'program'">
                        <dx-select-box
                            stylingMode="filled"
                            [value]="nazivGrada"
                            [dataSource]="gradovi"
                            valueExpr="idsifarnikgrad"
                            displayExpr="grad_naziv"
                            [searchEnabled]="true"
                            (onValueChanged)="onGradSelected($event)"
                            [grouped]="true">
                        </dx-select-box>
                    </div> 
                </dxi-item>
            </dxi-item>
            <dxi-item style="float: right;">
                <dx-button stylingMode="text" type="default" (onClick)="ucionicaEdit(ucionica)">SPREMI</dx-button>
            </dxi-item>
        </dx-form>
    </div>
</div>

<!--------------------------- TAB2: OSOBE-------------------------->
<div class="widget-container" *ngIf="odabranGlavniTab === 1">
        <div>
            <div class="responsive-paddings">
                <dx-drop-down-box
                    placeholder="Odabir..."
                    [dataSource]="administratori">
                    <dx-data-grid
                        height="100%"
                        [dataSource]="administratori"
                        [wordWrapEnabled]="true">
                            <dxi-column dataField="kor_punoIme" caption="Ime i prezime"></dxi-column>
                            <dxi-column caption="U učionici" dataField="uUcionici" cellTemplate="checkbox" alignment="center">
                                <div *dxTemplate="let data of 'checkbox'">
                                    <dx-check-box [value]="data.value" (onValueChanged)="onAdminSelected($event, data)"></dx-check-box>
                                </div>
                            </dxi-column> 
                    </dx-data-grid>
                </dx-drop-down-box>
            </div>
             <div>
                <dx-data-grid
                [showRowLines]="false"
                class="wide-card"
                [hoverStateEnabled]="true"
                [wordWrapEnabled]="true"
                [dataSource]="adminPrikazi"
                [columnAutoWidth]="true"
                [columnHidingEnabled]="true">
                    <dxo-search-panel [visible]="true"></dxo-search-panel>
                    <dxi-column dataField="kor_punoIme" caption="Administratori"></dxi-column>
                </dx-data-grid>
             </div>
        </div>
        <div id="polaznici">
            <div class="responsive-paddings">
                <dx-autocomplete #autocomplete placeholder="Dodajte polaznika..." [minSearchLength]="3" valueExpr="kor_punoIme" (onValueChanged)="polaznikTrazi($event)" 
                (onItemClick)="odabranPolaznik($event)" [dataSource]="polazniciIzvanUcionice" [showClearButton]="true">          
                </dx-autocomplete>
            </div>
            <div>
                <dx-data-grid
                [showRowLines]="false"
                class="wide-card"
                [hoverStateEnabled]="true"
                [wordWrapEnabled]="true"
                [dataSource]="polaznici"
                [columnAutoWidth]="true"
                [columnHidingEnabled]="true">
                <dxo-search-panel [visible]="true"></dxo-search-panel>
                    <dxi-column dataField="kor_punoIme" caption="Polaznici"></dxi-column>
                    <dxi-column type="buttons" ><dxi-button template="buttonTemplate"></dxi-button></dxi-column>
                    <div *dxTemplate="let buttonInfo of 'buttonTemplate'">
                        <div>
                            <dx-button class="kvizPolazika" (onClick)="kvizoviPolaznika(buttonInfo)">Kvizovi</dx-button>                   
                        </div>
                    </div>
                    <dxi-column type="buttons"  ><dxi-button template="bb"></dxi-button></dxi-column>
                    <div *dxTemplate="let buttonInfo of 'bb'">
                        <div>
                            <dx-button icon="trash" id="trash" (click)="obrisiPolaznika(buttonInfo)"></dx-button>                          
                        </div>
                    </div>
                    <!-- <dxi-column caption="Ukloni" cellTemplate="polaznikUkloni" alignment="center">
                        <div *dxTemplate="let data of 'polaznikUkloni'">
                            <dx-check-box [value]="data.value" (onValueChanged)="onAdminSelected($event, data)"></dx-check-box>
                        </div>
                    </dxi-column> -->
                </dx-data-grid>
            </div>
        </div>
</div>

<!--------------------------- TAB3: PREDMETI -------------------------->
    <div id="predmeti" *ngIf="odabranGlavniTab === 2">
        <div class="responsive-paddings">
            <dx-drop-down-box
                placeholder="Odabir predmeta..."
                [dataSource]="predmeti">
                <dx-list
                    keyExpr="idpredmet"
                    [selectedItemKeys]="idPredmeti"
                    [searchEnabled]="true"
                    searchExpr="pred_naziv"
                    searchMode="contains"
                    [showSelectionControls]="true"
                    [dataSource]="predmeti"
                    displayExpr="pred_naziv"
                    selectionMode="multiple"
                    (onItemClick)="onPredmetSelected($event)">
                </dx-list>
            </dx-drop-down-box>
        </div>
        <div>
            <dx-data-grid
            [showRowLines]="false"
            class="wide-card"
            [hoverStateEnabled]="true"
            [wordWrapEnabled]="true"
            [dataSource]="predmetPrikazi"
            [columnAutoWidth]="true"
            [columnHidingEnabled]="true">
            <dxo-search-panel [visible]="true"></dxo-search-panel>
                <dxi-column dataField="pred_naziv" caption="Predmet"></dxi-column>
                <dxi-column type="buttons" [width]="110"><dxi-button template="buttonPredavacTemplate"></dxi-button></dxi-column>
                <div *dxTemplate="let buttonInfo of 'buttonPredavacTemplate'">
                    <dx-button class="btnPredavac" (onClick)="predavaciNaPredmetu($event, buttonInfo.data.idpredmet, buttonInfo.data.pred_naziv)">Predavači</dx-button> 
                </div>
                <dxi-column type="buttons" [width]="110"><dxi-button template="buttonPolaznikTemplate"></dxi-button></dxi-column>
                <div *dxTemplate="let buttonInfo of 'buttonPolaznikTemplate'">
                    <dx-button class="btnPolaznik" (onClick)="polazniciNaPredmetu($event, buttonInfo.data.idpredmet, buttonInfo.data.pred_naziv)">Polaznici</dx-button> 
                </div>
                <dxi-column type="buttons" [width]="110"><dxi-button template="buttonMaterijalTemplate"></dxi-button></dxi-column>
                <div *dxTemplate="let buttonInfo of 'buttonMaterijalTemplate'">
                    <dx-button class="btnMaterijali" (onClick)="materijaliNaPredmetu($event, buttonInfo.data.idpredmet, buttonInfo.data.pred_naziv)">Materijali</dx-button> 
                </div>
                <dxi-column type="buttons" [width]="110"><dxi-button template="buttonKvizTemplate"></dxi-button></dxi-column>
                <div *dxTemplate="let buttonInfo of 'buttonKvizTemplate'">
                    <dx-button class="btnKvizovi" (onClick)="kvizoviNaPredmetu($event, buttonInfo.data.idpredmet, buttonInfo.data.pred_naziv)">Kvizovi</dx-button> 
                </div>
                <dxi-column type="buttons" [width]="110"><dxi-button template="buttonOcjenaTemplate"></dxi-button></dxi-column>
                <div *dxTemplate="let buttonInfo of 'buttonOcjenaTemplate'">
                    <dx-button class="btnOcjene" (onClick)="ocjenaNaPredmetu($event, buttonInfo.data.idpredmet, buttonInfo.data.pred_naziv)">Ocjene</dx-button> 
                </div>
            </dx-data-grid>
        </div>
    </div>

<!--------------------------- TAB4: ISPITNI ROKOVI -------------------------->
    <dx-data-grid *ngIf="odabranGlavniTab === 3"
            [showRowLines]="false"
            [hoverStateEnabled]="true"
            [wordWrapEnabled]="true"
            [dataSource]="rokovi"
            [columnAutoWidth]="true"
            [columnHidingEnabled]="true"
            (onInitNewRow)="onInitNewRow($event)"
            (onEditingStart)="onEditingStart($event)"
            (onRowInserted)="spremiIspitniRok($event)"
            (onRowUpdated)="urediIspitniRok($event)"
            (onRowExpanding)="onIspitniRokExpanding($event)"
            [masterDetail]="{enabled:true, template:'popisPolaznika'}"
            (onRowPrepared)="onRowPreparedIspitniRok($event)"
            (onCellPrepared)="onCellPreparedRokovi($event)"            
           >

        <dxo-editing
            mode="popup"
            [allowUpdating]="mozeSve"
            [allowDeleting]="false"
            [allowAdding]="mozeSve">
    
        <dxo-popup
            [closeOnOutsideClick]="true"
            [onShowing]="onShowing"
            title=""
            [showTitle]="true"
            width="520"
            height="600"
            [position]="{ my: 'center', at: 'center', of: window }">
        </dxo-popup>
<!----------------------------------- uređivanje rokova ------------------------------------------------>
       <dxo-form>
            <dxi-item itemType="group" [colCount]="2" [colSpan]="2">
                <dxi-item dataField="isprok_naziv" [colSpan]="2" [editorOptions]="{stylingMode:'filled'}">
                </dxi-item>
                <dxi-item dataField="idpredmet" editorType="dxSelectBox"
                    [editorOptions]="{wrapItemText:'true', stylingMode:'filled', readOnly:isEdited}">
                </dxi-item>
                <dxi-item dataField="idispit" editorType="dxSelectBox" [label]="{text: 'Ispit'}"
                    [editorOptions]="{wrapItemText: 'true', value: ispit, dataSource: ispiti, valueExpr:'idispit', displayExpr:'isp_naziv', 
                    onValueChanged: ispitChange, stylingMode:'filled', readOnly: isEdited}">
                <!-- <dxi-validation-rule type="required"></dxi-validation-rule> -->
                </dxi-item>
                <dxi-item dataField="isprok_pocetak" editorType="dxDateBox"
                    [editorOptions]="{stylingMode:'filled', min: minDate}">
                </dxi-item>
                <dxi-item dataField="isprok_kraj" editorType="dxDateBox"
                    [editorOptions]="{stylingMode:'filled', min: minDate}">
                </dxi-item>
                <dxi-item dataField="isprok_trajanje"[colSpan]="2">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-item>
            </dxi-item>
        </dxo-form>

        </dxo-editing>

        <dxo-paging [pageSize]="10"></dxo-paging>
        <dxo-pager [showPageSizeSelector]="true" [showInfo]="true"></dxo-pager>
        <dxo-search-panel [visible]="true"></dxo-search-panel>
        <dxo-header-filter [visible]="true"></dxo-header-filter>

            <dxi-column dataField="isprok_naziv" caption="Naziv roka">
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>
            <dxi-column dataField="idpredmet" caption="Predmet" [setCellValue]="predmetChange">
                <!-- <dxi-validation-rule type="required"></dxi-validation-rule> -->
                <dxo-lookup
                    [dataSource]="predmetPrikazi"
                    valueExpr="idpredmet"
                    displayExpr="pred_naziv">
                </dxo-lookup>
            </dxi-column>
            <dxi-column dataField="isp_naziv" caption="Ispit" [formItem]="{visible:false}">
                <!-- <dxi-validation-rule type="required"></dxi-validation-rule> -->
            </dxi-column>
            <dxi-column dataField="idispit" caption="Ispit" [visible]="false">
                <dxo-lookup
                    [dataSource]="ispiti"
                    valueExpr="idispit"
                    displayExpr="isp_naziv">
                </dxo-lookup>
            </dxi-column>
            <dxi-column dataField="isprok_pocetak" caption="Početak" dataType="date">
                <dxi-validation-rule type="custom" [validationCallback]="validateDatumOd"></dxi-validation-rule>
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>
            <dxi-column dataField="isprok_kraj" caption="Kraj" dataType="date">
                <dxi-validation-rule type="custom" [validationCallback]="validateDatumDo"></dxi-validation-rule>
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>
            <dxi-column dataField="isprok_trajanje" caption="Trajanje ispita (min)" alignment="center" editCellTemplate="dataCellTemplate">
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>
            <div *dxTemplate="let data of 'dataCellTemplate'">
                <dx-radio-group #eventRadioGroup
                    id="radios"
                    [items]="minute"
                    layout="horizontal" 
                    (onValueChanged)="trajanjeChanged($event, data)"
                    [(value)]="oznacen">
                </dx-radio-group>            
            </div>   
            <!-- Popis polaznika -->
            <div *dxTemplate="let pitanje of 'popisPolaznika'">
                <dx-data-grid
                    [dataSource]="polazniciIspitniRok"
                    [rowAlternationEnabled]="false"
                    [showRowLines]="false"
                    [showColumnLines]="false"
                    [showBorders]="false"
                    [columnAutoWidth]="true">
                        <dxi-column dataField="idkorisnik" caption="ID" [formItem]="{ visible: false }" [visible]="false"></dxi-column>
                        <dxi-column dataField="kor_punoIme" caption="Ime i prezime"></dxi-column>
                        <dxi-column caption="Ispit" cellTemplate="ispitPolaznika" alignment="center" [width]="110">
                            <div *dxTemplate="let cell of 'ispitPolaznika'">
                            <dx-button icon="columnchooser" class="kviz" (onClick)="prikaziIspitPolaznika(cell)"></dx-button>
                            </div>
                        </dxi-column>
                        <dxi-column dataField="isprez_ocjena" caption="Ocjena" width="150" alignment="center"></dxi-column>
                </dx-data-grid>
            </div> 
    </dx-data-grid>

<!--------------------------- TAB5: PREDAVANJA I PRISUTNOST -------------------------->
        <dx-data-grid *ngIf="odabranGlavniTab === 4"
            [showRowLines]="false"
            class="wide-card"
            [hoverStateEnabled]="true"
            [wordWrapEnabled]="true"
            [dataSource]="predavanja"
            [columnAutoWidth]="true"
            [columnHidingEnabled]="true"
            [masterDetail]="{enabled:true, template:'popisPolaznika'}"
            (onInitNewRow)="onInitPredavanje($event)"
            (onRowInserted)="spremiPredavanje($event)"
            (onEditingStart)="onEditingPredavanja($event)"
            (onCellClick)="cellPredavanjeEvent($event)"
            (onCellHoverChanged)="hoverPredavanje($event)"
            (onRowUpdated)="onRowUpdatedPredavanje($event)"
            (onRowRemoved)="onPredavanjeRemoved($event)"
            (onRowExpanding)="onPredavanjeExpanding($event)"
            (onCellPrepared)="onPredavanjePrepared($event)"
            (onRowPrepared)="onRowPreparedPredavanja($event)"
            >

            <dxo-editing
            mode="popup"
            [allowUpdating]="mozeSve"
            [allowDeleting]="true"
            [allowAdding]="mozeSve">

            <dxo-popup
            [closeOnOutsideClick]="false"
            [onShowing]="onShowing"
            title=""
            [showTitle]="true"
            width="520"
            height="600"
            [position]="{my:'center', at:'center', of:window}">
            </dxo-popup>

            <dxo-form>
                <dxi-item itemType="group" [colCount]="2" [colSpan]="2">
                    <dxi-item
                        dataField="ucip_naziv"
                        [colSpan]="2"
                        [editorOptions]="{stylingMode:'filled'}">
                    </dxi-item>
                    <dxi-item
                        dataField="ucip_lozinka"
                        [colSpan]="2"
                        [editorOptions]="{stylingMode:'filled'}">
                    </dxi-item>
                    <dxi-item dataField="predmet_idpredmet"
                        [editorOptions]="{stylingMode:'filled'}">
                    </dxi-item>
                    <!-- <dxi-item
                        dataField="predmet_idpredmet"
                        editorType="dxSelectBox"
                        [editorOptions]="{wrapItemText:'true',
                                        dataSource: predmetPrikazi,
                                        stylingMode:'filled',
                                        value: predavanje,
                                        valueExpr:'idpredmet',
                                        displayExpr:'pred_naziv',
                                        onValueChanged: odabraniPredmetChange}">
                                        <dxi-validation-rule type="required"></dxi-validation-rule>
                    </dxi-item> -->
                    <dxi-item
                        dataField="ucip_vrijeme"
                        [editorOptions]="{stylingMode:'filled', min: minDate}">
                    </dxi-item>
                    <dxi-item
                        dataField="ucip_trajanje"
                        [colSpan]="2">
                    </dxi-item>
                </dxi-item>
            </dxo-form>

            </dxo-editing>

            <dxo-paging [pageSize]="10"></dxo-paging>
            <dxo-pager [showPageSizeSelector]="true" [showInfo]="true"></dxo-pager>
            <dxo-search-panel [visible]="true"></dxo-search-panel>
            <dxo-header-filter [visible]="true"></dxo-header-filter>

            <dxi-column dataField="ucip_naziv" caption="Naziv" [editorOptions]="{stylingMode: 'filled'}">
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>
            <dxi-column dataField="ucip_vrijeme" caption="Datum i vrijeme" dataType="datetime">
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>
            <dxi-column dataField="ucip_trajanje" caption="Trajanje (min)" alignment="center" editCellTemplate="dataCellTemplate">
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>
            <div *dxTemplate="let data of 'dataCellTemplate'">
                <dx-radio-group #eventRadioGroup
                    id="radios"
                    [items]="minute"
                    layout="horizontal"
                    (onValueChanged)="trajanjeChanged($event, data)"
                    [(value)]="oznacen">
                </dx-radio-group>
            </div> 
            <dxi-column dataField="ucip_meetingID" caption="Meeting ID"></dxi-column>
            <dxi-column dataField="ucip_lozinka" caption="Lozinka"></dxi-column>
            <dxi-column dataField="predmet_idpredmet" caption="Predmet">
                <dxi-validation-rule type="required"></dxi-validation-rule>
                <dxo-lookup 
                    [wordWrapEnabled]="true"
                    [dataSource]="predmetPrikazi"
                    valueExpr="idpredmet"
                    displayExpr="pred_naziv">
                </dxo-lookup>
            </dxi-column>
             <!-- <dxi-column dataField="postotak" caption="Prisutnost" width="150"></dxi-column> -->
             <!-- Popis polaznika -->
             <div *dxTemplate="let pitanje of 'popisPolaznika'">
                <dx-data-grid
                    [dataSource]="polazniciPredavanjaPrisutnost"
                    [rowAlternationEnabled]="false"
                    [showRowLines]="false"
                    [showColumnLines]="false"
                    [showBorders]="false"
                    [columnAutoWidth]="true"
                    > <!-- (onCellClick)="oznaciPrisutnost($event);" -->
                        <dxi-column dataField="kor_punoIme" caption="Ime i prezime"></dxi-column>
                        <dxi-column dataField="prisutan" caption="Prisutan" dataType="boolean" width="150" [formItem]="{visible: false}" cellTemplate="cellTemplate">
                            <div *dxTemplate="let data of 'cellTemplate'">
                                <dx-check-box [value]="data.value" (onValueChanged)="oznaciPrisutnost($event, data)"></dx-check-box>
                            </div>
                        </dxi-column>
                </dx-data-grid>       
            </div>
        </dx-data-grid>

<!---------------------------------- TAB6: OBAVIJESTI --------------------------------------------------->
    <dx-data-grid *ngIf="odabranGlavniTab === 5"
        [showRowLines]="false"
        class="wide-card"
        [hoverStateEnabled]="true"
        [wordWrapEnabled]="true"
        [dataSource]="obavijesti"
        [columnAutoWidth]="true"
        [columnHidingEnabled]="true"
        (onInitNewRow)="onInitObavijest($event)"
        (onRowInserted)="onRowInserted($event)"
        (onEditingStart)="onEditingObavijest($event)"
        (onRowUpdated)="onRowUpdatedObavijest($event)"
        (onRowRemoved)="onRowRemovedObavijest($event)">

        <dxo-editing
            mode="popup"
            [allowAdding]="mozeSve"
            [allowUpdating]="mozeSve"
            [allowDeleting]="mozeSve">
            <dxo-popup
                [closeOnOutsideClick]="true"
                title="Obavijest"
                [showTitle]="true"
                [width]="500"
                [maxHeight]="450"
                [position]="{ my: 'center', at: 'center', of: window }">
                </dxo-popup>

                <dxo-form id="form" [colCount]="1" [showColonAfterLabel]="true">
                    <dxi-item itemType="group">
                        <dxi-item dataField="uciob_naslov" [editorOptions]="{stylingMode: 'filled'}">
                            <dxi-validation-rule type="required" message="Naslov je obavezno polje!"></dxi-validation-rule>
                        </dxi-item>
                        <dxi-item dataField="uciob_tekst" editorType="dxTextArea" [editorOptions]="{stylingMode: 'filled'}">
                            <dxi-validation-rule type="required" message="Tekst je obavezno polje!"></dxi-validation-rule>
                        </dxi-item>
                    </dxi-item>
                </dxo-form>
        </dxo-editing>
       
        <dxo-search-panel [visible]="true"></dxo-search-panel>

        <dxi-column dataField="uciob_naslov" caption="Naslov"></dxi-column>
        <dxi-column dataField="uciob_tekst" caption="Sadržaj"></dxi-column>
        <dxi-column dataField="kor_punoIme" caption="Autor" width="200"></dxi-column>
        <dxi-column dataField="createdAt" caption="Objavljeno" dataType="date" width="140"></dxi-column>

    </dx-data-grid>

    <!------------------------- UNUTAR TAB3 --------------------------->
    <!------------------------- Predavači na predmetu--------------------------->
    <dx-popup
        [width]="600"
        [height]="'auto'"
        title="{{nazivPredmeta}} - PREDAVAČI"
        [(visible)]="predavaciPopup">
        <!-- <p>Označite predavača za ovaj predmet: </p> -->
        <dx-scroll-view height="90%" class="full-height-scrollable">
            <dx-data-grid
            [dataSource]="predavacPrikazi"
            [wordWrapEnabled]="true">

           <dxi-column dataField="kor_punoIme" caption="Ime i prezime"></dxi-column>
           <dxi-column caption="U učionici" dataField="uUcionici" cellTemplate="checkbox" alignment="center">
               <div *dxTemplate="let data of 'checkbox'">
                   <dx-check-box [value]="data.value" (onValueChanged)="odabranPredavacNaPredmetu($event, data)"></dx-check-box>
               </div>
           </dxi-column>
            </dx-data-grid>
        </dx-scroll-view>
    </dx-popup>

    <!------------------------- Polaznici na predmetu--------------------------->
    <dx-popup
        [width]="600"
        [height]="'auto'"
        title="{{nazivPredmeta}} - POLAZNICI"
        [(visible)]="polazniciPopup">
        <dx-scroll-view height="90%" class="full-height-scrollable">
           <dx-data-grid
            [dataSource]="polazniciPrikazi"
            [wordWrapEnabled]="true">

           <dxi-column dataField="kor_punoIme" caption="Ime i prezime"></dxi-column>
           <dxi-column caption="Ne polaže" dataField="zavrsio" cellTemplate="checkbox" alignment="center">
               <div *dxTemplate="let data of 'checkbox'">
                   <dx-check-box [value]="data.value" (onValueChanged)="polaznikPolozioPredmet($event, data)"></dx-check-box>
               </div>
           </dxi-column>
            </dx-data-grid>
        </dx-scroll-view>
    </dx-popup>

    <!------------------------- Materijali na predmetu--------------------------->
    <dx-popup
        [width]="800"
        [height]="'auto'"
        title="{{nazivPredmeta}} - MATERIJALI"
        [(visible)]="materijaliPopup">
        <dx-scroll-view height="90%" class="full-height-scrollable">
        <dx-data-grid
            [dataSource]="globalniMaterijali"
            (onCellHoverChanged)="hover($event)"
            (onCellClick)="cellClickEvent($event)"
            (onCellPrepared)="onCellPrepared($event)"
            [wordWrapEnabled]="true">

            <dxi-column dataField="idpredmetmaterijal" caption="ID" [visible]="false"></dxi-column>
            <dxi-column dataField="pmat_naziv" caption="Naziv dokumenta"></dxi-column>
            <!-- <dxi-column dataField="materijal" caption="Materijal" ></dxi-column> -->

            <dxi-column caption="Na predmetu" dataField="uUcionici" cellTemplate="checkbox" alignment="center">
                <div *dxTemplate="let data of 'checkbox'">
                    <dx-check-box [(value)]="data.value" (onValueChanged)="checkboxChange($event, data)"></dx-check-box>
                </div>
            </dxi-column>
            <!-- <dxi-column dataField="dodao" caption="Dodao"></dxi-column> -->

        </dx-data-grid>
        </dx-scroll-view>
    </dx-popup>

    <!---------------------------------- Prikaz video zapisa  -------------------------------------------->
    <dx-popup
        title="Prikaz video zapisa"
        [(visible)]="prikaziVideo"
        [width]="500"
        [maxHeight]="400"
        [position]="{my:'center', at:'center', of:window}">
        <dx-load-indicator id="large-indicator" height="60" width="60" [visible]="prikaziLoading"></dx-load-indicator>
        <iframe [src]="vimeoURL | safe" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
    </dx-popup>


    <!------------------------- Kvizovi na predmetu--------------------------->
    <dx-popup
        [width]="800"
        [height]="'auto'"
        title="{{nazivPredmeta}} - KVIZOVI"
        [(visible)]="kvizoviPopup">
        <dx-scroll-view height="90%" class="full-height-scrollable">
        <dx-data-grid
            [showRowLines]="false"
            [hoverStateEnabled]="true"
            [wordWrapEnabled]="true"
            [dataSource]="kvizovi"
            [columnAutoWidth]="true"
            [columnHidingEnabled]="true"
            (onCellHoverChanged)="hoverKviz($event)"
            (onCellClick)="cellClickKviz($event)"
            keyExpr="idkviz">

        <dxo-paging [pageSize]="10"></dxo-paging>
        <dxo-pager [showPageSizeSelector]="true" [showInfo]="true"></dxo-pager>

            <dxi-column dataField="nazivKviza" caption="Naziv kviza"></dxi-column>
            <dxi-column dataField="brojPitanja" caption="Broj pitanja" alignment="center"></dxi-column>
        </dx-data-grid>
        </dx-scroll-view>

    </dx-popup>

    <!------------------------- Prikaz jednog kviza --------------------------->
    <dx-popup
        [width]="800"
        [height]="600"
        [title]="nazivKviza"
        [(visible)]="jedanKvizPopup">
        
        <dx-scroll-view height="90%" class="full-height-scrollable">
        <table cellspacing="0">
            <ng-container *ngFor="let pitanja of pitanjaZaKviz; let i = index">            
                <tr><p class="pitanje">{{ i+1 }}. {{ pitanja.kpit_naziv }}</p></tr>
                <img *ngIf="pitanja.iduploaddokument" class="kvizSlika" src="{{datotekeURL}}+{{pitanja.iduploaddokument}}+&access_token={{access_token}}" (click)="slikaPrikazi(pitanja.iduploaddokument)">
                <tr *ngFor="let odgovor of odgovoriKviz; let index= index">
                    <div *ngIf="odgovor.kvizpitanje_idkvizpitanje === pitanja.idkvizpitanje">
                    <td>
                        <!-- <span class="slova"> {{slovo[index]}}</span>           -->
                <!-- {{slovo}} -->
                <!-- {{index}} -->
                    <input type="checkbox" class="check" name="check" [value]="odgovor.idkvizodgovor" [checked]="odgovor.kodg_tocan == '1'" onclick="return false;"> 
                        <span *ngIf="!odgovor.iduploaddokument">{{ odgovor.pitanje }}</span>
                        <span *ngIf="odgovor.iduploaddokument">
                            <img id="odgovor{{odgovor.idkvizodgovor}}" class="slikatumbnail" src="{{datotekeURL}}+{{odgovor.iduploaddokument}}+&access_token={{access_token}}" (click)="slikaPrikazi(odgovor.iduploaddokument)">
                        </span>
                        <dx-popover *ngIf="odgovor.iduploaddokument"
                            target="#odgovor{{odgovor.idkvizodgovor}}" 
                            position="top"
                            showEvent="mouseenter"
                            hideEvent="mouseleave"
                            contentTemplate="popoverContent"
                            [height]="'auto'">
                        <dxo-animation>
                            <dxo-show 
                                type="pop"
                                [from]="{ scale: 0 }"
                                [to]="{ scale: 1 }"></dxo-show>
                            <dxo-hide 
                                type="fade"
                                [from]="1"
                                [to]="0"></dxo-hide>
                        </dxo-animation>
                        <div *dxTemplate="let data of 'popoverContent'">
                            <img class="odgovorSlika" src="{{datotekeURL}}+{{odgovor.iduploaddokument}}+&access_token={{access_token}}">                     
                        </div>
                    </dx-popover>
                    </td>
                    </div>
                </tr>
            </ng-container>
        </table>
        </dx-scroll-view>
    </dx-popup>

    <!------------------------- Prikaz jednog ispita --------------------------->
    <dx-popup
        [width]="800"
        [height]="600"
        [title]="polaznikImePrezime"
        [(visible)]="jedanIspitPopup">
        <dxi-toolbar-item
            widget="dxButton"
            location="after"
            [options]="{
                type:'normal',
                icon: 'fieldchooser',
                text: 'Ocijeni ispit',
                onClick: ocjenaIspita
            }">
        </dxi-toolbar-item>


        <dx-scroll-view height="90%" class="full-height-scrollable">
            <table cellspacing="0" class="klasa">
                <ng-container *ngFor="let pitanja of pitanjaZaIspit; let i = index">
                    <tr><p class="pitanje">{{ i+1 }}. {{ pitanja.ispp_tekst }}</p></tr>
                    <dx-check-box
                        class="checkbox"
                        [value]="pitanja.isprje_tocan"
                        (onValueChanged)="odgovorTocan($event, pitanja)"
                        text="Točno">
                    </dx-check-box>
                    <img *ngIf="pitanja.uploaddokument_iduploaddokument" class="kvizSlika" src="{{datotekeURL}}+{{pitanja.uploaddokument_iduploaddokument}}+&access_token={{access_token}}" (click)="slikaPrikazi(pitanja.uploaddokument_iduploaddokument)">
                    <div *ngIf="pitanja.ispp_oblik == 0 || pitanja.ispp_oblik == 1">
                        <tr *ngFor="let odgovor of odgovoriIspit; let index= index">
                            <div *ngIf="odgovor.ispitpitanje_idispitpitanje === pitanja.idispitpitanje">
                                <td>
                                    <input type="checkbox" class="check" name="check" [value]="odgovor.idispitodgovor" [checked]="odgovor.korisnik_tocan_odgovor" onclick="return false;">
                                    <span *ngIf="!odgovor.uploaddokument_iduploaddokument" [ngClass]="[odgovor.ispo_tocan === 1 ? 'tocan' : 'netocan']">{{ odgovor.ispo_tekst }}</span>
                                    <a href="{{datotekeURL}}+{{odgovor.uploaddokument_iduploaddokument}}+&access_token={{access_token}}" target="_blank"><img *ngIf="odgovor.uploaddokument_iduploaddokument" class="kvizSlika" src="{{datotekeURL}}+{{odgovor.uploaddokument_iduploaddokument}}+&access_token={{access_token}}"></a>
                                    <!-- <span><a href="{{datotekeURL}}+{{odgovor.uploaddokument_iduploaddokument}}+&access_token={{access_token}}" target="_blank"><p *ngIf="odgovor.uploaddokument_iduploaddokument">Preuzmi odgovor</p></a></span> -->
                                </td>
                            </div>
                        </tr>
                    </div>
                    <div *ngIf="pitanja.ispp_oblik == 2 || pitanja.ispp_oblik == 3">
                        <tr *ngFor="let odgovor of korisnikoviOdgovori; let index= index">
                            <div *ngIf="odgovor.ispitpitanje_idispitpitanje === pitanja.idispitpitanje">
                                <span>{{ odgovor.isprje_tekst }}</span>
                            </div>
                        </tr>
                    </div>
                    <div *ngIf="pitanja.ispp_oblik == 4">
                        <tr *ngFor="let odgovor of korisnikoviOdgovori; let index= index">
                            <div *ngIf="odgovor.ispitpitanje_idispitpitanje === pitanja.idispitpitanje">
                                <a href="{{datotekeURL}}+{{odgovor.uploaddokument_iduploaddokument}}+&access_token={{access_token}}" target="_blank"><p *ngIf="odgovor.uploaddokument_iduploaddokument">Preuzmi odgovor</p></a>
                            </div>
                        </tr>
                    </div>
                </ng-container>
            </table>
        </dx-scroll-view>
    </dx-popup>


    <!---------------- POPUP ZA OCJENJIVANJE ISPITA --------------------->
    <dx-popup
        title="Ocjenjivanje ispita"
        [(visible)]="ocijeniIspitPopup"
        [width]="570"
        [height]="200"
        [position]="{ my: 'center', at: 'center', of: window }">

        <div class="radio">
            <dx-radio-group #radioGroup
            id="radios"
            [items]="ocjene"
            layout="horizontal"
            (onValueChanged)="ocjenaChange($event)"
            [(value)]="dodijeljenaOcjena">
        </dx-radio-group>
        <dx-button class="spremi" type="default" text="Spremi ocjenu" (onClick)="ocijeni(dodijeljenaOcjena)"></dx-button>
        </div>

    </dx-popup>

    <!------- OCJENE SVAKOG POLAZNIKA NA PREDMETU ------->
    <dx-popup
        [width]="800"
        [height]="'auto'"
        title="{{nazivPredmeta}} - Ocjene polaznika"
        [(visible)]="ocjenePopup">
        <dx-scroll-view height="90%" class="full-height-scrollable">
        <dx-data-grid
            [dataSource]="polazniciPrikazi"
            (onCellHoverChanged)="hover($event)"
            (onCellClick)="cellClickEvent($event)"
            (onCellPrepared)="onCellPrepared($event)"
            [wordWrapEnabled]="true">

            <dxi-column dataField="korisnik_idkorisnik" caption="ID" [visible]="false"></dxi-column>
            <dxi-column dataField="kor_punoIme" caption="Ime i prezime"></dxi-column>

            <dxi-column caption="Ocjene polaznika" cellTemplate="polaznici" alignment="center" dataField="uppz_ocjena" width="170">
                <div *dxTemplate="let cell of 'polaznici'">
                    <dx-select-box
                        class="ocjenaSelect"
                        placeholder="Odaberi ocjenu"
                        [items]="ocjene"
                        wordWrapEnabled="true"
                        (onItemClick)="ocijeniPredmet(cell, $event)"
                        [(value)]="cell.data.uppz_ocjena">
                    </dx-select-box>
                </div>
            </dxi-column>
        </dx-data-grid>
        </dx-scroll-view>
    </dx-popup>

    <!-- CHAT -->
    <app-chat *ngIf="odabranGlavniTab == 6"></app-chat>

</div>



    <!-------  POPIS KVIZOVA NEKOG POLAZNIKA ------->
    <dx-popup
        [width]="800"
        [height]="'auto'"
        title="{{imePolaznikaKviza}} - Kvizovi polaznika"
        [(visible)]="popupPolaznikKviz">
        <dx-scroll-view height="90%" class="full-height-scrollable">
        <dx-data-grid
            [dataSource]="popisKvizovaPolaznika"
            (onCellHoverChanged)="hoverKvizoviPolaznika($event)"
            (onCellClick)="cellClickEventPolaznik($event)"
            (onCellPrepared)="onCellPreparedPolaznik($event)"
            [wordWrapEnabled]="true">

            <!-- <dxi-column dataField="korisnik_idkorisnik" caption="ID" [visible]="false"></dxi-column> -->
            <!-- <dxi-column dataField="kor_punoIme" caption="Ime i prezime"></dxi-column> -->
            <dxi-column caption="Predmet" dataField="pred_naziv" ></dxi-column>
            <dxi-column caption="Kviz" dataField="kvrez_naziv" ></dxi-column>
            <dxi-column caption="Postotak riješenosti" alignment="center" dataField="kvrez_postotak" [customizeText]="postotakPrikaz"></dxi-column>
        </dx-data-grid>
        </dx-scroll-view>
    </dx-popup>