import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

import { ApiService } from './../../../shared/services/api.service';
import { IdPredmetService } from 'src/app/shared/services/id-predmet.service';

import notify from 'devextreme/ui/notify';
import { custom } from 'devextreme/ui/dialog';
import { encodeHtml } from 'devextreme/core/utils/string';
import { DxFileUploaderComponent } from 'devextreme-angular/ui/file-uploader';
import validationEngine from "devextreme/ui/validation_engine";

@Component({
  selector: 'app-uredi-predmet',
  templateUrl: './uredi-predmet.component.html',
  styleUrls: ['./uredi-predmet.component.scss']
})
export class UrediPredmetComponent implements OnInit {

  access_token: any = localStorage.getItem('access_token');
  prava:any = localStorage.getItem('kor_prava');

  @ViewChild('notesUploader') uploader: DxFileUploaderComponent;
  datotekeURL = this._api.datotekeURL;
  uploadURL = this._api.uploadURL;
  window: any;
  predmetID: any;
  predmetNaziv: any;
  predmetDetalji = { 'predmetNaziv': '' };
  predavaci = [];
  odabraniTab: number = 0;
  odabraniMaterijal: number = 0;
  materijalPredavaca: boolean;
  idMaterijal: number;
  materijalNaziv: string;
  materijalLink: string;
  editActive: boolean = false;
  prikaziEdit: boolean = false;
  tabs = [
    { id: 0, text: "Predavači" },
    { id: 1, text: "Materijali"},
    { id: 2, text: "Kvizovi" },
    { id: 3, text: "Ispiti" }
  ];
  materijaliTabs = [
    { id: 0, text: "Globalni materijali" },
    { id: 1, text: "" }
  ];
  globalniMaterijali = [];
  materijaliPredavaca = [];
  companies = [
    { "ID": 1, "CompanyName": "Globalni materijali" }, 
    { "ID": 2, "CompanyName": "Materijali predavača" }
  ];
  allowAddingGlobalno: boolean = false;
  allowAddingPredavac: boolean = false;
  allowUpdatingGlobalno: boolean = false;
  allowUpdatingPredavac: boolean = false;
  allowDeletingGlobalno: boolean = false;
  allowDeletingPredavac: boolean = false;
  naslov: string;
  value: any[] = [];
  // getSelectedFiles () {
  //   return this.value;
  // }
   dodajUpload: boolean = false;
   dodajVideo: boolean = false;
   prikaziVideo: boolean = false;
   vimeoURL: string;
  // saveButtonOptions: any;
  // settingsButtonOptions: any;

  constructor(
    private _activatedRoute: ActivatedRoute, 
    public _api: ApiService,
    private _idPredmetService: IdPredmetService,
    public _sanitizer: DomSanitizer
  ) { 
                
    this.onShowing = this.onShowing.bind(this); 
    // this.toolbarIzbornik();

  }

  ngOnInit() {
    this.predmetID = this._activatedRoute.snapshot.params['id'];
    this.detaljiPredmeta(this.predmetID);
    this.tabMaterijaliProvjera();
  }

   uploadMaterijal(e) {
    //  console.log(e); 
     if(this.odabraniMaterijal == 0){
      let podaciZaSlanje = {
        "idmaterijal": this.idMaterijal,
        "predavac": localStorage.getItem('kor_prava'),
        "naziv": this.materijalNaziv,
        "access_token": this.access_token
      }
      // console.log(podaciZaSlanje);
      this._api.predmetMaterijalUredi(podaciZaSlanje).subscribe(res=>{
        // console.log(res);
        if(res.success == true){
          notify(res.message, "success", 1000);
          this.dodajUpload = false;
          // this.uploader.instance.reset();
          this.popisMaterijala();
        } else {
          notify(res.message, "error", 1000);
        }
      });
    } else if (this.odabraniMaterijal == 1) {
      //Uređivanje materijala predavača
      let podaciZaSlanje = {
        "idmaterijalpredavac": this.idMaterijal,
        "predavac": localStorage.getItem('kor_prava'),
        "naziv": this.materijalNaziv,
        "access_token": this.access_token
      }
      // console.log(podaciZaSlanje);
      this._api.predmetMaterijalUredi(podaciZaSlanje).subscribe(res=>{
        // console.log(res);
        if(res.success == true){
          notify(res.message, "success", 1000);
          this.dodajUpload = false;
          // this.uploader.instance.reset();
          this.popisMaterijala();
        } else {
          notify(res.message, "error", 1000);
        }
      });
    }
   }

   dodajVideoMaterijal(){
    //  console.log(e);
    //  if(this.odabraniMaterijal == 0){
      let podaciZaSlanje = {
        "link": this.materijalLink,
        "naziv": this.materijalNaziv,
        "idpredmet": this.predmetID,
        "prava": +this.prava,
        "idkorisnik": localStorage.getItem('idkorisnik'),
        "access_token": this.access_token
      };
      // console.log(podaciZaSlanje);
      this._api.predmetMaterijalDodajVideo(podaciZaSlanje).subscribe(res=>{
        // console.log(res);
        if(res.success == true){
          notify(res.message, "success", 1000);
          this.dodajVideo = false;
          validationEngine.resetGroup('formPopupVimeo');
          this.popisMaterijala();
        } else {
          notify(res.message, "error", 1000);
        }
      });
    //  } else if (this.odabraniMaterijal == 1) {
    //   let podaciZaSlanje = {
    //     "link": this.materijalLink,
    //     "naziv": this.materijalNaziv,
    //     "idpredmet": this.predmetID,
    //     "prava": this.prava
    //   };
    //   this._api.predmetMaterijalDodajVideo(podaciZaSlanje).subscribe(res=>{
    //     // console.log(res);
    //     if(res.success == true){
    //       notify(res.message, "success", 1000);
    //       this.dodajVideo = false;
    //       this.popisMaterijala();
    //     } else {
    //       notify(res.message, "error", 1000);
    //     }
    //   });
    //  }
   }

   form_fieldDataChanged(e){
    this.materijalNaziv = e.value;
   }

   video_fieldDataChanged(e){
      // console.log(e);
     if(e.dataField == "pmat_naziv" || e.dataField == "pmpred_naziv"){
      this.materijalNaziv = e.value;
      // console.log(this.materijalNaziv);
     }
     if(e.dataField == "materijal"){
      this.materijalLink = e.value;
      // console.log(this.materijalLink);
     }
   }

  prikazUploadForme(e){
    this.dodajUpload = true;
    // console.log(e);
  }

  prikazVideoForme(e){
    this.dodajVideo = true;
    // console.log(e);
  }

  // toolbarIzbornik(){
  //   this.saveButtonOptions = {
  //     text:"Upload",
  //     onClick: () => {
  //        this.dodajUpload = true;
  //     }
  //   };
  
  //   this.settingsButtonOptions = {
  //       text:"Video",
  //       onClick: () => {
  //           notify('Video has been clicked!');
  //       }
  //   };
  // }

  tabMaterijaliProvjera(){
    if(this.prava == '0' || this.prava == '1'){
      this.materijaliTabs[1].text = "Materijali predavača";
      this.allowAddingGlobalno = true;
      this.allowUpdatingGlobalno = true;
      this.allowDeletingGlobalno = true;
      this.materijalPredavaca = false;
    } else {
      this.materijaliTabs[1].text = "Moji materijali";
      this.allowAddingPredavac = true;
      this.allowUpdatingPredavac = true;
      this.allowDeletingPredavac = true;
      this.materijalPredavaca = true;
    }
  }

  selectTab($event){
    this.odabraniTab = $event.itemIndex;
    // console.log(this.odabraniTab);
    if(this.odabraniTab == 1){
      this.popisMaterijala();
    } else if (this.odabraniTab == 2){
      this._idPredmetService.posaljiIdUcionica(this.predmetID);
    } else if (this.odabraniTab == 3){
      this._idPredmetService.posaljiIdUcionica(this.predmetID);
    }
    if(this.odabraniTab != 1){
      this.odabraniMaterijal = 0;
    }
  }

  odaberiTabMaterijala($event){
    this.odabraniMaterijal = $event.itemIndex;
    // console.log(this.odabraniMaterijal);
  }

  detaljiPredmeta(predmetID){
    this.predavaci = [];
    this._api.predmetJedan({"idpredmet":predmetID, 'access_token': this.access_token}).subscribe(res=>{
      // console.log(res);
      if(res.success == true){
        this.predmetNaziv = res.predmet.pred_naziv;
        this.predmetDetalji.predmetNaziv = res.predmet.pred_naziv;
        this.predavaci = res.predavaci;
        // console.log(this.predavaci);
      } else {
         notify(res.message, "error", 1000);
      }
    });
  }

  oznaciPredavaca(e){
    // console.log(e.data);
    if (e.rowType == "data" && e.column.dataField === "naPredmetu" && this.allowAddingGlobalno) {
      this._api.predmetUredi({'predavac': [e.data.idkorisnik], 'idpredmet': this.predmetID, "access_token": this.access_token}).subscribe(res=>{
        // console.log(res);
        if(res.success == true){
          this.detaljiPredmeta(this.predmetID);
        } else {
          notify(res.message, "error", 1000);
        }
      });
    } else {
      notify("Nemate potrebna prava za promjenu statusa!", "error", 1000);
    }
  }

  onInitNewRow(e){;
    // console.log(e);
    this.naslov = 'Dodavanje materijala';
    this.editActive = false;
    // console.log(this.editActive);
  }

  onEditingStart(e){
    // console.log(e.data);
    this.editActive = true;
    // console.log(this.editActive);
    this.naslov = 'Uređivanje materijala';
  }

  onRowUpdated(e){
    // console.log(e.data);
    //Uređivanje globalnih materijala
    if(this.odabraniMaterijal == 0){
      let podaciZaSlanje = {
        "idmaterijal": e.data['idpredmetmaterijal'],
        // "idmaterijalpredavac": null, //Predavač
        "predavac": localStorage.getItem('kor_prava'),
        "naziv": e.data['pmat_naziv'],
        "access_token": this.access_token
      };
      // console.log(podaciZaSlanje);
      this._api.predmetMaterijalUredi(podaciZaSlanje).subscribe(res=>{
        // console.log(res);
        if(res.success == true){
          notify(res.message, "success", 1000);
          this.popisMaterijala();
        } else {
          notify(res.message, "error", 1000);
        }
      });
    } else if (this.odabraniMaterijal == 1) {
      //Uređivanje materijala predavača
      let podaciZaSlanje = {
        // "idmaterijal": e.data['idpredmetmaterijal'],
        "idmaterijalpredavac": e.data['idpredmetmaterijalpredavac'],
        "predavac": localStorage.getItem('kor_prava'),
        "naziv": e.data['pmpred_naziv'],
        "access_token": this.access_token
      };
      // console.log(podaciZaSlanje);
      this._api.predmetMaterijalUredi(podaciZaSlanje).subscribe(res=>{
        // console.log(res);
        if(res.success == true){
          notify(res.message, "success", 1000);
          this.popisMaterijala();
        } else {
          notify(res.message, "error", 1000);
        }
      });
    }
  }

  onRowRemoved(e){
    // console.log(e.data);
    if(e.data['idpredmetmaterijal'] != null){
      let podaciZaBrisanje = {
        "idmaterijal": e.data['iduploaddokument'],
        "idpredmetmaterijal": e.data['idpredmetmaterijal'],
        "vrsta": e.data['pmat_vrsta'],
        "access_token": this.access_token
      };
       this._api.predmetMaterijalObrisi(podaciZaBrisanje).subscribe(res=>{
        // console.log(res);
        if(res.success == true){
          notify(res.message, "success", 1000);
          this.popisMaterijala();
        } else {
          notify(res.message, "error", 1000);
        }
      });
    } else {
      let podaciZaBrisanje = {
        "idmaterijal": e.data['iduploaddokument'],
        "idpredmetmaterijalpredavac": e.data['idpredmetmaterijalpredavac'],
        "vrsta": e.data['pmpred_vrsta'],
        "access_token": this.access_token
      };
       this._api.predmetMaterijalObrisi(podaciZaBrisanje).subscribe(res=>{
        // console.log(res);
        if(res.success == true){
          notify(res.message, "success", 1000);
          this.popisMaterijala();
        } else {
          notify(res.message, "error", 1000);
        }
      });
    }
  }

  onShowing(e){
    e.component.option("title", this.naslov);
  }

  popup_hidden(e: any){
    // console.log(e);
    validationEngine.resetGroup('formPopupVimeo');
    validationEngine.resetGroup('formPopupUpload');
    this.uploader.instance.reset();
  }

  ///////\\\\\\\\
  // MATERIJALI \\

  uploadILIvideo(data){
    // console.log(data);
  }

  onUploaded(e){
    // console.log(e);
    let file = e.file;
    let formData:FormData = new FormData();
    formData.append('datoteka', file, file.name);
    formData.append('predmet', this.predmetID);
    formData.append('iddokument', this.predmetNaziv);
    formData.append('idkorisnik', localStorage.getItem('idkorisnik'));
    formData.append('nazivtablice', 'materijali');
    formData.append('access_token', this.access_token);
    this._api.uploadDatoteka(formData).subscribe(res=>{
      //  console.log(res);
      if(res.success == true){
        this.idMaterijal = res.idmaterijal;
        this.editActive = false;
        this.popisMaterijala();
      } else {
        notify(res.message, "error", 1000);
      }
    });
  }

  popisMaterijala(){
    this.globalniMaterijali = [];
    this.materijaliPredavaca = [];
    this._api.predmetMaterijalPopis({"idpredmet": this.predmetID, "predavac": localStorage.getItem('idkorisnik'), 'access_token': this.access_token}).subscribe(res=>{
      //  console.log(res);
      if(res.success == true){
        this.globalniMaterijali = res.popisGlobalnihMaterijala;
        this.materijaliPredavaca = res.popisMaterijalaPredavaca;
      } else {
        notify(res.message, "error", 1000);
      }
    });
  }

  prebaciGlobalno(data){
    let idMaterijal = data.data.idpredmetmaterijalpredavac;
    let encodedMessage = encodeHtml("Jeste li sigurni da ovaj materijal želite prebaciti na popis globalno dostupnih materijala?");
    let myDialog = custom({
        title: "Globalni materijal",
        messageHtml: encodedMessage,
        buttons: [{
            text: "PREBACI",
            onClick: (e) => {
                // console.log(e);
                return { buttonText: e.component.option("text") }
            }
        },{
          text: "ODUSTANI",
          onClick: (e) => {
              // console.log(e);
              return { buttonText: e.component.option("text") }
          }
      }]
    });
    myDialog.show().then((dialogResult) => {
      // console.log(dialogResult.buttonText);
       if(dialogResult.buttonText === 'PREBACI'){
        this._api.predmetMaterijalGlobaliziraj({"idmaterijal": idMaterijal, "access_token": this.access_token}).subscribe(res=>{
          // console.log(res);
          if(res.success == true){
            this.popisMaterijala();
            notify(res.message, "success", 1000);
          } else {
            notify(res.message, "error", 1000);
          }
        });
       } else {  }  
   });
  }

  onToolbarPreparing(e) { 
      e.toolbarOptions.items.unshift(
        {
          locateInMenu:'auto',
          location: 'after',
          template: "videoIliupload",
        }
      )
  }

  hover(e){
    if (e.rowType === "data" && e.column.dataField === "materijal"){        
      if (e.eventType == 'mouseover'){
        e.cellElement.style.fontWeight = "bold";
        e.cellElement.style.fontSize = "18px";
        e.cellElement.style.cursor = 'pointer';
      } else {
        e.cellElement.style.fontWeight = "normal";
        e.cellElement.style.fontSize = "14px";
        }  
    }
  }

  cellClickEvent(e){
    // console.log(e.data);
    if (e.rowType == "data" && e.column.dataField === "materijal") {
      if (e.data.pmat_vrsta == "video" || e.data.pmpred_vrsta == "video") {
        this.vimeoURL = "https://player.vimeo.com/video/"+e.data.materijal;
        this.prikaziVideo = true;
      } else {
        var url = (this.datotekeURL + e.data.iduploaddokument + "&access_token=" + this.access_token);
        window.open(url, "_blank");
      }
    }
  }

  onCellPrepared(e){
    if (e.rowType === "data" && e.column.dataField === "materijal") {
      e.cellElement.style.color = '#03a9f4';
      e.cellElement.style.textDecoration = 'underline';
    }
  }

  prikaziUrediNaziv(){
    this.prikaziEdit = !this.prikaziEdit;
  }

  urediNaziv(){
    let predmetZaEdit = {
      "idpredmet": this.predmetID,
      "naziv": this.predmetDetalji.predmetNaziv,
      "access_token": this.access_token
    };
    this._api.predmetUredi(predmetZaEdit).subscribe(res=>{
      if(res.success == true){
        notify(res.message, "success");
        this.prikaziEdit = false;
      } else {
        notify(res.message, "error");
      }
    });
  }

  naziv_fieldDataChanged(e){
    this.predmetDetalji.predmetNaziv = e.value;
  }

}