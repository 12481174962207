   <div class="widget-container-chat">
        <!-- <div class="noviRazgovor">
            <dx-form
                [showColonAfterLabel]="true">
                <dxi-item itemType="group">
                         <dxi-item
                            [label]="{text: 'Novi razgovor'}"
                            dataField="chatNaziv"
                            [editorOptions]="{stylingMode: 'filled'}">
                        </dxi-item>
                </dxi-item>
            </dx-form>
            <dx-button class="msg_send_btn" icon="comment" type="default"></dx-button>
        </div> -->
        <div class="messaging">
            <div class="inbox_msg">
              <div class="inbox_people">
                <div class="headind_srch">
                  <div class="recent_heading">
                    <h4>Razgovori <dx-button style="float: right; position: relative; right: -40px;" icon="add" type="default" (click)="chatAdd()"></dx-button></h4>
                  </div>
                </div>
                <div class="inbox_chat">
                  <div class="chat_list" [className]="i == odabraniChat ? 'active_chat chat_list' : 'chat_list'" *ngFor="let chat of chatPopis; let i = index" (click)="odaberiJedanRazgovor(i, chat)">
                    <div class="chat_people">
                      <div class="chat_ib">
                        <h5>{{chat.ime}}</h5>
                        <p>{{chat.kor_punoIme}} <i class="dx-icon-trash brisanjeChata" *ngIf="prava == 0 || prava == 1 || prava == 2 && idkorisnik == korisnik_idkorisnik" (click)="brisanjeChata(chat)"></i></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mesgs">
                <div class="msg_history" #myList>
                  <div [className]="poruka.korisnik_idkorisnik != idkorisnik ? 'incoming_msg' : 'outgoing_msg'" *ngFor="let poruka of chatPoruke; let i = index">
                    <div [className]="poruka.korisnik_idkorisnik != idkorisnik ? 'received_msg' : 'sent_msg'">
                        <div [className]="poruka.korisnik_idkorisnik != idkorisnik ? 'received_withd_msg' : ''">
                            <span class="time_date" *ngIf="poruka.korisnik_idkorisnik != idkorisnik"><i class="dx-icon-user dx-icon-custom-style"></i> {{poruka.kor_punoIme}} <i class="dx-icon-clock dx-icon-custom-style"></i> {{poruka.datum_kreiranja}}h <i class="dx-icon-trash brisanjeChata" *ngIf="prava == 0 || prava == 1 || prava == 2 && idkorisnik == korisnik_idkorisnik" (click)="brisanjePoruke(poruka)"></i></span>
                            <span class="time_date" *ngIf="poruka.korisnik_idkorisnik == idkorisnik"><i class="dx-icon-clock dx-icon-custom-style"></i> {{poruka.datum_kreiranja}}h <i class="dx-icon-trash brisanjeChata" *ngIf="prava == 0 || prava == 1 || prava == 2 && idkorisnik == korisnik_idkorisnik" (click)="brisanjePoruke(poruka)"></i></span>
                            <p *ngIf="poruka.poruka != 'null'" [innerHTML]="poruka.poruka">{{last ? scrollToBottom() : ''}}</p>
                            <p *ngIf="poruka.ime_datoteke != null" (click)="otvoriMaterijalChat(poruka)" style="cursor: pointer;"><i class="dx-icon-file"></i>{{poruka.ime_datoteke}}</p>
                        </div>
                    </div>
                  </div>
                </div>
                <div class="type_msg">
                  <div class="input_msg_write">
                    <input #novaPoruka (keyup)="handleKeyUp($event, sadrzajPoruke)" type="text" [(ngModel)]="sadrzajPoruke" name="sadrzajPoruke" (ngModelChange)="valueChange($event)" [ngModelOptions]="{ standalone: true }" id="sadrzajPoruke" class="write_msg" placeholder="Poruka..."/>
                    <label>
                        <dx-button *ngIf="!datotekaChat && chatID != null" class="msg_send_btn_upload" icon="upload" type="default" (click)="chatUpload()"></dx-button>
                        <dx-button *ngIf="datotekaChat" class="msg_send_btn_upload" icon="close" type="default" (click)="chatUploadUndo()"></dx-button>
                        <input style="display:none" *ngIf="datotekaChat == null" (change)="uploadPocetak($event)" type="file" id="fileUpload"> <!-- (change)="uploadPocetak(event)" onchange="console.log(event.target.files)" -->
                    </label>
                    <dx-button class="msg_send_btn" icon="comment" type="default" (click)="posaljiPoruku(sadrzajPoruke)" [disabled]="zakljucajGumb || chatID == null"></dx-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </div>

    <!-- Novi chat -->
    <dx-popup
        [width]="600"
        [height]="'auto'"
        title="Kreiraj razgovor"
        [(visible)]="chatPopup">
        <dx-form
        [formData]="chatDetalji" 
        [showColonAfterLabel]="true"
        (onFieldDataChanged)="chat_fieldDataChanged($event)">
                <dxi-item [colCount]="2"
                    [label]="{text: 'Naziv razgovora'}"
                    dataField="chatNaziv"
                    [editorOptions]="{stylingMode: 'filled'}">
                    <dxi-validation-rule 
                        type="required"
                        message="Naziv je obavezno polje!">
                    </dxi-validation-rule>
                </dxi-item>
                <dxi-item>
                    <dx-button
                    style="margin-top: -30px; float: right; margin-bottom: -10px;"
                    stylingMode="contained"
                    text="Spremi"
                    icon="check"
                    [height]="50"
                    [width]="120"
                    (onClick)="kreirajChat()">
                    </dx-button>
                </dxi-item>
        </dx-form>
    </dx-popup>