import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ApiService } from 'src/app/shared/services';

import * as moment from 'moment';

import DataSource from 'devextreme/data/data_source';
import notify from 'devextreme/ui/notify';
import { DxAutocompleteComponent } from 'devextreme-angular';
import validationEngine from "devextreme/ui/validation_engine";
import { custom } from 'devextreme/ui/dialog';
import { encodeHtml } from 'devextreme/core/utils/string';
import { IdUcionicaService } from 'src/app/shared/services/id-ucionica.service';

@Component({
  selector: 'app-uredi-ucionicu',
  templateUrl: './uredi-ucionicu.component.html',
  styleUrls: ['./uredi-ucionicu.component.scss']
})

export class UrediUcionicuComponent implements OnInit {

  uploadURL = this._api.uploadURL;

  access_token: any = localStorage.getItem('access_token');
  prava = +localStorage.getItem('kor_prava');
  idkorisnik = localStorage.getItem('idkorisnik');
  
  datotekeURL = this._api.datotekeURL;
  @ViewChild('autocomplete') autocomplete: DxAutocompleteComponent;
  ucionica: any;
  ucionicaNaziv: string;
  gradovi: any;
  ucionicaID: any;
  nazivGrada: any;
  administratori: any;
  polaznici: any;
  predmeti: any;
  predavaci: any;
  odabranGrad: any;
  predmetID: any;
  predavaciIzvanUcionice: any;
  polazniciIzvanUcionice: any;
  idAdministratori = [];
  idPredmeti = [];
  adminPrikazi = [];
  predmetPrikazi = [];
  predavacPrikazi = [];
  polazniciPrikazi: any;
  nazivPredmeta: any;
  window: any;
  prikaziLoading = false;
  rokovi: any;
  glavniTabovi = [
    { id: 0, text: 'Osnovni podaci' },
    { id: 1, text: 'Osobe' },
    { id: 2, text: 'Predmeti' },
    { id: 3, text: 'Ispitni rokovi' },
    { id: 4, text: 'Predavanja i prisutnost' },
    { id: 5, text: 'Obavijesti' },
    { id: 6, text: 'Chat' }
  ];
  odabraniTab: number = 0;
  odabranGlavniTab: number = 0;
  predavaciPopup = false;
  polazniciPopup = false;
  materijaliPopup = false;
  polazniciPredmeta: any;
  globalniMaterijali: any;
  vimeoURL: string;
  prikaziVideo: boolean = false;
  kvizovi: any;
  kvizoviPopup = false;
  jedanKvizPopup = false;
  kvizNaziv: any;
  jedanIspitPopup = false;
  pitanjaZaIspit = [{
    'idispitpitanje': null,
    'ispp_naziv': null,
    'ispp_oblik': null,
    'ispp_tekst': null,
    'ispp_vrsta': null,
    'uploaddokument_iduploaddokument': null,
    'isprje_tocan': null
  }];
  odgovoriIspit = [{
    'idispitodgovor': null,
    'ispitpitanje_idispitpitanje': null,
    'ispo_tekst': null,
    'ispo_tocan': null,
    'ispo_vrsta': null,
    'uploaddokument_iduploaddokument': null,
    'korisnik_tocan_odgovor': null,
    'idispitrjeseno': null
  }];
  korisnikoviOdgovori= [];
  polaznikImePrezime: string;
  kvizPopup: boolean = false;
  pitanjeSlika: boolean = false;
  pitanjaZaKviz: any;
  ukupnoPitanja: number;
  pitanjeKviz: any;
  slika: any;
  prikaziZavrsniGumb: any;
  odgovoriKviz: any;
  ponudjeniOdgovori: any;
  prviBroj: number = 1;
  index: number = 0;
  vrstaKviza: any;
  odgovoriZaProvjeru = [];
  slovo = ['A)', 'B)', 'C)', 'D)'];
  nazivKviza: any;
  mozeSve: boolean = false;
  naslov: any;
  ispiti: any;
  minute = [ 45, 60, 90, 120, 150, 180, 210 ];
  predmet: any;
  ispit: any;
  vrijemeTrajanja: any;
  startDate: any;
  endDate: any;
  oznacen: any;
  editIspitniRokPopup = false;
  isEdited = false;
  obavijesti = [];
  predavanja = [];
  polazniciPredavanjaPrisutnost = [];
  idPredavanjeSlanje: any;
  IDispitROK: number;
  IDispit: number;
  polazniciIspitniRok = [];
  IDucionicaPredavanje: number;
  today = new Date();
  danas = new Date().toISOString();
  danasnjiDan: any;
  tocnostOdgovora: any;
  ocijeniIspitPopup: boolean = false;
  ocjene = ['1', '2', '3', '4', '5', 'Zadovoljio', 'Nije zadovoljio'];
  dodijeljenaOcjena: any;
  rezultatID: any;
  predavanje: any;
  ocjenePopup: boolean = false;
  ocjenaPredmeta: any;
  popupPolaznikKviz = false;
  imePolaznikaKviza: any;
  popisKvizovaPolaznika: any;
  minDate: Date = new Date();

  constructor(
    public _api: ApiService,
    private _activatedRoute: ActivatedRoute,
    private _idUcionicaService: IdUcionicaService,
    private _router: Router
  ) {
    // this.danasnjiDan = moment(this.danas).format();
    this.danasnjiDan = this.danas + 1;
    this.today.setDate(this.today.getDate() - 1);
     if(this.prava === 0 || this.prava === 1){
       this.mozeSve = true;
     } else {
       this.mozeSve = false;
     }

     this.onShowing = this.onShowing.bind(this);
     this.predmetChange = this.predmetChange.bind(this);
     this.predmetChangePredavanje = this.predmetChangePredavanje.bind(this);
     this.odabraniPredmetChange = this.odabraniPredmetChange.bind(this);
     this.ispitChange = this.ispitChange.bind(this);
     
     this.validateDatumOd = this.validateDatumOd.bind(this);
     this.validateDatumDo = this.validateDatumDo.bind(this);
    //  this.trajanjeChanged = this.trajanjeChanged.bind(this)
    //  this.setCellValue = this.setCellValue.bind(this)
  }

  ngOnInit() {
    this.ucionicaID = this._activatedRoute.snapshot.params['id'];
    // console.log(this.ucionicaID);
    this.dohvatiJednuUcionicu();
    this.dohvatiGradove();
    this.dohvatiAdministratore();
    // this.dohvatiPolaznike();
  }

  odaberiTab(e){
    this.odabranGlavniTab = e.itemIndex;
    // console.log(this.odabranGlavniTab);
    if(this.odabranGlavniTab === 0){
      this.dohvatiJednuUcionicu();
    } else if (this.odabranGlavniTab === 1){
      this.dohvatiJednuUcionicu();
    } else if (this.odabranGlavniTab === 2){
      this.dohvatiJednuUcionicu();
    } if(this.odabranGlavniTab === 3){
      this.dohvatiIspitneRokove();
      this.dohvatiJednuUcionicu();
    } else if (this.odabranGlavniTab === 4){
      this.popisPredavanja();
      this.dohvatiJednuUcionicu();
    } else if (this.odabranGlavniTab === 5){
      this.popisObavijesti();
    } else if (this.odabranGlavniTab === 6){
      this._idUcionicaService.posaljiIdUcionica(this.ucionicaID);
    }
  }

  onGradSelected(e){
    // console.log(e);
    this.odabranGrad = e.value;
  }

  ucionicaEdit(e){
    this._api.ucionicaUredi({
        "access_token": this.access_token,
        "obrazovnaGodina": e.uci_obrazovnaGodina,
        "naziv": e.uci_naziv,
        "idgrad": this.odabranGrad,
        "iducionica": this.ucionicaID
    }).subscribe(res => {
      if(res.success == true){
        notify(res.message, 'success', 1000);
      } else {
        notify(res.message, 'error', 1000);
      }
    });
  }

  /**---------------------------- predmeti / materijali ------------------------------------------- */
  materijaliNaPredmetu(e, idpredmet, pred_naziv){
    this.materijaliPopup = true;
    this.predmetID = idpredmet;
    this.nazivPredmeta = pred_naziv;
    this.dohvatiGlobalneMaterijale();
  }

  checkboxChange(e, data){
    // console.log({"access_token": this.access_token, "iducionica": this.ucionicaID, "idpredmet": data.data.predmet_idpredmet, "idpredmetmaterijalpredavac": data.data.idpredmetmaterijalpredavac});
    this._api.ucionicaGlobalniMaterijaliIzmjena({"access_token": this.access_token, "iducionica": this.ucionicaID, "idpredmet": data.data.predmet_idpredmet, "idpredmetmaterijal": data.data.idpredmetmaterijal}).subscribe(res=> {
      if(res.success == true){
        notify(res.message, 'success', 1000);
      } else {
        notify(res.message, 'error', 1000);
      }
    });
  }

  hover(e){
    if (e.rowType === "data" && e.column.dataField === "pmat_naziv"){   
      if (e.eventType == 'mouseover'){
        e.cellElement.style.fontWeight = "bold";
        e.cellElement.style.fontSize = "18px";
        e.cellElement.style.cursor = 'pointer';
      } else {
        e.cellElement.style.fontWeight = "normal";
        e.cellElement.style.fontSize = "14px";
      }
    }
  }
  
  cellClickEvent(e){
    if (e.rowType == "data" && e.column.dataField === "pmat_naziv"){
        this.prikaziLoading = true;
      if (e.data.pmat_vrsta == "video" || e.data.pmpred_vrsta == "video") {
        this.vimeoURL = "https://player.vimeo.com/video/"+e.data.pmat_link;
        this.prikaziVideo = true;
        this.prikaziLoading = false;
      } else {
        var url = (this.datotekeURL + e.data.uploaddokument_iduploaddokument + "&access_token=" + this.access_token);
        window.open(url, "_blank");
      }
    }
  }
  
  onCellPrepared(e){
    if (e.rowType === "data" && e.column.dataField === "pmat_naziv"){
        e.cellElement.style.color = '#03a9f4';
        e.cellElement.style.textDecoration = 'underline';
    }
  }

  /**---------------------------- predmeti / kvizovi ------------------------------------------- */
  kvizoviNaPredmetu(e, idpredmet, pred_naziv){
    // console.log(e);
    this.kvizoviPopup = true;
    this.predmetID = idpredmet;
    this.nazivPredmeta = pred_naziv;
    this.popisKvizova();
  }

  hoverKviz(e){
    if (e.rowType === "data" && e.column.dataField === "nazivKviza"){
      if (e.eventType == 'mouseover'){
        e.cellElement.style.fontWeight = "bold";
        e.cellElement.style.fontSize = "18px";
        e.cellElement.style.cursor = 'pointer';
      } else {
        e.cellElement.style.fontWeight = "normal";
        e.cellElement.style.fontSize = "14px";
        }
    }
  }

  cellClickKviz(e){
    if (e.rowType == "data" && e.column.dataField === "nazivKviza") {
      this.jedanKvizPopup = true;
        // this.kvizID = e.data.idkviz+
      this._api.kvizJedan({"access_token": this.access_token, "idkviz": e.data.idkviz}).subscribe(res => {
          if(res.success === true){
            // this.predavacPrikazi = res.predavaci.filter(predavac => predavac.uUcionici === 1)    
          this.pitanjaZaKviz = res.pitanja;
          this.odgovoriKviz = res.odgovori;
          this.ukupnoPitanja = res.pitanja.length;
          this.nazivKviza = res.kviz[0].kviz_naziv;
          this.pitanjaKviz();
        } else {
          notify(res.message, 'error', 1000);
        }
      });
    }
  }

  slikaPrikazi(slika){
    var prikaz = this.datotekeURL + slika + "&access_token=" + this.access_token;
    window.open(prikaz, "_blank");
  }

  // pitanja sa odgovorima za kviz
  pitanjaKviz(){
      for (let i = 0; i < this.pitanjaZaKviz.length; i++){
          if(this.pitanjaZaKviz[i].kpit_vrsta === 0){
            this.pitanjeSlika = false;
          } else {
            this.pitanjeSlika = true;
          }
      }
  }

  /**++++++++++++++++++++++++ polaznik autocomplete ++++++++++++++++++++++++ */
  polaznikTrazi(e){
    if(e.value){
      if (e.value.length >= 3) {
        // console.log(e);
        this._api.korisnikPopisPretrazivanje({"access_token": this.access_token, "prava": 3, "ime": e.value, "iducionica": this.ucionicaID}).subscribe(res => {
          this.polazniciIzvanUcionice = res.korisnici;
        });
      }
    }
  }

  odabranPolaznik(e){
    this._api.ucionicaPolaznikIzmjena({"access_token": this.access_token, "iducionica": this.ucionicaID, "idkorisnik": e.itemData.idkorisnik}).subscribe(res => {
      if(res.success == true){
        notify(res.message, 'success', 1000);
        this.dohvatiJednuUcionicu();
        this.autocomplete.instance.reset();
      } else {
        notify(res.message, 'error', 1000);
      } 
    });
  }

  obrisiPolaznika(buttonInfo){
    // console.log(buttonInfo.key.idkorisnik);
    let encodedMessage = encodeHtml("Jeste li sigurni da želite obrisati polaznika?");
    let myDialog = custom({
        title: "Brisanje polaznika",
        messageHtml: encodedMessage,
        buttons: [{
            text: "NE",
            onClick: (e) => {
                return { buttonText: e.component.option("text") }
            }
        },{
            text: "DA",
            onClick: (e) => {
                return { buttonText: e.component.option("text") }
            }
        }]
    });
    myDialog.show().then((dialogResult) => {
       if(dialogResult.buttonText === 'DA'){
         this._api.ucionicaPolaznikIzmjena({"access_token": this.access_token, "iducionica": this.ucionicaID, "idkorisnik": buttonInfo.key.idkorisnik}).subscribe(res => {
          // console.log(res);
          if(res.success == true){
            this.dohvatiJednuUcionicu();
            notify(res.message, 'success', 1000);
          } else {
            notify(res.message, 'error', 1000);
          }
        });
       }
    });
  }

  cellClickEventPolaznik(e){
    // console.log(e.data);
    
    if (e.rowType == "data" && e.column.dataField === "kvrez_naziv"){
        this.prikaziLoading = true;  
        this.jedanKvizPopup = true;
        this._api.kvizJedan({"access_token": this.access_token, "idkviz": e.data.kviz_idkviz}).subscribe(res => {
          if(res.success === true){   
          this.pitanjaZaKviz = res.pitanja;
          this.odgovoriKviz = res.odgovori;
          this.ukupnoPitanja = res.pitanja.length;
          this.nazivKviza = res.kviz[0].kviz_naziv;
          this.pitanjaKviz();
        } else {
          notify(res.message, 'error', 1000);
        }
      });    
    }
  }
  
  onCellPreparedPolaznik(e){
    if (e.rowType === "data" && e.column.dataField === "kvrez_naziv"){
        // e.cellElement.style.color = '#03a9f4';
        // e.cellElement.style.textDecoration = 'underline';
    }
  }

  hoverKvizoviPolaznika(e){
  if (e.rowType === "data" && e.column.dataField === "kvrez_naziv"){
    if (e.eventType == 'mouseover'){
      e.cellElement.style.fontWeight = "bold";
      e.cellElement.style.fontSize = "18px";
      e.cellElement.style.cursor = 'pointer';
    } else {
      e.cellElement.style.fontWeight = "normal";
      e.cellElement.style.fontSize = "14px";
    }  
  }
}

  // Kvizovi polaznika
  kvizoviPolaznika(e){
    // console.log(e.data);    
    let podaci = {
      prava: this.prava,
      idkorisnik: e.data.idkorisnik,
      iducionica: +this.ucionicaID,
      access_token: this.access_token
    }
    // console.log(this.apiSlanje);  
    this.imePolaznikaKviza = e.data.kor_punoIme;
    this.popupPolaznikKviz = true;  
    
    this._api.rjKvizPopis(podaci).subscribe(res =>{
      // console.log(res);
      this.popisKvizovaPolaznika = res.kvizovi;      
    })
  }

    // prikaz postotka kod iznosa rabata
    postotakPrikaz (cellInfo) {
      return cellInfo.value + "%";
    }

//**----------------------------------- Dodavanje administratora ----------------------------------------------------- */
  onAdminSelected(e, data){
    this._api.ucionicaAdministratorIzmjena({"access_token": this.access_token, idkorisnik: data.data.idkorisnik, iducionica: +this.ucionicaID}).subscribe(res =>{
          // console.log(res);
          if(res.success == true){
            notify(res.message, 'success', 1000);
            this.dohvatiJednuUcionicu();
          } else {
            notify(res.message, 'error', 1000);
          }
    });
  }
  
  //**----------------------------------- Dodavanje predmeta ----------------------------------------------------- */
  onPredmetSelected(e){
    if(e.itemElement.ariaSelected === 'true'){
       this.predmetPrikazi.push(e.itemData);
       this._api.ucionicaPredmetIzmjena({"access_token": this.access_token, idpredmet: e.itemData.idpredmet, iducionica: +this.ucionicaID}).subscribe(res =>{ 
          if(res.success == true){
            notify(res.message, 'success', 1000);
          } else {
            notify(res.message, 'error', 1000);
          }
       });
    } else {
        this.predmetPrikazi = this.predmetPrikazi.filter(({ idpredmet }) => idpredmet !== e.itemData.idpredmet);
        this._api.ucionicaPredmetIzmjena({"access_token": this.access_token, idpredmet: e.itemData.idpredmet, iducionica: +this.ucionicaID}).subscribe(res =>{
            if(res.success == true){
              notify(res.message, 'success', 1000);
            } else {
              notify(res.message, 'error', 1000);
            }
        });
    }
  }

//**------------------------------- ISPITNI ROKOVI ------------------------------------------ */
  onCellPreparedRokovi(e){
    if (e.rowType === "data" && e.column.command === "edit"){
      let ispitVrijeme = moment(e.data.krajIspita).format();
      if (ispitVrijeme < this.danasnjiDan){
        e.cellElement.classList.add("novo");
      }}
  }

  onRowPreparedIspitniRok(e){
    if (e.rowType == 'data') {
       if(e.data.krajIspita != undefined || e.data.krajIspita != null){
          let ispitVrijeme = moment(e.data.krajIspita).format();
          if (ispitVrijeme < this.danasnjiDan){
            e.rowElement.style.background = '#EEEEEE';
          }
        }
    }
  }

  onInitNewRow(e){
    this.startDate = null;
    this.endDate = null;
    this.isEdited = false;
    // console.log(this.isEdited)
    this.naslov = 'Dodavanje ispitnog roka';
    //  this.predmet = null;
    //  this.ispit = null; 
    this.oznacen = null;
    this.ispiti = [];
  }

  onEditingStart(e){
    this.isEdited = true;
    this.naslov = 'Uređivanje ispitnog roka';
    // console.log(e.data)
    switch(e.data.isprok_trajanje){
      case '45':
        this.oznacen = this.minute[0];
        break;
      case '60':
        this.oznacen = this.minute[1];
        break;
      case '90':
        this.oznacen = this.minute[2];
        break;
      case '120':
        this.oznacen = this.minute[3];
        break;
      case '150':
        this.oznacen = this.minute[4];
        break;
      case '180':
        this.oznacen = this.minute[5];
        break;
      case '210':
        this.oznacen = this.minute[6];
        break;
    }
    this.ispit = e.data.idispit;
    // this.predmet = e.data.idpredmet;

    this.dohvatiPopisIspita(e.data.idpredmet);
  }

  onShowing(e){
    e.component.option("title", this.naslov);
  }

  predmetChange(rowData: any, value: any){
    rowData.idpredmet = value;
    this.dohvatiPopisIspita(value);
  }

  ispitChange(e){
    this.ispit = e.value;
  }

  trajanjeChanged(e: any, data){
    this.vrijemeTrajanja = e.value;
    data.setValue(e.value);
  }

  spremiIspitniRok(e){
    // this.vrijemeTrajanja = this.trajanjeChanged.bind(this);
    // console.log(e.data);
    let podaci = {
      "access_token": this.access_token,
      "iducionica": this.ucionicaID,
      "idispit": this.ispit,
      "kreirao": +localStorage.getItem('idkorisnik'),
      "pocetak": moment(this.startDate).format('YYYY-MM-DD'),
      "kraj": moment(this.endDate).format('YYYY-MM-DD'),
      "naziv": e.data.isprok_naziv,
      "trajanje": this.vrijemeTrajanja
    };
    // console.log(podaci);
    this._api.ispitniRokDodaj(podaci).subscribe(res => {
      if(res.success == true){
        notify(res.message, "success", 1000);
        this.dohvatiIspitneRokove();
      } else {
        notify(res.message, "error", 1000);
        this.dohvatiIspitneRokove();
      }
    });
  }

  urediIspitniRok(e){
    let podaciEdit = {
      "access_token": this.access_token,
      "idispitrok": e.data.idispitrok,
      "pocetak": moment(this.startDate).format('YYYY-MM-DD'),
      "kraj": moment(this.endDate).format('YYYY-MM-DD'),
      "naziv": e.data.isprok_naziv,
      "trajanje": +e.data.isprok_trajanje,
      "uredio": +localStorage.getItem('idkorisnik')
    };
    // console.log(podaciEdit)
    this._api.ispitniRokUredi(podaciEdit).subscribe(res => {
      if(res.success == true){
        notify(res.message, "success", 1000);
        this.dohvatiIspitneRokove();
      } else {
        notify(res.message, "error", 1000);
        this.dohvatiIspitneRokove();
      }
    });
  }

  onIspitniRokExpanding(e){
    e.component.collapseAll(-1);
    this.IDispitROK = e.key.idispitrok;
    this.IDispit = e.key.idispit;
    this._api.ispitniRokKorisnici({"idispitrok": this.IDispitROK, "access_token": this.access_token}).subscribe(res => {
      if(res.success == true){
        this.polazniciIspitniRok = res.ispitniRokKorisnici;
      } else {
        notify(res.message, "error");
      }
    });
  }

  validateDatumOd(e){
    // console.log(this.today);
    this.startDate = e.value;
    // console.log(this.startDate);
    // console.log(this.endDate);
    if(this.isEdited == false){
      var isValid = true;
      if(e.value != null && (e.value < this.today)){
            isValid = false;
            e.rule.message = 'Odabrani datum ne može biti prije današnjeg!';
      }
      if(e.value != null && (this.endDate != null || this.endDate != undefined)){
        if (this.endDate < e.value) {
            isValid = false;
            e.rule.message = 'Početni datum mora biti manji od završnog!';
        }
      }
      return isValid;
    } else {
      var isValid = true;
      return isValid;
    }
  }

  validateDatumDo(e){
    this.endDate = e.value;
    // console.log(this.startDate);
    // console.log(this.endDate);
    var isValid = true;
    if(e.value != null){
      if(this.startDate > e.value){
        isValid = false;
        e.rule.message = "Završni datum mora biti veći od početnog!";
      }
    }
    return isValid;
  } 

  prikaziIspitPolaznika(e){
    this.dodijeljenaOcjena = e.values[2];    // dodano radi prikaza ocjene
    this.pitanjaZaIspit = [];
    this.odgovoriIspit = [];
    this.korisnikoviOdgovori = [];
    if (e.rowType == "data" && e.column.cellTemplate === "ispitPolaznika") {
        this.jedanIspitPopup = true;
        this.polaznikImePrezime = e.data.kor_punoIme;
        //Pozivanje API-ja sa detaljima ispita jednog polaznika
        let podaciIspitPolaznik = {
          "idkorisnik": e.data.idkorisnik,
          "idispitrok": this.IDispitROK,
          "idispit": this.IDispit,
          "access_token": this.access_token
        };
        this._api.ispitJedanZaOcjenjivanje(podaciIspitPolaznik).subscribe(res=>{
          this.rezultatID = res.korisnikoviOdgovori[0].ispitrezultat_idispitrezultat;      // novo dodano
          // console.log(res)
          // this.pitanjaZaIspit = res.pitanja;
          for(let k = 0; k < res.pitanja.length; k++){
          this.pitanjaZaIspit.push({
                  'idispitpitanje': res.pitanja[k].idispitpitanje,
                  'ispp_naziv': res.pitanja[k].ispp_naziv,
                  'ispp_oblik': res.pitanja[k].ispp_oblik,
                  'ispp_tekst': res.pitanja[k].ispp_tekst,
                  'ispp_vrsta': res.pitanja[k].ispp_vrsta,
                  'uploaddokument_iduploaddokument': res.pitanja[k].uploaddokument_iduploaddokument,
                  'isprje_tocan': null
                })
          }
          // this.odgovoriIspit = res.odgovori;
          // this.korisnikoviOdgovori = res.korisnikoviOdgovori;
          // OBLIKA PITANJA 0 i 1
          for(let i = 0; i < res.odgovori.length; i++){
            for(let j = 0; j <  this.pitanjaZaIspit.length; j++){
              //Kvizaško pitanje
              if(this.pitanjaZaIspit[j].ispp_oblik == 0 && this.pitanjaZaIspit[j].idispitpitanje == res.odgovori[i].ispitpitanje_idispitpitanje){
                this.odgovoriIspit.push({
                  'idispitodgovor': res.odgovori[i].idispitodgovor,
                  'ispitpitanje_idispitpitanje': res.odgovori[i].ispitpitanje_idispitpitanje,
                  'ispo_tekst': res.odgovori[i].ispo_tekst,
                  'ispo_tocan': res.odgovori[i].ispo_tocan,
                  'ispo_vrsta': res.odgovori[i].ispo_vrsta,
                  'uploaddokument_iduploaddokument': res.odgovori[i].uploaddokument_iduploaddokument,
                  'korisnik_tocan_odgovor': null,
                  "idispitrjeseno": null,
                });
                for(let x = 0; x < res.korisnikoviOdgovori.length; x++){
                   if(res.odgovori[i].idispitodgovor == res.korisnikoviOdgovori[x].ispitodgovor_idispitodgovor){
                     this.odgovoriIspit[i].korisnik_tocan_odgovor = true;
                     this.odgovoriIspit[i].idispitrjeseno = res.korisnikoviOdgovori[x].idispitrjeseno;
                     this.pitanjaZaIspit[x].isprje_tocan = res.korisnikoviOdgovori[x].isprje_tocan;
                   }                 
                }
              }
              //Točno/netočno
              if(this.pitanjaZaIspit[j].ispp_oblik == 1 && this.pitanjaZaIspit[j].idispitpitanje == res.odgovori[i].ispitpitanje_idispitpitanje){
                this.odgovoriIspit.push({
                  'idispitodgovor': res.odgovori[i].idispitodgovor,
                  'ispitpitanje_idispitpitanje': res.odgovori[i].ispitpitanje_idispitpitanje,
                  'ispo_tekst': res.odgovori[i].ispo_tekst,
                  'ispo_tocan': res.odgovori[i].ispo_tocan,
                  'ispo_vrsta': res.odgovori[i].ispo_vrsta,
                  'uploaddokument_iduploaddokument': res.odgovori[i].uploaddokument_iduploaddokument,
                  'korisnik_tocan_odgovor': null,
                  "idispitrjeseno": null,
                });
                for(let x = 0; x < res.korisnikoviOdgovori.length; x++){
                   if(res.odgovori[i].idispitodgovor == res.korisnikoviOdgovori[x].ispitodgovor_idispitodgovor){
                     this.odgovoriIspit[i].korisnik_tocan_odgovor = true;
                     this.odgovoriIspit[i].idispitrjeseno = res.korisnikoviOdgovori[x].idispitrjeseno;
                     this.pitanjaZaIspit[x].isprje_tocan = res.korisnikoviOdgovori[x].isprje_tocan;
                   }
                }
                // this.odgovoriIspit.push(res.odgovori[i]);
              }
            }            
          
          }
          // OBLIKA PITANJA 2, 3 i 4
          for(let i = 0; i < res.korisnikoviOdgovori.length; i++){
            for(let j = 0; j < this.pitanjaZaIspit.length; j++){
                 //Esej
                 if(this.pitanjaZaIspit[j].ispp_oblik == 2 && this.pitanjaZaIspit[j].idispitpitanje == res.korisnikoviOdgovori[i].ispitpitanje_idispitpitanje){
                    this.korisnikoviOdgovori.push(res.korisnikoviOdgovori[i]);
                    this.pitanjaZaIspit[j].isprje_tocan = res.korisnikoviOdgovori[i].isprje_tocan;
                 }
                 //Nadopunjavanje
                 if(this.pitanjaZaIspit[j].ispp_oblik == 3 && this.pitanjaZaIspit[j].idispitpitanje == res.korisnikoviOdgovori[i].ispitpitanje_idispitpitanje){
                    this.korisnikoviOdgovori.push(res.korisnikoviOdgovori[i]);
                    this.pitanjaZaIspit[j].isprje_tocan = res.korisnikoviOdgovori[i].isprje_tocan;
                 }
                 //Upload
                 if(this.pitanjaZaIspit[j].ispp_oblik == 4 && this.pitanjaZaIspit[j].idispitpitanje == res.korisnikoviOdgovori[i].ispitpitanje_idispitpitanje){
                    this.korisnikoviOdgovori.push(res.korisnikoviOdgovori[i]);
                    this.pitanjaZaIspit[j].isprje_tocan = res.korisnikoviOdgovori[i].isprje_tocan;
                 }
            }
          };
          // console.log(this.pitanjaZaIspit)
        });
    }
  }

////////////////////////////////////
  dohvatiGradove(){
    this._api.gradPopis({"access_token": this.access_token}).subscribe(res=>{
      if(res.success == true){
        this.gradovi = new DataSource({
          store: {
              data: res.gradovi,
              type: 'array',
              key: 'idsifarnikgrad'
          },
          group: "Category"
      });
      } else {
        notify(res.message, "error", 1000);
      }
    });
  }

  dohvatiJednuUcionicu(){
    this.idAdministratori = [];
    this.idPredmeti = [];

    this._api.ucionicaJednaProsireno({"access_token": this.access_token, "iducionica": this.ucionicaID}).subscribe(res =>{
      if(res.success === true){
        this.ucionicaNaziv = res.ucionica.uci_naziv;
        this.ucionica = res.ucionica;
        this.nazivGrada = res.ucionica.idsifarnikgrad;
        this.administratori = res.administratori;
        this.polaznici = res.polaznici;
        this.predmeti = res.predmeti;
        this.predavaci = res.predavaci;

      // prikaz u padajućem izborniku
       this.adminPrikazi = res.administratori.filter(admin => admin.uUcionici === 1);
       this.predmetPrikazi = res.predmeti.filter(predmet => predmet.uUcionici === 1);

        for(let i = 0; i < this.adminPrikazi.length; i++){
          this.idAdministratori.push(this.adminPrikazi[i].idkorisnik)
        }
        for(let j = 0; j < this.predmetPrikazi.length; j++){
            this.idPredmeti.push(this.predmetPrikazi[j].idpredmet)         
        }
      } else {
        notify(res.message, "error", 1000);
      }
    });
  }
  
  dohvatiAdministratore(){
    this._api.korisnikPopis({"access_token": this.access_token, "prava": 1}).subscribe(res=>{
      if(res.success == true){
        this.administratori = res.korisnici;
      } else {
        notify(res.message, "error", 1000);
      }
    });
  }

  dohvatiPredavaceIzvanUcionice(){
    this._api.korisnikPopis({"access_token": this.access_token, "prava": 2}).subscribe(res=>{
      if(res.success == true){
        this.predavaciIzvanUcionice = res.korisnici;
      } else {
        notify(res.message, "error", 1000);
      }
    });
  }

  dohvatiPolaznikeIzvanUcionice(){
    this._api.korisnikPopis({"access_token": this.access_token, "prava": 3}).subscribe(res=>{
      if(res.success == true){
        this.polazniciIzvanUcionice = res.korisnici;
      } else {
        notify(res.message, "error", 1000);
      }
    });
  }

  predavaciNaPredmetu(e, idpredmet, pred_naziv){
    this.predavaciPopup = true;
    this.predmetID = idpredmet;
    this.nazivPredmeta = pred_naziv;
    this.dohvatiPredavace();
  }

  polazniciNaPredmetu(e, idpredmet, pred_naziv){
    this.polazniciPopup = true;
    this.predmetID = idpredmet;
    this.nazivPredmeta = pred_naziv;
    this.dohvatiPolaznike();
  }

  polaznikPolozioPredmet(e, data){
    this._api.ucionicaZavrsenPredmetIzmjena({"access_token": this.access_token, "iducionica": this.ucionicaID, "idpredmet": this.predmetID, "idkorisnik": data.data.korisnik_idkorisnik}).subscribe(res => {
      if(res.success == true){
          if(res.message === "Korisnik dodan na predmet"){
            notify("Polaznik ne treba polagati ovaj predmet", 'success', 1000);
          }
          if(res.message === "Korisnik uklonjen s predmeta"){
            notify("Polaznik treba polagati ovaj predmet", 'success', 1000);
          }
          this.dohvatiPolaznike();
        } else {
          notify(res.message, 'error', 1000);
          this.dohvatiPolaznike();
        }
    });
  }

  odabranPredavacNaPredmetu(e, data){
    this._api.ucionicaPredavacIzmjena({"access_token": this.access_token, "iducionica": this.ucionicaID, "idpredmet": this.predmetID, "idkorisnik": data.data.korisnik_idkorisnik}).subscribe(res =>{
        if(res.success == true){
          notify(res.message, 'success', 1000);
          this.dohvatiPredavace();
        } else {
          notify(res.message, 'error', 1000);
          this.dohvatiPredavace();
        }
    })
  }
  
  dohvatiPredavace(){
    // this.idPredavaci = [];
    this._api.predmetPredavaci({"access_token": this.access_token, "idpredmet": this.predmetID, "iducionica": this.ucionicaID}).subscribe(res=>{
      if(res.success == true){
        this.predavacPrikazi = res.predavaci;
      } else {
        notify(res.message, "error", 1000);
      }
    });
  }

  dohvatiPolaznike(){
    this._api.predmetPolaznici({"access_token": this.access_token, "idpredmet": this.predmetID, "iducionica": this.ucionicaID}).subscribe(res=>{
      if(res.success == true){
        this.polazniciPrikazi = res.polaznici;
      } else {
        notify(res.message, "error", 1000);
      }
    });
  }

  dohvatiGlobalneMaterijale(){
    this._api.predmetMaterijaliZaUcionicu({"access_token": this.access_token, "idpredmet": this.predmetID, "iducionica": this.ucionicaID}).subscribe(res => {
      if(res.success == true){
        this.globalniMaterijali = res.predmeti;
      } else {
        notify(res.message, "error", 1000);
      }
    });
  }

  popisKvizova(){
    this._api.kvizPopisZaKorisnika({"access_token": this.access_token, "idpredmet": this.predmetID, "idkorisnik": +localStorage.getItem('idkorisnik')}).subscribe(res => {
      if(res.success == true){
        this.kvizovi = res.kvizovi;
      } else {
        notify(res.message, "error", 1000);
      }     
    });
  }

  dohvatiIspitneRokove(){
    this._api.ispitniRokoviPopisZaUcionicu({"access_token": this.access_token, "iducionica": this.ucionicaID}).subscribe(res => {
      if(res.success == true){
       this.rokovi = res.ispitniRokovi;
      } else {
        notify(res.message, "error", 1000);
      }
    });
  }

  dohvatiPopisIspita(predmet){
    this._api.ispitPopis({"access_token": this.access_token, "idpredmet": predmet}).subscribe(res=>{
      if(res.success == true){
        this.ispiti = res.ispiti;
      } else {
        notify(res.message, "error");
      }
    });
  }

  /* Obavijesti */

  popisObavijesti(){
    this._api.ucionicaObavijestPopis({"iducionica": this.ucionicaID, "access_token": this.access_token}).subscribe(res=>{
      if(res.success == true){
        this.obavijesti = res['obavijesti'];
      } else {
        notify(res.message, "error");
      }
    });
  }

  onInitObavijest(e){
    this.naslov = 'Dodaj obavijest';
  }

  onRowInserted(e){
    let obavijestZaSlanje = {
      "iducionica": this.ucionicaID,
      "naslov": e.data['uciob_naslov'],
      "tekst": e.data['uciob_tekst'],
      "idkorisnik": this.idkorisnik,
      "access_token": this.access_token
    };
    this._api.ucionicaObavijestDodaj(obavijestZaSlanje).subscribe(res=>{
      if(res.success == true){
        notify(res.message, "success");
        this.popisObavijesti();
      } else {
        notify(res.message, "error");
      }
    });
  }

  onEditingObavijest(e){
    this.naslov = 'Uredi obavijest';
  }

  onRowUpdatedObavijest(e){
    let obavijestZaEdit = {
      "iducionicaobavijest": e.data['iducionicaobavijest'],
      "naslov": e.data['uciob_naslov'],
      "tekst": e.data['uciob_tekst'],
      "access_token": this.access_token
    };
    this._api.ucionicaObavijestUredi(obavijestZaEdit).subscribe(res=>{
      if(res.success == true){
        notify(res.message, "success");
        this.popisObavijesti();
      } else {
        notify(res.message, "error");
      }
    });
  }

  onRowRemovedObavijest(e){
    this._api.ucionicaObavijestObrisi({"iducionicaobavijest": e.data['iducionicaobavijest'], "access_token": this.access_token}).subscribe(res=>{
      if(res.success == true){
        notify(res.message, "success");
        this.popisObavijesti();
      } else {
        notify(res.message, "error");
      }
    });                                                       
  }

  //**------------------------------- PREDAVANJA I PRISUTNOST ------------------------------------------ */
  onRowPreparedPredavanja(e){
    if (e.rowType == 'data') {
      if(e.values[2]){
      // console.log(e)
      let d = e.values[2].toISOString();
      let b = moment(d).format();
      if (b < this.danasnjiDan){
      // e.rowElement.style.textDecoration = 'line-through'
      e.rowElement.style.background = '#EEEEEE';
      }
     } 
    }
  }

  popisPredavanja(){
    this._api.ucionicaPredavanjePopis({"iducionica":this.ucionicaID, "access_token": this.access_token}).subscribe(res=>{
      if(res.success == true){
        this.predavanja = res.predavanja;        
      } else {
        notify(res.message, "error");
      }
    });
  }

  onPredavanjeExpanding(e){
    e.component.collapseAll(-1);
    this.IDucionicaPredavanje = e.key.iducionicapredavanje;
    this.polazniciPrisutnost(e.key.iducionicapredavanje);
  }

  polazniciPrisutnost(iducionicapredavanje){
    this._api.ucionicaPredavanjePopisPrisutnost({"iducionica":this.ucionicaID, "iducionicapredavanje": iducionicapredavanje, "access_token": this.access_token}).subscribe(res=>{
      if(res.success == true){
        this.polazniciPredavanjaPrisutnost = res.polaznici;
      } else {
        notify(res.message, "error");
      }
    });
  }

  onInitPredavanje(e){;
    this.naslov = 'Dodavanje predavanja';
    this.idPredavanjeSlanje = null;
  }

  spremiPredavanje(e){
    // console.log(e.data);
    let predavanjeZaSlanje = {
      'naziv': e.data['ucip_naziv'],
      'vrijeme': moment(e.data['ucip_vrijeme']).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      'trajanje': e.data['ucip_trajanje'],
      'lozinka': e.data['ucip_lozinka'],
      'iducionica': +this.ucionicaID,
      'idpredmet': e.data['predmet_idpredmet'],  /* e.data['idpredmet'] */ // this.idPredavanjeSlanje,
      'email': localStorage.getItem('kor_email'),
      "access_token": this.access_token
    };
  //  console.log(predavanjeZaSlanje)
    this._api.ucionicaKreirajPredavanje(predavanjeZaSlanje).subscribe(res=>{
      if(res.success == true){
       notify(res.message, "success");
       this.popisPredavanja();
       } else {
       notify(res.message, "error");
       this.popisPredavanja();
       }
    });
  }

  onEditingPredavanja(e){
    //console.log(e.data);
    this.isEdited = true;
    this.naslov = 'Uređivanje predavanja';
    switch(e.data.ucip_trajanje){
      case 45:
        this.oznacen = this.minute[0];
        break;
      case 60:
        this.oznacen = this.minute[1];
        break;
      case 90:
        this.oznacen = this.minute[2];
        break;
      case 120:
        this.oznacen = this.minute[3];
        break;
      case 150:
        this.oznacen = this.minute[4];
        break;
      case 180:
        this.oznacen = this.minute[5];
        break;
      case 210:
        this.oznacen = this.minute[6];
        break;
    }
    //this.ispit = e.data.idispit;
  }

  onRowUpdatedPredavanje(e){
    //console.log(e.data);
    let predavanjeZaEdit = {
      'iducionicapredavanje': e.data['iducionicapredavanje'],
      'meetingID': e.data['ucip_meetingID'],
      'naziv': e.data['ucip_naziv'],
      'vrijeme': moment(e.data['ucip_vrijeme']).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      'trajanje': e.data['ucip_trajanje'],
      'lozinka': e.data['ucip_lozinka'],
      'iducionica': +this.ucionicaID,
      'idpredmet': e.data['predmet_idpredmet'], // this.idPredavanjeSlanje, // || e.data['predmet_idpredmet'],
      'email': localStorage.getItem('kor_email'),
      "access_token": this.access_token
    };
    //console.log(predavanjeZaEdit);
    this._api.ucionicaUrediPredavanje(predavanjeZaEdit).subscribe(res=>{
      //console.log(res);
        if(res.success == true){
          notify(res.message, "success", 1000);
          this.popisPredavanja();
        } else {
          notify(res.message, "error", 1000);
        }
      });
  }

  cellPredavanjeEvent(e){
    if(e.values != undefined && e.values.length > 0){
      let d = e.values[2].toISOString();
      var b = moment(d).format();
    }
    if (e.rowType == "data" && e.column.dataField === "ucip_meetingID" && this.danasnjiDan <= b) {
      var url = e.data.ucip_linkPredavac;
      window.open(url, "_blank"); 
    }
  }

  hoverPredavanje(e){
    if(e.values != undefined && e.values.length > 0){
      let d = e.values[2].toISOString();
      var b = moment(d).format();
    }
    if (e.rowType === "data" && e.column.dataField === "ucip_meetingID" && this.danasnjiDan <= b){
      if (e.eventType == 'mouseover'){
        e.cellElement.style.fontWeight = "bold";
        e.cellElement.style.fontSize = "18px";
        e.cellElement.style.cursor = 'pointer';
      } else {
        e.cellElement.style.fontWeight = "normal";
        e.cellElement.style.fontSize = "14px";
      }  
    }
  }

  onPredavanjePrepared(e){
    if(e.values != undefined && e.values.length > 0){
      let d = e.values[2].toISOString();
      var b = moment(d).format();
    }
    if (e.rowType === "data" && e.column.dataField === "ucip_meetingID" && this.danasnjiDan <= b) {
      e.cellElement.style.color = '#03a9f4';
      e.cellElement.style.textDecoration = 'underline';
    }
    // Ne prikazati edit i delete za prošla predavanja
    if (e.rowType === "data" && e.column.command === "edit"){
      let predavanjeDatum = moment(e.data.ucip_vrijeme).format();
      if (predavanjeDatum < this.danasnjiDan){
        e.cellElement.classList.add("novo");
      }
    }
  }

  predmetChangePredavanje(rowData: any, value: any){
    rowData.idpredmet = value;
  }

   odabraniPredmetChange(e){
    this.idPredavanjeSlanje = e.value;
    //console.log(this.idPredavanjeSlanje);
   }

  onPredavanjeRemoved(e){
    this._api.ucionicaPredavanjeObrisi({'iducionicapredavanje': e.data.iducionicapredavanje, "access_token": this.access_token}).subscribe(res=>{
        if(res.success == true){
          notify(res.message, "success");
          this.popisPredavanja();
        } else {
          notify(res.message, "error");
        }
    });
  }

  oznaciPrisutnost(e, data){
    // console.log(e);
    // console.log(data);
    if (data.rowType == "data" && data.column.dataField === "prisutan" && data.columnIndex == 1) {
      this._api.ucionicaPredavanjePrisutan({"idkorisnik": data.data.idkorisnik, 'iducionicapredavanje': this.IDucionicaPredavanje, "access_token": this.access_token}).subscribe(res=>{
        if(res.success == true){
          notify(res.message, "success");
          // this.polazniciPrisutnost(this.IDucionicaPredavanje);
        } else {
          notify(res.message, "error");
        }
      });
    }
  }

  //----------------- ocjena pojedinačnog odgovora na ispitu -----------------------//
  odgovorTocan(e, data){
    if(data.ispp_oblik === 0 || data.ispp_oblik === 1){
      for(let i = 0; i < this.odgovoriIspit.length; i++){
        if(this.odgovoriIspit[i].ispitpitanje_idispitpitanje === data.idispitpitanje  && this.odgovoriIspit[i].idispitrjeseno != null){
          this.tocnostOdgovora = this.odgovoriIspit[i].idispitrjeseno;
          // console.log(this.odgovoriIspit[i]);
        }
      }
    } else {
      for(let i = 0; i < this.korisnikoviOdgovori.length; i++){
        if(this.korisnikoviOdgovori[i].ispitpitanje_idispitpitanje === data.idispitpitanje){
          this.tocnostOdgovora = this.korisnikoviOdgovori[i].idispitrjeseno;
        }
      }
    }
    this._api.ispitOdgovorTocan({"access_token": this.access_token, "idispitrjeseno": this.tocnostOdgovora}).subscribe(res => {
      // console.log(res)
      if(res.success == true){
        notify(res.message, "success");
      } else {
        notify(res.message, "error");
      }
    });
  }

  ocjenaIspita = () => {
    // console.log('Ocijeni ispit');
    this.ocijeniIspitPopup = true;
  }

  ocjenaChange(e){
    // console.log(e);
    this.dodijeljenaOcjena = e.value;
  }

  ocijeni(ocjena){
    if(ocjena){
      this._api.ispitOcjeni({"access_token": this.access_token, "idispitrezultat": this.rezultatID, "ocjena": ocjena}).subscribe(res => {
        // console.log(res);
        if(res.success === true){
          notify(res.message, "success");
          this.ocijeniIspitPopup = false;
            // refresh podtablice
          this._api.ispitniRokKorisnici({"idispitrok": this.IDispitROK, "access_token": this.access_token}).subscribe(res => {
              if(res.success == true){
                this.polazniciIspitniRok = res.ispitniRokKorisnici;
              } else {
                notify(res.message, "error");
              }
          });
        } else {
          notify(res.message, "error");
        }
      });
    } else {
      notify('Potrebno je odabrati ocjenu!', 'error');
    }
  }

  //** ---------- ------------- */
  ocjenaNaPredmetu(e, idpredmet, pred_naziv){
    this.ocjenePopup = true;
    this.predmetID = idpredmet;
    this.nazivPredmeta = pred_naziv;
    this.dohvatiPolaznike();
  }
  
  ocijeniPredmet(data, e){
    this.ocjenaPredmeta = e.itemData;
      // console.log(data);
    let podaci = {
      "access_token": this.access_token,
      "idkorisnik": data.data.korisnik_idkorisnik,
      "iducionica": this.ucionicaID,
      "idpredmet": this.predmetID,
      "ocjena": this.ocjenaPredmeta
    };
    // console.log(podaci);
    this._api.predmetOcjeni(podaci).subscribe(res => {
      // console.log(res);
      if(res.success == true){
        notify(res.message, "success");
        this.dohvatiPolaznike();
      } else {
        notify(res.message, "error");
      }
    });
  }

}