import { Component, NgModule, Sanitizer } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { AuthService, AppInfoService, ApiService } from '../../services';

import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxCheckBoxModule } from 'devextreme-angular/ui/check-box';
import { DxTextBoxModule } from 'devextreme-angular/ui/text-box';
import { DxValidatorModule } from 'devextreme-angular/ui/validator';
import { DxValidationGroupModule } from 'devextreme-angular/ui/validation-group';

import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})

export class LoginFormComponent {

  login = '';
  password = '';
  poruka: string;
  passwordMode: string = 'password';

  prava = '1'; // 1 = super admin, 2 = admin, 3 = predavac, 4 = polaznik

  constructor(private authService: AuthService, 
              public appInfo: AppInfoService,
              public _api: ApiService) { }

  onLoginClick(args: any) {
 
    if (!args.validationGroup.validate().isValid) {
      return;
    }

    this._api.login({'korisnickoIme': this.login, 'lozinka': this.password}).subscribe(res=>{
      // console.log(res);
      if(res.success == true){
        // console.log(res.korisnik)
        localStorage.setItem('kor_prava', res.korisnik.kor_prava);
        localStorage.setItem('kor_email', res.korisnik.kor_email);
        localStorage.setItem('access_token', res.korisnik.access_token);
        localStorage.setItem('kor_punoIme', res.korisnik.kor_punoIme);
        localStorage.setItem('idkorisnik',  res.korisnik.idkorisnik)
        this.authService.logIn(this.login, this.password);
      } else {
         notify("Korisnik ne postoji ili nije aktivan!", "error", 600);
      }
    });

    args.validationGroup.reset();
  }

  prikaziLozinku(){
    this.passwordMode = this.passwordMode === "text" ? "password" : "text";
   }
  

}
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DxButtonModule,
    DxCheckBoxModule,
    DxTextBoxModule,
    DxValidatorModule,
    DxValidationGroupModule
  ],
  declarations: [ LoginFormComponent ],
  exports: [ LoginFormComponent ]
})
export class LoginFormModule { }
