import { Component, OnInit, ViewChild } from '@angular/core';

import { ApiService } from './../../shared/services/api.service';

import { custom } from 'devextreme/ui/dialog';
import { encodeHtml } from 'devextreme/core/utils/string';
import notify from 'devextreme/ui/notify';
import DataSource from 'devextreme/data/data_source';
import validationEngine from 'devextreme/ui/validation_engine';

@Component({
  selector: 'app-programi',
  templateUrl: './programi.component.html',
  styleUrls: ['./programi.component.scss']
})

export class ProgramiComponent implements OnInit {

  access_token: any = localStorage.getItem('access_token');

  dataSource = [];
  window: any;
  naslov: string;
  editingPopup: boolean = false;
  kProgram: boolean = false;
  sektori: DataSource;
  sektoriEdit = [];
  vrstaPrograma: DataSource;
  predmeti: any;
  treeBoxValue = [];
  idPredmeti = [];
  idSektor: number;
  idVrstaPrograma: number;
  programNaziv: string;
  predmetiOdabraniNaziviString: string;
  sektorZaEdit: any;
  vrstaProgramaZaEdit: any;
  predmetiOdabraniNaziviStringEdit: string;
  treeBoxValueEdit = [];
  idPredmetiEdit = [];
  idPredmetiEditSlanje = [];
  idProgram: number;

  constructor(
    public _api: ApiService
  ) { 
    this.onShowing = this.onShowing.bind(this); 
  }

  ngOnInit() {
    this.popisPrograma();
  }

  ngAfterContentInit(){
    this.dohvatiPopisSektora();
    this.dohvatiPopisVrstaPrograma();
    this.dohvatiPopisPredmeta();
  }

  popisPrograma(){
    this._api.programPopis({"access_token": this.access_token}).subscribe(res=>{
      if(res.success == true){
        this.dataSource = res.program;
      } else {
        notify(res.message, "error", 1000);
      }
    });
  }

  onToolbarPreparing(e) {    
      e.toolbarOptions.items.unshift(
        {
          locateInMenu:'auto',
          location: 'after',
          template: "kreiranjePrograma",
        }
      )
  }

  onShowing(e){  
    // console.log(e);
    e.component.option("title", this.naslov);
  }

  onInitNewRow(e){
    this.naslov = 'Kreiranje programa';
  }

  dohvatiPopisSektora(){
    this._api.sektorPopis({"access_token": this.access_token}).subscribe(res=>{
      if(res.success == true){
        this.sektori = new DataSource({
          store: {
              data: res.sektori,
              type: 'array',
              key: 'idsifarniksektor'
          },
          group: "Category"
        });
      } else {
        notify(res.message, "error", 1000);
      }
    });
  }

  dohvatiPopisVrstaPrograma(){
    this._api.vrstaProgramaPopis({"access_token": this.access_token}).subscribe(res=>{
      if(res.success == true){
        this.vrstaPrograma = new DataSource({
          store: {
              data: res.vrstaPrograma,
              type: 'array',
              key: 'idsifarniksektor'
          },
          group: "Category"
        });
      } else {
        notify(res.message, "error", 1000);
      }
    });
  }

  dohvatiPopisPredmeta(){
    let podaciZaSlanje = {
      "access_token": this.access_token
    };
    this._api.predmetPopis(podaciZaSlanje).subscribe(res=>{
      if(res.success == true){
        this.predmeti = new DataSource({
          store: {
              data: res.predmeti,
              type: 'array',
              key: 'idpredmet'
          }
        });
      } else {
         notify(res.message, "error", 1000);
      }
    });
  }

  // KREIRANJE PROGRAMA
  prikazFormeZaKreiranjePrograma(e){
    this.kProgram = true;
    // console.log(e);
  }

  kreirajProgram(programNaziv){
    // console.log('kreirajProgram');
    let podaciZaKreiranjePrograma = {
      "idsektor": this.idSektor,
      "idvrstaprograma": this.idVrstaPrograma,
      "naziv": programNaziv,
      "predmeti": this.idPredmeti,
      "access_token": this.access_token
    };
    // console.log(podaciZaSlanje);
    this._api.programDodaj(podaciZaKreiranjePrograma).subscribe(res=>{
      // console.log(res);
      if(res.success == true){
        notify(res.message, "success", 1000);
        this.popisPrograma();
        this.kProgram = false;
      } else {
         notify(res.message, "error", 1000);
      }
    });
  }

  form_fieldDataChangedKreiraj($event){
    // console.log($event);
    this.programNaziv = $event.value;
  }

  onSektorSelected($event){
    // console.log($event);
    this.idSektor = $event.value;
  }

  onVrstaProgramaSelected($event){
    this.idVrstaPrograma = $event.value;
    // console.log($event);
  }

  onPredmetSelected($event){
    // console.log($event);
    // NazivPredmeta
    if($event.removedItems.length != 0){
      let index = this.treeBoxValue.indexOf($event.removedItems[0].pred_naziv);
      if (index > -1) {
        this.treeBoxValue.splice(index, 1);
      } 
    } 
    if ($event.addedItems.length != 0){
      this.treeBoxValue.push($event.addedItems[0].pred_naziv);
    }
    this.predmetiOdabraniNaziviString = JSON.stringify(this.treeBoxValue).replace(/]|[[]/g, '').replace(/"/g,' ');
    //IdPredmeta
    if($event.removedItems.length != 0){
      let index = this.idPredmeti.indexOf($event.removedItems[0].idpredmet);
      if (index > -1) {
        this.idPredmeti.splice(index, 1);
      } 
    } 
    if ($event.addedItems.length != 0){
      this.idPredmeti.push($event.addedItems[0].idpredmet);
    }
  }

  popupHiddenKreiranje(e: any){
    // console.log(e);
    validationEngine.resetGroup('formaKreiranjePrograma');
    this.idPredmeti = [];
    this.treeBoxValue = [];
    this.predmetiOdabraniNaziviString = JSON.stringify(this.treeBoxValue).replace(/]|[[]/g, '').replace(/"/g,' ');
  }

  //UREĐIVANJE PROGRAMA
  showEditPopup(e){
    // console.log('showEditPopup');
    // console.log(e.data);
    this.editingPopup = true;
    this.sektorZaEdit = e.data.idsifarniksektor;
    this.vrstaProgramaZaEdit = e.data.idsifarnikvrstaprogram;
    this.idProgram = e.data.idprogram;
    this.programNaziv = e.data.programNaziv;
    this.predmetiJednogPrograma(e.data.idprogram);
  }

  predmetiJednogPrograma(idprogram){
    // console.log('jedanProgram');
    this._api.programJedan({"access_token": this.access_token, "idprogram": idprogram}).subscribe(res=>{
    // console.log(res);
     if(res.success == true){
      this.idPredmetiEdit = [];
      this.treeBoxValueEdit = [];
      this.predmetiOdabraniNaziviStringEdit = '';
       for(let i = 0; i < res.predmeti.length; i++){
         if(res.predmeti[i].uProgramu == 1){
          // console.log(res.predmeti[i]);
           this.idPredmetiEdit.push(res.predmeti[i].idpredmet);
           this.treeBoxValueEdit.push(res.predmeti[i].pred_naziv);
           this.predmetiOdabraniNaziviStringEdit = JSON.stringify(this.treeBoxValueEdit).replace(/]|[[]/g, '').replace(/"/g,' ');
         }
       }
     } else {
       notify(res.message, "error", 1000);
     }
   });
 }

  form_fieldDataChangedUredi($event){
    // console.log($event);
    this.programNaziv = $event.value;
  }

  onPredmetSelectedEdit($event){
    // console.log('onPredmetSelectedEdit');
    // console.log($event);
    // NazivPredmeta
    if($event.itemElement.ariaSelected === "false"){
      let index = this.treeBoxValueEdit.indexOf($event.itemData.pred_naziv);
      if (index > -1) {
        this.treeBoxValueEdit.splice(index, 1);
      } 
    } 
    if ($event.itemElement.ariaSelected === "true"){
      this.treeBoxValueEdit.push($event.itemData.pred_naziv);
    }
    this.predmetiOdabraniNaziviStringEdit = JSON.stringify(this.treeBoxValueEdit).replace(/]|[[]/g, '').replace(/"/g,' ');
    //IdPredmeta - prikaz odabranih
    if($event.itemElement.ariaSelected === "false"){
      let index = this.idPredmetiEdit.indexOf($event.itemData.idpredmet);
      if (index > -1) {
        this.idPredmetiEdit.splice(index, 1);
      } 
    } 
    if ($event.itemElement.ariaSelected === "true"){
      this.idPredmetiEdit.push($event.itemData.idpredmet);
    }
    //IdPredmeta - za slanje na API
    if ($event.itemElement.ariaSelected === "true"){
      let index = this.idPredmetiEditSlanje.indexOf($event.itemData.idpredmet);
      if (index > -1) {
        this.idPredmetiEditSlanje.splice(index, 1);
      } else {
        this.idPredmetiEditSlanje.push($event.itemData.idpredmet);
      }
    }
    if ($event.itemElement.ariaSelected === "false"){
      let index = this.idPredmetiEditSlanje.indexOf($event.itemData.idpredmet);
      if (index > -1) {
        this.idPredmetiEditSlanje.splice(index, 1);
      } else {
        this.idPredmetiEditSlanje.push($event.itemData.idpredmet);
      }
    }
    // console.log(this.idPredmetiEditSlanje);
  }

  urediProgram(programNaziv){
    let podaciZaEditPrograma = {
      "idprogram": this.idProgram,
      "idsektor": this.idSektor,
      "idvrstaprograma": this.idVrstaPrograma,
      "naziv": programNaziv,
      "predmeti": this.idPredmetiEditSlanje,
      "access_token": this.access_token
    };
    // console.log(podaciZaEditPrograma);
    this._api.programUredi(podaciZaEditPrograma).subscribe(res=>{
      // console.log(res);
      if(res.success == true){
        notify(res.message, "success", 1000);
        this.popisPrograma();
        this.idPredmetiEditSlanje = [];
        this.editingPopup = false;
      } else {
        notify(res.message, "error", 1000);
      }
    });
  }

  popupHiddenEdit(e: any){
    // console.log(e);
    validationEngine.resetGroup('formaEditPrograma');
    this.sektorZaEdit = null;
    this.vrstaProgramaZaEdit = null;
  }

  //BRISANJE PROGRAMA

  brisanjePrograma(e){
    let idProgramDELETE = e.data.idprogram;
    let encodedMessage = encodeHtml("Jeste li sigurni da želite obrisati program?");
      let myDialog = custom({
          title: "Brisanje programa",
          messageHtml: encodedMessage,
          buttons: [{
              text: "OBRIŠI",
              onClick: (e) => {
                  return { buttonText: e.component.option("text") }
              }
          },{
            text: "ODUSTANI",
            onClick: (e) => {
                return { buttonText: e.component.option("text") }
            }
        }]
      });
      myDialog.show().then((dialogResult) => {
          if(dialogResult.buttonText === 'OBRIŠI'){
            this._api.programObrisi({"idprogram":idProgramDELETE, "access_token": this.access_token}).subscribe(res=>{
              if(res.success == true){
                notify(res.message, "success", 1000);
                this.popisPrograma();
              } else {
                notify(res.message, "error", 1000);
              }
            });      
          }else{}  
      });
  }

}