<h2 class="content-block">Korisnici</h2>

<div class="content-block dx-card responsive-paddings">
    <div id="tabs">
        <dx-tabs
            #apiTabs
            [dataSource]="tabs"
            [selectedIndex]="0"
            (onItemClick)="selectTab($event)"
        ></dx-tabs>
    </div>

    <!-- Tablice -->
    <dx-data-grid
    class="wide-card"
    [rowAlternationEnabled]="false"
    [showRowLines]="false"
    [showColumnLines]="false"
    [showBorders]="false"
    [hoverStateEnabled]="true"
    [wordWrapEnabled]="true"
    [dataSource]="dataSource"
    [columnAutoWidth]="true"
    [columnHidingEnabled]="true"
    (onCellHoverChanged)="cellHover($event)"
    (onInitNewRow)="onInitNewRow($event)"
    (onRowInserted)="onRowInserted($event)"
    (onEditingStart)="onEditingStart($event)"
    (onRowUpdated)="onRowUpdated($event)"
    (onCellClick)="prikazProfila($event);"
    >

    <dxo-paging [pageSize]="10"></dxo-paging>
    <dxo-pager [showPageSizeSelector]="true" [showInfo]="true"></dxo-pager>
    <!-- <dxo-filter-row [visible]="true"></dxo-filter-row> -->
    <dxo-search-panel [visible]="true"></dxo-search-panel>

    <dxo-editing
    mode="popup"
    [allowUpdating]="allowUpdating"
    [allowDeleting]="false"
    [allowAdding]="allowAdding">

    <dxo-popup
    [closeOnOutsideClick]="true"
    [onShowing]="onShowing"
    title=""
    [showTitle]="true"
    [width]="widthPopup"
    [maxHeight]="maxHeight"
    [position]="{ my: 'center', at: 'center', of: window }">
    </dxo-popup>

    <dxo-form id="form" [colCount]="1"
        [showColonAfterLabel]="true">
        <dxi-item itemType="group"> <!-- caption="Opće informacije" -->
            <dxi-item
                dataField="kor_punoIme"
                [editorOptions]="{stylingMode: 'filled'}">
                <dxi-validation-rule
                    type="required"
                    message="Ime i prezime je obavezno polje!">
                </dxi-validation-rule>
            </dxi-item>
            <!-- <dxi-item dataField="kor_korisnickoIme" [editorOptions]="{readOnly: true}" *ngIf="hideCheckbox"></dxi-item> -->
            <dxi-item
                dataField="kor_oib"
                [editorOptions]="{stylingMode: 'filled'}"> <!-- *ngIf="odabraniTab == 3" -->
                <dxi-validation-rule
                    type="required"
                    message="OIB je obavezno polje!"
                    *ngIf="odabraniTab == 3">
                </dxi-validation-rule>
                <dxi-validation-rule
                    type="pattern"
                    pattern="[0-9]+"
                    message="OIB treba biti u brojčanom obliku!">
                </dxi-validation-rule>
                <dxi-validation-rule type="custom" [validationCallback]="validateRange"></dxi-validation-rule>
                <!-- <dxi-validation-rule
                    type="stringLength"
                    [min]="11"
                    [max]="11"
                    message="OIB treba imati točno 11 znakova!"
                    *ngIf="odabraniTab == 3">
                </dxi-validation-rule> -->
                <!-- <dxi-validation-rule 
                    type="async" 
                    [validationCallback]="provjeraOIB" 
                    [reevaluate]="true"
                    message="Navedeni OIB se već koristi!"
                    *ngIf="!editing">
                </dxi-validation-rule> -->
            </dxi-item>
            <dxi-item
                dataField="kor_email"
                [editorOptions]="{stylingMode: 'filled'}">
                <dxi-validation-rule
                    type="required"
                    message="E-mail je obavezno polje!">
                </dxi-validation-rule>
                <dxi-validation-rule
                    type="email"
                    message="Neispravan format e-maila!">
                </dxi-validation-rule>
                <dxi-validation-rule
                    type="async"
                    [validationCallback]="provjeraEMAIL"
                    [reevaluate]="true"
                    message="Navedena e-mail adresa se već koristi!"
                    *ngIf="!editing">
                </dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="kor_telefon" [editorOptions]="{stylingMode: 'filled'}"></dxi-item> <!-- *ngIf="odabraniTab == 1" -->
            <dxi-item dataField="kor_kvalifikacije" [editorOptions]="{stylingMode: 'filled'}" *ngIf="odabraniTab == 2"></dxi-item>
            <dxi-item dataField="kor_napomena" [editorOptions]="{stylingMode: 'filled'}" editorType="dxTextArea"></dxi-item>
        </dxi-item>
    </dxo-form>

    </dxo-editing>

    <dxi-column dataField="idkorisnik" caption="ID" [visible]="false" [width]="70" [allowFiltering]="false" alignment="center" [formItem]="{ visible: false }"></dxi-column>
    <dxi-column dataField="kor_punoIme" caption="Ime i prezime" [allowSorting]="false"></dxi-column>
    <dxi-column dataField="kor_oib" caption="OIB" [allowSorting]="false"></dxi-column> <!-- *ngIf="odabraniTab == 3" -->
    <dxi-column dataField="kor_korisnickoIme" caption="Korisničko ime" [allowSorting]="false"></dxi-column>
    <dxi-column dataField="kor_email" caption="E-mail" [allowSorting]="false" cellTemplate="mailTemplate">
        <div *dxTemplate="let data of 'mailTemplate'">
            <a href="mailto:{{data.value}}">{{data.value}}</a>
        </div>
    </dxi-column>
    <dxi-column dataField="kor_telefon" caption="Telefon" [allowSorting]="false"></dxi-column> <!--  *ngIf="odabraniTab == 1" -->
    <dxi-column dataField="kor_napomena" caption="Napomena" [visible]="false"></dxi-column>
    <dxi-column dataField="kor_kvalifikacije" caption="Kvalifikacija" [visible]="false"></dxi-column>
    <dxi-column dataField="kor_aktivan" caption="Aktivan" [width]="100" [allowSorting]="true" [formItem]="{ visible: false }" cellTemplate="cellTemplate" alignment="center"> 
        <div *dxTemplate="let data of 'cellTemplate'">
            <dx-switch [value]="data.value" [disabled]="!allowAdding"></dx-switch> <!-- prava != '0' || (idkorisnik == korisnikID)? false:true -->
        </div>
    </dxi-column>
    </dx-data-grid>

    <!-- Popup s detaljima korisnika -->
    <dx-popup
        title="Detalji profila"
        [(visible)]="prikaziDetaljeProfila"
        [width]="widthPopup"
        [maxHeight]="maxHeight"
        [position]="{ my: 'center', at: 'center', of: window }">
            <dx-form
                    [colCount]="1"
                    [formData]="popupPodaci">
                    <dxi-item itemType="group"> <!-- caption="Opće informacije" -->
                        <dxi-item dataField="kor_punoIme" [editorOptions]="{readOnly: true, stylingMode: 'filled'}"></dxi-item>
                        <dxi-item dataField="kor_oib" [editorOptions]="{readOnly: true, stylingMode: 'filled'}"></dxi-item> <!-- *ngIf="odabraniTab == 3" -->
                        <dxi-item dataField="kor_email" [editorOptions]="{readOnly: true, stylingMode: 'filled'}"></dxi-item> 
                        <dxi-item dataField="kor_telefon" [editorOptions]="{readOnly: true, stylingMode: 'filled'}"></dxi-item> <!-- *ngIf="odabraniTab == 1" -->
                        <dxi-item dataField="kor_kvalifikacije" *ngIf="odabraniTab == 2" [editorOptions]="{readOnly: true, stylingMode: 'filled'}"></dxi-item>
                        <dxi-item dataField="kor_napomena" editorType="dxTextArea" [editorOptions]="{readOnly: true, stylingMode: 'filled'}"></dxi-item>
                        <dxi-item dataField="kor_korisnickoIme" [editorOptions]="{readOnly: true, stylingMode: 'filled'}"></dxi-item>
                    </dxi-item>
            </dx-form>
    </dx-popup>

</div>